import { useContext, useEffect, useState, useRef } from 'react';
import Icon from '../Icon';
import { LocalMediaContext } from '../../providers/LocalMediaContext';
import { UserSettingsContext } from '../../providers/UserSettingsContext';
import clsx from 'clsx';
import { BroadcastLayoutContext } from '../../providers/BroadcastLayoutContext';
import { HandRaisedIcon } from '@heroicons/react/24/outline';
import Ably from 'ably';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useChannel } from 'ably/react'

export default function StreamPreview({ previewRef }) {
  const { permissions } = useContext(LocalMediaContext);
  const { screenShareActive, camActive } = useContext(BroadcastLayoutContext);
  const { localVideoMirror } = useContext(UserSettingsContext);

  const [mounted, setMounted] = useState(false);
  const [subtitle, setSubtitle] = useState('');
  const [timer, setTimer] = useState(null);

  const { streamKey, ingestEndpoint } = useContext(UserSettingsContext);
  const { channel } = useChannel('scribe', streamKey, (sub) => {
      setSubtitle(sub.data.text)

      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(() => {
        setSubtitle(null);
      }, 2000);

      setTimer(newTimer);
  });

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const shouldMirrorPreview =
    camActive && !screenShareActive && localVideoMirror && mounted;

  const mirrorClass = clsx('w-full h-full overflow-hidden relative', {
    'transform -scale-x-100': shouldMirrorPreview,
  });

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, [mounted]);

  return (
    <>
      <div className='flex items-center w-full h-full' style={{
          flexGrow: 1,
          padding: '1rem',
          height:'70vh',
          flexShrink: 1,
      }}>
        <div className='w-full h-full flex items-center rounded-md bg-surfaceAlt ring-1 ring-inset ring-black/5 dark-theme:ring-white/5 overflow-hidden'>
          <div className='w-full h-full overflow-hidden relative'>
            <div className={mirrorClass} style={{position: 'relative'}}>
              <Icon size={'lg'} style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  cursor: 'pointer'
                }}
                onClick={() => {
                    window.location.href = process.env.REACT_APP_BACKEND_URL.replace(
                        '/api',
                        '/projects',
                    );
                }}
                >
                <ChevronLeftIcon className='text-white' />
              </Icon>
              <canvas
                style={{
                  inset:0,
                  objectFit:'contain'
                }}
                key='STREAM_PREVIEW_VIDEO'
                id='cam-video-preview'
                className=' w-full h-full'
                ref={previewRef}
              ></canvas>
              {subtitle && (
                <div 
                  style={{
                          bottom: '10px',
                          transform: 'translateX(-50%)',
                          left: '50%',
                          position: 'absolute',
                          color: 'rgb(255, 255, 255)', 
                          direction: 'ltr',
                          font: '17.1754px Arial', 
                          width: 'auto',
                          padding: '5px', 
                          backgroundColor: 'rgba(0, 0, 0, 0.8)', 
                          borderColor: 'rgba(0, 0, 0, 0.8)'
                        }}>
                    { subtitle }
                </div>
              )}
            </div>
            {!permissions && (
              <div className='absolute inset-0 w-full h-full bg-surfaceAlt flex items-center justify-center'>
                <div className='max-w-xs flex flex-col gap-4 items-center justify-center text-center'>
                  <Icon size={'lg'}>
                    <HandRaisedIcon className='w-full text-uiText' />
                  </Icon>
                  <span className='text-uiText/75'>
                    To start streaming, allow access to your camera and
                    microphone
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
