import React from 'react';
import ReactDOM from 'react-dom';
import Flickity from 'flickity';
import 'flickity/dist/flickity.min.css';

export default class PricingSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            flickityReady: false,
        };

        this.refreshFlickity = this.refreshFlickity.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
    }

    componentDidMount() {
        this.flickity = new Flickity(this.flickityNode, this.props.options || {});

        this.setState({
            flickityReady: true,
        });
    }

    refreshFlickity() {
        if (!this.flickity) return
        this.flickity.reloadCells();
        this.flickity.resize();
        this.flickity.updateDraggable();
    }

    componentWillUnmount() {
        // if (this.flickity) this.flickity.destroy();
    }

    componentDidUpdate(prevProps, prevState) {
        const flickityDidBecomeActive = !prevState.flickityReady && this.state.flickityReady;
        const childrenDidChange = prevProps.children.length !== this.props.children.length;

        if (flickityDidBecomeActive || childrenDidChange) {
            this.refreshFlickity();
        }
    }

    renderPortal() {
        if (!this.flickityNode) {
            return null;
        }

        const mountNode = this.flickityNode.querySelector('.flickity-slider');

        if (mountNode) {
            return ReactDOM.createPortal(this.props.children, mountNode);
        }
    }

    nextSlide () {
        this.flickity.next()
    }

    prevSlide () {
        this.flickity.previous()
    }

    render() {
        return [
            <>
                <div className="pricing-slider" key="flickityBase" ref={node => (this.flickityNode = node)} />
                <div id="div_block-1191-709" className="ct-div-block carousel-slider__controls">
                    <button id="code_block-1192-709" className="ct-code-block prev carousel-slider__btn slider-btn-white" onClick={this.prevSlide}>
                        <svg xmlns="http://www.w3.org/2000/svg" color="#0c1352" width="100%" height="100%" fill="none" viewBox="0 0 32 26">
                            <path stroke="currentColor" strokeWidth="1.5" d="M14 1 2 13l12 12M2.598 13h28.8"></path>
                        </svg>
                    </button>
                    <button id="code_block-1193-709" className="ct-code-block carousel-slider__btn next slider-btn-white" onClick={this.nextSlide}>
                        <svg xmlns="http://www.w3.org/2000/svg" color="#0c1352" width="100%" height="100%" fill="none" viewBox="0 0 61 26">
                            <path stroke="currentColor" strokeWidth="1.5" d="m47.602 1 12 12-12 12M59 13H.203"></path>
                        </svg>
                    </button>
                </div>
            </>
            ,
            this.renderPortal(),
        ].filter(Boolean);
    }
}
