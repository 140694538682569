import { Skeleton } from '@mui/material';
import { useActions } from '../../overmind';

const OrderSummary = (props) => {
    const actions = useActions();
    return (
        <>
            <div className="order-summary-lexigo">
                <div className="flex items-center p-5">
                    <h1
                        className="text-1-5rem font-bold font-euclid-sb text-lexigo-1 nowrap font-grostesk"
                        style={{
                            fontSize: '1.5rem',
                            margin: 0,
                        }}
                    >
                        Order Summary
                    </h1>
                </div>

                <div className="p-3 border-t border-gray-100">
                    <div className="col-span-12 lg:col-span-8">
                        <div className="flex items-center">
                            <select
                                name="currency"
                                className="form-select w-full currency select-light"
                                onChange={(e) => {
                                    props.setCurrency(e.target.value);
                                }}
                                value={props.currency}
                            >
                                <option value="aud">AUD</option>
                                <option value="usd">USD</option>
                                <option value="sgd">SGD</option>
                                <option value="nzd">NZD</option>
                                <option value="cad">CAD</option>
                                <option value="gbp">GBP</option>
                                <option value="eur">EUR</option>
                            </select>
                        </div>
                    </div>

                    <div className="" id="sidedesc">
                        <div className="flex items-center border-t border-gray-100 mt-5">
                            <div className="mr-auto mt-5" id="fromlanguagedesc">
                                <span>
                                    From{' '}
                                    <span className="font-bold">
                                        {' '}
                                        {props.projectData
                                            ? props.projectData.file.language.language +
                                              ' ' +
                                              (props.projectData.file.language.lexigo_code ||
                                                  props.projectData.file.language.language_code)
                                            : ''}{' '}
                                    </span>{' '}
                                    to:
                                </span>
                            </div>
                            <div className="mt-5" id="targelanguagedesc">
                                {props.selectedLanguagesObj ? props.selectedLanguagesObj.length : 0} language/s
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center flex-wrap mt-3 mb-3" id="targetlangdesc">
                        {props.selectedLanguagesObj &&
                            props.selectedLanguagesObj.map((l, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="border border-lexigo-1 px-2 py-1 rounded-3px mr-2 mb-2 targetlangdesc"
                                    >
                                        {l.language} {l.lexigo_code}
                                    </div>
                                );
                            })}
                    </div>

                    <div className="flex items-center">
                        <div className="text-xs font-bold mr-auto">No. of files</div>
                        <div id="numberofFilesdesc ">1</div>
                    </div>
                    <div className="border-b border-gray-100 my-3"></div>
                    <div className="flex items-center mt-3">
                        <div className="text-xs font-bold">Total word count</div>
                        <div className="ct-div-block price-row__title-tooltip mr-auto">
                            <div className="ct-fancy-icon info-icon">
                                <svg id="svg-fancy_icon-181-672-1-1" data-id="svg-fancy_icon-181-672">
                                    <use xlinkHref="#Lexigoquestion"></use>
                                </svg>
                            </div>
                            <div id="div_block-182-672-1-1" className="ct-div-block price-row__tooltip-container">
                                <p
                                    id="text_block-183-672-1-1"
                                    className="ct-text-block price-row__tooltip-text"
                                    data-id="text_block-183-672"
                                >
                                    <span id="span-184-672-1-1" className="ct-span">
                                        The total word count displayed is the total words of all source file/s
                                        multiplied by the number of target languages.
                                    </span>
                                </p>
                                <div
                                    id="div_block-185-672-1-1"
                                    className="ct-div-block lines-container"
                                    data-id="div_block-185-672"
                                >
                                    <div
                                        id="code_block-186-672-1-1"
                                        className="ct-code-block lines"
                                        data-id="code_block-186-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#lines-1"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="" id="wordCountdesc">
                            {props.wordCount}
                        </div>
                    </div>

                    <div className="border-b border-gray-100 my-3"></div>
                    <div className="">
                        <div className="flex items-center">
                            {props.pricingLoading ? (
                                <Skeleton
                                    height={20}
                                    width={60}
                                    className="mr-auto"
                                    style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                />
                            ) : (
                                <div className="text-xs font-bold mr-auto">Sub-total</div>
                            )}
                            {props.pricingLoading ? (
                                <Skeleton height={20} width={50} style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                            ) : (
                                <div id="subtotal">
                                    <props.CurrencySymbol />
                                    {props.getSubtotal()}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="border-b border-gray-100 my-3"></div>
                    <div className="">
                        <div className="flex items-center">
                            {props.pricingLoading ? (
                                <Skeleton
                                    height={20}
                                    width={60}
                                    className="mr-auto"
                                    style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                />
                            ) : (
                                <>
                                    <div className="text-xs font-bold" id="subtotaltaxtext">
                                        {props.tax > 0 ? 'Tax (AU GST)' : 'Tax'}
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip mr-auto">
                                        <div className="ct-fancy-icon info-icon">
                                            <svg id="svg-fancy_icon-181-672-1-1" data-id="svg-fancy_icon-181-672">
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                <span id="span-184-672-1-1" className="ct-span">
                                                    Any applicable taxes such as GST or VAT are calculated here.
                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {props.pricingLoading ? (
                                <Skeleton height={20} width={50} style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                            ) : (
                                <div id="subtotaltax">
                                    {props.tax ? (
                                        <span>
                                            <props.CurrencySymbol />
                                            {props.tax}
                                        </span>
                                    ) : (
                                        <span>
                                            <props.CurrencySymbol />0
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="border-b border-gray-100 mt-5"></div>
                    {props.pricingLoading ? (
                        <Skeleton height={76} width="100%" style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                    ) : (
                        <div className="">
                            <div className="flex items-center p-5 bg-lexigo-gray rounded-3px">
                                <div className="mr-auto font-bold">Total</div>
                                <div className="font-bold text-3xl" id="total">
                                    <props.CurrencySymbol />
                                    {props.getTotal()}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-3 mb-5">
                <img src="dist/images/stripe-badge-white.png" alt="" className="w-full" />
            </div>
        </>
    );
};

export default OrderSummary;
