import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/payment';

export const handlePaymentRequest = async (payload, authorization) => {
    return axios
        .post(BACKEND_URL, payload, {
            headers: {
                Authorization: authorization,
                'Access-Control-Allow-Origin': '*',
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const fetchPaymentMethods = async (authorization) => {
    return axios
        .get(BACKEND_URL + '/method', {
            headers: {
                Authorization: authorization,
            },
        })
        .then((res) => {
            return res.data;
        });
};
