/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { ChevronDown, Send } from 'react-feather';
import { fetchLexigoLanguage } from '../requests/LanguageRequest';
import { createTranslation } from '../requests/TranslateRequest';
import useComponentVisible from './useComponentVisible';

const TranslationDropdown = ({
    config,
    setTranslateModal,
    setIsTranslation,
    id,
    translatedLanguage,
    fetchTranslated,
    setPlaying,
    setTranslateState,
    isTranslation,
    translateState,
    setCurrentLanguage,
    setTranslationSingleSelected,
    setCurrentLanguageObject,
    languageText,
    setLanguageText,
    translationSingleSelected,
    setTranslationSingleDropdown,
    setIsTranslateChange,
    projectData,
    user,
    access,
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const openTranslationPage = async () => {
        await Promise.all(
            selectedLanguages.map(async (language) => {
                await createTranslation(id, language);
            }),
        );
        await fetchTranslated(id, user.signature);
        if (
            !config.hideTranslateModal &&
            access &&
            (access.accessMapping.role.toLowerCase() === 'owner' ||
                access.accessMapping.role.toLowerCase() === 'editor')
        ) {
            setTranslateModal(true);
        } else {
            setTranslateState(true);
            setPlaying(false);
            setIsTranslation(true);
        }
    };

    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const fetchSelectedTranslation = async (selectedId) => {
        if (translatedLanguage) {
            setCurrentLanguage(selectedId);
            setCurrentLanguageObject(await fetchLexigoLanguage(selectedId));
            setTranslationSingleSelected(2);
            if (translatedLanguage.filter((e) => e.language_id == selectedId).length > 0) {
                setLanguageText(translatedLanguage.filter((e) => e.language_id == selectedId)[0].language.language);
            }
            setTranslationSingleSelected(selectedId);
        }
    };

    const handleLanguageClick = async (selectedId) => {
        if (selectedId == translationSingleSelected && translateState) {
            return;
        }
        setIsTranslateChange(true);
        fetchSelectedTranslation(selectedId);
        setTranslationSingleDropdown(false);
        setTranslateState(true);
        setIsComponentVisible(false);
    };

    const changeToDefaultLanguage = () => {
        if (translateState) {
            setTranslateState(false);
            setIsComponentVisible(!isComponentVisible);
        }
    };

    return (
        <div
            className={`w-full translation-dropdown-mobile ${
                access &&
                access.accessMapping.role.toLowerCase() !== 'owner' &&
                access.accessMapping.role.toLowerCase() !== 'editor'
                    ? 'translation-dropdown-editor'
                    : 'translation-dropdown'
            }`}
            style={{
                background: 'inherit',
                position: 'fixed',
                display: 'flex',
                zIndex: 999,
                paddingBottom: '2rem',
            }}
        >
            <div
                className="items-center inline-block w-45 mt-3 ml-3 mobile-title-view "
                style={{
                    marginLeft: '0',
                }}
            >
                View/Edit Subtitles in
            </div>

            <div
                className="custom-dropdown inline-block w-45 mt-1 ml-5 mobile-dropdown-custom"
                style={{
                    marginLeft: '35px',
                }}
                ref={ref}
            >
                <button
                    className={`dropdown-toggle space-between btn bg-white text-theme-lexigo rounded-3px w-full
                ${!isTranslation ? 'w-full' : ''}`}
                    aria-expanded="false"
                    onClick={() => {
                        setIsComponentVisible(!isComponentVisible);
                    }}
                >
                    <div
                        style={{
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            height: '15px',
                        }}
                    >
                        {!translateState ? projectData && projectData.file.language.language : languageText}{' '}
                    </div>
                    <ChevronDown className="w-4 h-4 ml-2" />
                </button>

                <div
                    className={`custom-dropdown-menu dropdown-menu-translation-tab w-56 ${
                        isComponentVisible ? 'show' : ''
                    }`}
                    style={{}}
                >
                    <div className="custom-dropdown-menu__content bg-control-dark rounded-3px">
                        <div className="container-language py-4 p-5">
                            <div className="form-check mt-2 pl-1" onClick={changeToDefaultLanguage}>
                                <input
                                    id="lang-1"
                                    className="form-check-input"
                                    type="checkbox"
                                    value="1"
                                    checked={translateState ? false : true}
                                    onChange={() => {}}
                                    onClick={changeToDefaultLanguage}
                                />
                                <label onClick={changeToDefaultLanguage} className="form-check-label">
                                    {projectData && projectData.file.language.language}
                                </label>
                            </div>

                            {translatedLanguage &&
                                translatedLanguage.map((data) => {
                                    return (
                                        <div
                                            key={'data-' + data.id}
                                            className="form-check mt-2 pl-1"
                                            onClick={() => handleLanguageClick(data.language.id)}
                                        >
                                            <input
                                                id={`checkbox-tr-${data.language.id}`}
                                                className="form-check-input"
                                                type="checkbox"
                                                value={data.language.id}
                                                checked={
                                                    translateState && translationSingleSelected == data.language.id
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {}}
                                                onClick={() => handleLanguageClick(data.language.id)}
                                            />
                                            <label
                                                className="form-check-label"
                                                onClick={() => handleLanguageClick(data.language.id)}
                                            >
                                                {data.language.language}
                                            </label>
                                            {access && access.accessMapping.role.toLowerCase() === 'viewer' ? (
                                                ''
                                            ) : (
                                                <Send
                                                    className="ml-auto"
                                                    color="#ffffff"
                                                    style={{
                                                        width: '0.65rem',
                                                        height: '0.65rem',
                                                        strokeWidth: 1,
                                                    }}
                                                    onClick={() => {
                                                        setIsComponentVisible(false);
                                                        handleLanguageClick(data.language.id);
                                                        openTranslationPage();
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TranslationDropdown;
