/* eslint-disable react-hooks/exhaustive-deps */
import Tooltip from '@mui/material/Tooltip';
import { json } from 'overmind';
import { useActions, useAppState } from '../../overmind';
import SpeakerIcon from '../Icon/SpeakerIcon';
import VolumeIcon from '../Icon/VolumeIcon.js';
import VolumeMuteIcon from '../Icon/VolumeMuteIcon';
import useComponentVisible from '../useComponentVisible';

const VolumeSetting = ({
    commitOriginalMusicVolume,
    commitVoiceOverVolume,
    commitVolume,
    player,
    commitOriginalVocalVolume,
}) => {
    const state = useAppState();
    const actions = useActions();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    return (
        <div className={`volume-setting ml-3 inline-block`} ref={ref}>
            <button
                className="dropdown-toggle btn box-shadow-none border-none text-white btn-bg-control py-2 rounded-3px"
                // onClick={muteOrUnMute}
                onClick={async () => {
                    setIsComponentVisible(!isComponentVisible);
                }}
            >
                <SpeakerIcon className="w-5 h-5" />
            </button>
            <div className={`volume-setting-content text-white  ${isComponentVisible ? 'block' : ''}`}>
                {state.voiceOverAudio !== null ? (
                    <>
                        <div
                            className="pointer border-b border-dark flex items-center text-content volume-setting-text"
                            style={{ height: '50px' }}
                            onClick={() => {
                                const muted = json(state.originalMusicVolume) <= 0;
                                if (state.originalMusic) {
                                    state.originalMusic.muted = !muted;
                                    actions.setMutedOriginalMusic(!muted);
                                    commitOriginalMusicVolume();
                                }
                            }}
                        >
                            {json(state.originalMusicVolume) <= 0 ? (
                                <Tooltip title="Unmute" placement="right" arrow>
                                    <span>
                                        <VolumeMuteIcon className="mr-2 pointer w-6 h-6" />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Mute" placement="right" arrow>
                                    <span>
                                        <VolumeIcon className="mr-2 pointer w-6 h-6" />
                                    </span>
                                </Tooltip>
                            )}
                            Original Music
                        </div>
                        <div
                            className="pointer border-b border-dark flex items-center text-content volume-setting-text"
                            style={{ height: '50px' }}
                            onClick={() => {
                                const muted = json(state.originalVocalVolume) <= 0;
                                if (state.originalVocal) {
                                    state.originalVocal.muted = !muted;
                                    actions.setMutedOriginalVocal(!muted);
                                    commitOriginalVocalVolume();
                                }
                            }}
                        >
                            {json(state.originalVocalVolume) <= 0 ? (
                                <Tooltip title="Unmute" placement="right" arrow>
                                    <span>
                                        <VolumeMuteIcon className="mr-2 pointer w-6 h-6" />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Mute" placement="right" arrow>
                                    <span>
                                        <VolumeIcon className="mr-2 pointer w-6 h-6" />
                                    </span>
                                </Tooltip>
                            )}
                            Original Vocal
                        </div>
                        <div
                            className="pointer border-dark flex items-center text-content volume-setting-text"
                            style={{ height: '50px' }}
                            onClick={() => {
                                const muted = json(state.voiceOverVolume) <= 0;
                                if (state.voiceOverAudio) {
                                    state.voiceOverAudio.muted = !muted;
                                    actions.setMutedVoiceOver(!muted);
                                    commitVoiceOverVolume();
                                }
                            }}
                        >
                            {json(state.voiceOverVolume) <= 0 ? (
                                <Tooltip title="Unmute" placement="right" arrow>
                                    <span>
                                        <VolumeMuteIcon className="mr-2 pointer w-6 h-6" />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Mute" placement="right" arrow>
                                    <span>
                                        <VolumeIcon className="mr-2 pointer w-6 h-6" />
                                    </span>
                                </Tooltip>
                            )}
                            Voice Over
                        </div>
                    </>
                ) : (
                    <div
                        className="pointer border-dark flex items-center text-content volume-setting-text"
                        x
                        onClick={() => {
                            const muted = json(state.volume) <= 0;
                            if (player) {
                                player.muted = !muted;
                                actions.setMutedPlayer(!muted);
                                commitVolume();
                            }
                        }}
                    >
                        {json(state.volume) <= 0 ? (
                            <Tooltip title="Unmute" placement="right" arrow>
                                <span>
                                    <VolumeMuteIcon className="mr-2 pointer w-6 h-6" />
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Mute" placement="right" arrow>
                                <span>
                                    <VolumeIcon className="mr-2 pointer w-6 h-6" />
                                </span>
                            </Tooltip>
                        )}
                        Original Audio
                    </div>
                )}
            </div>
        </div>
    );
};

export default VolumeSetting;
