import 'bootstrap';
import 'core-js';
import 'font-awesome/css/font-awesome.min.css';
import 'normalize.css';
import { Provider } from 'overmind-react';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { setLocale, setTranslations } from 'react-i18nify';
import App from './App';
import i18n from './i18n';
import './libs/contextmenu.css';
import { overmindConfig } from './overmind';

import { createOvermind } from 'overmind';

setTranslations(i18n);
const language = navigator.language.toLowerCase();
const defaultLang = i18n[language] ? language : 'en';
setLocale(defaultLang);

const overmind = createOvermind(overmindConfig, {
    devtools: true, // 'localhost:3031'
    devEnv: 'dev',
});
ReactDOM.render(
    <React.StrictMode>
        <Provider value={overmind}>
            <CookiesProvider>
                {/* <GlobalStyle /> */}
                <App defaultLang={defaultLang} />
                {/* {isMobile ? <Mobile /> : <App defaultLang={defaultLang} />} */}
            </CookiesProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
