import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/skip-video';

// payload = {
//     subtitle_translation_id
//     start_time
//     end_time
// }
export const createSkipVideo = async (payload) => {
    return axios.post(BACKEND_URL, payload).then((res) => {
        return res.data.data;
    });
};

// payload = {
//     id
//     start_time
//     end_time
// }
export const updateSkipVideo = async (payload) => {
    return axios.put(BACKEND_URL, payload).then((res) => {
        return res.data;
    });
};

export const deleteSkipVideo = async (id) => {
    return axios.delete(`${BACKEND_URL}/${id}`).then((res) => {
        return res.data;
    });
};

export const fetchSkipVideo = async (subtitle_translation_id) => {
    return axios.get(`${BACKEND_URL}/${subtitle_translation_id}`).then((res) => {
        return res.data.data;
    });
};
