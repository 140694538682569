function MicrophoneSlashIcon({ className }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M2.50509 3.72183C2.78401 3.44291 3.23624 3.44291 3.51516 3.72183L21.5638 21.7705C21.8427 22.0494 21.8427 22.5016 21.5638 22.7805C21.2849 23.0595 20.8326 23.0595 20.5537 22.7805L2.50509 4.73191C2.22617 4.45298 2.22617 4.00076 2.50509 3.72183Z'
        fill='currentColor'
      />
      <path
        d='M10.409 2.90901C10.831 2.48705 11.4033 2.25 12 2.25C12.5967 2.25 13.169 2.48705 13.591 2.90901C14.0129 3.33097 14.25 3.90326 14.25 4.5V12.3893L15.6135 13.7527C15.7033 13.429 15.75 13.0919 15.75 12.75V4.5C15.75 3.50544 15.3549 2.55161 14.6516 1.84835C13.9484 1.14509 12.9946 0.75 12 0.75C11.0054 0.75 10.0516 1.14509 9.34834 1.84835C8.64508 2.55161 8.25 3.50544 8.25 4.5V6.38928L9.75 7.88928V4.5C9.75 3.90326 9.98705 3.33097 10.409 2.90901Z'
        fill='currentColor'
      />
      <path
        d='M6.25067 10.5429L6.70708 10.9993C6.73487 11.0777 6.75 11.1621 6.75 11.25V12.75C6.75 14.1424 7.30312 15.4777 8.28768 16.4623C9.27225 17.4469 10.6076 18 12 18C12.5096 18 13.0115 17.9259 13.4915 17.7837L14.6611 18.9533C14.0552 19.2132 13.4115 19.3843 12.75 19.4582V21.75H15.75C16.1642 21.75 16.5 22.0858 16.5 22.5C16.5 22.9142 16.1642 23.25 15.75 23.25H8.25C7.83578 23.25 7.5 22.9142 7.5 22.5C7.5 22.0858 7.83578 21.75 8.25 21.75H11.25V19.4582C9.73573 19.289 8.31484 18.6108 7.22702 17.523C5.96115 16.2571 5.24999 14.5402 5.24999 12.75V11.25C5.24999 10.8358 5.58578 10.5 5.99999 10.5C6.08791 10.5 6.17229 10.5151 6.25067 10.5429Z'
        fill='currentColor'
      />
      <path
        d='M17.8964 16.0357C18.4513 15.0398 18.75 13.9103 18.75 12.75V11.25C18.75 10.8358 18.4142 10.5 18 10.5C17.5858 10.5 17.25 10.8358 17.25 11.25V12.75C17.25 13.5058 17.087 14.2447 16.7806 14.9199L17.8964 16.0357Z'
        fill='currentColor'
      />
      <path
        d='M12.2023 16.4945C12.1351 16.4982 12.0676 16.5 12 16.5C11.0054 16.5 10.0516 16.1049 9.34834 15.4017C8.64508 14.6984 8.25 13.7446 8.25 12.75V12.5422L12.2023 16.4945Z'
        fill='currentColor'
      />
    </svg>
  );
}

export default MicrophoneSlashIcon;
