/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Skeleton } from '@mui/material';
import { debounce } from 'lodash';
import { json } from 'overmind';
import { createRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { unstable_batchedUpdates } from 'react-dom';
import styled from 'styled-components';
import styles from '../css/SubtitleEdit.module.css';
import { useActions, useAppState } from '../overmind';
import { getFontById } from '../requests/FontRequest';
import { updateSubtitle } from '../requests/SubtitleRequest';
import { saveTranslation } from '../requests/TranslateRequest';
import { isPlaying, removePositionTag } from '../utils';
import TitleRND from './TitleRND';
import PlayerSubtitle from './PlayerComponents/PlayerSubtitle';

export const ARIAL_XHEIGHT = 2794;
export const ARIAL_ASCENDER = 1854;
export const ARIAL_DESCENDER = -434;

const Style = styled.div`
    width: 100%;
    .vid-container {
        position: relative;
        background-color: #000000;
        width: 100%;
        height: 100%;
        .video-mini {
            height: 150px;
            width: 266.67px;
        }
        .subtitle-number {
            z-index: 19;
            > span {
                background-color: #ffffff;
                padding: 0.1rem 0.25rem;
                margin-bottom: 1px;
                font-weight: bold;
            }
        }
        .style-container {
            position: absolute;
            top: 0;
            height: 100%;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            aspect-ratio: 16 / 9;
            .container {
                display: flex;
                position: absolute;
                width: 100%;
                z-index: 20;
                overflow: hidden;
            }
            .subtitle {
                display: flex;
                position: absolute;
                overflow: hidden;
                z-index: 20;
                left: 0;
                right: 0;
                width: 100%;
                user-select: none;
                pointer-events: none;

                .operate {
                    color: #fff;
                    font-size: 13px;
                    border-radius: 3px;
                    margin-bottom: 5px;
                    background-color: rgb(0 0 0 / 75%);
                    border: 1px solid rgb(255 255 255 / 20%);
                    cursor: pointer;
                    pointer-events: all;
                }

                .textarea {
                    overflow: hidden !important;
                    outline: none;
                    resize: none;
                    line-height: 1.2;
                    border: 1px solid #ffffff;
                    border-radius: 3px;
                    user-select: all;
                    pointer-events: all;
                }
            }
        }
        .video {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: relative;
            video {
                position: relative;
                height: 100%;
                width: 100%;
                outline: none;
                background-color: #000;
                cursor: pointer;
            }
        }
    }
`;

const VideoWrap = memo(
    ({
        $video,
        setPlayer,
        setPlaying,
        projectData,
        setVideoTime,
        videoTime,
        playPauseAction,
        setNearest,
        setCurrentIndex,
        ...props
    }) => {
        const { setTime } = useActions();
        const state = useAppState();
        const actions = useActions();

        useEffect(() => {
            setPlayer($video.current);
        }, [$video]);

        const onTimeUpdate = useCallback(
            (e) => {
                if ($video.current) {
                    if (videoTime === 0 && !isNaN($video.current.duration)) {
                        setVideoTime($video.current.duration);
                    }
                    const voiceOverAudio = state.voiceOverAudio;
                    const originalMusic = state.originalMusic;
                    const originalVocal = state.originalVocal;
                    if (voiceOverAudio !== null && originalVocal !== null && originalMusic !== null) {
                        // ini ambil apakah voice over audio dan video perbedaannya lebih dari setengah detik
                        if (Math.abs(voiceOverAudio.currentTime - $video.current.currentTime) > 0.5) {
                            voiceOverAudio.currentTime = $video.current.currentTime || 0;
                        }
                        if (Math.abs(originalMusic.currentTime - $video.current.currentTime) > 0.5) {
                            originalMusic.currentTime = $video.current.currentTime || 0;
                        }
                        if (Math.abs(originalVocal.currentTime - $video.current.currentTime) > 0.5) {
                            originalVocal.currentTime = $video.current.currentTime || 0;
                        }
                    }
                    // setTime($video.current.currentTime || 0);
                    //set player time

                    actions.setPlayerTime($video.current.currentTime || 0);

                    //auto scroll subtitle
                    const currentIndex = props.translateState
                        ? state.translation.findIndex(
                              (item) =>
                                  item.startTime <= $video.current.currentTime &&
                                  item.endTime > $video.current.currentTime,
                          )
                        : state.subtitle.findIndex(
                              (item) =>
                                  item.startTime <= $video.current.currentTime &&
                                  item.endTime > $video.current.currentTime,
                          );
                    let sub;
                    const subData = props.translateState ? json(state.translation) : json(state.subtitle);
                    sub = subData[currentIndex];
                    actions.setCurrentSub(sub);
                    let newNearest;
                    if (currentIndex < 0) {
                        if (subData.length <= 0) return;
                        newNearest = subData.reduce(function (prev, curr) {
                            return Math.abs(curr.startTime - $video.current.currentTime) <
                                Math.abs(prev.startTime - props.currentTime)
                                ? curr
                                : prev;
                        });
                    } else {
                        newNearest = subData[currentIndex];
                    }
                    setCurrentIndex(currentIndex);
                    setNearest(newNearest);
                }
            },
            [$video],
        );

        const onEnded = () => {
            if ($video.current) {
                setTime(0);
                playPauseAction();
                const footer = document.getElementById('footer');
                footer.scrollTo(0, 0);
            }
        };

        const onLoadedMetaData = () => {
            actions.setVoiceOverLoading(false);
            if ($video.current) {
                if (videoTime === 0 && !isNaN($video.current.duration)) {
                    setVideoTime($video.current.duration);
                }
            }
        };

        const onClick = () => {
            playPauseAction();
        };

        const onLoadStart = () => {
            // actions.setVoiceOverLoading(true)
        };

        const onLoadedData = () => {
            // actions.setVoiceOverLoading(false);
        };

        return (
            <>
                {state.skeletonLoading || state.voiceOverLoading ? (
                    <Skeleton
                        width="100%"
                        height="100%"
                        sx={{
                            transform: 'unset',
                        }}
                    />
                ) : (
                    ''
                )}
                <video
                    id="video"
                    onClick={onClick}
                    onLoadedMetadata={onLoadedMetaData}
                    onLoadedData={onLoadedData}
                    onLoadStart={onLoadStart}
                    onTimeUpdate={onTimeUpdate}
                    onEnded={onEnded}
                    preload="auto"
                    src={
                        !projectData?.header.optimized_url
                            ? projectData?.header.dropbox_url.replace('www.dropbox.com', 'dl.dropboxusercontent.com')
                            : projectData?.optimized_url.replace('www.dropbox.com', 'dl.dropboxusercontent.com')
                    }
                    ref={$video}
                    style={{
                        display: state.skeletonLoading || state.voiceOverLoading ? 'none' : 'block',
                    }}
                />
            </>
        );
    },
);
const Player = memo((props) => {
    const styleRef = useRef();
    const player = createRef();
    const [nearest, setNearest] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const state = useAppState();
    const [styleString, setStyleString] = useState(``);
    const [headingStyleString, setHeadingStyleString] = useState(``);
    const [headingTextContainerPosition, setHeadingTextContainerPosition] = useState(``);
    const [fontName, setFontName] = useState('Verdana');
    const [heading_fontName, setHeading_FontName] = useState('Verdana');
    const [fontData, setFontData] = useState(null);
    const [headingFontData, setHeadingFontData] = useState(null);

    const PlayerSubtitleProps = {
        nearest,
        styleRef,
        currentIndex,
        styleString,
        headingStyleString,
        headingTextContainerPosition,
        fontName,
        fontData,
        ...props
    }

    const getTextStyle = () => {
        let str = ``;
        if (props.config.bold) {
            str += ` ${styles.bold}`;
        }
        if (props.config.italic) {
            str += ` ${styles.italic}`;
        }
        if (props.config.underline) {
            str += ` ${styles.underline}`;
        }
        if (props.config.align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (props.config.align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (props.config.align === 'right') {
            str += ` ${styles.alignRight}`;
        }
        if (nearest) {
            if (/^{\\an[4|5|6]}/.test(nearest.text)) {
                str += ` ${styles.center}`;
            } else if (/^{\\an[1|2|3]}/.test(nearest.text)) {
                str += ` ${styles.bottom}`;
            } else if (/^{\\an[7|8|9]}/.test(nearest.text)) {
                str += ` ${styles.top}`;
            } else {
                if (props.config.position === 'Center') {
                    str += ` ${styles.center}`;
                } else if (props.config.position === 'Bottom' || props.config.position === 'Custom') {
                    str += ` ${styles.bottom}`;
                } else if (props.config.position === 'Top') {
                    str += ` ${styles.top}`;
                }
            }
        }
        return str;
    };


    const getHeadingTextStyle = () => {
        let str = ``;
        if (props.config.heading_bold) {
            str += ` ${styles.bold}`;
        }
        if (props.config.heading_italic) {
            str += ` ${styles.italic}`;
        }
        if (props.config.heading_underline) {
            str += ` ${styles.underline}`;
        }
        if (props.config.heading_align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (props.config.heading_align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (props.config.heading_align === 'right') {
            str += ` ${styles.alignRight}`;
        }
        if (props.config.heading_position === 'Center') {
            str += ` ${styles.center}`;
        } else if (props.config.heading_position === 'Bottom') {
            str += ` ${styles.bottom}`;
        } else if (props.config.heading_position === 'Top') {
            str += ` ${styles.top}`;
        }

        return str;
    };

    const getHeadingTextContainerPosition = () => {
        let str = ``;
        if (props.config.heading_position === 'Center') {
            str += ` ${styles.center}`;
        } else if (props.config.heading_position === 'Bottom') {
            str += ` ${styles.bottom}`;
        } else if (props.config.heading_position === 'Top') {
            str += ` ${styles.top}`;
        }

        if (props.config.heading_align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (props.config.heading_align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (props.config.heading_align === 'right') {
            str += ` ${styles.alignRight}`;
        }

        return str;
    };

    const getFontFamily = async () => {
        const font = await getFontById(props.config.fontFamily);
        const headingFont = await getFontById(props.config.heading_fontFamily);
        unstable_batchedUpdates(() => {
            setFontName(font.font_name);
            setHeading_FontName(headingFont.font_name);
            setFontData(font);
            setHeadingFontData(headingFont);
        });
    };

    useEffect(() => {
        getFontFamily();
    }, [props.config.fontFamily, props.config.heading_fontFamily]);

    const initByConfig = async () => {
        const textStyle = getTextStyle();
        // getTextContainerPosition();
        const headingTextContainerPosition = getHeadingTextContainerPosition();
        const headingTextStyle = getHeadingTextStyle();
        unstable_batchedUpdates(() => {
            setHeadingStyleString(headingTextStyle);
            setHeadingTextContainerPosition(headingTextContainerPosition);
            setStyleString(textStyle);
        });
    };
    useEffect(() => {
        initByConfig();
    }, [props.config]);

    const getAspectRatio = () => {
        if (
            (props.projectData.file.original_aspect_ratio &&
                props.projectData.file.original_aspect_ratio == props.config.ratio) ||
            props.config.ratio == '16:9'
        ) {
            return {};
        } else {
            return {
                width: '100%',
                height: '100%',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            };
        }
    };

    const getContainerAspectRatio = () => {
        if (props.config.ratio == '1:1') {
            return {
                width: '100%',
                height: '100%',
                aspectRatio: `1/1`,
            };
        } else if (props.config.ratio == '16:9') {
            return {
                width: '100%',
                height: '100%',
                aspectRatio: `16/9`,
            };
        } else if (props.config.ratio == '4:5') {
            return {
                width: '100%',
                height: '100%',
                aspectRatio: `4/5`,
            };
        } else if (props.config.ratio == '9:16') {
            return {
                width: '100%',
                height: '100%',
                aspectRatio: `9/16`,
            };
        }
    };

    const getLogoStyle = () => {
        let baseStyle = {
            display: 'flex',
            zIndex: 20,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            opacity: props.config.watermarkOpacity,
            height: props.player
                ? (props.player.offsetWidth >= props.player.offsetHeight
                      ? props.player.offsetWidth * 7
                      : props.player.offsetWidth * 5) / 100
                : 0, //(props.player.offsetHeight / props.projectData?.header.height) * 50
            objectFit: 'contain',
        };
        if (props.config.watermarkX == 50) {
            if (props.config.watermarkY == 50) {
                //center
                baseStyle = {
                    ...baseStyle,
                    top: '50%',
                    left: '50%',
                    transform: 'translateY(-50%) translateX(-50%)',
                };
            } else if (props.config.watermarkY < 50) {
                // top center
                baseStyle = {
                    ...baseStyle,
                    top: '8px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                };
            } else {
                //bottom center
                baseStyle = {
                    ...baseStyle,
                    bottom: '8px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                };
            }
        } else if (props.config.watermarkX < 50) {
            if (props.config.watermarkY == 50) {
                // center left
                baseStyle = {
                    ...baseStyle,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: '8px',
                };
            } else if (props.config.watermarkY < 50) {
                // top left
                baseStyle = {
                    ...baseStyle,
                    top: '8px',
                    left: '8px',
                };
            } else {
                //bottom left
                baseStyle = {
                    ...baseStyle,
                    bottom: '8px',
                    left: '8px',
                };
            }
        } else if (props.config.watermarkX > 50) {
            if (props.config.watermarkY == 50) {
                // center right
                baseStyle = {
                    ...baseStyle,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '8px',
                };
            } else if (props.config.watermarkY < 50) {
                // top right
                baseStyle = {
                    ...baseStyle,
                    top: '8px',
                    right: '8px',
                };
            } else {
                //bottom right
                baseStyle = {
                    ...baseStyle,
                    bottom: '8px',
                    right: '8px',
                };
            }
        }

        return baseStyle;
    };

    return (
        <Style
            className="player"
            style={{
                background: props.fullscreen ? '#000' : '',
            }}
        >
            <div
                className="vid-container mx-auto rnd-cover"
                ref={styleRef}
                style={getContainerAspectRatio()}
                onClick={(e) => {
                    e.stopPropagation();
                    props.playPauseAction;
                }}
            >
                <div className={`video`} style={getAspectRatio()} ref={player}>
                    <VideoWrap
                        {...props}
                        setNearest={setNearest}
                        setCurrentIndex={setCurrentIndex}
                    ></VideoWrap>
                </div>
                {state.skeletonLoading || state.voiceOverLoading ? (
                    ''
                ) : (
                    <div className="style-container">
                        {props.projectData?.watermark_url != '' ? (
                            <div
                                className="container"
                                style={{
                                    height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                                }}
                            >
                                <img src={props.projectData?.watermark_url} style={getLogoStyle()} />
                            </div>
                        ) : (
                            ''
                        )}
                        {props.access && props.access.userPackage.remove_scribe_watermark ? (
                            ''
                        ) : (
                            <div
                                className="container"
                                style={{
                                    height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                                }}
                            >
                                <img
                                    src="SCRIBE-watermark-logo.svg"
                                    style={{
                                        display: 'flex',
                                        zIndex: 22,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        opacity: 0.8,
                                        height: styleRef.current
                                            ? (styleRef.current.offsetWidth >= styleRef.current.offsetHeight
                                                  ? styleRef.current.offsetHeight * 7
                                                  : styleRef.current.offsetHeight * 5) / 100
                                            : 0, //(props.player.offsetHeight / props.projectData?.header.height) * 50
                                        objectFit: 'contain',
                                        top: '35px',
                                        right: '35px',
                                    }}
                                />
                            </div>
                        )}
                        <div
                            className="container"
                            onClick={(e) => {
                                e.stopPropagation();
                                props.playPauseAction;
                            }}
                            style={{
                                height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                            }}
                        >
                            {styleRef &&
                            (props.config.heading != '' || props.config.heading != null) &&
                            (!props.config.customHeadingTime || state.playerTime <= props.config.headingTime) ? (
                                <TitleRND
                                    {...props}
                                    headingFontData={headingFontData}
                                    styleRef={styleRef}
                                    heading_fontName={heading_fontName}
                                />
                            ) : null}

                            {props.player && state.currentSub ? (
                              <PlayerSubtitle {...PlayerSubtitleProps}/>
                            ) : null}
                        </div>
                    </div>
                )}
            </div>
        </Style>
    );
});
export default Player;
