/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import styles from '../css/HeadingTimeDropdown.module.css';
import useComponentVisible from './useComponentVisible';

const HeadingTimeDropdown = ({ config, setConfig }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [tempTime, setTempTime] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        setTempTime(config.headingTime);
    }, [config.headingTime]);

    const onConfigClick = (key, value) => {
        setConfig((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    return (
        <div className="custom-dropdown custom-dropdown-fixed dropdown-toolbar pr-2 w-full" ref={ref}>
            <a
                onClick={() => {
                    setIsComponentVisible(!isComponentVisible);
                }}
                style={{
                    cursor: 'pointer',
                }}
            >
                Heading time
            </a>

            <div className={`custom-dropdown-menu dropdown-menu-translation-tab ${isComponentVisible ? 'show' : ''}`}>
                <div className="custom-dropdown-menu__content px-4 py-2" style={{ width: '15rem', boxShadow: 'none' }}>
                    <div className="">
                        <div className="form-check pl-1">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="fullduration"
                                checked={!config.customHeadingTime}
                                onChange={() => {}}
                                onClick={(e) => {
                                    onConfigClick('customHeadingTime', false);
                                }}
                            />
                            <label
                                className="form-check-label"
                                style={{
                                    color: '#ffffff',
                                }}
                                htmlFor="fullduration"
                            >
                                Show for the full duration
                            </label>
                        </div>
                        <div className="form-check mt-2 pl-1">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="custom"
                                checked={config.customHeadingTime}
                                onChange={() => {}}
                                onClick={(e) => {
                                    onConfigClick('customHeadingTime', true);
                                }}
                            />
                            <label
                                style={{
                                    color: '#ffffff',
                                }}
                                className="form-check-label"
                                htmlFor="custom"
                            >
                                Custom (seconds)
                            </label>
                        </div>
                    </div>
                    {config.customHeadingTime ? (
                        <div className="flex items-center mt-3">
                            <input
                                type="text"
                                className={`form-control input-dark bg-box-dark ${error ? styles.error : ''}`}
                                style={{ width: '100%' }}
                                placeholder="Duration in seconds"
                                value={tempTime}
                                onChange={(e) => {
                                    setTempTime(e.target.value);
                                    if (!isNaN(e.target.value)) {
                                        setError(false);
                                        onConfigClick('headingTime', e.target.value);
                                    } else {
                                        setError(true);
                                    }
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default HeadingTimeDropdown;
