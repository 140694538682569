import { TextareaAutosize } from '@material-ui/core';
import { debounce } from 'lodash';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import { useAppState } from '../../overmind';
import { updateSubtitle } from '../../requests/SubtitleRequest';
import { extractContent } from '../../utils';

export default function StudioSubtitleCardInput({
    checkSub,
    projectData,
    access,
    s,
    setIsSaving,
    updateSub,
    tempSubtitle,
    setTempSubtitle,
    i,
    setSegmentIndex,
    highlightSegment,
    translatedLanguage,
}) {
    const state = useAppState();
    const [tempSub, setTempSub] = useState('');
    const hasSub = useCallback(
        (sub) => {
            let index = state.subtitle.indexOf(sub);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
                index = data.map((a) => a.id).indexOf(sub.id);
            }
            return index;
        },
        [state.subtitle],
    );

    useEffect(() => {
        if (s) {
            setTempSub(s);
        }
    }, [s]);

    const debouncedSave = useCallback(
        debounce((sub, nextValue) => {
            updateSubtitle(nextValue);
            updateSub(sub, nextValue);
            // setIsSaving(false);
        }, 1000),
        [],
    );

    const updateText = async (sub, text) => {
        // setIsSaving(true);
        const index = hasSub(sub);
        if (index < 0) return;
        let newSub = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
        newSub[index].text = text;
        let temp = [...tempSubtitle];
        temp[index].text = text;
        setTempSubtitle(temp);
        debouncedSave(sub, newSub[index]);
        setTempSub(newSub[index]);
    };

    return (
        <TextareaAutosize
            className={[
                'input-subtitle',
                'text-content',
                'w-full',
                projectData && projectData.file.language.rtl === 1 ? 'rtl-target-input' : '',
            ]
                .join(' ')
                .trim()}
            disabled={
                (access &&
                    access.accessMapping.role.toLowerCase() !== 'owner' &&
                    access.accessMapping.role.toLowerCase() !== 'editor') ||
                state.voiceOverAudio !== null ||
                state.voiceOverLoading ||
                (translatedLanguage && translatedLanguage.length > 0)
                    ? true
                    : false
            }
            value={extractContent(tempSub.text, false)}
            style={{
                border: 'none',
                minHeight: '24px',
                lineHeight: '1.75',
            }}
            onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
            }}
            id={`subtitle-card-${s.id}`}
            onFocus={() => {
                highlightSegment(s, i);
                setSegmentIndex(i);
            }}
            onChange={(event) => {
                updateText(s, event.target.value);
            }}
        />
    );
}
