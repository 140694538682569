import { MicrophoneIcon } from '@heroicons/react/24/solid';
import ShareIcon from '../../components/Icon/ShareIcon.js';
import toast from 'react-hot-toast';
import Button from '../Button';

export function ShareButton({ watchStreamUrl }) {
  const handleCopyClick = () => {
      navigator.clipboard.writeText(watchStreamUrl)
        .then(() => {
            toast.success('Watch stream link successfully copied.', { id: 'COPY_WATCH_STREAM' });
        })
        .catch(err => {
            toast.error('Watch stream link failed to copy.', { id: 'COPY_WATCH_STREAM_ERROR' });
        });
  };

  return (
      <button
          type="button"
          className="btn btn-lexigo-green text-white font-bold rounded-3px btn-save-hide-mobile"
          style={{ width: '50px', height: '50px', borderRadius: '50%' }}
          onClick={handleCopyClick}
      >
        <ShareIcon
            className="text-white h-6 w-6"
        />
      </button>
  );
}
