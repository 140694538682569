import { useEffect, useState } from 'react';
import { getUserByRole } from '../../requests/AccessRequest';
import { capitalizeFirstLetter, colorsClasses } from '../ShareModal';

const ShareUserIcon = (props) => {
    const [users, setUsers] = useState([]);

    const fetchUser = async (id) => {
        const getUser = await getUserByRole(id, 'All');
        setUsers(getUser.slice(Math.max(getUser.length - 4, 0)));
    };

    useEffect(() => {
        if (!props.id) return;
        fetchUser(props.id);
    }, [props.id, props.openShareModal]);

    return (
        <>
            {users.map((data, key) => {
                return (
                    <div
                        key={key}
                        className={`rounded-full ${
                            colorsClasses[key % 4]
                        } text-white font-regular w-10 h-10 flex items-center justify-center`}
                    >
                        {capitalizeFirstLetter(data.user.email.at(0))}
                    </div>
                );
            })}
        </>
    );
};

export default ShareUserIcon;
