import { useState } from 'react';
import { ChevronDown } from 'react-feather';
import UploadTranscriptionModal from './UploadTranscriptionModal';
import useComponentVisible from './useComponentVisible';

const ActionTranslationDropdown = (props) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [openUploadTranscriptModal, setUploadTranscriptModal] = useState(false);

    const downloadVideo = () => {
        setIsComponentVisible(false);
        props.setDownloadModal(true);
    };

    const uploadTranscription = () => {
        setIsComponentVisible(false);
        setUploadTranscriptModal(true);
    };

    const downloadTranscription = async () => {
        setIsComponentVisible(false);
        const targetLang = props.currentLanguageObject.id;
        const targetRtl = props.currentLanguageObject.rtl;
        const sourceId = props.projectData.header.id;
        const sourceRtl = props.projectData.file.language.rtl;
        // ${process.env.REACT_LEXIGO_API_URL}
        let downloadUrl = `${process.env.REACT_APP_LEXIGO_API_URL}/project/download-source-text-file-scribe?scribe_id=${sourceId}&target_lang=${targetLang}`;
        if (targetRtl == '1') {
            downloadUrl += '&target_rtl=1';
        }
        if (sourceRtl == '1') {
            downloadUrl += '&source_rtl=1';
        }
        window.open(downloadUrl, '_blank', 'noreferrer');
    };

    return (
        <>
            <div className="action-dropdown custom-dropdown" ref={ref}>
                <button
                    className="dropdown-toggle btn bg-white font-bold"
                    aria-expanded="true"
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                >
                    Action <ChevronDown className="w-4 h-4 ml-2" />
                </button>
                <div className={`action-dropdown-content custom-dropdown-menu ${isComponentVisible ? 'show' : ''}`}>
                    <div className="custom-dropdown-menu__content box">
                        <a className="cursor" onClick={downloadVideo}>
                            Download video
                        </a>
                        <a className="cursor" onClick={downloadTranscription}>
                            Download transcription
                        </a>
                        <a className="cursor" onClick={uploadTranscription}>
                            Upload transcription
                        </a>
                    </div>
                </div>
            </div>

            <UploadTranscriptionModal
                formatTranslation={props.formatTranslation}
                currentLanguageObject={props.currentLanguageObject}
                projectData={props.projectData}
                openUploadTranscriptModal={openUploadTranscriptModal}
                setUploadTranscriptModal={setUploadTranscriptModal}
            ></UploadTranscriptionModal>
        </>
    );
};

export default ActionTranslationDropdown;
