import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/languages';

export const fetchLanguages = async (signature, subtitle_id) => {
    return axios
        .get(BACKEND_URL + '/lexigo', {
            headers: {
                Authorization: signature + '!' + subtitle_id,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const fetchLanguage = async (id) => {
    return axios.get(BACKEND_URL + '/' + id).then((res) => {
        return res.data;
    });
};

export const fetchLexigoLanguage = async (id) => {
    return axios.get(BACKEND_URL + '/lexigo/by/' + id).then((res) => {
        return res.data;
    });
};

export const fetchLexigoLanguageByScribe = async (id) => {
    return axios.get(BACKEND_URL + '/lexigo/' + id).then((res) => {
        return res.data;
    });
};
