
import BroadcastApp from '../components/BroadcastApp/BroadcastApp';
import styles from '../css/LiveStream.module.css';
import BroadcastProvider from '../providers/BroadcastContext';
import BroadcastLayoutProvider from '../providers/BroadcastLayoutContext';
import BroadcastMixerProvider from '../providers/BroadcastMixerContext';
import LocalMediaProvider from '../providers/LocalMediaContext';
import ModalProvider from '../providers/ModalContext';
import UserSettingsProvider from '../providers/UserSettingsContext';
import CryptoJS  from 'crypto-js';
import * as Ably from 'ably';
import { AblyProvider, ChannelProvider } from 'ably/react'

function LiveStream(props: any) {
  const client = new Ably.Realtime("qeJeBw.4cXQvg:0NZwbKrt70lxW1Nu5fAVxzWSI-eIsBiqfk3-TQhKKHg");

  // let key = 'NzHhmhYt9N1LMMHhws5pTt1JtW4StuWk9jfQ/plX+GI=';
  // let encryptStr = CryptoJS.enc.Base64.parse('eyJpdiI6ImM3ek05R2hpYzVJQ0xIYnltUE5ibmc9PSIsInZhbHVlIjoiU040SFBrWHJxdkNVdmVudHNpV1lNZz09IiwibWFjIjoiOGMyZGQ3MWM3ZWZkOGZmNTZlZjhiNjdjMzljOTliYjhhODc0YzZiODkzNTg4M2NkNWEzYjdmODc2ODU1ZjZkZCIsInRhZyI6IiJ9');
  // let encryptData:any = encryptStr.toString(CryptoJS.enc.Utf8);
  // encryptData = JSON.parse(encryptData);
  // let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
  // console.log(iv)
  // var decrypted = CryptoJS.AES.decrypt(encryptData.value,  CryptoJS.enc.Base64.parse(key), {
  //     iv : iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  // });

  //   console.log(CryptoJS.enc.Utf8.stringify(decrypted))
    return (
        <>
        <AblyProvider client={ client }>
          <ChannelProvider channelName="scribe">
            <ModalProvider>
              <UserSettingsProvider>
                <LocalMediaProvider>
                  <BroadcastProvider>
                    <BroadcastMixerProvider>
                      <BroadcastLayoutProvider>
                        <BroadcastApp {...props} />
                      </BroadcastLayoutProvider>
                    </BroadcastMixerProvider>
                  </BroadcastProvider>
                </LocalMediaProvider>
              </UserSettingsProvider>
            </ModalProvider>
          </ChannelProvider>
        </AblyProvider>
        </>
    );
}

export default LiveStream;
