/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { XCircle } from 'react-feather';
import { saveExistingTemplate, storeTemplate } from '../../requests/TemplateRequest';

const SaveTemplateModal = (props) => {
    const [templateName, setTemplateName] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState('0');
    const [saveAsExistingTemplate, setSaveAsExistingTemplate] = useState(false);
    const [ratio, setRatio] = useState([]);

    useEffect(() => {
        if (props.config) {
            setRatio([props.config.ratio]);
        }
    }, [props.config]);
    const saveTemplate = async () => {
        setLoading(true);
        if (ratio.length <= 0 || (templateName == '' && selectedTemplateId == '0')) return;
        if (saveAsExistingTemplate) {
            if (props.translateState) {
                await saveExistingTemplate(
                    props.id,
                    props.config.ratio,
                    selectedTemplateId,
                    props.user.signature,
                    props.currentLanguage,
                );
            } else {
                await saveExistingTemplate(props.id, props.config.ratio, selectedTemplateId, props.user.signature);
            }
        } else {
            if (props.translateState) {
                await storeTemplate(
                    props.id,
                    ratio,
                    templateName,
                    props.user.signature,
                    props.config.ratio,
                    props.currentLanguage,
                );
            } else {
                await storeTemplate(props.id, ratio, templateName, props.user.signature, props.config.ratio);
            }
        }
        await props.fetchTemplate();
        setLoading(false);
        setTemplateName('');
        setRatio([]);
        props.setSaveTemplateModal(false);
        setSelectedTemplateId('0');
        setSaveAsExistingTemplate(false);
    };

    return (
        <Modal
            open={props.saveTemplateModal}
            onClose={() => {
                props.setSaveTemplateModal(false);
            }}
        >
            <div
                id="modal-download"
                className="modal show"
                style={{
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    zIndex: 10000,
                }}
                tabIndex="-1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-xl-custom">
                    <div className="modal-content modal-transparent h-full border-0">
                        <a data-dismiss="modal" href="javascript:;" onClick={() => props.setSaveTemplateModal(false)}>
                            {' '}
                            <XCircle className="w-8 h-8 text-gray-100" />{' '}
                        </a>
                        <div className="modal-body h-full">
                            <div className="grid grid-cols-12 gap-5">
                                <div className="col-span-4"></div>
                                <div className="col-span-12 lg:col-span-4">
                                    <div className="p-5 text-center mt-5">
                                        <div className="text-3xl font-bold" id="create-folder-text">
                                            Save Template
                                        </div>
                                    </div>
                                    <div className="p-5 mt-5">
                                        <div className="">
                                            <label
                                                for="name"
                                                className="form-label font-bold text-title text-base rounded-3px"
                                            >
                                                Template name
                                            </label>
                                            {!saveAsExistingTemplate ? (
                                                <>
                                                    <input
                                                        required="true"
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        value={templateName}
                                                        onChange={(e) => {
                                                            setTemplateName(e.target.value);
                                                        }}
                                                        style={{
                                                            color: '#000000',
                                                        }}
                                                        className="form-control mt-2"
                                                        placeholder="Enter your template name"
                                                    />
                                                </>
                                            ) : (
                                                <select
                                                    onChange={(event) => {
                                                        setSelectedTemplateId(event.target.value);
                                                    }}
                                                    name="template_name"
                                                    className="form-control mt-2"
                                                >
                                                    <option value={0} selected={selectedTemplateId == '0'}>
                                                        Select template
                                                    </option>
                                                    {props.template &&
                                                        props.template.map((t, i) => (
                                                            <option
                                                                key={i}
                                                                value={t.id}
                                                                selected={selectedTemplateId === t.id}
                                                            >
                                                                {t.name} ({t.ratio})
                                                            </option>
                                                        ))}
                                                </select>
                                            )}
                                            {props.template.length <= 0 ? (
                                                ''
                                            ) : (
                                                <div className="form-check mr-3 mt-3">
                                                    <input
                                                        id="checkbox-save-template"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={saveAsExistingTemplate}
                                                        onChange={() => {
                                                            setSaveAsExistingTemplate(!saveAsExistingTemplate);
                                                        }}
                                                    />
                                                    <label className="form-check-label" for="checkbox-save-template">
                                                        Save as existing template
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        {!saveAsExistingTemplate ? (
                                            <div className="mt-5">
                                                <label
                                                    for=""
                                                    className="form-label font-bold text-title text-base rounded-3px"
                                                >
                                                    Ratio
                                                </label>

                                                <div className="flex flex-col sm:flex-row mt-2">
                                                    <div className="form-check mr-3">
                                                        <input
                                                            id="checkbox-save-ratio-1"
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={ratio.includes('16:9')}
                                                            onChange={() => {
                                                                if (ratio.includes('16:9')) {
                                                                    let newRatio = ratio.filter((e) => e !== '16:9');
                                                                    setRatio(newRatio);
                                                                } else {
                                                                    setRatio([...ratio, '16:9']);
                                                                }
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="checkbox-save-ratio-1"
                                                            onChange={() => {
                                                                if (ratio.includes('16:9')) {
                                                                    let newRatio = ratio.filter((e) => e !== '16:9');
                                                                    setRatio(newRatio);
                                                                } else {
                                                                    setRatio([...ratio, '16:9']);
                                                                }
                                                            }}
                                                        >
                                                            16:9
                                                        </label>
                                                        <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                                    </div>
                                                    <div className="form-check mr-3 mt-2 sm:mt-0">
                                                        <input
                                                            id="checkbox-save-ratio-2"
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={ratio.includes('9:16')}
                                                            onChange={() => {
                                                                if (ratio.includes('9:16')) {
                                                                    let newRatio = ratio.filter((e) => e !== '9:16');
                                                                    setRatio(newRatio);
                                                                } else {
                                                                    setRatio([...ratio, '9:16']);
                                                                }
                                                            }}
                                                        />{' '}
                                                        <label
                                                            className="form-check-label"
                                                            for="checkbox-save-ratio-2"
                                                            onChange={() => {
                                                                if (ratio.includes('9:16')) {
                                                                    let newRatio = ratio.filter((e) => e !== '9:16');
                                                                    setRatio(newRatio);
                                                                } else {
                                                                    setRatio([...ratio, '9:16']);
                                                                }
                                                            }}
                                                        >
                                                            9:16
                                                        </label>
                                                        <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                                    </div>
                                                    <div className="form-check mr-3 mt-2 sm:mt-0">
                                                        <input
                                                            id="checkbox-save-ratio-3"
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={ratio.includes('4:5')}
                                                            onChange={() => {
                                                                if (ratio.includes('4:5')) {
                                                                    let newRatio = ratio.filter((e) => e !== '4:5');
                                                                    setRatio(newRatio);
                                                                } else {
                                                                    setRatio([...ratio, '4:5']);
                                                                }
                                                            }}
                                                        />{' '}
                                                        <label
                                                            className="form-check-label"
                                                            for="checkbox-save-ratio-3"
                                                            onChange={() => {
                                                                if (ratio.includes('4:5')) {
                                                                    let newRatio = ratio.filter((e) => e !== '4:5');
                                                                    setRatio(newRatio);
                                                                } else {
                                                                    setRatio([...ratio, '4:5']);
                                                                }
                                                            }}
                                                        >
                                                            4:5
                                                        </label>
                                                        <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                                    </div>
                                                    <div className="form-check mr-3 mt-2 sm:mt-0">
                                                        <input
                                                            id="checkbox-save-ratio-4"
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={ratio.includes('1:1')}
                                                            onChange={() => {
                                                                if (ratio.includes('1:1')) {
                                                                    let newRatio = ratio.filter((e) => e !== '1:1');
                                                                    setRatio(newRatio);
                                                                } else {
                                                                    setRatio([...ratio, '1:1']);
                                                                }
                                                            }}
                                                        />{' '}
                                                        <label
                                                            className="form-check-label"
                                                            for="checkbox-save-ratio-4"
                                                            onChange={() => {
                                                                if (ratio.includes('1:1')) {
                                                                    let newRatio = ratio.filter((e) => e !== '1:1');
                                                                    setRatio(newRatio);
                                                                } else {
                                                                    setRatio([...ratio, '1:1']);
                                                                }
                                                            }}
                                                        >
                                                            1:1
                                                        </label>
                                                        <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                                    </div>
                                                </div>
                                                {/* <select
                                                            name=""
                                                            id="selRatio"
                                                            className="form-control"
                                                            onChange={(e) => setRatio(e.target.value)}
                                                        >
                                                            <option value="16:9">16:9</option>
                                                            <option value="16:9">9:16</option>
                                                            <option value="4:5">4:5</option>
                                                            <option value="1:1">1:1</option>
                                                        </select> */}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className="mt-5">
                                            <div className="flex items-center justify-center">
                                                <button
                                                    onClick={() => {
                                                        props.setSaveTemplateModal(false);
                                                    }}
                                                    className="btn font-bold mr-5 rounded-3px w-full"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-lexigo-soft-green text-white font-bold rounded-3px w-full"
                                                    id="createfolder"
                                                    onClick={saveTemplate}
                                                    disabled={
                                                        loading ||
                                                        (selectedTemplateId == '0' && saveAsExistingTemplate) ||
                                                        (templateName === '' && !saveAsExistingTemplate)
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    {loading ? 'Saving...' : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SaveTemplateModal;
