import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const BACKEND_AUTH_URL = process.env.REACT_APP_BACKEND_URL + '/auth';

export const getGoogleAuthURL = async () => {
    return axios.get(BACKEND_URL + '/get-login-url').then((res) => {
        return res.data;
    });
};

export const setCode = async (code) => {
    return axios
        .post(BACKEND_URL + '/store-code', {
            code: code,
        })
        .then((res) => {
            return res.data;
        });
};

export const validateAccessToken = async (access_token) => {
    return axios
        .post(BACKEND_URL + '/validate-token', {
            access_token: access_token,
        })
        .then((res) => {
            return res.data.valid;
        })
        .catch(() => {
            return false;
        });
};

export const getUserBySignature = async (signature) => {
    return axios.get(BACKEND_AUTH_URL + '/' + signature).then((res) => {
        return res.data;
    });
};
