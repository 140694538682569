import { TextareaAutosize } from '@material-ui/core';
import { debounce } from 'lodash';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import { useActions, useAppState } from '../../overmind';
import { saveTranslation } from '../../requests/TranslateRequest';
import { extractContent } from '../../utils';

export default function TranslationEditorTranslationCardInput({
    s,
    resetSaveStatus,
    updateTranslation,
    setActiveTranslationDetailString,
    setIsSaving,
    tempTranslation,
    setTempTranslation,
    i,
    highlightSegment,
    ...props
}) {
    const state = useAppState();
    const actions = useActions();
    const [tempSub, setTempSub] = useState('');
    const hasSub = useCallback(
        (sub) => {
            let index = state.translation.indexOf(sub);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.translation)))];
                index = data.map((a) => a.id).indexOf(sub.id);
            }
            return index;
        },
        [state.translation],
    );

    useEffect(() => {
        if (s) {
            setTempSub(s);
        }
    }, [s]);

    const debouncedSave = useCallback(
        debounce((sub, nextValue) => {
            // if (nextValue.text.length > 0) {
            resetSaveStatus(i);
            saveTranslation(nextValue, props.user.signature);
            // }
            updateTranslation(sub, nextValue);
            // setActiveTranslationDetailString(nextValue);
            // setIsSaving(false);
        }, 1000),
        [],
    );

    const updateText = async (sub, text) => {
        // setIsSaving(true);
        const index = hasSub(sub);
        if (index < 0) return;
        let newSub = [...JSON.parse(JSON.stringify(json(state.translation)))];
        newSub[index].text = text;
        let temp = [...tempTranslation];
        temp[index].text = text;
        debouncedSave(sub, newSub[index]);
        // setTempTranslation(temp);
        setTempSub(newSub[index]);
    };

    const handleOnChange = (event) => {
        updateText(s, event.target.value);
    };

    return (
        <TextareaAutosize
            ref={props.setTranslationSegmentRef(`translation-ref-${i}`)}
            className={[
                'input-table',
                'w-full',
                'input-light',
                props.translationHeader &&
                !props.translationHeader.locked &&
                props.currentLanguageObject &&
                props.currentLanguageObject.rtl === 1
                    ? 'rtl-target-input'
                    : '',
            ]
                .join(' ')
                .trim()}
            disabled={
                (props.access &&
                    props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                    props.access.accessMapping.role.toLowerCase() !== 'editor') ||
                (props.translationHeader && props.translationHeader.locked) ||
                state.voiceOverAudio !== null ||
                state.voiceOverLoading ||
                (props.translationHeader && props.translationHeader.locked)
                    ? true
                    : false
            }
            value={
                props.translationHeader && props.translationHeader.locked
                    ? 'Translating...'
                    : extractContent(tempSub.text, false)
            }
            style={{
                border: 'none',
                minHeight: '24px',
            }}
            onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
            }}
            id={`workbench-translation-card-${s.id}`}
            onFocus={() => {
                // props.setSegmentIndex(i);
                actions.setSegmentIdx(i);
                // highlightSegment(s, i);
            }}
            onChange={handleOnChange}
        />
    );
}
