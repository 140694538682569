/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { createTheme, Drawer, ThemeProvider } from '@mui/material';
import { Download, Share2 } from 'react-feather';
import TranslationDropdown from '../../components/TranslationDropdown';
import { useActions, useAppState } from '../../overmind';
import CreateTranslationDropdown from '../CreateTranslationDropdown';

const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f6f8f9',
                },
            },
        },
    },
});

const MobileModalNav = (props) => {
    const state = useAppState();
    const actions = useActions();

    return (
        <ThemeProvider theme={theme}>
            <Drawer
                open={state.openMobileNav}
                anchor={'right'}
                onClose={() => {
                    actions.setOpenMobileNav(false);
                }}
            >
                <div className="mobile-side-nav p-5">
                    <a
                        id="close-modal-menu"
                        data-dismiss="modal"
                        href="javascript:;"
                        className="x-close-mobile"
                        onClick={() => actions.setOpenMobileNav(false)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-x-circle w-8 h-8 text-gray-500"
                        >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="15" y1="9" x2="9" y2="15"></line>
                            <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                    </a>
                    <img className="w-1/4 mr-auto mb-10" src="scribe-name.png" />
                    <div className="flex flex-col">
                        {props.access && props.access.accessMapping.role.toLowerCase() !== 'owner' ? (
                            ''
                        ) : (
                            <CreateTranslationDropdown {...props} isButton={true} />
                        )}
                        <TranslationDropdown {...props} />
                        {props.access && props.access.accessMapping.role.toLowerCase() !== 'owner' ? (
                            <br />
                        ) : (
                            <button
                                onClick={() => {
                                    props.setOpenShareModal(true);
                                }}
                                className="btn btn-white py-1 font-bold flex items-center my-5 rounded-3px"
                            >
                                {state.openMobileNav}
                                <Share2 width={16} className="inline-block" style={{ marginLeft: '-1.25rem' }} />
                                <span className="ml-5">Share</span>
                            </button>
                        )}

                        <button
                            onClick={() => props.setDownloadModal(true)}
                            className="btn btn-lexigo-green py-1 font-bold flex items-center text-white rounded-3px"
                        >
                            <Download width={16} className="inline-block" />
                            <span className="ml-5">Download</span>
                        </button>
                        <div className="flex items-center">
                            {props.isShared == '1' ? (
                                <div></div>
                            ) : (
                                <button
                                    onClick={() => {
                                        window.location.href = process.env.REACT_APP_BACKEND_URL.replace(
                                            '/api',
                                            '/projects',
                                        );
                                    }}
                                    className="btn btn-white font-bold mr-3 rounded-3px btn-back-projects-sidebar hidden"
                                >
                                    Back to projects
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </Drawer>
        </ThemeProvider>
    );
};

export default MobileModalNav;
