function ScreenShareIcon({ className }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 2C3.34315 2 2 3.34315 2 5V16C2 17.6569 3.34315 19 5 19H19C20.6569 19 22 17.6569 22 16V5C22 3.34315 20.6569 2 19 2H5ZM16.8281 10.0243C17.0573 9.78995 17.0573 9.41005 16.8281 9.17574L13.8933 6.17574C13.6641 5.94142 13.2924 5.94142 13.0632 6.17574C12.834 6.41005 12.834 6.78995 13.0632 7.02426L14.996 9L10.1522 9C8.96356 9 8 9.98497 8 11.2L8 14.4C8 14.7314 8.26279 15 8.58696 15C8.91112 15 9.17391 14.7314 9.17391 14.4L9.17391 11.2C9.17391 10.6477 9.6119 10.2 10.1522 10.2H14.996L13.0632 12.1757C12.834 12.4101 12.834 12.7899 13.0632 13.0243C13.2924 13.2586 13.6641 13.2586 13.8933 13.0243L16.8281 10.0243Z'
        fill='currentColor'
      />
      <path
        d='M2 21C2 20.4477 2.44772 20 3 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21Z'
        fill='currentColor'
      />
    </svg>
  );
}

export default ScreenShareIcon;
