import debounce from 'lodash/debounce';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { Copy } from 'react-feather';
import styled from 'styled-components';
import { useAppState } from '../overmind';
import { updateSubtitle } from '../requests/SubtitleRequest';
import { extractContent } from '../utils';

const Style = styled.div`
    margin-top: 10px;
    color: #ffffff;
    .text:focus {
        outline: none;
        box-shadow: none;
    }
    .plain-text-highlight {
        background-color: #9bcce3;
    }
`;
export default function PlainTextEditor({ updateSub, setIsSaving }) {
    const [height, setHeight] = useState(100);
    const [tempSubtitle, setTempSubtitle] = useState([]);
    const [selectedSub, setSelectedSub] = useState(null);
    const [cursor, setCursor] = useState(null);
    const state = useAppState();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [copied, setCopied] = useState(false);
    const resize = useCallback(() => {
        setHeight(document.body.clientHeight - 170);
    }, [setHeight]);

    const hasSub = useCallback((sub) => tempSubtitle.indexOf(sub), [tempSubtitle]);

    useEffect(() => {
        const sub = [...json(state.subtitle)];
        setTempSubtitle(sub);
    }, [state.subtitle]);

    const debouncedSave = useCallback(
        debounce((sub, nextValue) => {
            updateSub(sub, {
                text: nextValue.text,
            });
            updateSubtitle(nextValue);
            setIsSaving(false);
        }, 1000),
        [],
    );
    const updateText = async (sub, text) => {
        setIsSaving(true);
        const index = hasSub(sub);
        if (index < 0) return;
        let newSub = [...tempSubtitle];
        newSub[index].text = text;
        debouncedSave(sub, newSub[index]);
        setTempSubtitle(newSub);
    };

    const props = {
        openDeleteModal,
        setOpenDeleteModal,
    };

    const copyToClipboard = () => {
        let subtitleData = '';
        tempSubtitle.map((s, i) => {
            if (i > 0) {
                subtitleData += '\n';
            }
            subtitleData += extractContent(s.text);
        });
        setCopied(true);
        navigator.clipboard.writeText(subtitleData);
    };

    useEffect(() => {
        setTimeout(function () {
            setCopied(false);
        }, 5000);
    }, [copied]);

    return (
        <Style>
            <div className="flex justify-between">
                <div></div>
                <button onClick={copyToClipboard}>
                    <Copy
                        stroke="#17BDB7"
                        className="w-4 h-4"
                        style={{
                            display: 'inline',
                            marginBottom: '0.5rem',
                        }}
                    />
                    {copied ? (
                        <span
                            style={{
                                fontSize: '10px',
                                marginLeft: '0.25rem',
                            }}
                        >
                            Copied to clipboard
                        </span>
                    ) : (
                        ''
                    )}
                </button>
            </div>
            <div>
                {tempSubtitle.map((s, i) => {
                    return (
                        <span key={i}>
                            <ContentEditable
                                className={[
                                    'text',
                                    props.currentLanguageObject && props.currentLanguageObject.rtl === 1
                                        ? 'rtl-target-input'
                                        : '',
                                ]
                                    .join(' ')
                                    .trim()}
                                html={extractContent(s.text)}
                                disabled={state.voiceOverAudio !== null}
                                id={`subtitle-span-${s.id}`}
                                onChange={(e) => {
                                    updateText(s, e.target.value);
                                }} // handle innerHTML change
                                tagName="span"
                            />
                            <span> / </span>
                        </span>
                    );
                })}
            </div>
        </Style>
    );
}
