/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { debounce } from 'lodash';
import { json } from 'overmind';
import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { unstable_batchedUpdates } from 'react-dom';
import styled from 'styled-components';
import styles from '../css/SubtitleEdit.module.css';
import { useActions, useAppState } from '../overmind';
import { getFontById } from '../requests/FontRequest';
import { updateSubtitle } from '../requests/SubtitleRequest';
import { saveTranslation } from '../requests/TranslateRequest';
import { fetchSkipVideo } from '../requests/skipVideoRequest';
import { removePositionTag } from '../utils';
import { ARIAL_ASCENDER, ARIAL_DESCENDER, ARIAL_XHEIGHT } from './Player';
import TitleRND from './TitleRND';
import MiniPlayerSubtitle from './PlayerComponents/MiniPlayerSubtitle';
const Style = styled.div`
    width: 100%;
    .vid-container {
        position: relative;
        background-color: #000000;
        width: 100%;
        height: 100%;
        .video-mini {
            height: 150px;
            width: 266.67px;
        }
        .style-container {
            position: absolute;
            top: 0;
            height: 100%;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            aspect-ratio: 16 / 9;
            .container {
                display: flex;
                position: absolute;
                width: 100%;
                z-index: 20;
                overflow: hidden;
            }
            .subtitle {
                display: flex;
                position: absolute;
                overflow: hidden;
                z-index: 20;
                left: 0;
                right: 0;
                width: 100%;
                user-select: none;
                pointer-events: none;

                &.subtitle-number {
                    z-index: 19;
                    > div {
                        background-color: #ffffff;
                        padding: 0.1rem 0.25rem;
                        margin-bottom: 1px;
                        font-weight: bold;
                    }
                }

                .operate {
                    color: #fff;
                    font-size: 13px;
                    border-radius: 3px;
                    margin-bottom: 5px;
                    background-color: rgb(0 0 0 / 75%);
                    border: 1px solid rgb(255 255 255 / 20%);
                    cursor: pointer;
                    pointer-events: all;
                }

                .textarea {
                    overflow: hidden !important;
                    outline: none;
                    resize: none;
                    line-height: 1.2;
                    border: 1px solid #ffffff;
                    border-radius: 3px;
                    user-select: all;
                    pointer-events: all;
                }
            }
        }
        .video {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: relative;
            video {
                position: relative;
                height: 100%;
                width: 100%;
                outline: none;
                background-color: #000;
                cursor: pointer;
            }
        }
    }
`;

const VideoWrap = ({
    $miniVideo,
    setMiniPlayer,
    setMiniPlaying,
    miniPlaying,
    projectData,
    setVideoTime,
    videoTime,
    translateState,
    highlightSegment,
}) => {
    const { setMiniTime } = useActions();
    const state = useAppState();

    const currentIndex = translateState
        ? state.translation.findIndex(
              (item) => item.startTime <= state.miniCurrentTime && item.endTime > state.miniCurrentTime,
          )
        : state.subtitle.findIndex(
              (item) => item.startTime <= state.miniCurrentTime && item.endTime > state.miniCurrentTime,
          );
    let lastIndex = -1;

    useEffect(() => {
        if (currentIndex !== -1 && miniPlaying) {
            highlightSegment(state.subtitle[currentIndex], currentIndex);

            let scrollableContainer = document.getElementById('workbench-scrollable-container');
            if (scrollableContainer) {
                try {
                    const top =
                        document.getElementById(`workbench-subtitle-box-${currentIndex}`).offsetTop -
                        document.getElementById(`workbench-table-head`).offsetTop * 10;
                    scrollableContainer.scroll({
                        top, //scroll to the bottom of the element
                        behavior: 'smooth', //auto, smooth, initial, inherit
                    });
                    lastIndex = currentIndex;
                } catch (e) {
                    if (lastIndex <= -1) lastIndex = 0;
                    try {
                        const top =
                            document.getElementById(`workbench-subtitle-box-${lastIndex}`).offsetTop *
                                (currentIndex - lastIndex) -
                            document.getElementById(`workbench-table-head`).offsetTop * 10;
                        scrollableContainer.scroll({
                            top, //scroll to the bottom of the element
                            behavior: 'smooth', //auto, smooth, initial, inherit
                        });
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        }
    }, [currentIndex, miniPlaying]);

    useEffect(() => {
        setMiniPlayer($miniVideo.current);
    }, [setMiniPlayer, setMiniPlaying, setVideoTime, $miniVideo]);

    const onTimeUpdate = useCallback(
        (e) => {
            if ($miniVideo.current) {
                const voiceOverAudio = state.voiceOverAudio;
                const originalMusic = state.originalMusic;
                const originalVocal = state.originalVocal;
                if (voiceOverAudio !== null && originalVocal !== null && originalMusic !== null) {
                    // ini ambil apakah voice over audio dan video perbedaannya lebih dari setengah detik
                    if (Math.abs(voiceOverAudio.currentTime - $miniVideo.current.currentTime) > 0.5) {
                        voiceOverAudio.currentTime = $miniVideo.current.currentTime || 0;
                    }
                    if (Math.abs(originalMusic.currentTime - $miniVideo.current.currentTime) > 0.5) {
                        originalMusic.currentTime = $miniVideo.current.currentTime || 0;
                    }
                    if (Math.abs(originalVocal.currentTime - $miniVideo.current.currentTime) > 0.5) {
                        originalVocal.currentTime = $miniVideo.current.currentTime || 0;
                    }
                }
                setMiniTime($miniVideo.current.currentTime || 0);
            }
        },
        [$miniVideo],
    );

    const onEnded = () => {
        if ($miniVideo.current) {
            setMiniTime(0);
            setMiniPlaying(!miniPlaying);
        }
    };

    const onLoadedMetaData = () => {
        if ($miniVideo.current) {
            if (videoTime === 0) {
                setVideoTime($miniVideo.current.duration);
            }
        }
    };

    const onClick = () => {
        setMiniPlaying(!miniPlaying);
    };

    return (
        <video
            id="mini-video"
            onClick={onClick}
            onLoadedMetadata={onLoadedMetaData}
            onTimeUpdate={onTimeUpdate}
            onEnded={onEnded}
            preload="auto"
            src={
                projectData && !projectData.header.optimized_url
                    ? projectData.header.dropbox_url
                    : projectData?.optimized_url
            }
            ref={$miniVideo}
        />
    );
};

export default function MiniPlayer(props) {
    const subRef = useRef();
    const styleRef = useRef();
    const miniPlayer = createRef();
    const [nearest, setNearest] = useState(null);
    const state = useAppState();
    const actions = useActions();
    const [fontData, setFontData] = useState(null);
    const [headingFontData, setHeadingFontData] = useState(null);

    const currentIndex = props.translateState
        ? state.translation.findIndex(
              (item) => item.startTime <= state.miniCurrentTime && item.endTime > state.miniCurrentTime,
          )
        : state.subtitle.findIndex(
              (item) => item.startTime <= state.miniCurrentTime && item.endTime > state.miniCurrentTime,
          );

    useMemo(() => {
        const index = json(currentIndex);
        let sub;
        if (props.translateState) {
            sub = json(state.translation)[index];
        } else {
            sub = json(state.subtitle)[index];
        }
        actions.setMiniCurrentSub(sub);
    }, [props.translateState, state.translation, currentIndex, state.subtitle]);

    useEffect(async () => {
        if (props.translationHeader) {
            const skipVideoData = await fetchSkipVideo(props.translationHeader.id);
            actions.setSkipVideoData(skipVideoData);
        }
    }, [props.translationHeader]);

    useMemo(() => {
        let newNearest;
        let miniCurrentTime = state.miniCurrentTime;
        if (currentIndex < 0) {
            if (props.translateState) {
                if (state.translation.length <= 0) return;
                newNearest = json(state.translation).reduce(function (prev, curr) {
                    return Math.abs(curr.startTime - miniCurrentTime) < Math.abs(prev.startTime - props.currentTime)
                        ? curr
                        : prev;
                });
            } else {
                if (state.subtitle.length <= 0) return;
                newNearest = json(state.subtitle).reduce(function (prev, curr) {
                    return Math.abs(curr.startTime - miniCurrentTime) < Math.abs(prev.startTime - props.currentTime)
                        ? curr
                        : prev;
                });
            }
        } else {
            if (props.translateState) {
                newNearest = json(state.translation)[currentIndex];
            } else {
                newNearest = json(state.subtitle)[currentIndex];
            }
        }
        setNearest(newNearest);
    }, [state.miniCurrentTime, state.subtitle, currentIndex]);

    useEffect(() => {
        let miniCurrentTime = state.miniCurrentTime;
        const skipVideoData = json(state.skipVideoData);
        if (props.$miniVideo && props.$miniVideo.current) {
            skipVideoData.forEach((skip) => {
                if (miniCurrentTime >= skip.start_time && miniCurrentTime < skip.end_time) {
                    props.$miniVideo.current.currentTime = skip.end_time;
                    miniCurrentTime = skip.end_time;
                }
            });
        }
    }, [state.miniCurrentTime, state.skipVideoData]);

    const [styleString, setStyleString] = useState(``);
    const [headingStyleString, setHeadingStyleString] = useState(``);
    const [headingTextContainerPosition, setHeadingTextContainerPosition] = useState(``);
    const [fontName, setFontName] = useState('Verdana');
    const [heading_fontName, setHeading_FontName] = useState('Verdana');

    const getTextStyle = () => {
        let str = ``;
        if (props.config.bold) {
            str += ` ${styles.bold}`;
        }
        if (props.config.italic) {
            str += ` ${styles.italic}`;
        }
        if (props.config.underline) {
            str += ` ${styles.underline}`;
        }
        if (props.config.align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (props.config.align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (props.config.align === 'right') {
            str += ` ${styles.alignRight}`;
        }
        if (nearest) {
            if (/^{\\an[4|5|6]}/.test(nearest.text)) {
                str += ` ${styles.center}`;
            } else if (/^{\\an[1|2|3]}/.test(nearest.text)) {
                str += ` ${styles.bottom}`;
            } else if (/^{\\an[7|8|9]}/.test(nearest.text)) {
                str += ` ${styles.top}`;
            } else {
                if (props.config.position === 'Center') {
                    str += ` ${styles.center}`;
                } else if (props.config.position === 'Bottom') {
                    str += ` ${styles.bottom}`;
                } else if (props.config.position === 'Top') {
                    str += ` ${styles.top}`;
                }
            }
        }
        setStyleString(str);
    };

    const getStyleHeight = () => {
        return styleRef.current?.clientHeight;
    };

    const getTextContainerPosition = () => {
        let obj = {};
        let str = ``;
        let height = getStyleHeight(); // - 8; // 16 ini 8 top 8 bottom
        if (nearest) {
            if (/^{\\an[4|5|6]}/.test(nearest.text)) {
                str += ` ${styles.center}`;
                obj.bottom = '50%';
            } else if (/^{\\an[1|2|3]}/.test(nearest.text)) {
                str += ` ${styles.bottom}`;
                if (props.config.position === 'Custom' && props.config.marginV > 50) {
                    obj.top = `${height - (height * props.config.marginV) / 100}px`;
                } else {
                    obj.bottom = `${height - (height * (100 - props.config.marginV)) / 100}px`;
                }
            } else if (/^{\\an[7|8|9]}/.test(nearest.text)) {
                str += ` ${styles.top}`;
                if (props.config.position === 'Custom' && props.config.marginV > 50) {
                    obj.bottom = `${height - (height * props.config.marginV) / 100}px`;
                } else {
                    obj.top = `${height - (height * (100 - props.config.marginV)) / 100}px`;
                }
            } else {
                if (props.config.position === 'Center') {
                    obj.bottom = '50%';
                    str += ` ${styles.center}`;
                } else if (props.config.position === 'Bottom') {
                    str += ` ${styles.bottom}`;
                    obj.bottom = `8px`;
                } else if (props.config.position === 'Top') {
                    str += ` ${styles.top}`;
                    obj.top = `8px`;
                } else if (props.config.position === 'Custom') {
                    str += ` ${styles.bottom}`;
                    if (props.config.marginV > 50) {
                        obj.top = `${height - (height * props.config.marginV) / 100}px`;
                    } else {
                        obj.bottom = `${height - (height * (100 - props.config.marginV)) / 100}px`;
                    }
                }
            }
        }

        if (props.config.align === 'center') {
            str += ` ${styles.alignCenter}`;
            obj.textAlign = 'center';
            obj.justifyContent = 'center';
        } else if (props.config.align === 'left') {
            str += ` ${styles.alignLeft}`;
            obj.textAlign = 'left';
            obj.justifyContent = 'flex-start';
            obj.transform = 'translateX(8px)';
        } else if (props.config.align === 'right') {
            str += ` ${styles.alignRight}`;
            obj.textAlign = 'right';
            obj.justifyContent = 'flex-end';
            obj.transform = 'translateX(-8px)';
        }
        return obj;
    };

    const getHeadingTextStyle = () => {
        let str = ``;
        if (props.config.heading_bold) {
            str += ` ${styles.bold}`;
        }
        if (props.config.heading_italic) {
            str += ` ${styles.italic}`;
        }
        if (props.config.heading_underline) {
            str += ` ${styles.underline}`;
        }
        if (props.config.heading_align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (props.config.heading_align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (props.config.heading_align === 'right') {
            str += ` ${styles.alignRight}`;
        }
        if (props.config.heading_position === 'Center') {
            str += ` ${styles.center}`;
        } else if (props.config.heading_position === 'Bottom') {
            str += ` ${styles.bottom}`;
        } else if (props.config.heading_position === 'Top') {
            str += ` ${styles.top}`;
        }

        setHeadingStyleString(str);
    };

    const getHeadingTextContainerPosition = () => {
        let str = ``;
        if (props.config.heading_position === 'Center') {
            str += ` ${styles.center}`;
        } else if (props.config.heading_position === 'Bottom') {
            str += ` ${styles.bottom}`;
        } else if (props.config.heading_position === 'Top') {
            str += ` ${styles.top}`;
        }

        if (props.config.heading_align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (props.config.heading_align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (props.config.heading_align === 'right') {
            str += ` ${styles.alignRight}`;
        }

        setHeadingTextContainerPosition(str);
    };

    const getFontFamily = async () => {
        const font = await getFontById(props.config.fontFamily);
        const headingFont = await getFontById(props.config.heading_fontFamily);
        unstable_batchedUpdates(() => {
            setFontName(font.font_name);
            setHeading_FontName(headingFont.font_name);
            setFontData(font);
            setHeadingFontData(headingFont);
        });
    };

    useEffect(() => {
        getFontFamily();
    }, [props.config.fontFamily, props.config.heading_fontFamily]);

    useEffect(() => {
        getTextStyle();
        getTextContainerPosition();
        getHeadingTextContainerPosition();
        getHeadingTextStyle();
    }, [props.config]);

    const getAspectRatio = () => {
        if (
            (props.projectData.file.original_aspect_ratio &&
                props.projectData.file.original_aspect_ratio == props.config.ratio) ||
            props.config.ratio == '16:9'
        ) {
            return {};
        } else {
            return {
                width: '100%',
                height: '100%',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            };
        }
    };

    const getContainerAspectRatio = () => {
        if (props.config.ratio == '1:1') {
            return {
                width: 'auto',
                height: '100%',
                aspectRatio: `1/1`,
            };
        } else if (props.config.ratio == '16:9') {
            return {};
        } else if (props.config.ratio == '4:5') {
            return {
                width: 'auto',
                height: '100%',
                aspectRatio: `4/5`,
            };
        } else if (props.config.ratio == '9:16') {
            return {
                width: 'auto',
                height: '100%',
                aspectRatio: `9/16`,
            };
        }
    };

    const getLogoStyle = () => {
        let baseStyle = {
            display: 'flex',
            zIndex: 20,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            opacity: props.config.watermarkOpacity,
            height: props.miniPlayer ? (props.miniPlayer.offsetHeight * 10) / 100 : 0, //(props.miniPlayer.offsetHeight / props.projectData.header.height) * 50
            objectFit: 'contain',
        };
        if (props.config.watermarkX == 50) {
            if (props.config.watermarkY == 50) {
                //center
                baseStyle = {
                    ...baseStyle,
                    top: '50%',
                    left: '50%',
                    transform: 'translateY(-50%) translateX(-50%)',
                };
            } else if (props.config.watermarkY < 50) {
                // top center
                baseStyle = {
                    ...baseStyle,
                    top: '8px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                };
            } else {
                //bottom center
                baseStyle = {
                    ...baseStyle,
                    bottom: '8px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                };
            }
        } else if (props.config.watermarkX < 50) {
            if (props.config.watermarkY == 50) {
                // center left
                baseStyle = {
                    ...baseStyle,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: '8px',
                };
            } else if (props.config.watermarkY < 50) {
                // top left
                baseStyle = {
                    ...baseStyle,
                    top: '8px',
                    left: '8px',
                };
            } else {
                //bottom left
                baseStyle = {
                    ...baseStyle,
                    bottom: '8px',
                    left: '8px',
                };
            }
        } else if (props.config.watermarkX > 50) {
            if (props.config.watermarkY == 50) {
                // center right
                baseStyle = {
                    ...baseStyle,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '8px',
                };
            } else if (props.config.watermarkY < 50) {
                // top right
                baseStyle = {
                    ...baseStyle,
                    top: '8px',
                    right: '8px',
                };
            } else {
                //bottom right
                baseStyle = {
                    ...baseStyle,
                    bottom: '8px',
                    right: '8px',
                };
            }
        }

        return baseStyle;
    };

    const getWidth = () => {
        return subRef.current?.el?.current?.clientWidth;
    };


    const MiniPlayerSubtitleProps = {
        nearest,
        styleRef,
        currentIndex,
        styleString,
        headingStyleString,
        headingTextContainerPosition,
        fontName,
        fontData,
        ...props
    }

    return (
        <Style
            className="miniPlayer"
            style={{
                background: props.fullscreen ? '#000' : '',
            }}
        >
            <div ref={styleRef} className="vid-container mx-auto" style={getContainerAspectRatio()}>
                <div className={`video`} style={getAspectRatio()} ref={miniPlayer}>
                    <VideoWrap {...props}></VideoWrap>
                </div>
                <div className="style-container">
                    {props.projectData && props.projectData.watermark_url != '' ? (
                        <div
                            className="container"
                            style={{
                                height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                            }}
                        >
                            <img src={props.projectData.watermark_url} style={getLogoStyle()} />
                        </div>
                    ) : (
                        ''
                    )}
                    {props.access && props.access.userPackage.remove_scribe_watermark ? (
                        ''
                    ) : (
                        <div
                            className="container"
                            style={{
                                height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                            }}
                        >
                            <img
                                src="scribe-name.png"
                                style={{
                                    display: 'flex',
                                    zIndex: 20,
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    opacity: 0.5,
                                    height: props.miniPlayer ? (props.miniPlayer.offsetHeight * 10) / 100 : 0, //(props.player.offsetHeight / props.projectData.header.height) * 50
                                    objectFit: 'contain',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translateY(-50%) translateX(-50%)',
                                }}
                            />
                        </div>
                    )}
                    <div
                        className="container"
                        onClick={props.playPauseAction}
                        style={{
                            height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                        }}
                    >
                        {styleRef &&
                        (props.config.heading != '' || props.config.heading != null) &&
                        (!props.config.customHeadingTime || state.miniCurrentTime <= props.config.headingTime) ? (
                            <TitleRND
                                {...props}
                                disableDragging={true}
                                headingFontData={headingFontData}
                                styleRef={styleRef}
                                heading_fontName={heading_fontName}
                            />
                        ) : null}

                        {props.miniPlayer &&
                        state.miniCurrentSub &&
                        props.translationHeader &&
                        !props.translationHeader.locked ? (
                            
                            <MiniPlayerSubtitle {...MiniPlayerSubtitleProps}/>
                            // <>
                            //     <div className={`subtitle`} style={getTextContainerPosition()}>
                            //         <ContentEditable
                            //             ref={subRef}
                            //             className={`textarea ${styleString} ${
                            //                 currentSub.text == null || currentSub.text == '' ? 'hidden' : ''
                            //             }`}
                            //             style={{
                            //                 color: props.config.fontColor,
                            //                 direction: props.translateState
                            //                     ? props.currentLanguageObject && props.currentLanguageObject.rtl == 1
                            //                         ? 'rtl'
                            //                         : 'ltr'
                            //                     : props.projectData && props.projectData.file.language.rtl === 1
                            //                     ? 'rtl'
                            //                     : 'ltr',
                            //                 font: `${calculateFontSize()}px ${fontName}`,
                            //                 width: `auto`,
                            //                 padding: '3px',
                            //                 margin: '3px',
                            //                 backgroundColor:
                            //                     props.config.effect === 'Background'
                            //                         ? props.config.backgroundColor
                            //                         : 'rgba(36,37,46,.96)',
                            //                 borderColor:
                            //                     props.config.effect === 'Background'
                            //                         ? props.config.backgroundColor
                            //                         : 'rgba(36,37,46,.96)',
                            //                 textShadow:
                            //                     props.config.effect === 'Outline'
                            //                         ? `-2px -2px 0 ${props.config.backgroundColor}, 2px -2px 0 ${props.config.backgroundColor}, -2px 2px 0 ${props.config.backgroundColor}, 2px 2px 0 ${props.config.backgroundColor}`
                            //                         : props.config.effect === 'Drop Shadow'
                            //                         ? `5pt 5pt ${props.config.backgroundColor}`
                            //                         : '',
                            //             }}
                            //             disabled={
                            //                 (props.access &&
                            //                     props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                            //                     props.access.accessMapping.role.toLowerCase() !== 'editor') ||
                            //                 state.voiceOverAudio !== null ||
                            //                 state.voiceOverLoading
                            //                     ? true
                            //                     : false
                            //             }
                            //             id="test"
                            //             onChange={(event) => {
                            //                 let position = getCaretPosition(subRef.current.el.current);
                            //                 updateText(event.target.value).then(() =>
                            //                     setCaretPosition(
                            //                         subRef.current.el.current,
                            //                         position.offsets.start,
                            //                         position.offsets.start,
                            //                     ),
                            //                 );
                            //             }}
                            //             onClick={onClick}
                            //             onFocus={onFocus}
                            //             onKeyDown={onFocus}
                            //             spellCheck={false}
                            //             value={currentSub.text}
                            //             html={removePositionTag(currentSub.text)}
                            //             // tagName="input"
                            //         />
                            //     </div>
                            //     {/* <div
                            //         className="subtitle subtitle-number"
                            //         style={{
                            //             ...getTextContainerPosition(),
                            //             transform: `translateY(${getTranslateYSubNumber()}) translateX(${getTranslateXSubNumber()})`,
                            //         }}
                            //     >
                            //         <div>#{currentIndex + 1}</div>
                            //     </div> */}
                            // </>
                        ) : null}
                    </div>
                </div>
            </div>
        </Style>
    );
}
