import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { useEffect, useRef, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Rnd } from 'react-rnd';
import '../css/rcp.css';
import { useActions, useAppState } from '../overmind';
import { uploadCover } from '../requests/SubtitleRequest';

const AudioCoverRND = ({
    id,
    config,
    setConfig,
    setProjectData,
    projectData,
    showCover,
    setShowCover,
    videoCoverRef,
    currentLanguage,
    ...props
}) => {
    const actions = useActions();
    const state = useAppState();
    const rndRef = useRef();
    const imgRef = useRef();
    const [stateRnd, setStateRnd] = useState({
        x: 10,
        y: 10,
        width: '',
        height: '',
    });

    useEffect(async () => {
        if (state.audioCoverActive && state.audioCoverSrc && state.audioCoverFilename) {
            setStateRnd({
                width: videoCoverRef.current.clientWidth * 0.4,
                height: 'auto',
                x: 10,
                y: 10,
            });
        }
    }, [state.audioCoverActive, state.audioCoverSrc, state.audioCoverFilename, config]);

    const uploadCoverRnd = () => {
        fetch(state.audioCoverSrc)
            .then((res) => res.blob())
            .then(async (blob) => {
                let formData = new FormData();
                formData.append('id', id);
                formData.append('ratio', config.ratio);
                formData.append('filename', state.audioCoverFilename);
                formData.append('cover', blob);
                formData.append('main_width', videoCoverRef.current.clientWidth);
                formData.append('width', imgRef.current.clientWidth);
                formData.append('main_height', videoCoverRef.current.clientHeight);
                formData.append('height', imgRef.current.clientHeight);
                formData.append(
                    'percent_width',
                    ((imgRef.current.clientWidth / videoCoverRef.current.clientWidth) * 100).toFixed(2),
                );
                formData.append(
                    'percent_height',
                    ((imgRef.current.clientHeight / videoCoverRef.current.clientHeight) * 100).toFixed(2),
                );
                formData.append('position_x', stateRnd.x);
                formData.append('position_y', stateRnd.y);
                formData.append(
                    'percent_position_x',
                    ((stateRnd.x / videoCoverRef.current.clientWidth) * 100).toFixed(2),
                );
                formData.append(
                    'percent_position_y',
                    ((stateRnd.y / videoCoverRef.current.clientHeight) * 100).toFixed(2),
                );
                formData.append('language_id', currentLanguage);

                actions.setAudioPlayerLoading(true);

                const cover = await uploadCover(formData);
                setProjectData((prevData) => ({
                    ...prevData,
                    cover_url: cover.cover_url,
                    cover: cover.cover,
                }));

                setConfig((prevData) => ({
                    ...prevData,
                    coverFileName: cover.cover.file_name,
                }));

                actions.setAudioPlayerLoading(false);
                actions.setAudioCoverActive(false);
                actions.setAudioCoverSrc(null);
                actions.setAudioCoverFilename(null);
            });
    };

    const [styleImage, setStyleImage] = useState({
        width: '100%',
        height: '100%',
    });

    const style = {
        border: '1.5px solid rgb(56, 231, 255)',
        zIndex: '21',
    };

    const CornerHandle = () => (
        <div
            style={{
                position: 'absolute',
                top: '4px',
                left: '4px',
                width: '12px',
                height: '12px',
                background: 'rgb(255, 255, 255)',
                boxShadow: 'rgba(0, 0, 0, 0.25) 3px 3px 3px',
                borderRadius: '999px',
            }}
        />
    );

    return (
        <>
            {videoCoverRef && (
                <Rnd
                    ref={rndRef}
                    bounds=".rnd-cover"
                    style={style}
                    size={{ width: stateRnd.width, height: stateRnd.height }}
                    position={{ x: stateRnd.x, y: stateRnd.y }}
                    onDragStop={(e, d) => {
                        setStateRnd({
                            width: stateRnd.width,
                            height: stateRnd.height,
                            x: d.x,
                            y: d.y,
                        });
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setStateRnd({
                            width: ref.style.width,
                            height: ref.style.height,
                            x: position.x,
                            y: position.y,
                        });
                    }}
                    lockAspectRatio={true}
                    resizeHandleComponent={{
                        bottomRight: <CornerHandle />,
                        topRight: <CornerHandle />,
                        bottomLeft: <CornerHandle />,
                        topLeft: <CornerHandle />,
                    }}
                >
                    <img
                        ref={imgRef}
                        style={styleImage}
                        src={state.audioCoverSrc}
                        alt={state.audioCoverFilename}
                        draggable={false}
                    />

                    <Stack
                        style={{
                            position: 'absolute',
                            background: 'transparent',
                            paddingTop: '0.5rem',
                        }}
                        direction="row"
                        spacing={2}
                    >
                        <Button
                            onClick={() => {
                                if (state.audioPlayerLoading) {
                                    return false;
                                }

                                uploadCoverRnd();
                            }}
                            variant="contained"
                        >
                            {state.audioPlayerLoading ? 'Saving...' : 'Save'}
                        </Button>
                        {!state.audioPlayerLoading && (
                            <Button
                                onClick={() => {
                                    actions.setAudioCoverActive(false);
                                    actions.setAudioCoverSrc(null);
                                    actions.setAudioCoverFilename(null);
                                }}
                                style={{ backgroundColor: '#c70422ff', color: '#FFFFFF' }}
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        )}
                    </Stack>
                </Rnd>
            )}
        </>
    );
};

export default AudioCoverRND;
