const en = {
    LOADING: 'Loading...',
    SUB_TEXT: 'Subtitle Text',
    OPEN_VIDEO: 'Open Video',
    OPEN_SUB: 'Open Subtitle',
    SAVE: 'Save',
    BOLD: 'B',
    EXPORT_ASS: 'Export ASS',
    EXPORT_SRT: 'Export SRT',
    EXPORT_VTT: 'Export VTT',
    EXPORT_TXT: 'Export TXT',
    EXPORT_VIDEO_WITH_SUB: 'Export Video',
    EXPORT_VIDEO: 'Export Video',
    TRANSLATE: 'Translation',
    TRANSLATING: 'Translation...',
    TRANSLAT_SUCCESS: 'Translation success',
    DECODE_START: 'Start decoding audio',
    DECODE_SUCCESS: 'Decoding audio success',
    DECODE_ERROR: 'Decoding audio failed',
    VIDEO_EXT_ERR: 'Unable to open this video format',
    SUB_EXT_ERR: 'Unable to open this subtitle format',
    MOBILE_TIP: 'Please use computer access',
    HOTKEY_01: 'Space: Play / Pause',
    HOTKEY_02: 'Ctrl + Z: Undo',
    CLEAR: 'Clear',
    UNDO: 'Undo',
    DELETE: 'Delete Subtitle',
    MERGE: 'Merge Next',
    SPLIT: 'Split Subtitle',
    LOADING_FFMPEG: 'Loading dependency...',
    LOADING_FONT: 'Loading font...',
    LOADING_VIDEO: 'Loading video...',
    LOADING_SUB: 'Loading subtitle...',
    CLEAR_TIP: 'Confirm that all data is cleared',
    BURN_ERROR: 'Burn subtitles failed',
    BURN_START: 'Start burn subtitles',
    BURN_SUCCESS: 'Burn subtitles success',
    OPEN_VIDEO_ERROR: 'Please open the video first',
};

const it = {
    LOADING: 'Caricamento in corso...',
    SUB_TEXT: 'Sottotitoli',
    OPEN_VIDEO: 'Apri video',
    OPEN_SUB: 'Apri sottotitoli',
    EXPORT_ASS: 'Esporta ASS',
    EXPORT_SRT: 'Esporta SRT',
    EXPORT_VTT: 'Esporta VTT',
    EXPORT_VIDEO: 'Esporta video',
    TRANSLATE: 'Traduci',
    TRANSLATING: 'Traduzione...',
    TRANSLAT_SUCCESS: 'Traduzione riuscita',
    DECODE_START: 'Avvia decodifica audio',
    DECODE_SUCCESS: 'Decodifica audio riuscita',
    DECODE_ERROR: 'Decodifica audio non riuscita',
    VIDEO_EXT_ERR: 'Impossibile aprire questo formato video',
    SUB_EXT_ERR: 'Impossibile aprire questo formato di sottotitoli',
    MOBILE_TIP: "Utilizza l'accesso al computer",
    HOTKEY_01: 'Spazio: Riproduci / Pausa',
    HOTKEY_02: 'Ctrl + Z: Annulla',
    CLEAR: 'Pulisci',
    UNDO: 'Annulla',
    DELETE: 'Elimina sottotitoli',
    MERGE: 'Unisci dopo',
    SPLIT: 'Dividi sottotitoli',
    LOADING_FFMPEG: 'Caricamento dipendenza in corso...',
    LOADING_FONT: 'Caricamento font in corso...',
    LOADING_VIDEO: 'Caricamento video in corso...',
    LOADING_SUB: 'Caricamento sottotitoli in corso...',
    CLEAR_TIP: 'Conferma che tutti i dati siano stati cancellati',
    BURN_ERROR: 'Masterizzazione sottotitoli non riuscita',
    BURN_START: 'Avvia masterizzazione sottotitoli',
    BURN_SUCCESS: 'Masterizzazione sottotitoli riuscita',
    OPEN_VIDEO_ERROR: 'Apri prima il video',
};

const tr = {
    LOADING: 'Loading...',
    SUB_TEXT: 'Subtitle Text',
    OPEN_VIDEO: 'Video Aç',
    OPEN_SUB: 'Altyazı Aç',
    EXPORT_ASS: 'ASS Çıkar',
    EXPORT_SRT: 'SRT Çıkar',
    EXPORT_VTT: 'VTT Çıkar',
    EXPORT_VIDEO: 'Videoyu Çıkar',
    TRANSLATE: 'Çevir',
    TRANSLATING: 'Çevriliyor...',
    TRANSLAT_SUCCESS: 'Çevirme başarılı',
    DECODE_START: 'Ses çözümlemesi başladı',
    DECODE_SUCCESS: 'Ses çözümlemesi başarılı',
    DECODE_ERROR: 'Ses çözümlemesi başarısız',
    VIDEO_EXT_ERR: 'Bu video biçimi açılamıyor',
    SUB_EXT_ERR: 'Bu altyazı biçimi açılamıyor',
    MOBILE_TIP: 'Lütfen bilgisayardan erişin',
    HOTKEY_01: 'Boşluk: Oynat / Duraklat',
    HOTKEY_02: 'Ctrl + Z: Geri Al',
    CLEAR: 'Temizle',
    UNDO: 'Geri Al',
    DELETE: 'Altyazıyı Sil',
    MERGE: 'Sonrakiyle Birleştir',
    SPLIT: 'Altyazıyı Böl',
    LOADING_FFMPEG: 'Bağımlılık yükleniyor...',
    CLEAR_TIP: 'Tüm verilerin silineceğini onaylıyor musunuz?',
    BURN_ERROR: 'Altyazı gömme başarısız',
    BURN_START: 'Altyazı gömmeyi başlat',
    BURN_SUCCESS: 'Altyazı gömme başarılı',
    OPEN_VIDEO_ERROR: 'Lütfen önce videoyu açın',
};

const zh = {
    LOADING: '加载中...',
    SUB_TEXT: '字幕文本',
    OPEN_VIDEO: '打开视频',
    OPEN_SUB: '打开字幕',
    EXPORT_ASS: '导出 ASS',
    EXPORT_SRT: '导出 SRT',
    EXPORT_VTT: '导出 VTT',
    EXPORT_VIDEO: '导出视频',
    TRANSLATE: '批量翻译',
    TRANSLATING: '翻译中...',
    TRANSLAT_SUCCESS: '翻译成功',
    DECODE_START: '开始解码音频',
    DECODE_SUCCESS: '解码音频成功',
    DECODE_ERROR: '解码音频失败',
    VIDEO_EXT_ERR: '无法打开该视频格式',
    SUB_EXT_ERR: '无法打开该字幕格式',
    MOBILE_TIP: '请使用电脑访问',
    HOTKEY_01: '空格键: 播放 / 暂停',
    HOTKEY_02: 'Ctrl + Z: 撤销',
    CLEAR: '清空',
    UNDO: '撤销',
    DELETE: '删除字幕',
    MERGE: '合并下一个',
    SPLIT: '拆分字幕',
    LOADING_FFMPEG: '加载依赖中...',
    LOADING_FONT: '加载字体中...',
    LOADING_VIDEO: '加载视频中...',
    LOADING_SUB: '加载字幕中...',
    CLEAR_TIP: '确认清空所有数据吗',
    BURN_ERROR: '合成字幕失败',
    BURN_START: '开始合成字幕',
    BURN_SUCCESS: '合成字幕成功',
    OPEN_VIDEO_ERROR: '请先打开视频',
};

const i18n = {
    en,
    it,
    zh,
    'zh-cn': zh,
    'zh-tw': zh,
    tr,
};

export default i18n;
