import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ColorPicker, toColor, useColor } from 'react-color-palette';
import { Folder, X } from 'react-feather';
import styles from '../../css/SubtitleEdit.module.css';
import AspectRatioPicker from '../AspectRatioPicker.js';
import Effect from '../Effect.js';
import FontFamilyPicker from '../FontFamilyPicker.js';
import FontSizePicker from '../FontSizePicker.js';
import HeadingTimeDropdown from '../HeadingTimeDropdown';
import AlignCenterIcon from '../Icon/AlignCenterIcon.js';
import AlignLeftIcon from '../Icon/AlignLeftIcon.js';
import AlignRightIcon from '../Icon/AlignRightIcon.js';
import AspectRatioIcon from '../Icon/AspectRatioIcon.js';
import BoldIcon from '../Icon/BoldIcon.js';
import ItalicIcon from '../Icon/ItalicIcon.js';
import SubtitleIcon from '../Icon/SubtitleIcon.js';
import TypeIcon from '../Icon/TypeIcon.js';
import UnderlineIcon from '../Icon/UnderlineIcon.js';
import WaveIcon from '../Icon/WaveIcon.js';
import LogoBar from '../LogoBar.js';
import Position from '../Position.js';
import VerticalMarginSlider from '../VerticalMarginSlider';

const ToolbarRight = (props) => {
    const { config, setConfig, user, id } = props;

    const [showCc, setShowCc] = useState(false);
    const [showType, setShowType] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [showRatio, setShowRatio] = useState(false);
    const [showTemplate, setShowTemplate] = useState(false);
    const [activeColor, setActiveColor] = useState(null);

    const [fontColorModal, setFontColorModal] = React.useState(false);
    const [headingFontColor, setHeadingFontColor] = useColor('hex', config.heading_fontColor);
    const [headingFontColorOld, setHeadingFontColorOld] = useColor('hex', config.heading_fontColor);
    const [fontColor, setFontColor] = useColor('hex', config.fontColor);
    const [fontColorOld, setFontColorOld] = useColor('hex', config.fontColor);
    const [headingBackgroundColor, setHeadingBackgroundColor] = useColor('hex', config.heading_backgroundColor);
    const [headingBackgroundColorOld, setHeadingBackgroundColorOld] = useColor('hex', config.heading_backgroundColor);
    const [backgroundColor, setBackgroundColor] = useColor('hex', config.backgroundColor);
    const [backgroundColorOld, setBackgroundColorOld] = useColor('hex', config.backgroundColor);
    const [subtitleHighlight, setSubtitleHighlight] = useState(config.box_highlight);
    const [boxHighlightColor, setBoxHighlightColor] = useColor('hex', config.box_highlight_color);
    const [boxHighlightColorOld, setBoxHighlightColorOld] = useColor('hex', config.box_highlight_color);
    
    const subtitleRef = useRef(null);
    const typeRef = useRef(null);
    const imageRef = useRef(null);
    const ratioRef = useRef(null);
    const templateRef = useRef(null);

    const handleFontColorModal = (isSave, type = null) => {
        if (!type) type = activeColor;
        else setActiveColor(type);
        setFontColorModal(!fontColorModal);
        if (isSave === undefined) return;
        if (type === 'heading_fontColor')
            if (isSave) {
                onConfigClick('heading_fontColor', headingFontColor.hex);
                setHeadingFontColorOld(headingFontColor);
            } else {
                setHeadingFontColor(headingFontColorOld);
            }
        else if (type === 'fontColor')
            if (isSave) {
                onConfigClick('fontColor', fontColor.hex);
                setFontColorOld(fontColor);
            } else {
                setFontColor(fontColorOld);
            }
        else if (type === 'heading_backgroundColor')
            if (isSave) {
                onConfigClick('heading_backgroundColor', headingBackgroundColor.hex);
                setHeadingBackgroundColorOld(headingBackgroundColor);
            } else {
                setHeadingBackgroundColor(headingBackgroundColorOld);
            }
        else if (type === 'backgroundColor')
            if (isSave) {
                onConfigClick('backgroundColor', backgroundColor.hex);
                setBackgroundColorOld(backgroundColor);
            } else {
                setBackgroundColor(backgroundColorOld);
            }
        else if (type === 'boxHighlightColor')
            if (isSave) {
                onConfigClick('box_highlight_color', boxHighlightColor.hex);
                setBoxHighlightColorOld(boxHighlightColor);
            } else {
                setBoxHighlightColor(boxHighlightColorOld);
            }
    };

    const setColor = (color) => {
        switch (activeColor) {
            case 'heading_fontColor':
                setHeadingFontColor(color);
                break;
            case 'fontColor':
                setFontColor(color);
                break;
            case 'heading_backgroundColor':
                setHeadingBackgroundColor(color);
                break;
            case 'backgroundColor':
                setBackgroundColor(color);
                break;                
            case 'boxHighlightColor':
                setBoxHighlightColor(color);
                break;
        }
    };

    const onLogoPositionChange = (key, value) => {
        if (key == 'watermarkOpacity') {
            if (value < 0 || value > 1) {
                return;
            }
        } else {
            if (value < 0 || value > 100) {
                return;
            }
        }
        setConfig((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const onConfigClick = (key, value) => {
        setConfig((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    useEffect(() => {
        function handleClickOutsideCc(event) {
            if (subtitleRef.current && !subtitleRef.current.contains(event.target)) {
                setShowCc(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutsideCc);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideCc);
        };
    }, [subtitleRef]);

    useEffect(() => {
        function handleClickOutsideType(event) {
            if (typeRef.current && !typeRef.current.contains(event.target)) {
                setShowType(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutsideType);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideType);
        };
    }, [typeRef]);

    useEffect(() => {
        function handleClickOutsideImage(event) {
            if (imageRef.current && !imageRef.current.contains(event.target)) {
                setShowImage(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutsideImage);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideImage);
        };
    }, [imageRef]);

    useEffect(() => {
        function handleClickOutsideRatio(event) {
            if (ratioRef.current && !ratioRef.current.contains(event.target)) {
                setShowRatio(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutsideRatio);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideRatio);
        };
    }, [ratioRef]);

    useEffect(() => {
        function handleClickOutsideTemplate(event) {
            if (templateRef.current && !templateRef.current.contains(event.target)) {
                setShowTemplate(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutsideTemplate);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideTemplate);
        };
    }, [templateRef]);

    const hideAll = (type = null) => {
        setShowCc(type === 'cc' ? !showCc : false);
        setShowType(type === 'type' ? !showType : false);
        setShowImage(type === 'image' ? !showImage : false);
        setShowRatio(type === 'ratio' ? !showRatio : false);
        setShowTemplate(type === 'template' ? !showTemplate : false);
    };

    const [sliderValue, setSliderValue] = useState(config.watermarkOpacity);

    const handleSliderUp = () => {
        onConfigClick('watermarkOpacity', sliderValue);
    };

    const [title, setTitle] = React.useState(null);
    React.useEffect(() => {
        setTitle(config.heading === null || config.heading === undefined ? '' : config.heading);
        setSubtitleHighlight(config.box_highlight)
        setBoxHighlightColor(toColor('hex',config.box_highlight_color))
        setBoxHighlightColorOld(toColor('hex',config.box_highlight_color))
    }, [config]);

    const debouncedSave = useCallback(
        debounce((nextValue) => {
            onConfigClick('heading', nextValue);
        }, 1000),
        [],
    );

    return (
        <div className="video-action-right rounded-3px hide-fullscreen hide-for-mobile">
            <Modal
                open={fontColorModal}
                onClose={() => {
                    handleFontColorModal(false);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: 'unset',
                        outline: 'none',
                    }}
                >
                    {activeColor === 'heading_fontColor' ? (
                        <ColorPicker
                            width={456}
                            height={228}
                            color={headingFontColor}
                            onChange={(color) => setColor(color)}
                            hideHSB
                            alpha
                            hideHSV
                            dark
                        />
                    ) : (
                        <></>
                    )}
                    {activeColor === 'fontColor' ? (
                        <ColorPicker
                            width={456}
                            height={228}
                            color={fontColor}
                            onChange={(color) => setColor(color)}
                            hideHSB
                            alpha
                            hideHSV
                            dark
                        />
                    ) : (
                        <></>
                    )}
                     {activeColor === 'boxHighlightColor' ? (                    
                        <ColorPicker
                            width={456}
                            height={228}
                            color={boxHighlightColor}
                            onChange={(color) => setColor(color)}
                            hideHSB
                            alpha
                            hideHSV
                            dark
                        />
                    ) : (
                        <></>
                    )}
                    {activeColor === 'heading_backgroundColor' ? (
                        <ColorPicker
                            width={456}
                            height={228}
                            color={headingBackgroundColor}
                            onChange={(color) => setColor(color)}
                            hideHSB
                            alpha
                            hideHSV
                            dark
                        />
                    ) : (
                        <></>
                    )}
                    {activeColor === 'backgroundColor' ? (
                        <ColorPicker
                            width={456}
                            height={228}
                            color={backgroundColor}
                            onChange={(color) => setColor(color)}
                            hideHSB
                            alpha
                            hideHSV
                            dark
                        />
                    ) : (
                        <></>
                    )}
                    <Stack
                        style={{
                            backgroundColor: '#181818',
                            padding: '0 20px 20px',
                            borderBottomRightRadius: '10px',
                            borderBottomLeftRadius: '10px',
                        }}
                        direction="row"
                        className="flex justify-end gap-2"
                    >
                        <Button
                            onClick={() => {
                                handleFontColorModal(false);
                            }}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleFontColorModal(true);
                            }}
                            variant="contained"
                        >
                            Save
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            <div className={`video-action-item ${showCc ? 'active' : ''}`}>
                <Tooltip title="Subtitle Design" placement="left" arrow>
                    <div
                        className="video-action-icon"
                        onClick={() => {
                            hideAll('cc');
                        }}
                    >
                        <SubtitleIcon className="w-5 h-5" />
                    </div>
                </Tooltip>
                <div className="video-action-menu" style={{ display: showCc ? '' : 'none' }} ref={subtitleRef}>
                    <div className="font-bold flex items-center justify-between video-action-header">
                        Subtitle Design
                        <X
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => {
                                hideAll(null);
                            }}
                        />
                    </div>
                    <div className="video-action-body flex flex-col gap-2">
                        <div className="flex items-center text-white">
                            <FontFamilyPicker
                                name="fontFamily"
                                fontFamily={config.fontFamily}
                                onConfigClick={onConfigClick}
                                user={user}
                                {...props}
                                id={id}
                            />
                            <FontSizePicker name="fontSize" fontSize={config.fontSize} onConfigClick={onConfigClick} />
                        </div>
                        <div className="divider divider-dark m-0"></div>
                        <div className="flex toolbar-full">
                            {/* Bold */}
                            <div
                                className={`btn-toolbar ${styles.bold} ${config.bold ? 'active' : ''}`}
                                onClick={() => onConfigClick('bold', !config.bold)}
                            >
                                <BoldIcon />
                            </div>
                            {/* Italic */}
                            <div
                                className={`btn-toolbar ${styles.italic} ${config.italic ? 'active' : ''}`}
                                onClick={() => onConfigClick('italic', !config.italic)}
                            >
                                <ItalicIcon />
                            </div>
                            {/* Underline */}
                            <div
                                className={`btn-toolbar ${styles.underline} ${config.underline ? 'active' : ''}`}
                                onClick={() => onConfigClick('underline', !config.underline)}
                            >
                                <UnderlineIcon />
                            </div>
                            {/* font color */}
                            <div
                                className={`btn-toolbar`}
                                onClick={() => {
                                    handleFontColorModal(false, 'fontColor');
                                }}
                            >
                                <img srcSet="./src/images/ColorIcon.png" className="w-4 h-4" />
                            </div>
                        </div>
                        <div className="divider divider-dark m-0"></div>
                        <div className="flex toolbar-full">
                            {/* Align left */}
                            <div
                                className={`btn-toolbar ${config.align === 'left' ? 'active' : ''}`}
                                onClick={() => onConfigClick('align', 'left')}
                            >
                                <AlignLeftIcon />
                            </div>
                            {/* Align center */}
                            <div
                                className={`btn-toolbar ${config.align === 'center' ? 'active' : ''}`}
                                onClick={() => onConfigClick('align', 'center')}
                            >
                                <AlignCenterIcon />
                            </div>
                            {/* Align right */}
                            <div
                                className={`btn-toolbar ${config.align === 'right' ? 'active' : ''}`}
                                onClick={() => onConfigClick('align', 'right')}
                            >
                                <AlignRightIcon />
                            </div>
                        </div>
                        <div className="divider divider-dark m-0"></div>
                        <div className="toolbar-full flex items-center text-white">
                            <Position name="position" position={config.position} onConfigClick={onConfigClick} />
                            {config.position === 'Custom' ? (
                                <VerticalMarginSlider config={config} setConfig={setConfig} {...props} />
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="divider divider-dark m-0"></div>
                        <div className="flex items-center gap-2">
                            {/* background color */}
                            <div
                                className={`btn-toolbar mr-2`}
                                style={{ width: '61px' }}
                                onClick={() => {
                                    handleFontColorModal(false, 'backgroundColor');
                                }}
                            >
                                <img srcSet="./src/images/ColorIcon.png" className="w-4 h-4" />
                            </div>
                            <Effect name="effect" effect={config.effect} onConfigClick={onConfigClick} />
                        </div>
                        <div className="divider divider-dark m-0"></div>
                        <div className="flex items-center gap-2">
                            {/* subtitle highlight color */}
                            <div className="flex flex-col sm:flex-row" style={{height:'32px'}}>
                                <div className="form-check ">                                    
                                    <input
                                        id="checkbox-box-highlight"
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={subtitleHighlight}
                                        onChange={() => {
                                            onConfigClick('box_highlight', !subtitleHighlight)
                                            setSubtitleHighlight(!subtitleHighlight)
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        for="checkbox-box-highlight"
                                    >
                                        Enable subtitle highlight
                                    </label>
                                </div>
                            </div>
                            {subtitleHighlight ? <div
                                className={`btn-toolbar`}
                                style={{ width: '61px' }}
                                onClick={() => {
                                    handleFontColorModal(false, 'boxHighlightColor');
                                }}
                            >
                                <img srcSet="./src/images/ColorIcon.png" className="w-4 h-4" />
                            </div>:''}
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className={`video-action-item ${showType ? 'active' : ''}`}>
                <Tooltip title="Title" placement="left" arrow>
                    <div
                        className="video-action-icon"
                        onClick={() => {
                            hideAll('type');
                        }}
                    >
                        <TypeIcon className="w-5 h-5" />
                    </div>
                </Tooltip>
                <div className="video-action-menu" style={{ display: showType ? '' : 'none' }} ref={typeRef}>
                    <div className="font-bold flex items-center justify-between video-action-header">
                        Title
                        <X
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => {
                                hideAll(null);
                            }}
                        />
                    </div>
                    <div className="video-action-body flex flex-col gap-2">
                        {props.access && props.access.userPackage.resize_video ? (
                            <>
                                <input
                                    type="text"
                                    className="form-control input-dark mb-2"
                                    style={{ fontFamily: 'GorditaRegular' }}
                                    placeholder="Type your title"
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        debouncedSave(e.target.value);
                                    }}
                                />
                                <div className="flex items-center bg-control-dark text-white">
                                    <FontFamilyPicker
                                        name="heading_fontFamily"
                                        fontFamily={config.heading_fontFamily}
                                        onConfigClick={onConfigClick}
                                        user={user}
                                        id={id}
                                        {...props}
                                    />
                                    <FontSizePicker
                                        name="heading_fontSize"
                                        fontSize={config.heading_fontSize}
                                        onConfigClick={onConfigClick}
                                    />
                                </div>
                                <div className="divider divider-dark m-0"></div>
                                <div className="toolbar-full flex items-center bg-control-dark text-white">
                                    <HeadingTimeDropdown
                                        className={`btn-toolbar`}
                                        config={config}
                                        setConfig={setConfig}
                                    />
                                </div>
                                <div className="divider divider-dark m-0"></div>
                                <div className="flex toolbar-full">
                                    {/* Bold */}
                                    <div
                                        className={`btn-toolbar ${styles.bold} ${config.heading_bold ? 'active' : ''}`}
                                        onClick={() => onConfigClick('heading_bold', !config.heading_bold)}
                                    >
                                        <BoldIcon />
                                    </div>
                                    {/* Italic */}
                                    <div
                                        className={`btn-toolbar ${styles.italic} ${
                                            config.heading_italic ? 'active' : ''
                                        }`}
                                        onClick={() => onConfigClick('heading_italic', !config.heading_italic)}
                                    >
                                        <ItalicIcon />
                                    </div>
                                    {/* Underline */}
                                    <div
                                        className={`btn-toolbar ${styles.underline} ${
                                            config.heading_underline ? 'active' : ''
                                        }`}
                                        onClick={() => onConfigClick('heading_underline', !config.heading_underline)}
                                    >
                                        <UnderlineIcon />
                                    </div>
                                    {/* font color */}
                                    <div
                                        className={`btn-toolbar`}
                                        onClick={() => {
                                            handleFontColorModal(false, 'heading_fontColor');
                                        }}
                                    >
                                        <img srcSet="./src/images/ColorIcon.png" className="w-4 h-4" />
                                    </div>
                                </div>
                                <div className="divider divider-dark m-0"></div>
                                <div className="flex items-center gap-2">
                                    {/* backround color */}
                                    <div
                                        className={`btn-toolbar mr-2`}
                                        style={{ width: '61px' }}
                                        onClick={() => {
                                            handleFontColorModal(false, 'heading_backgroundColor');
                                        }}
                                    >
                                        <img srcSet="./src/images/ColorIcon.png" className="w-4 h-4" />
                                    </div>
                                    <Effect
                                        name="heading_effect"
                                        effect={config.heading_effect}
                                        onConfigClick={onConfigClick}
                                    />
                                </div>
                            </>
                        ) : (
                            'Upgrade to use this features'
                        )}
                    </div>
                </div>
            </div>
            <div className={`video-action-item ${showImage ? 'active' : ''}`}>
                <Tooltip title="Watermark Logo" placement="left" arrow>
                    <div
                        className="video-action-icon"
                        onClick={() => {
                            hideAll('image');
                        }}
                    >
                        <WaveIcon className="w-5 h-5" />
                    </div>
                </Tooltip>
                <div className="video-action-menu" style={{ display: showImage ? '' : 'none' }} ref={imageRef}>
                    <div className="font-bold flex items-center justify-between video-action-header">
                        Logo
                        <X
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => {
                                hideAll(null);
                            }}
                        />
                    </div>
                    <div className="video-action-body flex flex-col gap-2">
                        {props.access && props.access.userPackage.add_watermark ? (
                            <LogoBar {...props} />
                        ) : (
                            'Upgrade to use this features'
                        )}
                    </div>
                </div>
            </div>
            <div className={`video-action-item ${showRatio ? 'active' : ''}`}>
                <Tooltip title="Aspect Ratio" placement="left" arrow>
                    <div
                        className="video-action-icon"
                        onClick={() => {
                            hideAll('ratio');
                        }}
                    >
                        <AspectRatioIcon className="w-5 h-5" />
                    </div>
                </Tooltip>
                <div className="video-action-menu" style={{ display: showRatio ? '' : 'none' }} ref={ratioRef}>
                    <div className="font-bold flex items-center justify-between video-action-header">
                        Aspect Ratio
                        <X
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => {
                                hideAll(null);
                            }}
                        />
                    </div>
                    <div className="video-action-body flex flex-col gap-2">
                        {props.access && props.access.userPackage.resize_video ? (
                            <AspectRatioPicker {...props} />
                        ) : (
                            'Upgrade to use this features'
                        )}
                    </div>
                </div>
            </div>
            <div className={`video-action-item ${showTemplate ? 'active' : ''}`}>
                <Tooltip title="Design Templates" placement="left" arrow>
                    <div
                        className="video-action-icon"
                        onClick={() => {
                            hideAll('template');
                        }}
                    >
                        <Folder className="w-5 h-5" />
                    </div>
                </Tooltip>
                <div className="video-action-menu" style={{ display: showTemplate ? '' : 'none' }} ref={templateRef}>
                    <div className="font-bold flex items-center justify-between video-action-header">
                        Design Templates
                        <X
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => {
                                hideAll(null);
                            }}
                        />
                    </div>
                    <div className="video-action-body flex flex-col gap-2">
                        {props.access && props.access.userPackage.save_and_edit_template ? (
                            <>
                                <a
                                    href="javascript:;"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        props.setSaveTemplateModal(true);
                                    }}
                                    className="btn btn-lexigo-green text-white font-bold w-full mr-3 rounded-3px mb-2"
                                >
                                    Save Template
                                </a>
                                <a
                                    href="javascript:;"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        props.setLoadTemplateModal(true);
                                    }}
                                    className="btn text-white font-bold w-full mr-3 rounded-3px mb-2"
                                >
                                    Load Template
                                </a>
                            </>
                        ) : (
                            'Upgrade to use this features'
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ToolbarRight;
