import { Modal } from '@mui/material';
import React, { useState } from 'react';
import { X } from 'react-feather';
import { getTotalDurationRequest } from '../requests/UtilRequest';

const TranslateModal = ({
    config,
    setConfig,
    translateModal,
    setTranslateState,
    setPlaying,
    setTranslateModal,
    setIsTranslation,
    createTranslation,
    id,
    user,
    access,
    fetchTranslated,
    selectedLanguages,
    ...rest
}) => {
    const [confirmation1, setConfirmation1] = useState(false);
    const [confirmation2, setConfirmation2] = useState(false);
    const [confirmation3, setConfirmation3] = useState(false);

    const confirm = async () => {
        if (!access) return;
        if (confirmation1 && confirmation2 && confirmation3) {
            setConfig((prevState) => ({
                ...prevState,
                hideTranslateModal: !config.hideTranslateModal,
            }));
            setTranslateModal(false);
            let totalDuration = await getTotalDurationRequest(user.id, rest.projectData.file.workspace_id);
            const translationVideoDuration = parseFloat(rest.projectData.file.duration) * selectedLanguages.length;
            totalDuration = translationVideoDuration + parseFloat(totalDuration);
            const maxDuration = access.userPackage.length;
            if (totalDuration > maxDuration) {
                rest.setDurationExceedModal(true);
            } else {
                rest.setShowPricingModal(true);
            }
        }
    };

    return (
        <Modal
            open={translateModal}
            onClose={() => {
                setTranslateModal(false);
            }}
        >
            <div
                id="modal-translate"
                className="modal show"
                style={{
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    zIndex: 10000,
                }}
                tabIndex="-1"
                aria-hidden="true"
                data-backdrop="static"
                role="dialog"
            >
                <div className="modal-dialog">
                    <div className="modal-content h-full">
                        <div className="modal-header flex items-center justify-between">
                            <p className="modal-title font-bold">Translate Subtitle</p>
                            <a data-dismiss="modal" href="javascript:;" onClick={() => setTranslateModal(false)}>
                                <X className="w-5 h-5" />
                            </a>
                        </div>
                        <div className="modal-body h-full">
                            <div className="form-check mr-2 items-start">
                                <input
                                    id="checkbox-switch-1"
                                    checked={confirmation1}
                                    onChange={() => setConfirmation1(!confirmation1)}
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    style={{
                                        width: '14px',
                                        height: '14px',
                                    }}
                                />
                                <label className="form-check-label" for="checkbox-switch-1">
                                    Please confirm your original subtitles are final and have been checked for spelling,
                                    grammar and any other errors.
                                </label>
                            </div>
                            <div className="form-check mr-2 items-start mt-5">
                                <input
                                    id="checkbox-switch-2"
                                    checked={confirmation2}
                                    onChange={() => setConfirmation2(!confirmation2)}
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    style={{
                                        width: '14px',
                                        height: '14px',
                                    }}
                                />
                                <label className="form-check-label" for="checkbox-switch-2">
                                    This will increase the accuracy of your translation.
                                </label>
                            </div>
                            <div className="form-check mr-2 items-start mt-5">
                                <input
                                    id="checkbox-switch-3"
                                    checked={confirmation3}
                                    onChange={() => setConfirmation3(!confirmation3)}
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    style={{
                                        width: '14px',
                                        height: '14px',
                                    }}
                                />
                                <label className="form-check-label" for="checkbox-switch-3">
                                    Any edits made to the original subtitles after a translation order will not be
                                    reflected in the translation.
                                </label>
                            </div>
                            <div className="mt-5 text-center">
                                <button
                                    className={`btn text-white py-1 lg:py-2 font-bold rounded-3px flex-1 ${
                                        confirmation1 && confirmation2 && confirmation3
                                            ? 'btn-lexigo-green'
                                            : 'btn-lexigo-disabled'
                                    }`}
                                    onClick={() => {
                                        confirm();
                                    }}
                                    disabled={!confirmation1 && !confirmation2 && !confirmation3}
                                >
                                    I understand and confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default TranslateModal;
