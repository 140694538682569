"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconMenuItem = void 0;
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var system_1 = require("@mui/system");
var StyledMenuItem = (0, system_1.styled)(material_1.MenuItem)({
    paddingLeft: '4px',
    paddingRight: '4px',
    display: 'flex',
    justifyContent: 'space-between',
});
var StyledTypography = (0, system_1.styled)(material_1.Typography)({
    paddingLeft: '8px',
    paddingRight: '8px',
    textAlign: 'left',
});
var FlexBox = (0, system_1.styled)(system_1.Box)({
    display: 'flex',
});
var IconMenuItem = (0, react_1.forwardRef)(function (_a, ref) {
    var leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, label = _a.label, MenuItemProps = _a.MenuItemProps, className = _a.className, props = __rest(_a, ["leftIcon", "rightIcon", "label", "MenuItemProps", "className"]);
    return (react_1.default.createElement(StyledMenuItem, __assign({}, MenuItemProps, { ref: ref, className: className }, props),
        react_1.default.createElement(FlexBox, null,
            leftIcon,
            react_1.default.createElement(StyledTypography, null, label)),
        rightIcon));
});
exports.IconMenuItem = IconMenuItem;
IconMenuItem.displayName = 'IconMenuItem';
