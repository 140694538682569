import { TextareaAutosize } from '@material-ui/core';
import { debounce } from 'lodash';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import { useAppState } from '../../overmind';
import { saveTranslation } from '../../requests/TranslateRequest';
import { extractContent } from '../../utils';

export default function StudioTranslationCardInput({
    s,
    updateTranslation,
    setActiveTranslationDetailString,
    setIsSaving,
    tempTranslation,
    setTempTranslation,
    i,
    setSegmentIndex,
    highlightSegment,
    ...props
}) {
    const state = useAppState();
    const [tempSub, setTempSub] = useState('');
    const hasSub = useCallback(
        (sub) => {
            let index = state.translation.indexOf(sub);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.translation)))];
                index = data.map((a) => a.id).indexOf(sub.id);
            }
            return index;
        },
        [state.translation],
    );

    useEffect(() => {
        if (s) {
            setTempSub(s);
        }
    }, [s]);

    const debouncedSave = useCallback(
        debounce((sub, nextValue) => {
            saveTranslation(nextValue, props.user.signature);
            setActiveTranslationDetailString(nextValue);
            updateTranslation(sub, nextValue);
            setIsSaving(false);
        }, 1000),
        [],
    );

    const updateText = async (sub, text) => {
        setIsSaving(true);
        const index = hasSub(sub);
        if (index < 0) return;
        let newSub = [...JSON.parse(JSON.stringify(json(state.translation)))];
        newSub[index].text = text;
        let temp = [...tempTranslation];
        temp[index].text = text;
        setTempTranslation(temp);
        debouncedSave(sub, newSub[index]);
        setTempSub(newSub[index]);
    };

    return (
        <TextareaAutosize
            className={[
                'input-subtitle',
                'text-content',
                'w-full',
                props.currentLanguageObject && props.currentLanguageObject.rtl === 1 ? 'rtl-target-input' : '',
            ]
                .join(' ')
                .trim()}
            disabled={
                (props.access &&
                    props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                    props.access.accessMapping.role.toLowerCase() !== 'editor') ||
                (props.translationHeader && props.translationHeader.locked) ||
                state.voiceOverAudio !== null ||
                state.voiceOverLoading
                    ? true
                    : false
            }
            value={extractContent(tempSub.text, false)}
            style={{
                border: 'none',
                minHeight: '24px',
                lineHeight: '1.75',
            }}
            onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
            }}
            id={`translation-card-${s.id}`}
            onFocus={() => {
                highlightSegment(s, i);
                setSegmentIndex(i);
            }}
            onChange={(event) => {
                updateText(s, event.target.value);
            }}
        />
    );
}
