import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/template';

export const storeTemplate = async (subtitle_id, for_ratio, name, signature, ratio, language_id = 0) => {
    return axios
        .post(
            BACKEND_URL + '/store',
            {
                language_id: language_id,
                subtitle_id: subtitle_id,
                name: name,
                for_ratio: for_ratio,
                ratio: ratio,
            },
            {
                headers: {
                    Authorization: signature,
                },
            },
        )
        .then((res) => {
            return res.data;
        });
};

export const saveExistingTemplate = async (subtitle_id, ratio, template_id, signature, language_id = 0) => {
    return axios
        .post(
            BACKEND_URL + '/save',
            {
                language_id: language_id,
                subtitle_id: subtitle_id,
                ratio: ratio,
                template_id: template_id,
            },
            {
                headers: {
                    Authorization: signature,
                },
            },
        )
        .then((res) => {
            return res.data;
        });
};

export const applyTemplate = async (template_id, subtitle_id, language_id) => {
    return axios
        .post(BACKEND_URL + '/load', {
            language_id: language_id,
            subtitle_id: subtitle_id,
            template_id: template_id,
        })
        .then((res) => {
            return res.data;
        });
};

export const getTemplate = async (signature) => {
    return axios
        .get(BACKEND_URL + '/index', {
            headers: {
                Authorization: signature,
            },
        })
        .then((res) => {
            return res.data;
        });
};
