import DT from 'duration-time-conversion';
import { debounce } from 'lodash';
import isEqual from 'lodash/isEqual';
import { json } from 'overmind';
import React, { useCallback, useEffect, useState } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import { unstable_batchedUpdates } from 'react-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useActions, useAppState } from '../overmind';
import { updateSubtitle } from '../requests/SubtitleRequest';
import { saveTranslation } from '../requests/TranslateRequest';
import { extractContent, getKeyCode } from '../utils';

const Timeline = styled.div`
    // position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;

    .react-contextmenu-wrapper {
        position: absolute;
        z-index: 9;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: all;
    }

    .thumbnail {
        position: absolute;
        top: 70%;
        left: 0;
        radius: 5px;
        height: 20%;
        display: flex;
        justify-content: left;
        align-items: center;
        overflow: hidden;
        text-align: left;
        color: #0c1352;
        font-size: 14px;
        user-select: none;
        pointer-events: all;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 5%;

        .preview {
            // border-radius: 45%;
        }
    }

    .sub-item {
        position: absolute;
        top: 65px;
        left: 0;
        z-index: 1200;
        height: 65px;
        margin-top: 2.5px;
        display: flex;
        justify-content: left;
        align-items: center;
        overflow: auto;
        text-align: left;
        color: #0c1352;
        font-size: 14px;
        cursor: move;
        user-select: none;
        pointer-events: all;
        border-radius: 5px;
        border: 2px solid #cbd0d3;

        &.sub-illegal {
            background-color: rgba(199, 81, 35, 0.5);
        }

        .sub-handle {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            height: 100%;
            cursor: col-resize;
            user-select: none;
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        .sub-text {
            position: relative;
            z-index: 0;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            word-break: break-word;
            font-family: 'GorditaRegular';
            font-weight: normal;
            width: 100%;
            height: 100%;
            background-color: transparent; //#1d1e24;
            &::before {
                position: absolute;
                content: '';
                left: 8px;
                right: 8px;
                top: -2px;
                height: 2px;
                // background-color: #1d1e24;
            }
            &::after {
                position: absolute;
                content: '';
                left: 8px;
                right: 8px;
                bottom: -2px;
                height: 2px;
                // background-color: #1d1e24;
            }

            p {
                margin: 2px 0;
                line-height: 1.5;

                &.bilingual {
                    transform: scale(0.8);
                }
            }
        }

        &.sub-highlight {
            border-color: #17bdb8;
        }

        .sub-duration {
            opacity: 0.5;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            font-size: 12px;
        }
    }
`;

let lastId = 0;
let lower = 0;
let upper = 0;

let index = 0;
export function getCurrentSubs(subs, beginTime, duration, currentIndex) {
    if (currentIndex < 0) {
        currentIndex = lastId;
    }

    lastId = currentIndex;
    lower = currentIndex - 12;
    if (lower < 0) {
        lower = 0;
    }
    upper = currentIndex + 12;
    if (upper > subs.length) {
        upper = subs.length;
    }
    return subs.filter((item) => {
        return (
            (item.startTime >= beginTime && item.startTime <= beginTime + duration) ||
            (item.endTime >= beginTime && item.endTime <= beginTime + duration) ||
            (item.startTime < beginTime && item.endTime > beginTime + duration)
        );
    });
}

function magnetically(time, closeTime) {
    if (!closeTime) return time;
    if (time > closeTime - 0.1 && closeTime + 0.1 > time) {
        return closeTime;
    }
    return time;
}

let lastTarget = null;
let lastSub = null;
let lastType = '';
let lastX = 0;
let lastIndex = -1;
let lastWidth = 0;
let lastDiffX = 0;
let isDroging = false;

export default React.memo(
    function ({
        player,
        translateState,
        videoTime,
        translatedLanguage,
        audioTime,
        type,
        checkSub,
        translationHeader,
        updateTranslation,
        access,
        removeSub,
        hasSub,
        hasTranslation,
        updateSub,
        projectData,
        currentLanguageObject,
        setIsSaving,
        setActiveTranslationDetailString,
        user,
        listRef,
    }) {
        const subtitleLeftValue = 0.715;
        const $blockRef = React.createRef();
        const $subsRef = React.createRef();
        const state = useAppState();
        const actions = useActions();
        const [render, setRender] = useState({
            time: 0,
        });
        const gridGap = document.body.clientWidth / render.gridNum;
        const [currentSubs, setCurrentSubs] = useState([]);
        const [scrollingWafecursor, setScrollingWafecursor] = useState({});
        const [startTime, setStartTime] = useState(0);
        const [endTime, setEndTime] = useState(0);
        // const currentIndex =
        //     currentSubs && currentSubs.length > 0
        //         ? currentSubs.findIndex(
        //               (item) => item.startTime <= state.currentTime && item.endTime > state.currentTime,
        //           )
        //         : 0;

        //v ini tambah 0.75
        const location = useLocation();
        const query = new URLSearchParams(location.search);
        const isShared = query.get('shared');
        const isOriginalLocked = json(state.accessDetails).map((access) => {
            return access.details.filter((x) => x.language_id == -1).length;
        });
        const onMouseDown = (sub, event, type) => {
            event.stopPropagation();
            if (
                (access &&
                    access.accessMapping.role.toLowerCase() !== 'owner' &&
                    access.accessMapping.role.toLowerCase() !== 'editor') ||
                state.voiceOverAudio !== null ||
                state.voiceOverLoading ||
                (isShared == 1 && translateState == false && isOriginalLocked.length > 0 && isOriginalLocked[0] === 0)
            ) {
                return;
            }
            lastSub = sub;
            if (event.button !== 0) return;
            isDroging = true;
            lastType = type;
            lastX = event.pageX;
            lastIndex = currentSubs.indexOf(sub);
            lastTarget = $subsRef.current.children[lastIndex];
            lastWidth = parseFloat(lastTarget.style.width);
        };

        const onDoubleClick = (sub, event) => {
            const $subs = event.currentTarget;
            const index = translateState == true ? hasTranslation(lastSub) : hasSub(lastSub);
            const currentSub = translateState == true ? state.translation : state.subtitle;
            const previou = currentSub[index - 1];
            const next = currentSub[index + 1];
            if (previou && next) {
                const width = (next.startTime - previou.endTime) * 10 * gridGap;
                $subs.style.width = `${width}px`;
                const start = DT.d2t(previou.endTime);
                const end = DT.d2t(next.startTime);
                updateSub(sub, {
                    start,
                    end,
                });
            }
        };

        const onDocumentMouseMove = useCallback((event) => {
            if (isDroging && lastTarget) {
                lastDiffX = event.pageX - lastX;
                if (lastType === 'left') {
                    lastTarget.style.width = `${lastWidth - lastDiffX}px`;
                    lastTarget.style.transform = `translate(${lastDiffX}px,0)`;
                } else if (lastType === 'right') {
                    lastTarget.style.width = `${lastWidth + lastDiffX}px`;
                } else {
                    lastTarget.style.transform = `translate(${lastDiffX}px,0)`;
                }
            }
        }, []);

        const onDocumentMouseUp = useCallback(() => {
            if (isDroging && lastTarget && lastDiffX) {
                const timeDiff = lastDiffX / state.waveformZoom;
                const currentSub = translateState == true ? state.translation : state.subtitle;
                const index = translateState == true ? hasTranslation(lastSub) : hasSub(lastSub);
                const previou = currentSub[index - 1];
                const next = currentSub[index + 1];
                const startTime = magnetically(lastSub.startTime + timeDiff, previou ? previou.endTime : null);
                const endTime = magnetically(lastSub.endTime + timeDiff, next ? next.startTime : null);
                const width = (endTime - startTime) * 10 * gridGap;

                if ((previou && endTime < previou.startTime) || (next && startTime > next.endTime)) {
                    //
                    // console.log('nothing');
                } else {
                    if (lastType === 'left') {
                        if (startTime >= 0 && lastSub.endTime - startTime >= 0.2) {
                            const start = DT.d2t(startTime);
                            if (translateState == true) {
                                saveTranslation(
                                    {
                                        ...lastSub,
                                        start,
                                    },
                                    user.signature,
                                );
                                updateTranslation(lastSub, { start });
                            } else {
                                updateSubtitle({
                                    ...lastSub,
                                    start,
                                });
                                updateSub(lastSub, { start });
                            }
                        } else {
                            lastTarget.style.width = `${width}px`;
                        }
                    } else if (lastType === 'right') {
                        if (endTime >= 0 && endTime - lastSub.startTime >= 0.2) {
                            const end = DT.d2t(endTime);

                            if (translateState == true) {
                                saveTranslation(
                                    {
                                        ...lastSub,
                                        end,
                                    },
                                    user.signature,
                                );
                                updateTranslation(lastSub, { end });
                            } else {
                                updateSubtitle({
                                    ...lastSub,
                                    end,
                                });
                                updateSub(lastSub, { end });
                            }
                        } else {
                            lastTarget.style.width = `${width}px`;
                        }
                    } else {
                        if (startTime > 0 && endTime > 0 && endTime - startTime >= 0.2) {
                            const start = DT.d2t(startTime);
                            const end = DT.d2t(endTime);

                            if (translateState == true) {
                                saveTranslation(
                                    {
                                        ...lastSub,
                                        start,
                                        end,
                                    },
                                    user.signature,
                                );
                                updateTranslation(lastSub, {
                                    start,
                                    end,
                                });
                            } else {
                                updateSubtitle({
                                    ...lastSub,
                                    start,
                                    end,
                                });
                                updateSub(lastSub, {
                                    start,
                                    end,
                                });
                            }
                        } else {
                            lastTarget.style.width = `${width}px`;
                        }
                    }
                }
                lastTarget.style.transform = `translate(0,0)`;
            }

            lastType = '';
            lastX = 0;
            lastWidth = 0;
            lastDiffX = 0;
            isDroging = false;
        }, [gridGap, hasSub, hasTranslation, state.subtitle, state.translation, updateSub, translateState]);

        const onKeyDown = useCallback(
            (event) => {
                const sub = currentSubs[lastIndex];
                if (sub && lastTarget) {
                    const keyCode = getKeyCode(event);
                    switch (keyCode) {
                        case 37:
                            updateSub(sub, {
                                start: DT.d2t(sub.startTime - 0.1),
                                end: DT.d2t(sub.endTime - 0.1),
                            });
                            player.currentTime = sub.startTime - 0.1;
                            break;
                        case 39:
                            updateSub(sub, {
                                start: DT.d2t(sub.startTime + 0.1),
                                end: DT.d2t(sub.endTime + 0.1),
                            });
                            player.currentTime = sub.startTime + 0.1;
                            break;
                        case 8:
                        case 46:
                            removeSub(sub);
                            break;
                        default:
                            break;
                    }
                }
            },
            [currentSubs, player, removeSub, updateSub],
        );

        useEffect(() => {
            document.addEventListener('mousemove', onDocumentMouseMove);
            document.addEventListener('mouseup', onDocumentMouseUp);
            window.addEventListener('keydown', onKeyDown);
            return () => {
                document.removeEventListener('mousemove', onDocumentMouseMove);
                document.removeEventListener('mouseup', onDocumentMouseUp);
                window.removeEventListener('keydown', onKeyDown);
            };
        }, [onDocumentMouseMove, onDocumentMouseUp, onKeyDown]);

        const debouncedSave = useCallback(
            debounce((sub, nextValue) => {
                if (translateState == true) {
                    saveTranslation(nextValue, user.signature);
                    setActiveTranslationDetailString(nextValue);
                    updateTranslation(sub, nextValue);
                } else {
                    updateSubtitle(nextValue);
                    updateSub(sub, nextValue);
                }

                setIsSaving(false);
            }, 2000),
            [translateState],
        );

        const hasCurrentSub = useCallback(
            (sub) => {
                let index = currentSubs.indexOf(sub);
                if (index == -1) {
                    const data = [...currentSubs];
                    index = data.map((a) => a.id).indexOf(sub.id);
                }
                return index;
            },
            [currentSubs],
        );

        const updateText = async (sub, text) => {
            setIsSaving(true);
            const index = hasCurrentSub(sub);
            if (index < 0) return;
            let newSubs = [...JSON.parse(JSON.stringify(json(currentSubs)))];
            newSubs[index].text = text;
            debouncedSave(sub, newSubs[index]);
            setCurrentSubs(newSubs);
        };

        const onPlay = (e) => {
            const currentIndex = translateState
                ? state.translation.findIndex((item) => item.startTime <= e && item.endTime > e)
                : state.subtitle.findIndex((item) => item.startTime <= e && item.endTime > e);

            let el;
            const elementName = translateState ? 'translation' : 'subtitle';
            const subs = translateState ? state.translation : state.subtitle;
            //scroll subtitle card, belum translation
            if (currentIndex > -1) {
                document.querySelectorAll(`[id^=${elementName}-box-]`).forEach((box) => {
                    box.classList.remove('segment-highlight');
                    box.style.borderColor = 'inherit';
                });
                document.querySelectorAll(`[id^=${elementName}-span-]`).forEach((box) => {
                    box.classList.remove('plain-text-highlight');
                    box.style.borderColor = 'inherit';
                });
                let element = document.getElementById(`${elementName}-box-${subs[currentIndex].id}`);
                if (element != null) {
                    element.classList.add('segment-highlight');
                    element.style.borderColor = subs.speaker ? subs.speaker.speaker_color : '#17BDB8';
                }
                element = document.getElementById(`${elementName}-span-` + subs[currentIndex].id);
                if (element != null) {
                    element.classList.add('plain-text-highlight');
                }
                let scrollableContainer = document.getElementById('subtitles');
                if (scrollableContainer && index !== currentIndex) {
                    index = currentIndex;
                    // console.log(`subtitle-box-${subs[currentIndex].id}`);
                    try {
                        // scrollableContainer.scrollTo(
                        //     0,
                        //     document.getElementById(`subtitle-box-${subs[currentIndex].id}`).offsetTop,
                        // );
                        scrollableContainer.scrollTo({
                            top: document.getElementById(`${elementName}-box-${subs[currentIndex].id}`).offsetTop, //scroll to the bottom of the element
                            behavior: 'smooth', //auto, smooth, initial, inherit
                        });
                    } catch (e) {
                        try {
                            listRef.current.scrollToIndex(currentIndex);
                        } catch (e) {
                            console.log(e);
                        }
                        console.log(e);
                    }
                }
                try {
                    scrollableContainer = document.getElementById('plain-text');
                    // scrollableContainer.scrollTo(
                    //     0,
                    //     document.getElementById(`subtitle-span-${subs[currentIndex].id}`).offsetTop,
                    // );
                    scrollableContainer.scroll({
                        top: document.getElementById(`${elementName}-span-${subs[currentIndex].id}`).offsetTop, //scroll to the bottom of the element
                        behavior: 'smooth', //auto, smooth, initial, inherit
                    });
                } catch (e) {
                    // console.log(e);
                }
            }
            for (let i = 0; i < subs.length; i++) {
                // const s = currentSubs[i];
                // const invalid = checkSub(s, );
                // const id = `sub-text-${s.id}`;
                // el = document.getElementById(id);
                // if (el == null) continue;
                // if (invalid) {
                //     el.classList.add('illegal');
                // } else {
                //     el.classList.remove('illegal');
                // }

                el = document.getElementById(`sub-block-${subs[i].id}`);
                if (el !== null) {
                    el.classList.remove('sub-highlight');
                }
            }
            try {
                el = document.getElementById(`sub-block-${subs[currentIndex].id}`);
                if (el !== null) {
                    el.classList.add('sub-highlight');
                }
            } catch (e) {
                // console.log(e);
            }
        };

        useEffect(() => {
            const e = state.seekTime;
            const completeCurrentIndex = translateState
                ? state.translation.findIndex((item) => item.startTime <= e && item.endTime > e)
                : state.subtitle.findIndex((item) => item.startTime <= e && item.endTime > e);
            const subs = translateState
                ? getCurrentSubs(
                      state.translation,
                      e - state.waveformDuration,
                      state.waveformDuration * 2,
                      completeCurrentIndex,
                  )
                : getCurrentSubs(
                      state.subtitle,
                      e - state.waveformDuration,
                      state.waveformDuration * 2,
                      completeCurrentIndex,
                  );
            setCurrentSubs(subs);
        }, [state.seekTime]);

        useEffect(() => {
            // const subs = translateState ? state.translation : state.subtitle.slice(0, 5);
            const currentTime = player.currentTime ? player.currentTime : 0;
            let completeCurrentIndex = 0;
            if (currentTime > 0) {
                completeCurrentIndex = translateState
                    ? state.translation.findIndex((item) => item.startTime <= currentTime && item.endTime > currentTime)
                    : state.subtitle.findIndex((item) => item.startTime <= currentTime && item.endTime > currentTime);
            }
            const subs = translateState
                ? getCurrentSubs(
                      state.translation,
                      currentTime - state.waveformDuration,
                      state.waveformDuration * 2,
                      completeCurrentIndex,
                  )
                : getCurrentSubs(
                      state.subtitle,
                      currentTime - state.waveformDuration,
                      state.waveformDuration * 2,
                      completeCurrentIndex,
                  );
            setCurrentSubs(subs);
            if (state.wavesurferCursor && state.wavesurferCursor.current) {
                state.wavesurferCursor.current.on('timeupdate', (e) => {
                    onPlay(e);
                });
                state.wavesurferCursor.current.on('scroll', (visibleStartTime, visibleEndTime) => {
                    if (startTime != visibleStartTime || endTime != visibleEndTime) {
                        setScrollingWafecursor({currentTime, completeCurrentIndex, visibleStartTime, visibleEndTime})
                    }
                });
                return () => {
                    state.wavesurferCursor.current.un('timeupdate');
                    state.wavesurferCursor.current.un('scroll');
                };
            }
        }, [translateState, state.subtitle, state.translation, state.waveformDuration, state.wavesurferCursor]);

        useEffect(() => {
            if (scrollingWafecursor.visibleStartTime && scrollingWafecursor.visibleEndTime && (startTime != scrollingWafecursor.visibleStartTime || endTime != scrollingWafecursor.visibleEndTime)) {
                const subs = translateState
                    ? getCurrentSubs(
                          state.translation,
                          scrollingWafecursor.visibleStartTime,
                          scrollingWafecursor.visibleEndTime - scrollingWafecursor.visibleStartTime,
                          scrollingWafecursor.completeCurrentIndex,
                      )
                    : getCurrentSubs(
                          state.subtitle,
                          scrollingWafecursor.visibleStartTime,
                          scrollingWafecursor.visibleEndTime - scrollingWafecursor.visibleStartTime,
                          scrollingWafecursor.completeCurrentIndex,
                      );
                setStartTime(scrollingWafecursor.visibleStartTime);
                setEndTime(scrollingWafecursor.visibleEndTime);
                setCurrentSubs(subs);
            }
        }, [scrollingWafecursor, startTime, endTime, translateState, state.subtitle, state.translation]);

        useEffect(() => {
            try {
                const wavesurferCursor = json(state.wavesurferCursor.current);
                wavesurferCursor.zoom(state.waveformZoom);
            } catch (e) {}
        }, [state.waveformZoom]);

        useEffect(() => {
            let currentMinZoom = state.minZoom;
            const bestMinZoom = Math.ceil((window.innerWidth * 3) / 4 / player.duration);
            if (bestMinZoom > currentMinZoom) {
                currentMinZoom = bestMinZoom;
                unstable_batchedUpdates(() => {
                    actions.setMinZoom(bestMinZoom);
                });
            }
            if (state.waveformZoom < bestMinZoom) {
                actions.setWaveformZoom(bestMinZoom);
            }
        }, [player]);

        return (
            <Timeline ref={$blockRef}>
                <div ref={$subsRef}>
                    {currentSubs &&
                        currentSubs.map((sub, key) => {
                            return (
                                <div
                                    id={`sub-block-${sub.id}`}
                                    className={'sub-item hide-fullscreen-timeline'}
                                    key={key}
                                    style={{
                                        direction: translateState
                                            ? currentLanguageObject && currentLanguageObject.rtl == 1
                                                ? 'rtl'
                                                : 'ltr'
                                            : projectData.file.language.rtl === 1
                                            ? 'rtl'
                                            : 'ltr',
                                        left: (sub.startTime - startTime) * state.waveformZoom,
                                        width: (sub.endTime - sub.startTime) * state.waveformZoom,
                                        cursor:
                                            isShared == 1 &&
                                            translateState == false &&
                                            isOriginalLocked.length > 0 &&
                                            isOriginalLocked[0] === 0
                                                ? 'default'
                                                : 'move',
                                    }}
                                    onMouseDown={(event) => onMouseDown(sub, event)}
                                    // onDoubleClick={(event) => onDoubleClick(sub, event)}
                                >
                                    <ContextMenuTrigger id="contextmenu" holdToDisplay={-1}>
                                        <div
                                            className="sub-handle"
                                            style={{
                                                left: 0,
                                                width: 10,
                                                cursor:
                                                    isShared == 1 &&
                                                    translateState == false &&
                                                    isOriginalLocked.length > 0 &&
                                                    isOriginalLocked[0] === 0
                                                        ? 'default'
                                                        : 'col-resize',
                                            }}
                                            onMouseDown={(event) => onMouseDown(sub, event, 'left')}
                                        ></div>

                                        <div
                                            id={`sub-text-${sub.id}`}
                                            className={`sub-text`}
                                            style={{
                                                direction: translateState
                                                    ? currentLanguageObject && currentLanguageObject.rtl == 1
                                                        ? 'rtl'
                                                        : 'ltr'
                                                    : projectData.file.language.rtl === 1
                                                    ? 'rtl'
                                                    : 'ltr',
                                            }}
                                            title={extractContent(sub.text)}
                                        >
                                            {`${extractContent(sub.text)}`.split(/\r?\n/).map((line, index) => (
                                                //   <p key={index}>{line == 'null' || line == null ? '' : line}</p>
                                                <textarea
                                                    disabled={
                                                        (access &&
                                                            access.accessMapping.role.toLowerCase() !== 'owner' &&
                                                            access.accessMapping.role.toLowerCase() !== 'editor') ||
                                                        (translationHeader && translationHeader.locked) ||
                                                        state.voiceOverAudio !== null ||
                                                        state.voiceOverLoading ||
                                                        (isShared == 1 &&
                                                            translateState == false &&
                                                            isOriginalLocked.length > 0 &&
                                                            isOriginalLocked[0] === 0) ||
                                                        (translateState == false &&
                                                            translatedLanguage &&
                                                            translatedLanguage.length > 0)
                                                            ? true
                                                            : false
                                                    }
                                                    style={{ width: '100%', fontSize: '12px' }}
                                                    className="input-subtitle"
                                                    key={index}
                                                    value={line == 'null' || line == null ? '' : line}
                                                    onChange={(event) => {
                                                        updateText(sub, event.target.value);
                                                    }}
                                                />
                                                //   <ContentEditable
                                                //       key={index}
                                                //       value={line == 'null' || line == null ? '' : line}
                                                //       html={line == 'null' || line == null ? '' : line}
                                                //   />
                                            ))}
                                        </div>

                                        <div
                                            className="sub-handle"
                                            style={{
                                                right: 0,
                                                cursor:
                                                    isShared == 1 &&
                                                    translateState == false &&
                                                    isOriginalLocked.length > 0 &&
                                                    isOriginalLocked[0] === 0
                                                        ? 'default'
                                                        : 'col-resize',
                                                width: 10,
                                            }}
                                            onMouseDown={(event) => onMouseDown(sub, event, 'right')}
                                        ></div>
                                        {/* <div className="sub-duration">{sub.duration}</div> */}
                                    </ContextMenuTrigger>
                                </div>
                            );
                        })}
                </div>
            </Timeline>
        );
    },
    (prevProps, nextProps) => {
        return (
            isEqual(prevProps.subtitleUpdate, nextProps.subtitleUpdate) &&
            isEqual(prevProps.subtitle, nextProps.subtitle) &&
            isEqual(prevProps.translateState, nextProps.translateState) &&
            prevProps.currentTime === nextProps.currentTime
        );
    },
);
