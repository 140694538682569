import { Slider } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

const VerticalMarginSlider = (props) => {
    const [vMargin, setVMargin] = useState(0);
    const handleChange = (event, newValue) => {
        setVMargin(newValue);
        debouncedSave(newValue);
    };

    const debouncedSave = useCallback(
        debounce((nextValue) => {
            props.setConfig((prevState) => ({
                ...prevState,
                marginV: nextValue,
            }));
        }, 1000),
        [],
    );

    useEffect(() => {
        if (props.config) {
            setVMargin(props.config.marginV);
        }
    }, [props.config]);

    return (
        // <div
        //     className={`vertical-margin-dropdown`}
        //     style={{
        //         width: '100%',
        //     }}
        // >
        //     <button
        //         className="dropdown-toggle btn box-shadow-none border-none"
        //         style={{
        //             fontSize: '14px',
        //             width: '100%',
        //         }}
        //     >
        //         Vertical Margin
        //     </button>
        <div style={{ width: '100%' }}>
            <Slider
                valueLabelFormat={(val) => val + '%'}
                valueLabelDisplay="auto"
                aria-label="marginV"
                value={vMargin}
                onChange={handleChange}
            />{' '}
        </div>
        // </div>
    );
};

export default VerticalMarginSlider;
