import React, { useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import 'react-image-crop/dist/ReactCrop.css';
import { Rnd } from 'react-rnd';
import styles from '../css/SubtitleEdit.module.css';
import '../css/rcp.css';
import { useActions, useAppState } from '../overmind';
import { ARIAL_ASCENDER, ARIAL_DESCENDER, ARIAL_XHEIGHT } from './Player';

const TitleRND = ({
    id,
    config,
    setConfig,
    setProjectData,
    projectData,
    showCover,
    setShowCover,
    heading_fontName,
    headingFontData,
    currentLanguage,
    styleRef,
    disableDragging = false,
    ...props
}) => {
    const actions = useActions();
    const state = useAppState();
    const [headingStyleString, setHeadingStyleString] = useState(``);
    const [headingTextContainerPosition, setHeadingTextContainerPosition] = useState(``);
    const rndRef = useRef();
    const imgRef = useRef();
    const [fontSize, setFontSize] = useState(0);
    const [stateRnd, setStateRnd] = useState({
        x: 10,
        y: 10,
        width: '',
        height: '',
    });

    useEffect(async () => {
        if (styleRef) {
            setStateRnd({
                width: 'auto',
                height: 'auto',
                x: `${(config.heading_pos_x / 100) * styleRef.current.clientWidth}`,
                y: `${(config.heading_pos_y / 100) * styleRef.current.clientHeight}`,
            });
            initByConfig();
        }
    }, [config]);

    useEffect(async () => {
        try{
            if (styleRef && styleRef.current != null) {
                const interval = setTimeout(() => {
                    const clientWidth = styleRef.current ?styleRef.current.clientWidth : 0;
                    const clientHeight = styleRef.current ?styleRef.current.clientHeight : 0;
                    setStateRnd({
                        width: 'auto',
                        height: 'auto',
                        x: `${(config.heading_pos_x / 100) * clientWidth}`,
                        y: `${(config.heading_pos_y / 100) * clientHeight}`,
                    });
                    clearTimeout(interval);
                }, 1000);
            }
        }catch(e){}
    }, [props.fullscreen]);

    const style = {
        zIndex: '21',
    };

    const getHeadingTextStyle = () => {
        let str = ``;
        if (config.heading_bold) {
            str += ` ${styles.bold}`;
        }
        if (config.heading_italic) {
            str += ` ${styles.italic}`;
        }
        if (config.heading_underline) {
            str += ` ${styles.underline}`;
        }
        // if (config.heading_align === 'center') {
        //     str += ` ${styles.alignCenter}`;
        // } else if (config.heading_align === 'left') {
        //     str += ` ${styles.alignLeft}`;
        // } else if (config.heading_align === 'right') {
        //     str += ` ${styles.alignRight}`;
        // }
        // if (config.heading_position === 'Center') {
        //     str += ` ${styles.center}`;
        // } else if (config.heading_position === 'Bottom') {
        //     str += ` ${styles.bottom}`;
        // } else if (config.heading_position === 'Top') {
        //     str += ` ${styles.top}`;
        // }

        // setHeadingStyleString(str);
        return str;
    };

    const getHeadingTextContainerPosition = () => {
        let str = ``;
        if (config.heading_position === 'Center') {
            str += ` ${styles.center}`;
        } else if (config.heading_position === 'Bottom') {
            str += ` ${styles.bottom}`;
        } else if (config.heading_position === 'Top') {
            str += ` ${styles.top}`;
        }

        if (config.heading_align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (config.heading_align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (config.heading_align === 'right') {
            str += ` ${styles.alignRight}`;
        }

        // setHeadingTextContainerPosition(str);
        return str;
    };

    const initByConfig = async () => {
        // getTextContainerPosition();
        const headingTextContainerPosition = getHeadingTextContainerPosition();
        const headingTextStyle = getHeadingTextStyle();
        unstable_batchedUpdates(() => {
            setHeadingStyleString(headingTextStyle);
            setHeadingTextContainerPosition(headingTextContainerPosition);
        });
    };

    const getVideoWidth = () => {
        let width = projectData.header.width;
        if (config.ratio == '16:9') {
            if (width < 1280) {
                return 1280;
            }
        } else if (config.ratio == '9:16') {
            width = projectData.header.height;
            if (width < 720) {
                return 720;
            }
        } else if (config.ratio == '4:5') {
            width = (width * 4) / 5;
            if (width < 576) {
                return 576;
            }
        } else if (config.ratio == '1:1') {
            if (width < 720) {
                return 720;
            }
        }
        return width;
    };

    const calculateFontSize = () => {
        try {
            let headingFontSize = styleRef
                ? (styleRef.current.offsetWidth / getVideoWidth()) * config.heading_fontSize
                : 0;
            if (!headingFontData.xHeight) {
                if (disableDragging) {
                    if (headingFontSize > 10) return headingFontSize;
                    else return 10;
                }
                return headingFontSize;
            }
            const arialCender = ARIAL_ASCENDER - ARIAL_DESCENDER;
            const destinationCender = headingFontData.ascender - headingFontData.descender;

            // rumus 1 -> lato lebih kecil
            // const arialFac = (headingFontSize * ARIAL_XHEIGHT) / arialCender;
            // const dest = (headingFontSize * headingFontData.xHeight) / destinationCender;
            // console.log(`arial: ${arialFac}`);
            // console.log(`dest: ${dest}`);
            // console.log(`res: ${headingFontSize - (arialFac - dest)}`);
            // return headingFontSize - (arialFac - dest);

            //rumus 2 -> cambay kekecilan
            // const res = (headingFontData.xHeight / ARIAL_XHEIGHT) * headingFontSize;
            // console.log(`res: ${res}`);
            // return res;

            //rumus 3 -> paling mirip
            const res =
                (headingFontSize * (headingFontData.xHeight / ARIAL_XHEIGHT)) / (destinationCender / arialCender);
            // console.log(`res: ${res}`);
            if (disableDragging) {
                if (res > 10) return Math.floor(res);
                else return 10;
            }
            return Math.floor(res);
        } catch (e) {
            return 0;
        }
    };

    return (
        <>
            {styleRef && (
                <Rnd
                    ref={rndRef}
                    bounds=".rnd-cover"
                    style={style}
                    enableResizing={false}
                    disableDragging={disableDragging}
                    size={{ width: stateRnd.width, height: stateRnd.height }}
                    position={{ x: stateRnd.x, y: stateRnd.y }}
                    onDragStop={(e, d) => {
                        setConfig({
                            ...config,
                            heading_pos_x: (d.x / styleRef.current.clientWidth) * 100,
                            heading_pos_y: (d.y / styleRef.current.clientHeight) * 100,
                        });
                        setStateRnd({
                            width: stateRnd.width,
                            height: stateRnd.height,
                            x: d.x,
                            y: d.y,
                        });
                    }}
                    lockAspectRatio={true}
                >
                    <p
                        className={`textarea ${headingStyleString} ${config.heading ? '' : 'hidden'}`}
                        style={{
                            color: config.heading_fontColor,
                            font: `${calculateFontSize()}px ${heading_fontName}`,
                            width: `auto`,
                            backgroundColor:
                                config.heading_effect === 'Background'
                                    ? config.heading_backgroundColor
                                    : 'rgba(0,0,0,0)',
                            borderColor:
                                config.heading_effect === 'Background'
                                    ? config.heading_backgroundColor
                                    : 'rgba(0,0,0,0)',
                            textShadow:
                                config.heading_effect === 'Outline'
                                    ? `-2px -2px 0 ${config.heading_backgroundColor}, 2px -2px 0 ${config.heading_backgroundColor}, -2px 2px 0 ${config.heading_backgroundColor}, 2px 2px 0 ${config.heading_backgroundColor}`
                                    : config.heading_effect === 'Drop Shadow'
                                    ? `5pt 5pt ${config.heading_backgroundColor}`
                                    : '',
                        }}
                        value={config.heading}
                        disabled
                        spellCheck={false}
                    >
                        {config.heading}
                    </p>
                </Rnd>
            )}
        </>
    );
};

export default TitleRND;
