import DT from 'duration-time-conversion';
import { debounce } from 'lodash';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../../css/SubtitleCard.module.css';
import { useAppState } from '../../overmind';
import { updateSubtitle } from '../../requests/SubtitleRequest';
import { saveTranslation } from '../../requests/TranslateRequest';

const TimeInput = ({
    updateSub,
    updateTranslation,
    translationState,
    access,
    s,
    setIsSaving,
    signature,
    translatedLanguage,
}) => {
    const state = useAppState();
    const [tempSub, setTempSub] = useState('');

    const debouncedSave = useCallback(
        debounce((sub, nextValue) => {
            if (!translationState) {
                updateSubtitle(nextValue);
                // updateSub(sub, nextValue);
            } else {
                saveTranslation(nextValue, signature);
                // updateTranslation(sub, nextValue);
            }
            setIsSaving(false);
        }, 1000),
        [],
    );

    useEffect(() => {
        if (s) {
            setTempSub(s);
        }
    }, [s]);

    const hasSub = useCallback(
        (sub) => {
            let index = state.subtitle.indexOf(sub);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
                index = data.map((a) => a.id).indexOf(sub.id);
            }
            return index;
        },
        [state.subtitle],
    );

    const hasTranslation = useCallback(
        (sub) => {
            let index = state.translation.indexOf(sub);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.translation)))];
                index = data.map((a) => a.id).indexOf(sub.id);
            }
            return index;
        },
        [state.translation],
    );

    const validateDurationFormat = (time) => {
        return /^\d\d:\d\d:\d\d(\.\d{1,7})?$/.test(time);
    };

    const validateDuration = (oldSub, time, start = false) => {
        try {
            if (
                validateDurationFormat(time) &&
                validateDurationFormat(oldSub.end) &&
                validateDurationFormat(oldSub.start)
            ) {
                if (start) {
                    if (DT.t2d(oldSub.end.slice(0, 12)) >= DT.t2d(time.slice(0, 12))) {
                        return true;
                    }
                } else {
                    if (DT.t2d(oldSub.start.slice(0, 12)) <= DT.t2d(time.slice(0, 12))) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        } catch (e) {
            return false;
        }
    };

    const updateTime = async (sub, time, start = false) => {
        const index = translationState ? hasTranslation(sub) : hasSub(sub);
        if (index < 0) return;
        let newSub;
        if (!translationState) {
            newSub = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
        } else {
            newSub = [...JSON.parse(JSON.stringify(json(state.translation)))];
        }
        if (start) {
            newSub[index].start = time;
        } else {
            newSub[index].end = time;
        }
        setTempSub(newSub[index]);
        if (validateDurationFormat(time)) {
            if (!translationState) {
                if (start) {
                    updateSub(sub, { start: time });
                } else {
                    updateSub(sub, { end: time });
                }
            } else {
                if (start) {
                    updateTranslation(sub, { start: time });
                } else {
                    updateTranslation(sub, { end: time });
                }
            }
        } else {
            return;
        }
        debouncedSave(sub, newSub[index]);
    };

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const isShared = query.get('shared');

    const isOriginalLocked = json(state.accessDetails).map((access) => {
        return access.details.filter((x) => x.language_id == -1).length;
    });

    return (
        <>
            <input
                type="text"
                className={`bg-box-content-dark text-gray-500 p-2 rounded-3px text-xs ${
                    validateDuration(tempSub, tempSub.start, true) ? '' : styles.error
                }`}
                disabled={
                    (access &&
                        access.accessMapping.role.toLowerCase() !== 'owner' &&
                        access.accessMapping.role.toLowerCase() !== 'editor') ||
                    state.voiceOverAudio !== null ||
                    state.voiceOverLoading ||
                    state.timeLock ||
                    (isShared == 1 && !translationState && isOriginalLocked.length > 0 && isOriginalLocked[0] === 0)
                        ? true
                        : false
                }
                value={tempSub.start}
                style={{ border: 'none', maxWidth: '115px' }}
                onInput={(event) => {
                    updateTime(tempSub, event.target.value, true);
                }}
            />

            <span className="mx-3 text-gray-500">-</span>

            <input
                type="text"
                className={`bg-box-content-dark text-gray-500 p-2 mr-auto rounded-3px text-xs ${
                    validateDuration(tempSub, tempSub.end, false) ? '' : styles.error
                }`}
                disabled={
                    (access &&
                        access.accessMapping.role.toLowerCase() !== 'owner' &&
                        access.accessMapping.role.toLowerCase() !== 'editor') ||
                    state.voiceOverAudio !== null ||
                    state.voiceOverLoading ||
                    state.timeLock ||
                    (isShared == 1 && !translationState && isOriginalLocked.length > 0 && isOriginalLocked[0] === 0)
                        ? true
                        : false
                }
                value={tempSub.end}
                style={{ border: 'none', maxWidth: '115px' }}
                onChange={(event) => {
                    updateTime(tempSub, event.target.value, false);
                }}
            />
        </>
    );
};

export default TimeInput;
