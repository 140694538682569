import { Modal, Skeleton } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { AlertOctagon, ArrowRight, Cpu, Layers, User, UserCheck, Users, X } from 'react-feather';
import { useActions, useAppState } from '../../overmind';
import { fetchLexigoLanguage } from '../../requests/LanguageRequest';
import { handlePaymentRequest } from '../../requests/PaymentRequest';
import { calculatePrice } from '../../requests/PricingRequest';
import { fetchSubtitleData } from '../../requests/SubtitleRequest';
import { createTranslation, lockTranslation } from '../../requests/TranslateRequest';
import { getIp, getTotalDurationRequest } from '../../requests/UtilRequest';
import CompareFeatures from './CompareFeatures.js';
import Payment from './Payment';
import PricingDetailsModal from './PricingDetailsModal';
import PricingSlider from './PricingSlider.js';

export const PricingPackage = {
    PURE: 0,
    PLUS: 1,
    PRO: 2,
    PRIME: 3,
};

const PricingModal = (props) => {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [naatiCertified, setNaatiCertified] = useState(false);
    const [currency, setCurrency] = useState('usd');
    const [targetLangIds, setTargetLangIds] = useState('');
    const [tax, setTax] = useState(0);
    const [selectedLanguagesObj, setSelectedLanguagesObj] = useState([]);
    const [wordCount, setWordCount] = useState(0);
    const [translateBy, setTranslateBy] = useState('lexigo');
    const [totalDuration, setTotalDuration] = useState(0);
    const [pureTranslateModal, setPureTranslateModal] = useState(false);
    const state = useAppState();
    const actions = useActions();

    useEffect(() => {
        const countWords = async () => {
            let wordsCount = 0;
            if (props.id == null) return 0;
            const data = await fetchSubtitleData(props.id);

            data.details.map((sub) => {
                wordsCount += sub.text.split(' ').length;
            });
            return wordsCount;
        };

        const getLanguageObj = (scribeLangId) => {
            return props.languages.filter((l) => l.id === scribeLangId)[0];
        };

        const getPricing = async () => {
            props.setPricingLoading(true);
            if (!props.projectData) return;
            const wordsCount = await countWords();
            let totalDuration = await getTotalDurationRequest(props.user.id, props.projectData.file.workspace_id);
            setTotalDuration(totalDuration);

            setWordCount(wordsCount);
            let getTargetLangIds = props.selectedLanguages;
            let languagesObj = await Promise.all(
                props.selectedLanguages.map(async (l) => {
                    return getLanguageObj(l);
                }),
            );
            setSelectedLanguagesObj(languagesObj);
            getTargetLangIds = getTargetLangIds.join(',');
            setTargetLangIds(getTargetLangIds);
            if (props.selectedLanguages && props.selectedLanguages.length > 0) {
                const calculate = await calculatePrice(getTargetLangIds, currency, wordsCount);
                if (naatiCertified) {
                    calculate.data.package_pro_price =
                        parseFloat(calculate.data.package_pro_price) + parseFloat(calculate.data.add_naati_price);
                    calculate.data.package_prime_price =
                        parseFloat(calculate.data.package_prime_price) + parseFloat(calculate.data.add_naati_price);
                }
                calculate.data.package_plus_price = parseFloat(calculate.data.package_plus_price);
                calculate.data.package_plus_price_per_word = parseFloat(calculate.data.package_plus_price_per_word);
                calculate.data.package_prime_price = parseFloat(calculate.data.package_prime_price);
                calculate.data.package_prime_price_per_word = parseFloat(calculate.data.package_prime_price_per_word);
                calculate.data.package_pro_price = parseFloat(calculate.data.package_pro_price);
                calculate.data.package_pro_price_per_word = parseFloat(calculate.data.package_pro_price_per_word);
                calculate.data.package_pure_price = parseFloat(calculate.data.package_pure_price);
                calculate.data.package_pure_price_per_word = parseFloat(calculate.data.package_pure_price_per_word);

                props.setPricing(calculate.data);
                if (calculate.data.not_supported_language.length > 0) {
                    setSelectedPackage(null);
                }
                props.setPricingLoading(false);
            }
        };
        getPricing();
    }, [props.selectedLanguages, currency, naatiCertified]);

    const handleSelect = (pricePackage) => {
        setSelectedPackage(pricePackage);
    };

    const handleNext = () => {
        if (selectedPackage !== null) {
            setOpenPaymentModal(true);
            props.setShowPricingModal(false);
        }
    };

    const handleSelectThenNext = (pricePackage) => {
        setSelectedPackage(pricePackage);
        setOpenPaymentModal(true);
        props.setShowPricingModal(false);
    };

    const handleMobilePureSelect = () => {
        setSelectedPackage(PricingPackage.PURE);
        setPureTranslateModal(true);
    };

    useEffect(() => {
        if (naatiCertified) {
            if (selectedPackage === PricingPackage.PURE || selectedPackage === PricingPackage.PLUS) {
                setSelectedPackage(null);
            }
        }
    }, [naatiCertified, selectedPackage]);

    const getSubtotal = useCallback(() => {
        const total =
            selectedPackage === PricingPackage.PURE
                ? props.pricing.package_pure_price
                : selectedPackage === PricingPackage.PRO
                ? props.pricing.package_pro_price
                : selectedPackage === PricingPackage.PLUS
                ? props.pricing.package_plus_price
                : selectedPackage === PricingPackage.PRIME
                ? props.pricing.package_prime_price
                : 0;

        return parseFloat(total).toFixed(2);
    });

    const CurrencySymbol = () => {
        if (currency === 'gbp') {
            return '€';
        } else if (currency === 'eur') {
            return '£';
        } else {
            return '$';
        }
    };

    useEffect(() => {
        const getLocationByIp = async () => {
            const ipData = await getIp();

            const uniEropa = [
                'AT',
                'NL',
                'BE',
                'BG',
                'CZ',
                'DK',
                'EE',
                'FI',
                'HU',
                'IE',
                'IT',
                'DE',
                'HR',
                'LV',
                'LT',
                'LU',
                'MT',
                'PL',
                'PT',
                'FR',
                'RO',
                'CY',
                'SI',
                'SK',
                'ES',
                'SE',
                'GR',
            ];
            let countryCode = ipData['geoplugin_countryCode'][0];
            if (countryCode) {
                if (uniEropa.includes(countryCode)) {
                    setCurrency('eur');
                } else if (countryCode == 'US' || countryCode == 'USA') {
                    setCurrency('usd');
                } else if (countryCode == 'SG' || countryCode == 'SGP') {
                    setCurrency('sgd');
                } else if (countryCode == 'NZ' || countryCode == 'NZL') {
                    setCurrency('nzd');
                } else if (countryCode == 'CA' || countryCode == 'CAN') {
                    setCurrency('cad');
                } else if (countryCode == 'GB' || countryCode == 'GBR') {
                    setCurrency('gbp');
                } else if (countryCode == 'AU' || countryCode == 'AUS') {
                    setCurrency('aud');
                } else {
                    setCurrency('aud');
                }
            } else {
                setCurrency('aud');
            }
        };
        getLocationByIp();
    }, []);

    const getTotal = () => {
        return (parseFloat(getSubtotal()) + parseFloat(tax)).toFixed(2);
    };

    const pureTranslate = async () => {
        //free
        actions.setLoading(true);
        props.setPricingLoading(true);
        const paymentRequest = await handlePaymentRequest(
            {
                targetLang: targetLangIds,
                currency: currency,
                wordCount: wordCount,
                selectedPricing: 'pure',
                tax: 0,
                fullname: null,
                organisation: null,
                email: null,
                password: null,
                phone_number: null,
                bill_address: null,
                city: null,
                state: null,
                zip: null,
                country: null,
                card_no: null,
                exp_month: null,
                exp_year: null,
                phoneNumber: null,
                cvv: null,
                paymentMethodId: null,
                savePaymentDetails: false,
                naatiCertified: naatiCertified,
                naati_per_word: pricing.naati_per_word,
                add_naati_price: pricing.add_naati_price,
            },
            `${props.user.signature}!${props.id}`,
        );
        if (paymentRequest['success']) {
            for await (const language_id of props.selectedLanguages) {
                await createTranslation(props.id, language_id);
                await lockTranslation(props.id, language_id);
                actions.setTimeLock(true);
            }
            let translatedLanguage;
            if (props.user) {
                translatedLanguage = await props.fetchTranslated(props.id, props.user.signature);
            }
            //change language checkbox
            if (translatedLanguage) {
                // props.setIsTranslateChange(true);
                const selectedId = props.selectedLanguages[0];
                props.setTranslationSingleSelected(selectedId);
                if (translatedLanguage.filter((e) => e.language_id == selectedId).length > 0) {
                    props.setLanguageText(
                        translatedLanguage.filter((e) => e.language_id == selectedId)[0].language.language,
                    );
                }
                let url = new URL(window.location);
                url.searchParams.set('t', true);
                url.searchParams.set('tid', selectedId);
                window.history.pushState(null, '', url.toString());
                const lexigoLanguage = await fetchLexigoLanguage(selectedId);

                unstable_batchedUpdates(() => {
                    props.setCurrentLanguage(selectedId);
                    props.setSelectedLanguages([]);
                    props.setIsTranslation(true);
                    props.setTranslateState(true);
                    props.setPlaying(false);
                    props.setShowPricingModal(false);
                    props.setPricingLoading(false);
                    props.setCurrentLanguageObject(lexigoLanguage);
                    props.setIsTranslateChange(true);
                });
                actions.setLoading(false);
                return;
            }
        }
        //it should be deleted
        unstable_batchedUpdates(() => {
            props.setSelectedLanguages([]);
            props.setIsTranslation(true);
            props.setTranslateState(true);
            props.setPlaying(false);
            props.setShowPricingModal(false);
            actions.setLoading(false);
            props.setPricingLoading(false);
        });
    };

    const translateMyself = async () => {
        actions.setLoading(true);
        props.setPricingLoading(true);
        for await (const language_id of props.selectedLanguages) {
            await createTranslation(props.id, language_id);
        }
        let translatedLanguage;
        if (props.user) {
            translatedLanguage = await props.fetchTranslated(props.id, props.user.signature);
        }
        //change language checkbox
        if (translatedLanguage) {
            // props.setIsTranslateChange(true);
            const selectedId = props.selectedLanguages[0];
            props.setTranslationSingleSelected(selectedId);
            if (translatedLanguage.filter((e) => e.language_id == selectedId).length > 0) {
                props.setLanguageText(
                    translatedLanguage.filter((e) => e.language_id == selectedId)[0].language.language,
                );
            }
            let url = new URL(window.location);
            url.searchParams.set('t', true);
            url.searchParams.set('tid', selectedId);
            window.history.pushState(null, '', url.toString());
            const lexigoLanguage = await fetchLexigoLanguage(selectedId);
            props.setCurrentLanguageObject(lexigoLanguage);
            unstable_batchedUpdates(() => {
                props.setCurrentLanguage(selectedId);
                props.setSelectedLanguages([]);
                props.setIsTranslation(true);
                props.setTranslateState(true);
                props.setPlaying(false);
                props.setShowPricingModal(false);
                props.setPricingLoading(false);
                props.setIsTranslateChange(true);
                setTranslateBy('lexigo');
            });
            actions.setLoading(false);
            return;
        }
        //it should be deleted
        unstable_batchedUpdates(() => {
            props.setSelectedLanguages([]);
            props.setIsTranslation(true);
            props.setTranslateState(true);
            props.setPlaying(false);
            props.setShowPricingModal(false);
            actions.setLoading(false);
            props.setPricingLoading(false);
            setTranslateBy('lexigo');
        });
    };

    const nextProps = {
        selectedPackage,
        CurrencySymbol,
        setSelectedPackage,
        openDetailsModal,
        setOpenDetailsModal,
        openPaymentModal,
        setOpenPaymentModal,
        handleSelect,
        setCurrency,
        currency,
        naatiCertified,
        selectedLanguagesObj,
        setSelectedLanguagesObj,
        wordCount,
        setTargetLangIds,
        targetLangIds,
        tax,
        setTax,
        getSubtotal,
        getTotal,
        ...props,
    };

    const handleClickAccordion = (e) => {
        let content = e.target.nextElementSibling;
        if (!content) return;
        let currentHeight = content.offsetHeight;
        let realHeight = content.scrollHeight;

        if (currentHeight === 0) {
            content.style.maxHeight = realHeight + 'px';
            content.classList.add('is--open');
            e.target.classList.add('is--open');
        } else {
            content.style.maxHeight = realHeight + 'px';
            setTimeout(() => (content.style.maxHeight = '0'), 100);
            content.classList.remove('is--open');
            e.target.classList.remove('is--open');
        }
    };

    const [collapseHeight, setCollapseHeight] = useState(0);
    const [height, setHeight] = useState(null);

    const handleScroll = (e) => {
        if (e && e.target.scrollTop > 200) setCollapseHeight(0);
        else setCollapseHeight(height);
    };

    useEffect(async () => {
        setTimeout(() => {
            handleScroll();
        }, 0);

        if (props.showPricingModal) {
            if (height === null) {
                setTimeout(() => {
                    try {
                        let pricingCollapse = document.querySelector('.pricing-collapse-on-scroll');
                        if (pricingCollapse.offsetHeight) setHeight(pricingCollapse.offsetHeight);
                        handleScroll();
                    } catch (e) {}
                }, 0);
            }
        } else {
            setHeight(null);
        }
    }, [props.showPricingModal]);

    const [translateMySelfModal, setTranslateMySelfModal] = useState(false);
    const TranslateMySelfModal = ({ translateMySelfModal, setTranslateMySelfModal }) => {
        return (
            <Modal
                open={translateMySelfModal}
                onClose={() => {
                    setTranslateMySelfModal(false);
                }}
            >
                <div
                    id="modal-translate"
                    className="modal show"
                    style={{
                        marginTop: '0px',
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        zIndex: 10000,
                    }}
                    tabIndex="-1"
                    aria-hidden="true"
                    data-backdrop="static"
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content h-full">
                            <div className="modal-body h-full">
                                <p className="modal-title font-bold mb-5">
                                    Are you sure you want to translate this by yourself? This process can't be undone
                                </p>
                                <button
                                    className={`btn text-white py-1 lg:py-2 font-bold rounded-3px flex-1`}
                                    onClick={() => {
                                        setTranslateMySelfModal(false);
                                        translateMyself();
                                        setTranslateBy('myteam');
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className={`btn ml-3 text-white py-1 lg:py-2 font-bold rounded-3px flex-1`}
                                    onClick={() => {
                                        setTranslateMySelfModal(false);
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const PureTranslateModal = ({ pureTranslateModal, setPureTranslateModal }) => {
        return (
            <Modal
                open={pureTranslateModal}
                onClose={() => {
                    setPureTranslateModal(false);
                }}
            >
                <div
                    id="modal-translate-pure"
                    className="modal show"
                    style={{
                        marginTop: '0px',
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        zIndex: 10000,
                    }}
                    tabIndex="-1"
                    aria-hidden="true"
                    data-backdrop="static"
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content h-full">
                            <div className="modal-body h-full">
                                <p className="modal-title font-bold mb-5">
                                    Are you sure you want to translate this by machine translation only?
                                </p>
                                <button
                                    className={`btn text-white py-1 lg:py-2 font-bold rounded-3px flex-1`}
                                    onClick={() => {
                                        setPureTranslateModal(false);
                                        pureTranslate();
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className={`btn ml-3 text-white py-1 lg:py-2 font-bold rounded-3px flex-1`}
                                    onClick={() => {
                                        setPureTranslateModal(false);
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };
    return (
        <>
            <Payment {...nextProps} />
            <TranslateMySelfModal
                translateMySelfModal={translateMySelfModal}
                setTranslateMySelfModal={setTranslateMySelfModal}
            />
            <PureTranslateModal pureTranslateModal={pureTranslateModal} setPureTranslateModal={setPureTranslateModal} />

            <PricingDetailsModal {...nextProps} />
            <Modal
                open={props.showPricingModal}
                onClose={() => {
                    props.setShowPricingModal(false);
                }}
            >
                <div
                    id="modal-pricing"
                    style={{
                        marginTop: '0px',
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        zIndex: 10000,
                    }}
                    className="modal show"
                    data-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-xl modal-xl-custom">
                        <div className="modal-content modal-transparent h-full border-0">
                            <a
                                onClick={() => {
                                    props.setShowPricingModal(false);
                                }}
                                data-dismiss="modal"
                                href="javascript:;"
                            >
                                <X className="w-8 h-8 text-dark" />
                            </a>
                            <div
                                className="modal-body h-screen"
                                style={{
                                    padding: '0',
                                    overflow: 'auto',
                                }}
                                onScroll={handleScroll}
                            >
                                <div className="post">
                                    <div
                                        id="panel-translated"
                                        className="post__tabs nav nav-tabs flex-row bg-gray-300 dark:bg-dark-2 text-gray-600 box-tab-bench pricing-container gap-3"
                                        role="tablist"
                                        style={{
                                            background: '#f6f8f9',
                                            height: '61px',
                                            boxShadow: 'none',
                                            marginTop: '0',
                                            padding: '.5rem 0',
                                        }}
                                    >
                                        <label className="flex flex-grow rounded-3px items-center p-5 border pointer">
                                            <input
                                                className="form-check-input mr-4"
                                                name="translateBy"
                                                value="lexigo"
                                                checked={translateBy === 'lexigo'}
                                                onClick={() => setTranslateBy('lexigo')}
                                                type="checkbox"
                                            />
                                            Translate with LEXIGO
                                        </label>
                                        {/* {props.pricing.not_supported_language.length <= 0 ? ( */}
                                        <label className="flex flex-grow rounded-3px items-center p-5 border pointer">
                                            <input
                                                className="form-check-input mr-4"
                                                name="translateBy"
                                                value="myteam"
                                                checked={translateBy === 'myteam'}
                                                onClick={() => {
                                                    // delete later (testing only to go to translate component)
                                                    // if (translateBy === 'myteam') {
                                                    //     translateMyself();
                                                    // }
                                                    // end delete
                                                    // setTranslateBy('myteam');
                                                    setTranslateMySelfModal(true);
                                                }}
                                                type="checkbox"
                                            />
                                            Translate with my team
                                        </label>
                                        {/* ) : (
                                            ''
                                        )} */}
                                        {/*<a*/}
                                        {/*    href="javascript:;"*/}
                                        {/*    className="w-full sm:w-40 py-4 text-center flex justify-center items-center active"*/}
                                        {/*    style={{ width: '100%' }}*/}
                                        {/*>*/}
                                        {/*    Translate by LEXIGO*/}
                                        {/*</a>*/}
                                        {/*<a*/}
                                        {/*    href="javascript:;"*/}
                                        {/*    className="w-full sm:w-40 py-4 text-center flex justify-center items-center"*/}
                                        {/*    style={{ width: '100%' }}*/}
                                        {/*    onClick={translateMyself}*/}
                                        {/*>*/}
                                        {/*    Translate myself*/}
                                        {/*</a>*/}
                                    </div>
                                </div>

                                <div className="pricing-overlay">
                                    <div className="pricing-content">
                                        <section
                                            id="case-study"
                                            className="ct-section testimonials-section wrapper overflow--hidden hide-pricing-for-desktop"
                                        >
                                            <div className="ct-section-inner-wrap h-auto section-custom">
                                                <div
                                                    id="div_block-1168-709"
                                                    className="ct-div-block testimonials-section__right section-sub-custom"
                                                >
                                                    <div
                                                        id="div_block-69-672"
                                                        className="ct-div-block plan-switcher background-none plan-mobile"
                                                    >
                                                        <select
                                                            onChange={(e) => {
                                                                setCurrency(e.target.value);
                                                            }}
                                                            value={currency}
                                                            name="currency"
                                                            className="form-select w-fit-content currency select-light"
                                                        >
                                                            <option value="aud">AUD - Australian dollar</option>
                                                            <option value="usd">USD - US dollar</option>
                                                            <option value="sgd">SGD - Singapore dollar</option>
                                                            <option value="nzd">NZD - New Zealand dollar</option>
                                                            <option value="cad">CAD - Canadian dollar</option>
                                                            <option value="gbp">GBP - British pound</option>
                                                            <option value="eur">EUR - Euro</option>
                                                        </select>
                                                        <div className="w-full sm:w-auto flex items-center sm:ml-auto mt-3">
                                                            {/*
                                                            <input
                                                                checked={naatiCertified}
                                                                className="naaticertified show-code form-check-switch mr-3"
                                                                type="checkbox"
                                                                onChange={(e) => {
                                                                    setNaatiCertified(e.target.checked);
                                                                }}
                                                            />
                                                            <div className="ct-div-block price-row__title flex--row--center">
                                                                <div className="ct-text-block">
                                                                    <span className="ct-span">NAATI-certified</span>
                                                                </div>
                                                                <div className="ct-div-block price-row__title-tooltip">
                                                                    <div className="ct-fancy-icon">
                                                                        <svg
                                                                            id="svg-fancy_icon-181-672-1-1"
                                                                            className=""
                                                                            data-id="svg-fancy_icon-181-672"
                                                                        >
                                                                            <use xlinkHref="#Lexigoquestion"></use>
                                                                        </svg>
                                                                    </div>
                                                                    <div
                                                                        id="div_block-182-672-1-1"
                                                                        className="ct-div-block price-row__tooltip-container tooltip-bottom"
                                                                    >
                                                                        <p
                                                                            id="text_block-183-672-1-1"
                                                                            className="ct-text-block price-row__tooltip-text"
                                                                            data-id="text_block-183-672"
                                                                        >
                                                                            <span
                                                                                id="span-184-672-1-1"
                                                                                className="ct-span"
                                                                            >
                                                                                For Australian translation requirements,
                                                                                it may be a condition that you need a
                                                                                NAATI-certified translation. If so,
                                                                                please click this switch. For more
                                                                                information on NAATI, please visit
                                                                                lexigo.com or email hi@lexigo.com
                                                                            </span>
                                                                        </p>
                                                                        <div
                                                                            id="div_block-185-672-1-1"
                                                                            className="ct-div-block lines-container container-pricing-1"
                                                                            data-id="div_block-185-672"
                                                                        >
                                                                            <div
                                                                                id="code_block-186-672-1-1"
                                                                                className="ct-code-block lines"
                                                                                data-id="code_block-186-672"
                                                                            >
                                                                                <svg>
                                                                                    <use xlinkHref="#lines-1"></use>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            */}
                                                        </div>
                                                    </div>
                                                    <PricingSlider
                                                        options={{
                                                            cellAlign: 'left',
                                                            contain: true,
                                                            groupCells: true,
                                                            adaptiveHeight: true,
                                                            pageDots: true,
                                                            prevNextButtons: false,
                                                            percentPosition: false,
                                                        }}
                                                    >
                                                        <div
                                                            id="div_block-1202-709-3"
                                                            className="ct-div-block testimonials-slider-item card--hover-line card--hover-line-prime"
                                                            data-id="div_block-1202-709"
                                                            aria-hidden="true"
                                                            style={{
                                                                position: 'absolute',
                                                                left: 0,
                                                            }}
                                                        >
                                                            <div
                                                                id="div_block-97-672"
                                                                className="ct-div-block plan-container"
                                                            >
                                                                <div className="col-span-12 pricingForm">
                                                                    <div className="flex items-center plan-name">
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width={70}
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <img
                                                                                    className="mr-1 w-4 h-4"
                                                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                                                    alt=""
                                                                                />
                                                                                <h4
                                                                                    id="headline-74-672"
                                                                                    className="ct-headline"
                                                                                >
                                                                                    <span
                                                                                        id="span-75-672"
                                                                                        className="text-change font-bold text-center font-grostesk"
                                                                                    >
                                                                                        Prime
                                                                                    </span>
                                                                                </h4>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <p
                                                                        id="text_block-100-672"
                                                                        className="ct-text-block t--14 plan-desc flex items-center"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <User className="w-4 h-4 mr-1" />
                                                                                <span
                                                                                    id="span-101-672"
                                                                                    className="ct-span"
                                                                                >
                                                                                    Translator
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                    <p
                                                                        id="text_block-100-672"
                                                                        className="ct-text-block t--14 plan-desc flex items-center"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <Users className="w-4 h-4 mr-1" />
                                                                                <span
                                                                                    id="span-101-672"
                                                                                    className="ct-span"
                                                                                >
                                                                                    Checker/Editor + Proofreader
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                    <div className="border-pricing">
                                                                        <div className="plan-description t--14">
                                                                            {props.pricingLoading ? (
                                                                                <Skeleton
                                                                                    height={60}
                                                                                    width="100%"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            'rgba(0,0,0,.11)',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <span>
                                                                                    Professional Translation, Checking
                                                                                    and Editing plus Proofreading by
                                                                                    qualified human translators.
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        id="div_block-102-672"
                                                                        className="ct-div-block plan-price-container"
                                                                    >
                                                                        <div
                                                                            id="text_block-103-672"
                                                                            className="ct-text-block plan-price"
                                                                        >
                                                                            <span
                                                                                id="span-104-672"
                                                                                className="ct-span font-grostesk textpriceprime"
                                                                            >
                                                                                {props.pricingLoading ? (
                                                                                    <Skeleton
                                                                                        width={110}
                                                                                        height={40}
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                'rgba(0,0,0,.11)',
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        <CurrencySymbol />
                                                                                        {props.pricing
                                                                                            .not_supported_language
                                                                                            .length <= 0
                                                                                            ? props.pricing.package_prime_price.toFixed(
                                                                                                  2,
                                                                                              )
                                                                                            : '-'}
                                                                                    </>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <p className="plan-price-per-word t--14">
                                                                            {props.pricingLoading ? (
                                                                                <Skeleton
                                                                                    width={100}
                                                                                    height={20}
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            'rgba(0,0,0,.11)',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {props.pricing
                                                                                        .not_supported_language
                                                                                        .length <= 0 ? (
                                                                                        <>
                                                                                            from <CurrencySymbol />
                                                                                            {naatiCertified
                                                                                                ? parseFloat(
                                                                                                      props.pricing
                                                                                                          .package_prime_price_naati_per_word,
                                                                                                  ).toFixed(2)
                                                                                                : props.pricing.package_prime_price_per_word.toFixed(
                                                                                                      2,
                                                                                                  )}
                                                                                            /word
                                                                                        </>
                                                                                    ) : (
                                                                                        'Not Available'
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={45.6}
                                                                            width={170}
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                            className="mt-pricing mb-pricing"
                                                                        />
                                                                    ) : (
                                                                        <button
                                                                            className={`ct-link-button theme-button pricing-section__btn t--gordita plan-purchase-btn mt-pricing mb-pricing ${
                                                                                props.pricing.not_supported_language
                                                                                    .length > 0
                                                                                    ? 'option-disabled'
                                                                                    : 'bg-prime primebutton'
                                                                            }`}
                                                                            onClick={() => {
                                                                                handleSelectThenNext(
                                                                                    PricingPackage.PRIME,
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                props.pricing.not_supported_language
                                                                                    .length <= 0
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                        >
                                                                            {props.pricing.not_supported_language
                                                                                .length <= 0
                                                                                ? 'Select'
                                                                                : 'Not Available'}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="div_block-1210-709-3"
                                                                className="ct-div-block lines-container container-pricing-5"
                                                                data-id="div_block-1210-709 "
                                                            >
                                                                <div
                                                                    id="code_block-1211-709-3"
                                                                    className="ct-code-block lines"
                                                                    data-id="code_block-1211-709"
                                                                >
                                                                    <svg>
                                                                        <use xlinkHref="#lines-3"></use>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id="div_block-1202-709-2"
                                                            className="ct-div-block testimonials-slider-item card--hover-line"
                                                            data-id="div_block-1202-709"
                                                            aria-hidden="true"
                                                            style={{
                                                                position: 'absolute',
                                                                left: 0,
                                                            }}
                                                        >
                                                            <div
                                                                id="div_block-85-672"
                                                                className="ct-div-block plan-container"
                                                            >
                                                                <div className="col-span-12 pricingForm">
                                                                    <div className="flex items-center plan-name">
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width={70}
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <img
                                                                                    className="mr-1 w-4 h-4"
                                                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                                                    alt=""
                                                                                />
                                                                                <h4
                                                                                    id="headline-74-672"
                                                                                    className="ct-headline"
                                                                                >
                                                                                    <span
                                                                                        id="span-75-672"
                                                                                        className="text-change font-bold text-center font-grostesk"
                                                                                    >
                                                                                        Pro
                                                                                    </span>
                                                                                </h4>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <p
                                                                        id="text_block-88-672"
                                                                        className="ct-text-block t--14 plan-desc flex items-center"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <User className="w-4 h-4 mr-1" />
                                                                                <span
                                                                                    id="span-101-672"
                                                                                    className="ct-span"
                                                                                >
                                                                                    Translator
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                    <p
                                                                        id="text_block-88-672"
                                                                        className="ct-text-block t--14 plan-desc flex items-center"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <UserCheck className="w-4 h-4 mr-1" />
                                                                                <span
                                                                                    id="span-89-672"
                                                                                    className="ct-span"
                                                                                >
                                                                                    Proofreader
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                    <div className="border-pricing">
                                                                        <div className="plan-description t--14">
                                                                            {props.pricingLoading ? (
                                                                                <Skeleton
                                                                                    height={60}
                                                                                    width="100%"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            'rgba(0,0,0,.11)',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <span>
                                                                                    Professional Translation plus
                                                                                    separate Checking and Editing by
                                                                                    qualified human translators.
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        id="div_block-90-672"
                                                                        className="ct-div-block plan-price-container"
                                                                    >
                                                                        <div
                                                                            id="text_block-91-672"
                                                                            className="ct-text-block plan-price"
                                                                        >
                                                                            <span
                                                                                id="span-92-672"
                                                                                className="ct-span font-grostesk textpricepro"
                                                                            >
                                                                                {props.pricingLoading ? (
                                                                                    <Skeleton
                                                                                        width={110}
                                                                                        height={40}
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                'rgba(0,0,0,.11)',
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        <CurrencySymbol />
                                                                                        {props.pricing
                                                                                            .not_supported_language
                                                                                            .length <= 0
                                                                                            ? props.pricing.package_pro_price.toFixed(
                                                                                                  2,
                                                                                              )
                                                                                            : '-'}
                                                                                    </>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <p className="plan-price-per-word t--14">
                                                                            {props.pricingLoading ? (
                                                                                <Skeleton
                                                                                    width={100}
                                                                                    height={20}
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            'rgba(0,0,0,.11)',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {props.pricing
                                                                                        .not_supported_language
                                                                                        .length <= 0 ? (
                                                                                        <>
                                                                                            from <CurrencySymbol />
                                                                                            {naatiCertified
                                                                                                ? parseFloat(
                                                                                                      props.pricing
                                                                                                          .package_pro_price_naati_per_word,
                                                                                                  ).toFixed(2)
                                                                                                : props.pricing.package_pro_price_per_word.toFixed(
                                                                                                      2,
                                                                                                  )}
                                                                                            /word
                                                                                        </>
                                                                                    ) : (
                                                                                        'Not Available'
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={45.6}
                                                                            width={170}
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                            className="mt-pricing mb-pricing"
                                                                        />
                                                                    ) : (
                                                                        <button
                                                                            className={`ct-link-button theme-button pricing-section__btn t--gordita plan-purchase-btn mt-pricing mb-pricing ${
                                                                                props.pricing.not_supported_language
                                                                                    .length > 0
                                                                                    ? 'option-disabled'
                                                                                    : 'bg-pro probutton'
                                                                            }`}
                                                                            disabled={
                                                                                props.pricing.not_supported_language
                                                                                    .length <= 0
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onClick={() => {
                                                                                handleSelectThenNext(
                                                                                    PricingPackage.PRO,
                                                                                );
                                                                            }}
                                                                        >
                                                                            {props.pricing.not_supported_language
                                                                                .length <= 0
                                                                                ? 'Select'
                                                                                : 'Not Available'}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="div_block-1210-709-2"
                                                                className="ct-div-block lines-container container-pricing-4"
                                                                data-id="div_block-1210-709"
                                                            >
                                                                <div
                                                                    id="code_block-1211-709-2"
                                                                    className="ct-code-block lines"
                                                                    data-id="code_block-1211-709"
                                                                >
                                                                    <svg>
                                                                        <use xlinkHref="#lines-3"></use>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id="div_block-1202-709-1"
                                                            className="ct-div-block testimonials-slider-item card--hover-line is-selected"
                                                            data-id="div_block-1202-709"
                                                            style={{
                                                                position: 'absolute',
                                                                left: 0,
                                                            }}
                                                        >
                                                            <div
                                                                id="div_block-73-672"
                                                                className="ct-div-block plan-container"
                                                            >
                                                                <div className="col-span-12 pricingForm">
                                                                    <div className="flex items-center plan-name">
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width={70}
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <img
                                                                                    className="mr-1 w-4 h-4"
                                                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                                                    alt=""
                                                                                />
                                                                                <div className="text-change font-bold text-center font-grostesk">
                                                                                    Plus
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <p
                                                                        id="text_block-76-672"
                                                                        className="ct-text-block t--14 plan-desc flex items-center"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <Cpu className="w-4 h-4 mr-1" />
                                                                                <span
                                                                                    id="span-77-672"
                                                                                    className="ct-span"
                                                                                >
                                                                                    Machine Translation
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                    <p
                                                                        id="text_block-76-672"
                                                                        className="ct-text-block t--14 plan-desc flex items-center"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <User className="w-4 h-4 mr-1" />
                                                                                <span
                                                                                    id="span-77-672"
                                                                                    className="ct-span"
                                                                                >
                                                                                    Human review
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                    <div className="border-pricing">
                                                                        <div className="plan-description t--14">
                                                                            {props.pricingLoading ? (
                                                                                <Skeleton
                                                                                    height={60}
                                                                                    width="100%"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            'rgba(0,0,0,.11)',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <span>
                                                                                    Human-in-the-loop enterprise-level
                                                                                    AI-powered translation.
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        id="div_block-78-672"
                                                                        className="ct-div-block plan-price-container"
                                                                    >
                                                                        <div
                                                                            id="text_block-79-672"
                                                                            className="ct-text-block plan-price"
                                                                        >
                                                                            <span
                                                                                id="span-80-672"
                                                                                className="ct-span font-grostesk textpriceplus"
                                                                            >
                                                                                {props.pricingLoading ? (
                                                                                    <Skeleton
                                                                                        width={110}
                                                                                        height={40}
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                'rgba(0,0,0,.11)',
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        <CurrencySymbol />
                                                                                        {props.pricing
                                                                                            .not_supported_language
                                                                                            .length <= 0
                                                                                            ? props.pricing.package_plus_price.toFixed(
                                                                                                  2,
                                                                                              )
                                                                                            : '-'}
                                                                                    </>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <p className="plan-price-per-word t--14">
                                                                            {props.pricingLoading ? (
                                                                                <Skeleton
                                                                                    width={100}
                                                                                    height={20}
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            'rgba(0,0,0,.11)',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {props.pricing
                                                                                        .not_supported_language
                                                                                        .length <= 0 &&
                                                                                    !naatiCertified ? (
                                                                                        <>
                                                                                            from <CurrencySymbol />
                                                                                            {props.pricing.package_plus_price_per_word.toFixed(
                                                                                                2,
                                                                                            )}
                                                                                            /word
                                                                                        </>
                                                                                    ) : (
                                                                                        <>Not Available</>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={45.6}
                                                                            width={170}
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                            className="mt-pricing mb-pricing"
                                                                        />
                                                                    ) : (
                                                                        <button
                                                                            className={`ct-link-button theme-button pricing-section__btn t--gordita plan-purchase-btn mt-pricing mb-pricing ${
                                                                                props.pricing.not_supported_language
                                                                                    .length > 0 || naatiCertified
                                                                                    ? 'option-disabled'
                                                                                    : 'bg-plus plusbutton'
                                                                            }`}
                                                                            disabled={
                                                                                props.pricing.not_supported_language
                                                                                    .length <= 0 && !naatiCertified
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onClick={() => {
                                                                                handleSelectThenNext(
                                                                                    PricingPackage.PLUS,
                                                                                );
                                                                            }}
                                                                        >
                                                                            {props.pricing.not_supported_language
                                                                                .length <= 0
                                                                                ? 'Select'
                                                                                : 'Not Available'}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="div_block-1210-709-1"
                                                                className="ct-div-block lines-container container-pricing-3"
                                                                data-id="div_block-1210-709"
                                                            >
                                                                <div
                                                                    id="code_block-1211-709-1"
                                                                    className="ct-code-block lines"
                                                                    data-id="code_block-1211-709"
                                                                >
                                                                    <svg>
                                                                        <use xlinkHref="#lines-3"></use>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id="div_block-1170-709"
                                                            className="ct-div-block testimonials-slider-item card--hover-line is-selected"
                                                            style={{
                                                                position: 'absolute',
                                                                left: 0,
                                                            }}
                                                        >
                                                            <div
                                                                id="div_block-73-672"
                                                                className="ct-div-block plan-container"
                                                            >
                                                                <div className="col-span-12 pricingForm">
                                                                    <div className="flex items-center plan-name">
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width={70}
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <img
                                                                                    className="mr-1 w-4 h-4"
                                                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                                                    alt=""
                                                                                />
                                                                                <div className="text-change font-bold text-center font-grostesk">
                                                                                    Pure
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <p
                                                                        id="text_block-76-672"
                                                                        className="ct-text-block t--14 plan-desc flex items-center"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <Cpu className="w-4 h-4 mr-1" />
                                                                                <span
                                                                                    id="span-77-672"
                                                                                    className="ct-span"
                                                                                >
                                                                                    Machine Translation
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                    <p
                                                                        id="text_block-76-672"
                                                                        className="ct-text-block t--14 plan-desc flex items-center"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={20}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <Layers className="w-4 h-4 mr-1" />
                                                                                <span
                                                                                    id="span-77-672"
                                                                                    className="ct-span"
                                                                                >
                                                                                    Machine QA
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                    <div className="border-pricing">
                                                                        <div className="plan-description t--14">
                                                                            {props.pricingLoading ? (
                                                                                <Skeleton
                                                                                    height={60}
                                                                                    width="100%"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            'rgba(0,0,0,.11)',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <span>
                                                                                    Enterprise-level AI-powered
                                                                                    translation.df
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        id="div_block-78-672"
                                                                        className="ct-div-block plan-price-container"
                                                                    >
                                                                        <div
                                                                            id="text_block-79-672"
                                                                            className="ct-text-block plan-price"
                                                                        >
                                                                            <span
                                                                                id="span-80-672"
                                                                                className="ct-span font-grostesk textpricepure"
                                                                            >
                                                                                {props.pricingLoading ? (
                                                                                    <Skeleton
                                                                                        width={110}
                                                                                        height={40}
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                'rgba(0,0,0,.11)',
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        {props.pricing
                                                                                            .not_supported_language
                                                                                            .length <= 0 &&
                                                                                        !naatiCertified
                                                                                            ? props.audioTime
                                                                                                ? Math.ceil(
                                                                                                      props.audioTime /
                                                                                                          60,
                                                                                                  )
                                                                                                : Math.ceil(
                                                                                                      props.videoTime /
                                                                                                          60,
                                                                                                  )
                                                                                            : '-'}{' '}
                                                                                        {props.audioTime
                                                                                            ? Math.ceil(
                                                                                                  props.audioTime / 60,
                                                                                              ) > 1
                                                                                                ? 'minutes'
                                                                                                : 'minute'
                                                                                            : Math.ceil(
                                                                                                  props.videoTime / 60,
                                                                                              ) > 1
                                                                                            ? 'minutes'
                                                                                            : 'minute'}
                                                                                    </>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <p className="plan-price-per-word t--14">
                                                                            {props.pricingLoading ? (
                                                                                <Skeleton
                                                                                    width={100}
                                                                                    height={20}
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            'rgba(0,0,0,.11)',
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {props.pricing
                                                                                        .not_supported_language
                                                                                        .length <= 0 &&
                                                                                    !naatiCertified ? (
                                                                                        <>
                                                                                            {/* <CurrencySymbol />{props.pricing.package_pure_price_per_word.toFixed(
                                                                                        2,
                                                                                    )} */}
                                                                                            from{' '}
                                                                                            {Math.ceil(
                                                                                                (props.access &&
                                                                                                    props.access
                                                                                                        .userPackage
                                                                                                        .length -
                                                                                                        totalDuration) /
                                                                                                    60,
                                                                                            )}{' '}
                                                                                            Minutes
                                                                                        </>
                                                                                    ) : (
                                                                                        <>Not Available</>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={45.6}
                                                                            width={170}
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                            className="mt-pricing mb-pricing"
                                                                        />
                                                                    ) : (
                                                                        <button
                                                                            type="submit"
                                                                            className={`ct-link-button theme-button pricing-section__btn t--gordita plan-purchase-btn  mt-pricing mb-pricing ${
                                                                                props.pricing.not_supported_language
                                                                                    .length > 0 || naatiCertified
                                                                                    ? 'option-disabled'
                                                                                    : 'bg-pure purebutton'
                                                                            }`}
                                                                            disabled={
                                                                                props.pricing.not_supported_language
                                                                                    .length <= 0 && !naatiCertified
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onClick={() => {
                                                                                handleMobilePureSelect();
                                                                            }}
                                                                        >
                                                                            {props.pricing.not_supported_language
                                                                                .length <= 0
                                                                                ? 'Select'
                                                                                : 'Not Available'}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="div_block-1178-709"
                                                                className="ct-div-block lines-container container-pricing-2"
                                                            >
                                                                <div
                                                                    id="code_block-1179-709"
                                                                    className="ct-code-block lines"
                                                                >
                                                                    <svg>
                                                                        <use xlinkHref="#lines-3"></use>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </PricingSlider>
                                                </div>
                                            </div>
                                        </section>

                                        <section id="pricing" className="ct-section wrapper pricing-section">
                                            <div
                                                className={`header-pricing ${
                                                    !props.pricingLoading && collapseHeight === 0 ? 'collapsed' : ''
                                                }`}
                                                style={{ backgroundColor: '#F6F8F9' }}
                                            >
                                                <div className="ct-div-block pricing-header-sub">
                                                    <div className="pricing-section__plans-cont pricing-grid--3 pricing-grid--new">
                                                        <div className="ct-div-block plan-switcher background-none">
                                                            <form className="mb-4">
                                                                <select
                                                                    name="currency"
                                                                    className="form-select w-fit-content currency select-light"
                                                                    onChange={(e) => {
                                                                        setCurrency(e.target.value);
                                                                    }}
                                                                    value={currency}
                                                                >
                                                                    <option value="aud">AUD - Australian dollar</option>
                                                                    <option value="usd">USD - US dollar</option>
                                                                    <option value="sgd">SGD - Singapore dollar</option>
                                                                    <option value="nzd">
                                                                        NZD - New Zealand dollar
                                                                    </option>
                                                                    <option value="cad">CAD - Canadian dollar</option>
                                                                    <option value="gbp">GBP - British pound</option>
                                                                    <option value="eur">EUR - Euro</option>
                                                                </select>
                                                            </form>
                                                            <div className="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
                                                                {/*
                                                                <input
                                                                    checked={naatiCertified}
                                                                    className="naaticertified show-code form-check-switch mr-3"
                                                                    type="checkbox"
                                                                    onChange={(e) => {
                                                                        setNaatiCertified(e.target.checked);
                                                                    }}
                                                                />
                                                                <div className="ct-div-block price-row__title flex--row--center">
                                                                    <div className="ct-text-block">
                                                                        <span className="ct-span">NAATI-certified</span>
                                                                    </div>
                                                                    <div className="ct-div-block price-row__title-tooltip">
                                                                        <div className="ct-fancy-icon">
                                                                            <svg
                                                                                id="svg-fancy_icon-181-672-1-1"
                                                                                data-id="svg-fancy_icon-181-672"
                                                                            >
                                                                                <use xlinkHref="#Lexigoquestion"></use>
                                                                            </svg>
                                                                        </div>
                                                                        <div
                                                                            id="div_block-182-672-1-1"
                                                                            className="ct-div-block price-row__tooltip-container tooltip-bottom"
                                                                        >
                                                                            <p
                                                                                id="text_block-183-672-1-1"
                                                                                className="ct-text-block price-row__tooltip-text"
                                                                                data-id="text_block-183-672"
                                                                            >
                                                                                <span
                                                                                    id="span-184-672-1-1"
                                                                                    className="ct-span"
                                                                                >
                                                                                    For Australian translation
                                                                                    requirements, it may be a condition
                                                                                    that you need a NAATI-certified
                                                                                    translation. If so, please click
                                                                                    this switch. For more information on
                                                                                    NAATI, please visit lexigo.com or
                                                                                    email hi@lexigo.com
                                                                                </span>
                                                                            </p>
                                                                            <div
                                                                                id="div_block-185-672-1-1"
                                                                                className="ct-div-block lines-container"
                                                                                data-id="div_block-185-672"
                                                                            >
                                                                                <div
                                                                                    id="code_block-186-672-1-1"
                                                                                    className="ct-code-block lines"
                                                                                    data-id="code_block-186-672"
                                                                                >
                                                                                    <svg>
                                                                                        <use xlinkHref="#lines-1"></use>
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                */}
                                                            </div>
                                                        </div>

                                                        <div
                                                            id="div_block-73-672"
                                                            className={`ct-div-block plan-container ${
                                                                selectedPackage === PricingPackage.PRIME ? 'active' : ''
                                                            }`}
                                                        >
                                                            <div className="flex items-center plan-name">
                                                                {props.pricingLoading ? (
                                                                    <Skeleton
                                                                        height={20}
                                                                        width={70}
                                                                        style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <img
                                                                            className="mr-1 w-4 h-4"
                                                                            src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                                            alt=""
                                                                        />
                                                                        <h4
                                                                            id="headline-74-672"
                                                                            className="ct-headline"
                                                                        >
                                                                            <span
                                                                                id="span-75-672"
                                                                                className="text-change font-bold text-center font-grostesk"
                                                                            >
                                                                                Prime
                                                                            </span>
                                                                        </h4>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div
                                                                className="pricing-collapse-on-scroll"
                                                                style={
                                                                    !props.pricingLoading
                                                                        ? { height: collapseHeight + 'px' }
                                                                        : undefined
                                                                }
                                                            >
                                                                <p
                                                                    id="text_block-100-672"
                                                                    className="ct-text-block t--14 plan-desc flex items-center"
                                                                >
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={20}
                                                                            width="100%"
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <User className="w-4 h-4 mr-1" />
                                                                            <span id="span-101-672" className="ct-span">
                                                                                Translator
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <p
                                                                    id="text_block-100-672"
                                                                    className="ct-text-block t--14 plan-desc flex items-center"
                                                                >
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={20}
                                                                            width="100%"
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <Users className="w-4 h-4 mr-1" />
                                                                            <span id="span-101-672" className="ct-span">
                                                                                Checker/Editor + Proofreader
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <div className="border-pricing">
                                                                    <div className="plan-description t--14">
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={60}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <span>
                                                                                Professional Translation, Checking and
                                                                                Editing plus Proofreading by qualified
                                                                                human translators.
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="div_block-78-672"
                                                                className="ct-div-block plan-price-container"
                                                            >
                                                                <div
                                                                    id="text_block-79-672"
                                                                    className="ct-text-block plan-price"
                                                                >
                                                                    <span
                                                                        id="span-80-672"
                                                                        className="ct-span font-grostesk textpriceprime"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                width={110}
                                                                                height={40}
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <CurrencySymbol />
                                                                                {props.pricing.not_supported_language
                                                                                    .length <= 0
                                                                                    ? props.pricing.package_prime_price.toFixed(
                                                                                          2,
                                                                                      )
                                                                                    : '-'}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <p className="t--14 mt-1">
                                                                {props.pricingLoading ? (
                                                                    <Skeleton
                                                                        width={100}
                                                                        height={20}
                                                                        style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        from <CurrencySymbol />
                                                                        {naatiCertified
                                                                            ? parseFloat(
                                                                                  props.pricing
                                                                                      .package_prime_price_naati_per_word,
                                                                              ).toFixed(2)
                                                                            : props.pricing.package_prime_price_per_word.toFixed(
                                                                                  2,
                                                                              )}
                                                                        /word
                                                                    </>
                                                                )}
                                                            </p>
                                                            {props.pricingLoading ? (
                                                                <Skeleton
                                                                    height={47.6}
                                                                    width={170}
                                                                    style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    className="mt-pricing mb-pricing"
                                                                />
                                                            ) : (
                                                                <button
                                                                    className={`ct-link-button theme-button pricing-section__btn t--gordita plan-purchase-btn plan-purchase-btn-desktop  mt-pricing ${
                                                                        props.pricing.not_supported_language.length > 0
                                                                            ? 'option-disabled'
                                                                            : 'bg-prime primebutton'
                                                                    }`}
                                                                    disabled={
                                                                        props.pricing.not_supported_language.length <= 0
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    onClick={() =>
                                                                        selectedPackage === PricingPackage.PRIME
                                                                            ? handleNext()
                                                                            : handleSelect(PricingPackage.PRIME)
                                                                    }
                                                                >
                                                                    {selectedPackage === PricingPackage.PRIME ? (
                                                                        <>
                                                                            <span>Next</span>
                                                                            <div className="plan-arrow-right">
                                                                                <ArrowRight
                                                                                    className="w-5 h-5"
                                                                                    style={{ strokeWidth: 1.5 }}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <span>
                                                                            {props.pricing.not_supported_language
                                                                                .length <= 0
                                                                                ? 'Select'
                                                                                : 'Not Available'}
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div
                                                            id="div_block-73-672"
                                                            className={`ct-div-block plan-container ${
                                                                selectedPackage === PricingPackage.PRO ? 'active' : ''
                                                            }`}
                                                        >
                                                            <div className="flex items-center plan-name">
                                                                {props.pricingLoading ? (
                                                                    <Skeleton
                                                                        height={20}
                                                                        width={70}
                                                                        style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <img
                                                                            className="mr-1 w-4 h-4"
                                                                            src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                                            alt=""
                                                                        />
                                                                        <h4
                                                                            id="headline-74-672"
                                                                            className="ct-headline"
                                                                        >
                                                                            <span
                                                                                id="span-75-672"
                                                                                className="text-change font-bold text-center font-grostesk"
                                                                            >
                                                                                Pro
                                                                            </span>
                                                                        </h4>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div
                                                                className="pricing-collapse-on-scroll"
                                                                style={
                                                                    !props.pricingLoading
                                                                        ? { height: collapseHeight + 'px' }
                                                                        : undefined
                                                                }
                                                            >
                                                                <p
                                                                    id="text_block-88-672"
                                                                    className="ct-text-block t--14 plan-desc flex items-center"
                                                                >
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={20}
                                                                            width="100%"
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <User className="w-4 h-4 mr-1" />
                                                                            <span id="span-101-672" className="ct-span">
                                                                                Translator
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <p
                                                                    id="text_block-88-672"
                                                                    className="ct-text-block t--14 plan-desc flex items-center"
                                                                >
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={20}
                                                                            width="100%"
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <UserCheck className="w-4 h-4 mr-1" />
                                                                            <span id="span-89-672" className="ct-span">
                                                                                Proofreader
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <div className="border-pricing">
                                                                    <div className="plan-description t--14">
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={60}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <span>
                                                                                Professional Translation plus separate
                                                                                Checking and Editing by qualified human
                                                                                translators.
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="div_block-78-672"
                                                                className="ct-div-block plan-price-container"
                                                            >
                                                                <div
                                                                    id="text_block-79-672"
                                                                    className="ct-text-block plan-price"
                                                                >
                                                                    <span
                                                                        id="span-80-672"
                                                                        className="ct-span font-grostesk textpricepro"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                width={110}
                                                                                height={40}
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <CurrencySymbol />
                                                                                {props.pricing.not_supported_language
                                                                                    .length <= 0
                                                                                    ? props.pricing.package_pro_price.toFixed(
                                                                                          2,
                                                                                      )
                                                                                    : '-'}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <p className="t--14 mt-1">
                                                                {props.pricingLoading ? (
                                                                    <Skeleton
                                                                        width={100}
                                                                        height={20}
                                                                        style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {props.pricing.not_supported_language.length <=
                                                                        0 ? (
                                                                            <>
                                                                                from <CurrencySymbol />
                                                                                {naatiCertified
                                                                                    ? parseFloat(
                                                                                          props.pricing
                                                                                              .package_pro_price_naati_per_word,
                                                                                      ).toFixed(2)
                                                                                    : parseFloat(
                                                                                          props.pricing
                                                                                              .package_pro_price_per_word,
                                                                                      ).toFixed(2)}
                                                                                /word
                                                                            </>
                                                                        ) : (
                                                                            'Not Available'
                                                                        )}
                                                                    </>
                                                                )}
                                                            </p>
                                                            {props.pricingLoading ? (
                                                                <Skeleton
                                                                    height={47.6}
                                                                    width={170}
                                                                    style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    className="mt-pricing mb-pricing"
                                                                />
                                                            ) : (
                                                                <button
                                                                    className={`ct-link-button theme-button pricing-section__btn t--gordita plan-purchase-btn plan-purchase-btn-desktop  mt-pricing ${
                                                                        props.pricing.not_supported_language.length > 0
                                                                            ? 'option-disabled'
                                                                            : 'bg-pro probutton'
                                                                    }`}
                                                                    disabled={
                                                                        props.pricing.not_supported_language.length <= 0
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    onClick={() =>
                                                                        selectedPackage === PricingPackage.PRO
                                                                            ? handleNext()
                                                                            : handleSelect(PricingPackage.PRO)
                                                                    }
                                                                >
                                                                    {selectedPackage === PricingPackage.PRO ? (
                                                                        <>
                                                                            <span>Next</span>
                                                                            <div className="plan-arrow-right">
                                                                                <ArrowRight
                                                                                    className="w-5 h-5"
                                                                                    style={{ strokeWidth: 1.5 }}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <span>
                                                                            {props.pricing.not_supported_language
                                                                                .length <= 0
                                                                                ? 'Select'
                                                                                : 'Not Available'}
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div
                                                            id="div_block-73-672"
                                                            className={`ct-div-block plan-container ${
                                                                selectedPackage === PricingPackage.PLUS ? 'active' : ''
                                                            }`}
                                                        >
                                                            <div className="flex items-center plan-name">
                                                                {props.pricingLoading ? (
                                                                    <Skeleton
                                                                        height={20}
                                                                        width={70}
                                                                        style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <img
                                                                            className="mr-1 w-4 h-4"
                                                                            src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                                            alt=""
                                                                        />
                                                                        <div className="text-change font-bold text-center font-grostesk">
                                                                            Plus
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div
                                                                className="pricing-collapse-on-scroll"
                                                                style={
                                                                    !props.pricingLoading
                                                                        ? { height: collapseHeight + 'px' }
                                                                        : undefined
                                                                }
                                                            >
                                                                <p
                                                                    id="text_block-76-672"
                                                                    className="ct-text-block t--14 plan-desc flex items-center"
                                                                >
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={20}
                                                                            width="100%"
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <Cpu className="w-4 h-4 mr-1" />
                                                                            <span id="span-77-672" className="ct-span">
                                                                                Machine Translation
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <p
                                                                    id="text_block-76-672"
                                                                    className="ct-text-block t--14 plan-desc flex items-center"
                                                                >
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={20}
                                                                            width="100%"
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <User className="w-4 h-4 mr-1" />
                                                                            <span id="span-77-672" className="ct-span">
                                                                                Human review
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <div className="border-pricing">
                                                                    <div className="plan-description t--14">
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={60}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <span>
                                                                                Human-in-the-loop enterprise-level
                                                                                AI-powered translation.
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="div_block-78-672"
                                                                className="ct-div-block plan-price-container"
                                                            >
                                                                <div
                                                                    id="text_block-79-672"
                                                                    className="ct-text-block plan-price"
                                                                >
                                                                    <span
                                                                        id="span-80-672"
                                                                        className="ct-span font-grostesk textpriceplus"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                width={110}
                                                                                height={40}
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                <CurrencySymbol />
                                                                                {props.pricing.not_supported_language
                                                                                    .length <= 0
                                                                                    ? props.pricing.package_plus_price.toFixed(
                                                                                          2,
                                                                                      )
                                                                                    : '-'}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <p className="t--14 mt-1">
                                                                {props.pricingLoading ? (
                                                                    <Skeleton
                                                                        width={100}
                                                                        height={20}
                                                                        style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {props.pricing.not_supported_language.length <=
                                                                            0 && !naatiCertified ? (
                                                                            <>
                                                                                from <CurrencySymbol />
                                                                                {props.pricing.package_plus_price_per_word.toFixed(
                                                                                    2,
                                                                                )}
                                                                                /word
                                                                            </>
                                                                        ) : (
                                                                            <>Not Available</>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </p>
                                                            {props.pricingLoading ? (
                                                                <Skeleton
                                                                    height={47.6}
                                                                    width={170}
                                                                    style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    className="mt-pricing mb-pricing"
                                                                />
                                                            ) : (
                                                                <button
                                                                    className={`ct-link-button theme-button pricing-section__btn t--gordita plan-purchase-btn plan-purchase-btn-desktop  mt-pricing ${
                                                                        props.pricing.not_supported_language.length >
                                                                            0 || naatiCertified
                                                                            ? 'option-disabled'
                                                                            : 'bg-plus plusbutton'
                                                                    }`}
                                                                    disabled={
                                                                        props.pricing.not_supported_language.length <=
                                                                            0 && !naatiCertified
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    onClick={() =>
                                                                        selectedPackage === PricingPackage.PLUS
                                                                            ? handleNext()
                                                                            : handleSelect(PricingPackage.PLUS)
                                                                    }
                                                                >
                                                                    {selectedPackage === PricingPackage.PLUS ? (
                                                                        <>
                                                                            <span>Next</span>
                                                                            <div className="plan-arrow-right">
                                                                                <ArrowRight
                                                                                    className="w-5 h-5"
                                                                                    style={{ strokeWidth: 1.5 }}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <span>
                                                                            {props.pricing.not_supported_language
                                                                                .length <= 0
                                                                                ? 'Select'
                                                                                : 'Not Available'}
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div
                                                            id="div_block-73-672"
                                                            className={`ct-div-block plan-container ${
                                                                selectedPackage === PricingPackage.PURE ? 'active' : ''
                                                            }`}
                                                        >
                                                            <div className="flex items-center plan-name">
                                                                {props.pricingLoading ? (
                                                                    <Skeleton
                                                                        height={20}
                                                                        width={70}
                                                                        style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <img
                                                                            className="mr-1 w-4 h-4"
                                                                            src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                                            alt=""
                                                                        />
                                                                        <div className="text-change font-bold text-center font-grostesk">
                                                                            Pure
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div
                                                                className="pricing-collapse-on-scroll"
                                                                style={
                                                                    !props.pricingLoading
                                                                        ? { height: collapseHeight + 'px' }
                                                                        : undefined
                                                                }
                                                            >
                                                                <p
                                                                    id="text_block-76-672"
                                                                    className="ct-text-block t--14 plan-desc flex items-center"
                                                                >
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={20}
                                                                            width="100%"
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <Cpu className="w-4 h-4 mr-1" />
                                                                            <span id="span-77-672" className="ct-span">
                                                                                Machine Translation
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <p
                                                                    id="text_block-76-672"
                                                                    className="ct-text-block t--14 plan-desc flex items-center"
                                                                >
                                                                    {props.pricingLoading ? (
                                                                        <Skeleton
                                                                            height={20}
                                                                            width="100%"
                                                                            style={{
                                                                                backgroundColor: 'rgba(0,0,0,.11)',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <Layers className="w-4 h-4 mr-1" />
                                                                            <span id="span-77-672" className="ct-span">
                                                                                Machine QA
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <div className="border-pricing">
                                                                    <div className="plan-description t--14">
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                height={60}
                                                                                width="100%"
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <span>
                                                                                Enterprise-level AI-powered translation.
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="div_block-78-672"
                                                                className="ct-div-block plan-price-container"
                                                            >
                                                                <div
                                                                    id="text_block-79-672"
                                                                    className="ct-text-block plan-price"
                                                                >
                                                                    <span
                                                                        id="span-80-672"
                                                                        className="ct-span font-grostesk textpricepure"
                                                                    >
                                                                        {props.pricingLoading ? (
                                                                            <Skeleton
                                                                                width={110}
                                                                                height={40}
                                                                                style={{
                                                                                    backgroundColor: 'rgba(0,0,0,.11)',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                {props.pricing.not_supported_language
                                                                                    .length <= 0 && !naatiCertified
                                                                                    ? props.audioTime
                                                                                        ? Math.ceil(
                                                                                              props.audioTime / 60,
                                                                                          )
                                                                                        : Math.ceil(
                                                                                              props.videoTime / 60,
                                                                                          )
                                                                                    : '-'}{' '}
                                                                                {props.audioTime
                                                                                    ? Math.ceil(props.audioTime / 60) >
                                                                                      1
                                                                                        ? 'minutes'
                                                                                        : 'minute'
                                                                                    : Math.ceil(props.videoTime / 60) >
                                                                                      1
                                                                                    ? 'minutes'
                                                                                    : 'minute'}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <p className="t--14 mt-1">
                                                                {props.pricingLoading ? (
                                                                    <Skeleton
                                                                        width={100}
                                                                        height={20}
                                                                        style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {props.pricing.not_supported_language.length <=
                                                                            0 && !naatiCertified ? (
                                                                            <>
                                                                                {/*<CurrencySymbol /> {props.pricing.package_pure_price_per_word.toFixed(
                                                                            2,
                                                                        )} */}
                                                                                from{' '}
                                                                                {Math.ceil(
                                                                                    (props.access &&
                                                                                        props.access.userPackage
                                                                                            .length - totalDuration) /
                                                                                        60,
                                                                                )}{' '}
                                                                                Minutes
                                                                            </>
                                                                        ) : (
                                                                            <>Not Available</>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </p>
                                                            {props.pricingLoading ? (
                                                                <Skeleton
                                                                    height={47.6}
                                                                    width={170}
                                                                    style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                                                                    className="mt-pricing mb-pricing"
                                                                />
                                                            ) : (
                                                                <button
                                                                    className={`ct-link-button theme-button pricing-section__btn t--gordita plan-purchase-btn plan-purchase-btn-desktop  mt-pricing ${
                                                                        props.pricing.not_supported_language.length >
                                                                            0 || naatiCertified
                                                                            ? 'option-disabled'
                                                                            : 'bg-pure purebutton'
                                                                    }`}
                                                                    disabled={
                                                                        props.pricing.not_supported_language.length <=
                                                                            0 && !naatiCertified
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    onClick={() =>
                                                                        selectedPackage === PricingPackage.PURE
                                                                            ? setPureTranslateModal(true)
                                                                            : handleSelect(PricingPackage.PURE)
                                                                    }
                                                                >
                                                                    {selectedPackage === PricingPackage.PURE ? (
                                                                        <>
                                                                            <span>Next</span>
                                                                            <div className="plan-arrow-right">
                                                                                <ArrowRight
                                                                                    className="w-5 h-5"
                                                                                    style={{ strokeWidth: 1.5 }}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <span>
                                                                            {props.pricing.not_supported_language
                                                                                .length <= 0
                                                                                ? 'Select'
                                                                                : 'Not Available'}
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-container">
                                                <div className="pricing-content">
                                                    {props.pricing.not_supported_language.length > 0 ? (
                                                        <div className={`col-span-12 intro-y`}>
                                                            <div
                                                                className="alert alert-primary-soft show flex mb-2"
                                                                role="alert"
                                                            >
                                                                <AlertOctagon className="w-6 h-6 mr-2" />
                                                                The following language/s are not supported on one or
                                                                more of our packages:
                                                                {props.pricing.not_supported_language.join(', ')}.
                                                                Please go back and exclude the language/s to continue
                                                                with your order, otherwise please contact us for help
                                                                with unsupported languages.
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <CompareFeatures {...props} />
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default PricingModal;
