import { useEffect, useState } from 'react';
const TimerTableSub = ({ subData }) => {
    const [tempSub, setTempSub] = useState('');

    useEffect(() => {
        if (subData) {
            setTempSub(subData);
        }
    }, [subData]);
    return (
        <>
            <div className="table-timer-wrapper">
                <input type="text" className="p-2 rounded-3px text-xs" value={tempSub.start} disabled />
                <span className="mx-3 text-gray-500">-</span>
                <input type="text" className="p-2 rounded-3px text-xs" value={tempSub.end} disabled />
            </div>
        </>
    );
};

export default TimerTableSub;
