/* eslint-disable no-script-url */

import { Modal } from '@mui/material';
import Cleave from 'cleave.js/react';
import $ from 'jquery';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Check, X } from 'react-feather';
import Geosuggest from 'react-geosuggest';
import { useActions } from '../../overmind';
import { fetchCountries } from '../../requests/CountryRequest';
import { fetchLexigoLanguage } from '../../requests/LanguageRequest';
import { fetchAdditionalUserData } from '../../requests/LexigoDataRequest';
import { fetchPaymentMethods, handlePaymentRequest } from '../../requests/PaymentRequest';
import { createTranslation, lockTranslation } from '../../requests/TranslateRequest';
import { capitalize } from '../../utils';
import OrderSummary from './OrderSummary';
import { PricingPackage } from './PricingModal';

const TextBoxEntity = {
    FULLNAME: 'fullname',
    ORGANISATION: 'organisation',
    EMAIL: 'email',
    PASSWORD: 'password',
    PHONENUMBER: 'phonenumber',
    BILLADDRESS: 'billaddress',
    CITY: 'city',
    STATE: 'state',
    POSTALCODE: 'postalcode',
    COUNTRY: 'country',
    CARDHOLDER_NAME: 'cardholdername',
    EXPIRATIONDATE: 'expirationdate',
    CARDNUMBER: 'cardnumber',
    CVV: 'cvv',
    PAYMENTMETHODID: 'paymentmethodid',
};

/* eslint-disable jsx-a11y/anchor-is-valid */
const Payment = (props) => {
    const [onFocus, setOnFocus] = useState('');
    const actions = useActions();
    const [initLoading, setInitLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [showCreateCC, setShowCreateCC] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [changeBillingAddress, setChangeBillingAddress] = useState(false);
    const [savePaymentDetails, setSavePaymentDetails] = useState(false);
    const [countryLists, setCountryLists] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [formData, setFormData] = useState({
        [TextBoxEntity.FULLNAME]: '',
        [TextBoxEntity.ORGANISATION]: '',
        [TextBoxEntity.EMAIL]: '',
        [TextBoxEntity.PHONENUMBER]: '',
        [TextBoxEntity.BILLADDRESS]: '',
        [TextBoxEntity.CITY]: '',
        [TextBoxEntity.STATE]: '',
        [TextBoxEntity.POSTALCODE]: '',
        [TextBoxEntity.COUNTRY]: '',
        [TextBoxEntity.CARDHOLDER_NAME]: '',
        [TextBoxEntity.EXPIRATIONDATE]: '',
        [TextBoxEntity.CARDNUMBER]: '',
        [TextBoxEntity.CVV]: '',
        [TextBoxEntity.PAYMENTMETHODID]: '',
    });
    const [error, setError] = useState({
        [TextBoxEntity.FULLNAME]: false,
        [TextBoxEntity.ORGANISATION]: false,
        [TextBoxEntity.EMAIL]: false,
        [TextBoxEntity.PHONENUMBER]: false,
        [TextBoxEntity.BILLADDRESS]: false,
        [TextBoxEntity.CARDHOLDER_NAME]: false,
        [TextBoxEntity.EXPIRATIONDATE]: false,
        [TextBoxEntity.CARDNUMBER]: false,
        [TextBoxEntity.CVV]: false,
        [TextBoxEntity.PAYMENTMETHODID]: false,
        [TextBoxEntity.CITY]: false,
        [TextBoxEntity.STATE]: false,
        [TextBoxEntity.POSTALCODE]: false,
        [TextBoxEntity.COUNTRY]: false,
        request: '',
    });

    const handleOnInput = (textEntity) => {
        setOnFocus(textEntity);
    };

    const handleOnchange = useCallback((textEntity, value) => {
        setError({
            ...error,
            [textEntity]: false,
        });
        if (textEntity === TextBoxEntity.PHONENUMBER) {
            value = value.replace(/[^\d | +]/g, '');
        } else if (textEntity === TextBoxEntity.BILLADDRESS) {
            const address = addresses[value];
            const data = {
                ...formData,
                [TextBoxEntity.COUNTRY]: address ? address.country : '',
                [textEntity]: value,
            };
            setFormData(data);
        } else {
            const data = {
                ...formData,
                [textEntity]: value,
            };
            setFormData(data);
        }
    });

    const handleOnSubmit = async () => {
        setError({
            [TextBoxEntity.FULLNAME]: false,
            [TextBoxEntity.ORGANISATION]: false,
            [TextBoxEntity.EMAIL]: false,
            [TextBoxEntity.PHONENUMBER]: false,
            [TextBoxEntity.BILLADDRESS]: false,
            [TextBoxEntity.CARDHOLDER_NAME]: false,
            [TextBoxEntity.EXPIRATIONDATE]: false,
            [TextBoxEntity.CARDNUMBER]: false,
            [TextBoxEntity.CVV]: false,
            [TextBoxEntity.PAYMENTMETHODID]: false,
            [TextBoxEntity.CITY]: false,
            [TextBoxEntity.STATE]: false,
            [TextBoxEntity.POSTALCODE]: false,
            [TextBoxEntity.COUNTRY]: false,
            request: '',
        });
        if (processing) return;
        const newError = { ...error };

        let valid = true;

        Object.keys(newError).forEach(function (key) {
            if (key === TextBoxEntity.PHONENUMBER) return;
            if (formData[key] === '') {
                if (
                    (key === TextBoxEntity.CARDNUMBER ||
                        key === TextBoxEntity.CVV ||
                        key === TextBoxEntity.EXPIRATIONDATE ||
                        key === TextBoxEntity.CARDHOLDER_NAME) &&
                    formData[TextBoxEntity.PAYMENTMETHODID] !== ''
                ) {
                    return;
                } else if (
                    !changeBillingAddress &&
                    (key === TextBoxEntity.CITY ||
                        key === TextBoxEntity.COUNTRY ||
                        key === TextBoxEntity.POSTALCODE ||
                        key === TextBoxEntity.STATE)
                ) {
                    return;
                } else {
                    if (
                        key === TextBoxEntity.PAYMENTMETHODID &&
                        (formData[TextBoxEntity.CARDNUMBER] !== '' ||
                            formData[TextBoxEntity.CVV] !== '' ||
                            formData[TextBoxEntity.EXPIRATIONDATE] !== '' ||
                            formData[TextBoxEntity.CARDHOLDER_NAME] !== '')
                    ) {
                        return;
                    }
                    newError[key] = true;
                    valid = false;
                }
            }
        });

        setError(newError);

        if (valid) {
            // let dialCode = iti.getSelectedCountryData().dialCode;
            setProcessing(true);
            let phoneNumber = formData[TextBoxEntity.PHONENUMBER];
            const cardexpiry = formData[TextBoxEntity.EXPIRATIONDATE].split('/');
            const exp_month = cardexpiry[0];
            const exp_year = cardexpiry[1];
            const selectedPackage = props.selectedPackage;
            let selectedPricing = 'pro';
            if (selectedPackage === PricingPackage.PURE) {
                selectedPricing = 'pure';
            } else if (selectedPackage === PricingPackage.PLUS) {
                selectedPricing = 'plus';
            } else if (selectedPackage === PricingPackage.PRIME) {
                selectedPricing = 'prime';
            } else if (selectedPackage === PricingPackage.PRO) {
                selectedPricing = 'pro';
            }
            setProcessing(true);
            const paymentRequest = await handlePaymentRequest(
                {
                    targetLang: props.targetLangIds,
                    currency: props.currency,
                    wordCount: props.wordCount,
                    selectedPricing: selectedPricing,
                    tax: props.tax,
                    fullname: formData[TextBoxEntity.FULLNAME],
                    organisation: formData[TextBoxEntity.ORGANISATION],
                    email: formData[TextBoxEntity.EMAIL],
                    password: formData[TextBoxEntity.PASSWORD],
                    phone_number: formData[TextBoxEntity.PASSWORD],
                    bill_address: formData[TextBoxEntity.BILLADDRESS],
                    city: formData[TextBoxEntity.CITY],
                    state: formData[TextBoxEntity.STATE],
                    zip: formData[TextBoxEntity.POSTALCODE],
                    country: formData[TextBoxEntity.COUNTRY],
                    card_no: formData[TextBoxEntity.CARDNUMBER],
                    exp_month: exp_month,
                    exp_year: exp_year,
                    phoneNumber: phoneNumber,
                    cvv: formData[TextBoxEntity.CVV],
                    paymentMethodId: formData[TextBoxEntity.PAYMENTMETHODID],
                    savePaymentDetails: savePaymentDetails,
                    naatiCertified: props.naatiCertified,
                    naati_per_word: props.pricing.naati_per_word,
                    add_naati_price: props.pricing.add_naati_price,
                },
                `${props.user.signature}!${props.id}`,
            );
            if (!paymentRequest['success']) {
                const newError = { ...error };
                newError['request'] = paymentRequest['error'];
                setError(newError);
                setProcessing(false);
            } else {
                actions.setLoading(true);
                actions.setTimeLock(true);
                setError({
                    [TextBoxEntity.FULLNAME]: false,
                    [TextBoxEntity.ORGANISATION]: false,
                    [TextBoxEntity.EMAIL]: false,
                    [TextBoxEntity.PHONENUMBER]: false,
                    [TextBoxEntity.BILLADDRESS]: false,
                    [TextBoxEntity.CARDHOLDER_NAME]: false,
                    [TextBoxEntity.EXPIRATIONDATE]: false,
                    [TextBoxEntity.CARDNUMBER]: false,
                    [TextBoxEntity.CVV]: false,
                    [TextBoxEntity.CITY]: false,
                    [TextBoxEntity.STATE]: false,
                    [TextBoxEntity.COUNTRY]: false,
                    [TextBoxEntity.POSTALCODE]: false,
                    request: '',
                });
                for await (const language_id of props.selectedLanguages) {
                    await createTranslation(props.id, language_id);
                    await lockTranslation(props.id, language_id);
                }
                let translatedLanguage;
                if (props.user) {
                    translatedLanguage = await props.fetchTranslated(props.id, props.user.signature);
                }

                //
                //change language checkbox
                if (translatedLanguage) {
                    // props.setIsTranslateChange(true);
                    const selectedId = props.selectedLanguages[0];
                    props.setTranslationSingleSelected(selectedId);
                    if (translatedLanguage.filter((e) => e.language_id === selectedId).length > 0) {
                        props.setLanguageText(
                            translatedLanguage.filter((e) => e.language_id === selectedId)[0].language.language,
                        );
                    }
                    let url = new URL(window.location);
                    url.searchParams.set('t', true);
                    url.searchParams.set('tid', selectedId);
                    window.history.pushState(null, '', url.toString());
                    props.setCurrentLanguageObject(await fetchLexigoLanguage(selectedId));
                    // props.setIsTranslateChange(true);
                    unstable_batchedUpdates(() => {
                        props.setCurrentLanguage(selectedId);
                        props.setSelectedLanguages([]);
                        props.setIsTranslation(true);
                        props.setTranslateState(true);
                        props.setPlaying(false);
                        props.setOpenPaymentModal(false);
                        props.setShowPricingModal(false);
                        setProcessing(false);
                        props.setIsTranslateChange(true);
                    });
                    actions.setLoading(false);
                    return;
                }
                //it should be deleted
                unstable_batchedUpdates(() => {
                    props.setSelectedLanguages([]);
                    actions.setLoading(false);
                    props.setIsTranslation(true);
                    props.setTranslateState(true);
                    props.setPlaying(false);
                    props.setOpenPaymentModal(false);
                    props.setShowPricingModal(false);
                    setProcessing(false);
                });
                //
            }
        } else {
            if (
                (error[TextBoxEntity.CITY] ||
                    error[TextBoxEntity.STATE] ||
                    error[TextBoxEntity.POSTALCODE] ||
                    error[TextBoxEntity.COUNTRY]) &&
                !showAddress
            ) {
                findAddressClick();
            }
        }
    };

    const findAddressClick = () => {
        $('#showAddress').toggle('slow/400/fast');
        setShowAddress(!showAddress);
    };

    const getAdditionalUserData = useCallback(async (payload) => {
        setInitLoading(true);
        const lexigo_obj = JSON.parse(props.user.lexigo_object);
        const extData = await fetchAdditionalUserData(payload);
        let billaddress = '';
        let phone = '';
        let fullname = '';
        let emailaddress = '';
        let organisation = '';

        if (props.user.manualregister) {
            fullname = capitalize(props.user.name);
            emailaddress = props.user.email;
            organisation = props.user.organization;
        } else {
            fullname = capitalize(lexigo_obj.firstname) + ' ' + capitalize(lexigo_obj.lastname);
            emailaddress = lexigo_obj.emailaddress;
            organisation = lexigo_obj.organisation;
        }

        if (extData !== null) {
            if (extData.address.length > 0) {
                billaddress = extData.address[0];
            }
            if (extData.phone !== null) {
                if (extData.phone.countrycode && extData.phone.phonenumber) {
                    phone = extData.phone.countrycode + '' + extData.phone.phonenumber;
                }
            }
        }
        const methods = (await fetchPaymentMethods(`${props.user.signature}!${props.id}`))['data'];
        const data = {
            ...formData,
            [TextBoxEntity.FULLNAME]: fullname,
            [TextBoxEntity.BILLADDRESS]: billaddress,
            [TextBoxEntity.EMAIL]: emailaddress,
            [TextBoxEntity.ORGANISATION]: organisation,
            [TextBoxEntity.PHONENUMBER]: phone,
            [TextBoxEntity.PAYMENTMETHODID]: methods.length > 0 ? methods[0].id : '',
        };
        setAddresses(extData.address);
        setFormData(data);
        setPaymentMethods(methods);
    });

    // const initPaymentMethod = useCallback(async () => {
    //     const methods = (await fetchPaymentMethods(`${props.user.signature}!${props.id}`))['data'];
    //     setPaymentMethods(methods);
    //     handleOnchange(TextBoxEntity.PAYMENTMETHODID, methods[0].id);
    // }, [props.user, props.id]);

    // useEffect(() => {
    //     if (props.showPricingModal) {
    //         initPaymentMethod();
    //     }
    // }, [props.user, props.id, initPaymentMethod, props.showPricingModal]);

    useEffect(() => {
        if (props.showPricingModal && !initLoading) {
            getAdditionalUserData({
                user_id: props.user.id,
            });
        }
    }, [props.showPricingModal, props.user, initLoading]);

    useEffect(() => {
        if (paymentMethods.length > 0) {
            handleOnchange(TextBoxEntity.PAYMENTMETHODID, paymentMethods[0].id);
            setShowCreateCC(false); //ganti ini jadi false
        } else {
            setShowCreateCC(true);
        }
    }, [paymentMethods]);

    useEffect(() => {
        if (showCreateCC) {
            handleOnchange(TextBoxEntity.PAYMENTMETHODID, '');
        } else {
            if (paymentMethods.length > 0) {
                handleOnchange(TextBoxEntity.PAYMENTMETHODID, paymentMethods[0].id);
            }
        }
    }, [showCreateCC]);

    useEffect(() => {
        const getTax = () => {
            if (formData[TextBoxEntity.COUNTRY] === 'Australia') {
                props.setCurrency('aud');
                props.setTax((props.getSubtotal() * 0.1).toFixed(2));
            } else {
                props.setTax(0);
            }
        };
        getTax();
    }, [formData, props]);

    useEffect(() => {
        $('#findAdresss').on('click', function () {
            $('#showAddress').toggle('slow/400/fast');

            setTimeout(() => {
                $('input').each((x, elem) => {
                    if ($(elem).val()) {
                        $(elem).parents('.form-group-animation').addClass('focused');
                    }
                });
            }, 400);
        });
        const initCountry = async () => {
            setCountryLists(await fetchCountries());
        };
        initCountry();
    }, []);

    const onSuggestSelect = (e) => {
        if (e) {
            handleOnchange(TextBoxEntity.BILLADDRESS, e.label);
            const address_components = e.gmaps.address_components;
            const locality = address_components.find((x) => x.types.includes('locality'));
            let city = '';
            let state = '';
            let postalcode = '';
            let country = '';
            if (!locality) {
                const local_city = address_components.find((x) => x.types.includes('administrative_area_level_2'));
                city = local_city ? local_city.long_name : '';
                handleOnchange(TextBoxEntity.CITY, city.long_name);
            } else {
                city = locality ? locality.long_name : '';
            }

            country = address_components.find((x) => x.types.includes('country'));
            country = country ? country.long_name : '';
            state = address_components.find((x) => x.types.includes('administrative_area_level_1'));
            state = state ? state.long_name : '';

            postalcode = address_components.find((x) => x.types.includes('postal_code'));
            postalcode = postalcode ? postalcode.long_name : '';
            const data = {
                ...formData,
                [TextBoxEntity.CITY]: city,
                [TextBoxEntity.COUNTRY]: country,
                [TextBoxEntity.STATE]: state,
                [TextBoxEntity.POSTALCODE]: postalcode,
            };
            setFormData(data);
        }
    };

    return (
        <Modal
            open={props.openPaymentModal}
            onClose={() => {
                props.setOpenPaymentModal(false);
            }}
        >
            <div
                id="modal-payment"
                className="modal show modal-share"
                data-backdrop="static"
                tabIndex="-1"
                aria-hidden="true"
                style={{
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    zIndex: 10001,
                    overflow: 'auto',
                }}
            >
                <div className="modal-dialog modal-xl modal-xl-custom bg-f6">
                    <div className="modal-content h-full border-0 text-sm background-none box-shadow-none">
                        <a onClick={() => props.setOpenPaymentModal(false)} data-dismiss="modal" href="javascript:;">
                            {' '}
                            <X className="w-8 h-8 text-dark" />{' '}
                        </a>
                        <div className="modal-body pricing-proxi-container h-full overflow-auto pb-5">
                            <div className="grid grid-cols-12 gap-x-5 h-full ">
                                <div className="col-span-12 lg:col-span-8">
                                    <h2 className="text-2rem font-euclid-sb text-lexigo-1 mt-5">Payment</h2>
                                    <div className="grid grid-cols-12 gap-5 mt-12">
                                        <div className="col-span-12" id="paymentError">
                                            <div
                                                className={`badge-warning-lexigo ${
                                                    error['request'] === '' ? 'hidden' : ''
                                                }`}
                                            >
                                                <div className={`text-warning-lexgio `} id="paymentErrorText">
                                                    {error['request']}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12  lg:col-span-6 relative">
                                            <div
                                                className={`form-group-animation ${
                                                    onFocus === TextBoxEntity.FULLNAME ||
                                                    formData[TextBoxEntity.FULLNAME] !== ''
                                                        ? 'focused'
                                                        : ''
                                                }`}
                                            >
                                                <label for="" className="form-label-animation">
                                                    Full Name
                                                </label>
                                                <input
                                                    disabled
                                                    onFocus={() => handleOnInput(TextBoxEntity.FULLNAME)}
                                                    onBlur={() => handleOnInput('')}
                                                    onChange={(e) =>
                                                        handleOnchange(TextBoxEntity.FULLNAME, e.target.value)
                                                    }
                                                    value={formData[TextBoxEntity.FULLNAME]}
                                                    type="text"
                                                    name="fullname"
                                                    className={`form-input-animation form-control form-control-custom2 ${
                                                        error[TextBoxEntity.FULLNAME] ? 'parsley-error' : ''
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-12  lg:col-span-6 relative">
                                            <div
                                                className={`form-group-animation ${
                                                    onFocus === TextBoxEntity.ORGANISATION ||
                                                    formData[TextBoxEntity.ORGANISATION] !== ''
                                                        ? 'focused'
                                                        : ''
                                                }`}
                                            >
                                                <label for="" className="form-label-animation">
                                                    Organisation
                                                </label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    onFocus={() => handleOnInput(TextBoxEntity.ORGANISATION)}
                                                    value={formData[TextBoxEntity.ORGANISATION]}
                                                    onBlur={() => handleOnInput('')}
                                                    onChange={(e) =>
                                                        handleOnchange(TextBoxEntity.ORGANISATION, e.target.value)
                                                    }
                                                    name="organisation"
                                                    className={`form-input-animation form-control form-control-custom2 ${
                                                        error[TextBoxEntity.ORGANISATION] ? 'parsley-error' : ''
                                                    }`}
                                                    data-parsley-required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-12 relative">
                                            <div
                                                className={`form-group-animation ${
                                                    onFocus === TextBoxEntity.EMAIL ||
                                                    formData[TextBoxEntity.EMAIL] !== ''
                                                        ? 'focused'
                                                        : ''
                                                }`}
                                            >
                                                <label for="" className="form-label-animation">
                                                    Email
                                                </label>
                                                <input
                                                    disabled
                                                    type="email"
                                                    onFocus={() => handleOnInput(TextBoxEntity.EMAIL)}
                                                    onChange={(e) =>
                                                        handleOnchange(TextBoxEntity.EMAIL, e.target.value)
                                                    }
                                                    value={formData[TextBoxEntity.EMAIL]}
                                                    onBlur={() => handleOnInput('')}
                                                    name="email"
                                                    className={`form-input-animation form-control form-control-custom2 ${
                                                        error[TextBoxEntity.EMAIL] ? 'parsley-error' : ''
                                                    }`}
                                                    data-parsley-required=""
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-12 lg:col-span-6 relative">
                                            <div
                                                className={`form-group-animation ${
                                                    onFocus === TextBoxEntity.PHONENUMBER ||
                                                    formData[TextBoxEntity.PHONENUMBER] !== ''
                                                        ? 'focused'
                                                        : ''
                                                }`}
                                            >
                                                <label for="" className="form-label-animation">
                                                    Phone Number
                                                </label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    onFocus={() => handleOnInput(TextBoxEntity.PHONENUMBER)}
                                                    onChange={(e) =>
                                                        handleOnchange(TextBoxEntity.PHONENUMBER, e.target.value)
                                                    }
                                                    value={formData[TextBoxEntity.PHONENUMBER]}
                                                    onBlur={() => handleOnInput('')}
                                                    name="phone"
                                                    className={`form-input-animation form-control form-control-custom2 ${
                                                        error[TextBoxEntity.PHONENUMBER] ? 'parsley-error' : ''
                                                    }`}
                                                    data-parsley-required=""
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-12 lg:col-span-6 relative">
                                            <div
                                                className={`form-group-animation ${
                                                    onFocus === TextBoxEntity.BILLADDRESS ||
                                                    formData[TextBoxEntity.BILLADDRESS] !== ''
                                                        ? 'focused'
                                                        : ''
                                                }`}
                                                style={{
                                                    position: 'relative',
                                                }}
                                            >
                                                <label for="" className="form-label-animation">
                                                    Billing Address
                                                </label>
                                                {changeBillingAddress || addresses.length <= 0 ? (
                                                    <Geosuggest
                                                        type="text"
                                                        id="detAddress"
                                                        data-geocomplete="name"
                                                        name="address"
                                                        className={`form-input-animation form-control ${
                                                            error[TextBoxEntity.BILLADDRESS] ? 'parsley-error' : ''
                                                        }`}
                                                        style={{
                                                            backgroundColor: '#ffffff',
                                                        }}
                                                        inputClassName={`pac-target-input `}
                                                        data-parsley-required=""
                                                        onFocus={() => handleOnInput(TextBoxEntity.BILLADDRESS)}
                                                        value={formData[TextBoxEntity.BILLADDRESS]}
                                                        onChange={(e) => handleOnchange(TextBoxEntity.BILLADDRESS, e)}
                                                        onSuggestSelect={onSuggestSelect}
                                                        placeholder=""
                                                        onBlur={() => handleOnInput('')}
                                                        autoComplete="off"
                                                    />
                                                ) : (
                                                    <div className="form-group-animation">
                                                        <label for="" className="form-label-animation">
                                                            Billing Address
                                                        </label>
                                                        <select
                                                            name="billingaddress"
                                                            className={`form-input-animation form-control select-light ${
                                                                error[TextBoxEntity.BILLADDRESS] ? 'parsley-error' : ''
                                                            }`}
                                                            value={formData[TextBoxEntity.BILLADDRESS]}
                                                            onChange={(e) =>
                                                                handleOnchange(
                                                                    TextBoxEntity.BILLADDRESS,
                                                                    e.target.value,
                                                                )
                                                            }
                                                        >
                                                            {addresses &&
                                                                addresses.map((address, i) => {
                                                                    return (
                                                                        <option key={i} value={i}>
                                                                            {address.text}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </select>
                                                    </div>
                                                )}

                                                <div
                                                    className="pac-container pac-logo"
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                    id="pac-container_detAddress"
                                                ></div>
                                            </div>

                                            <div className="text-right mt-2">
                                                {changeBillingAddress || addresses.length <= 0 ? (
                                                    <a
                                                        id="findAdresss"
                                                        onClick={findAddressClick}
                                                        href="javascript:;"
                                                        className="underline-hover"
                                                    >
                                                        Can't find your address?
                                                    </a>
                                                ) : (
                                                    <a
                                                        id="findAdresss"
                                                        onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                [TextBoxEntity.BILLADDRESS]: '',
                                                            });
                                                            setChangeBillingAddress(true);
                                                        }}
                                                        href="javascript:;"
                                                        className="underline-hover"
                                                    >
                                                        Change billing address
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        <div id="showAddress" className={`col-span-12 ${showAddress ? '' : 'hidden'}`}>
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-12 lg:col-span-6 relative">
                                                    <div
                                                        className={`form-group-animation ${
                                                            onFocus === TextBoxEntity.CITY ||
                                                            formData[TextBoxEntity.CITY] !== ''
                                                                ? 'focused'
                                                                : ''
                                                        }`}
                                                    >
                                                        <label for="" className="form-label-animation">
                                                            City
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="city"
                                                            name="city"
                                                            className={`form-input-animation form-control ${
                                                                error[TextBoxEntity.CITY] ? 'parsley-error' : ''
                                                            }`}
                                                            onFocus={() => handleOnInput(TextBoxEntity.CITY)}
                                                            value={formData[TextBoxEntity.CITY]}
                                                            onChange={(e) =>
                                                                handleOnchange(TextBoxEntity.CITY, e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8"
                                                        height="8"
                                                        color="red"
                                                        fill="currentColor"
                                                        className="bi bi-asterisk icon-absolute"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
                                                    </svg>
                                                </div>
                                                <div className="col-span-12 lg:col-span-6 relative">
                                                    <div
                                                        className={`form-group-animation ${
                                                            onFocus === TextBoxEntity.STATE ||
                                                            formData[TextBoxEntity.STATE] !== ''
                                                                ? 'focused'
                                                                : ''
                                                        }`}
                                                    >
                                                        <label for="" className="form-label-animation">
                                                            State
                                                        </label>
                                                        <input
                                                            onChange={(e) =>
                                                                handleOnchange(TextBoxEntity.STATE, e.target.value)
                                                            }
                                                            onFocus={() => handleOnInput(TextBoxEntity.STATE)}
                                                            value={formData[TextBoxEntity.STATE]}
                                                            type="text"
                                                            id="state"
                                                            name="state"
                                                            className={`form-input-animation form-control ${
                                                                error[TextBoxEntity.STATE] ? 'parsley-error' : ''
                                                            }`}
                                                            data-geocomplete="administrative_area_level_1"
                                                        />
                                                    </div>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8"
                                                        height="8"
                                                        color="red"
                                                        fill="currentColor"
                                                        className="bi bi-asterisk icon-absolute"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
                                                    </svg>
                                                </div>
                                                <div className="col-span-12 lg:col-span-6 relative">
                                                    <div
                                                        className={`form-group-animation ${
                                                            onFocus === TextBoxEntity.POSTALCODE ||
                                                            formData[TextBoxEntity.POSTALCODE] !== ''
                                                                ? 'focused'
                                                                : ''
                                                        }`}
                                                    >
                                                        <label for="" className="form-label-animation">
                                                            Postal Code
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="zip"
                                                            name="zip"
                                                            className={`form-input-animation form-control ${
                                                                error[TextBoxEntity.POSTALCODE] ? 'parsley-error' : ''
                                                            }`}
                                                            data-geocomplete="postal_code"
                                                            onFocus={() => handleOnInput(TextBoxEntity.POSTALCODE)}
                                                            value={formData[TextBoxEntity.POSTALCODE]}
                                                            onChange={(e) =>
                                                                handleOnchange(TextBoxEntity.POSTALCODE, e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8"
                                                        height="8"
                                                        color="red"
                                                        fill="currentColor"
                                                        className="bi bi-asterisk icon-absolute"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
                                                    </svg>
                                                </div>
                                                <div className="col-span-12 lg:col-span-6 relative">
                                                    <div className="form-group-animation focused">
                                                        <label for="" className="form-label-animation">
                                                            Country
                                                        </label>
                                                        <select
                                                            name="country"
                                                            className={`form-input-animation form-control ${
                                                                error[TextBoxEntity.COUNTRY] ? 'parsley-error' : ''
                                                            }`}
                                                            value={formData[TextBoxEntity.COUNTRY]}
                                                            onChange={(e) =>
                                                                handleOnchange(TextBoxEntity.COUNTRY, e.target.value)
                                                            }
                                                        >
                                                            <option>Select Country</option>
                                                            {countryLists.map((country) => {
                                                                return (
                                                                    <option
                                                                        value={country.description}
                                                                        key={country.id}
                                                                    >
                                                                        {country.description}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="8"
                                                            height="8"
                                                            color="red"
                                                            fill="currentColor"
                                                            className="bi bi-asterisk icon-absolute"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="font-bold text-xl">Payment</div>
                                        </div>
                                        {!showCreateCC ? (
                                            <div className="col-span-12">
                                                <ul className="price-list">
                                                    {paymentMethods &&
                                                        paymentMethods.map((paymentMethod, i) => {
                                                            return (
                                                                <li id={`expressCheck-${i}`} key={paymentMethod.id}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={i}
                                                                        name="priority"
                                                                        checked={
                                                                            paymentMethod.id ===
                                                                            formData[TextBoxEntity.PAYMENTMETHODID]
                                                                        }
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                handleOnchange(
                                                                                    TextBoxEntity.PAYMENTMETHODID,
                                                                                    paymentMethod.id,
                                                                                );
                                                                            } else {
                                                                                handleOnchange(
                                                                                    TextBoxEntity.PAYMENTMETHODID,
                                                                                    '',
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    <label
                                                                        for={i}
                                                                        style={{
                                                                            border: '1px solid #17bdb7',
                                                                        }}
                                                                    >
                                                                        **** **** **** {paymentMethod.card.last4}
                                                                    </label>
                                                                    {paymentMethod.id ===
                                                                    formData[TextBoxEntity.PAYMENTMETHODID] ? (
                                                                        <Check
                                                                            className="w-5 h-5 check-express"
                                                                            style={{
                                                                                color: '#ffffff',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </li>
                                                            );
                                                        })}
                                                    <li>
                                                        <label
                                                            style={{
                                                                border: '1px solid #17bdb7',
                                                            }}
                                                            onClick={() => {
                                                                setShowCreateCC(!showCreateCC);
                                                            }}
                                                        >
                                                            Create new card
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="col-span-12 lg:col-span-6 relative">
                                                    <div
                                                        className={`form-group-animation ${
                                                            onFocus === TextBoxEntity.CARDHOLDER_NAME ||
                                                            formData[TextBoxEntity.CARDHOLDER_NAME] !== ''
                                                                ? 'focused'
                                                                : ''
                                                        }`}
                                                    >
                                                        <label for="" className="form-label-animation">
                                                            Cardholder Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="ccname"
                                                            name="ccname"
                                                            className={`form-input-animation form-control ${
                                                                error[TextBoxEntity.CARDHOLDER_NAME]
                                                                    ? 'parsley-error'
                                                                    : ''
                                                            }`}
                                                            data-parsley-required="true"
                                                            onFocus={() => handleOnInput(TextBoxEntity.CARDHOLDER_NAME)}
                                                            value={formData[TextBoxEntity.CARDHOLDER_NAME]}
                                                            onChange={(e) =>
                                                                handleOnchange(
                                                                    TextBoxEntity.CARDHOLDER_NAME,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            onBlur={() => handleOnInput('')}
                                                        />
                                                    </div>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8"
                                                        height="8"
                                                        color="red"
                                                        fill="currentColor"
                                                        className="bi bi-asterisk icon-absolute"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
                                                    </svg>
                                                </div>
                                                <div className="col-span-12 lg:col-span-6 relative">
                                                    <div
                                                        className={`form-group-animation ${
                                                            onFocus === TextBoxEntity.CARDNUMBER ||
                                                            formData[TextBoxEntity.CARDNUMBER] !== ''
                                                                ? 'focused'
                                                                : ''
                                                        }`}
                                                    >
                                                        <label for="" className="form-label-animation">
                                                            Card Number
                                                        </label>
                                                        <Cleave
                                                            options={{
                                                                creditCard: true,
                                                            }}
                                                            id="ccnumber"
                                                            name="ccnumber"
                                                            className={`form-input-animation form-control ${
                                                                error[TextBoxEntity.CARDNUMBER] ? 'parsley-error' : ''
                                                            }`}
                                                            data-parsley-required="true"
                                                            onFocus={() => handleOnInput(TextBoxEntity.CARDNUMBER)}
                                                            value={formData[TextBoxEntity.CARDNUMBER]}
                                                            onChange={(e) =>
                                                                handleOnchange(TextBoxEntity.CARDNUMBER, e.target.value)
                                                            }
                                                            onBlur={() => handleOnInput('')}
                                                        />
                                                    </div>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8"
                                                        height="8"
                                                        color="red"
                                                        fill="currentColor"
                                                        className="bi bi-asterisk icon-absolute"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
                                                    </svg>
                                                </div>
                                                <div className="col-span-12 lg:col-span-6 relative">
                                                    <div
                                                        className={`form-group-animation ${
                                                            onFocus === TextBoxEntity.EXPIRATIONDATE ||
                                                            formData[TextBoxEntity.EXPIRATIONDATE] !== ''
                                                                ? 'focused'
                                                                : ''
                                                        }`}
                                                    >
                                                        <label for="" className="form-label-animation">
                                                            Expiration Date (MM/YY)
                                                        </label>
                                                        <Cleave
                                                            options={{
                                                                blocks: [2, 2],
                                                                delimiter: '/',
                                                            }}
                                                            id="ccexpiry"
                                                            name="ccexpiry"
                                                            className={`form-input-animation form-control ${
                                                                error[TextBoxEntity.EXPIRATIONDATE]
                                                                    ? 'parsley-error'
                                                                    : ''
                                                            }`}
                                                            data-parsley-required="true"
                                                            onFocus={() => handleOnInput(TextBoxEntity.EXPIRATIONDATE)}
                                                            value={formData[TextBoxEntity.EXPIRATIONDATE]}
                                                            onChange={(e) =>
                                                                handleOnchange(
                                                                    TextBoxEntity.EXPIRATIONDATE,
                                                                    e.target.value,
                                                                )
                                                            }
                                                            onBlur={() => handleOnInput('')}
                                                        />
                                                    </div>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8"
                                                        height="8"
                                                        color="red"
                                                        fill="currentColor"
                                                        className="bi bi-asterisk icon-absolute"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
                                                    </svg>
                                                </div>
                                                <div className="col-span-12 lg:col-span-6 relative">
                                                    <div
                                                        className={`form-group-animation ${
                                                            onFocus === TextBoxEntity.CVV ||
                                                            formData[TextBoxEntity.CVV] !== ''
                                                                ? 'focused'
                                                                : ''
                                                        }`}
                                                    >
                                                        <label for="" className="form-label-animation">
                                                            CVV
                                                        </label>
                                                        <Cleave
                                                            options={{
                                                                blocks: [3],
                                                                delimiter: '',
                                                            }}
                                                            id="cccvv"
                                                            name="cccvv"
                                                            className={`form-input-animation form-control ${
                                                                error[TextBoxEntity.CVV] ? 'parsley-error' : ''
                                                            }`}
                                                            data-parsley-required="true"
                                                            onFocus={() => handleOnInput(TextBoxEntity.CVV)}
                                                            value={formData[TextBoxEntity.CVV]}
                                                            onChange={(e) =>
                                                                handleOnchange(TextBoxEntity.CVV, e.target.value)
                                                            }
                                                            onBlur={() => handleOnInput('')}
                                                        />
                                                    </div>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8"
                                                        height="8"
                                                        color="red"
                                                        fill="currentColor"
                                                        className="bi bi-asterisk icon-absolute"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
                                                    </svg>
                                                </div>
                                                <div className="col-span-12 relative">
                                                    <div className="form-check mr-3">
                                                        <input
                                                            id="checkbox-save-details"
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={savePaymentDetails}
                                                            onChange={() => setSavePaymentDetails(!savePaymentDetails)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="checkbox-save-details"
                                                            style={{
                                                                color: '#2d3748',
                                                            }}
                                                        >
                                                            Save payment details for future use
                                                        </label>
                                                        <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {showCreateCC && paymentMethods.length > 0 ? (
                                            <div className="col-span-12 relative">
                                                <div className="text-right mt-2">
                                                    <a
                                                        id="createCCbtn"
                                                        onClick={() => {
                                                            setShowCreateCC(!showCreateCC);
                                                        }}
                                                        href="javascript:;"
                                                        className="underline-hover"
                                                    >
                                                        Select existing card
                                                    </a>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        <div className="col-span-12 flex-btn-responsive mb-5">
                                            <button
                                                id="back4"
                                                type="button"
                                                className="btn btn-white btn-outline font-bold px-10 py-3"
                                                onClick={() => {
                                                    props.setOpenPaymentModal(false);
                                                    props.setShowPricingModal(true);
                                                }}
                                            >
                                                Back
                                            </button>
                                            <a
                                                href="javascript:;"
                                                // data-toggle="modal"
                                                // data-target="#thankyouPage"
                                                // data-dismiss="modal"
                                                disabled={processing ? true : false}
                                                onClick={handleOnSubmit}
                                                type="button"
                                                className="btn btn-lexigo font-bold text-white px-10 py-3"
                                            >
                                                {processing ? 'Processing...' : `Pay $${props.getTotal()}`}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12 lg:col-span-4 mt-0 lg:mt-5 pt-0 lg:pt-5">
                                    <OrderSummary {...props} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Payment;
