import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

const SurferCursor = ({}) => {
    const $wavesurfer = useRef();
    let [wavesurferCursor, setWavesurferCursor] = useState(null);
    const [play, setPlay] = useState(false);
    useEffect(() => {
        try {
            const wavesurferCursor = WaveSurfer.create({
                container: document.body,
                waveColor: 'rgb(200, 0, 200)',
                progressColor: 'rgb(100, 0, 100)',
                url: 'https://dl.dropboxusercontent.com/s/lzohlc9nduckzy7/64213865438d5.mp4?raw=1',
                minPxPerSec: 100,
            });

            setWavesurferCursor(wavesurferCursor);
            return () => {
                wavesurferCursor.destroy();
            };
        } catch (e) {}
    }, []);

    useEffect(() => {
        if (!wavesurferCursor) return;
        if (play) {
            wavesurferCursor.play();
        } else {
            wavesurferCursor.pause();
        }
    }, [wavesurferCursor, play]);
    return (
        <>
            <div
                style={{ backgroundColor: 'black' }}
                className="wavesurfer wave-cursor"
                id="wave-cursor"
                ref={$wavesurfer}
            />
            <button
                onClick={() => {
                    setPlay(!play);
                }}
            >
                Play
            </button>
        </>
    );
};

export default function Test(props) {
    return <SurferCursor {...props} />;
}
