import { createTheme, ThemeProvider } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { ChevronLeft, MoreHorizontal } from 'react-feather';
import { useAppState } from '../../overmind';
import { NestedMenuItem } from '../MuiNestedMenu/components/NestedMenuItem';
export default function FormatMenu(props) {
    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        minWidth: 0,
                        padding: 'inherit',
                        color: 'inherit',
                        borderRadius: 'inherit',
                        fontFamily: 'inherit',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontFamily: 'GorditaRegular',
                        fontSize: '0.8rem',
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: 'GorditaRegular',
                        fontSize: '0.8rem',
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#2B2C39',
                        color: '#fff',
                    },
                },
            },
        },
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const state = useAppState();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTop = () => {
        const newText = props.s.text.replace(/{\\an\d}/, '');
        if (props.config.align === 'left') {
            props.updateText(props.s, '{\\an7}' + newText);
        } else if (props.config.align === 'center') {
            props.updateText(props.s, '{\\an8}' + newText);
        } else if (props.config.align === 'right') {
            props.updateText(props.s, '{\\an9}' + newText);
        }
        handleClose();
    };

    const handleCenter = () => {
        const newText = props.s.text.replace(/{\\an\d}/, '');
        if (props.config.align === 'left') {
            props.updateText(props.s, '{\\an4}' + newText);
        } else if (props.config.align === 'center') {
            props.updateText(props.s, '{\\an5}' + newText);
        } else if (props.config.align === 'right') {
            props.updateText(props.s, '{\\an6}' + newText);
        }
        handleClose();
    };

    const handleBottom = () => {
        const newText = props.s.text.replace(/{\\an\d}/, '');
        if (props.config.align === 'left') {
            props.updateText(props.s, '{\\an1}' + newText);
        } else if (props.config.align === 'center') {
            props.updateText(props.s, '{\\an2}' + newText);
        } else if (props.config.align === 'right') {
            props.updateText(props.s, '{\\an3}' + newText);
        }
        handleClose();
    };

    return (
        <ThemeProvider theme={theme}>
            <span
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreHorizontal className="w-4 h-4 ml-auto" />
            </span>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 22,
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {state.voiceOverAudio === null ? (
                    <MenuItem
                        className="right-menu-item bg-box-content-dark"
                        onClick={() => {
                            if (props.formatId.includes(props.i)) {
                                const index = props.formatId.indexOf(props.i);
                                const newFormatId = [...props.formatId];
                                newFormatId.splice(index, 1);
                                props.setFormatId(newFormatId);
                            } else {
                                props.setFormatId([...props.formatId, props.i]);
                            }
                            handleClose();
                        }}
                    >
                        Format
                    </MenuItem>
                ) : (
                    ''
                )}

                <NestedMenuItem
                    label="Position"
                    parentMenuOpen={open}
                    transformOrigin={{
                        vertical: 0,
                        horizontal: 'right',
                    }}
                    leftIcon={
                        <ChevronLeft
                            className="w-4 h-4"
                            style={{
                                fontSize: '17px!important',
                                marginTop: '1.25px',
                            }}
                        />
                    }
                >
                    <MenuItem
                        selected={
                            /^{\\an[7|8|9]}/.test(props.s.text)
                                ? true
                                : props.config.position == 'Top' &&
                                  !/^{\\an[4|5|6]}/.test(props.s.text) &&
                                  !/^{\\an[1|2|3]}/.test(props.s.text)
                                ? true
                                : false
                        }
                        onClick={handleTop}
                    >
                        Top
                    </MenuItem>
                    <MenuItem
                        selected={
                            /^{\\an[4|5|6]}/.test(props.s.text)
                                ? true
                                : props.config.position == 'Center' &&
                                  !/^{\\an[7|8|9]}/.test(props.s.text) &&
                                  !/^{\\an[1|2|3]}/.test(props.s.text)
                                ? true
                                : false
                        }
                        onClick={handleCenter}
                    >
                        Center
                    </MenuItem>
                    <MenuItem
                        selected={
                            /^{\\an[1|2|3]}/.test(props.s.text)
                                ? true
                                : props.config.position == 'Bottom' &&
                                  !/^{\\an[4|5|6]}/.test(props.s.text) &&
                                  !/^{\\an[7|8|9]}/.test(props.s.text)
                                ? true
                                : false
                        }
                        onClick={handleBottom}
                    >
                        Bottom
                    </MenuItem>
                </NestedMenuItem>
            </Menu>
        </ThemeProvider>
    );
}
