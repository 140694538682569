/* eslint-disable no-lone-blocks */
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppState } from '../overmind';
import { getLogs } from '../requests/TranslateRequest';
import { extractContent } from '../utils';

/* eslint-disable jsx-a11y/anchor-is-valid */
const TranslationChangeLogTab = (props) => {
    const [segmentLogs, setSegmentLogs] = useState(null);
    const [logsLoading, setLogsLoading] = useState(false);
    const state = useAppState();

    const formatLog = (details) => {
        const result = {};
        details.forEach((data) => {
            const createdAt = moment(moment.utc(data.created_at).local().format());
            const key = `${createdAt.format('D MMMM YYYY')}`;
            data.time = `${createdAt.format('hh:mm A')}`;
            if (result[key]) {
                result[key] = [...result[key], data];
            } else {
                result[key] = [data];
            }
        });
        return result;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchSegmentLog = async (workbenchDetailId) => {
        const data = await getLogs(workbenchDetailId);
        setSegmentLogs(formatLog(data.data));
    };

    const getInitials = (string) => {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(() => {
        if (state.workbenchDetailId != null) {
            setLogsLoading(true);
            fetchSegmentLog(state.workbenchDetailId);
            setTimeout(() => {
                setLogsLoading(false);
            }, 500);
        }
    }, [state.workbenchDetailId]);

    return (
        <div
            id="history"
            style={{ maxHeight: '50vh' }}
            className="tab-pane"
            role="tabpanel"
            aria-labelledby="history-tab"
        >
            <div className="min-h-sidetab-history">
                {logsLoading ? (
                    <div className="flex flex-col gap-4 py-2">
                        <div>
                            <Skeleton height={40} width="100%" style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                            <Skeleton height={20} width="40%" style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                        </div>
                        <div>
                            <Skeleton height={40} width="100%" style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                            <Skeleton height={20} width="40%" style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                        </div>
                        <div>
                            <Skeleton height={40} width="100%" style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                            <Skeleton height={20} width="40%" style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                        </div>
                    </div>
                ) : segmentLogs ? (
                    Object.keys(segmentLogs).length > 0 ? (
                        Object.keys(segmentLogs).map((key) => {
                            return segmentLogs[key].map((data) => {
                                return (
                                    <>
                                        {' '}
                                        <div id="history-box " className="p-4 bg-f6 rounded-3px history-box-active">
                                            {extractContent(data.after)}
                                        </div>
                                        <div className="flex items-center mt-1 mb-3">
                                            {props.access && props.access.accessMapping.role.toLowerCase() === 'owner'
                                                ? data.user.type === 'internal'
                                                    ? data.user.name + ', '
                                                    : data.user.email + ', '
                                                : ''}
                                            {key}, {data.time}
                                        </div>
                                    </>
                                );
                            });
                        })
                    ) : (
                        <div id="history-box " className="p-4 bg-f6 rounded-3px history-box-active text-xs">
                            No Data Available
                        </div>
                    )
                ) : (
                    <div id="history-box " className="p-4 bg-f6 rounded-3px history-box-active text-xs">
                        Please select any segment to view the ones who modify the translation on this segment
                    </div>
                )}
            </div>
        </div>
    );
};

export default TranslationChangeLogTab;
