import ThreeDotsLoader from '../ThreeDotsLoader';

const FullScreenLoading = () => {
    return (
        <div
            className="col-span-12 flex flex-col justify-end items-center h-full"
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                marginTop: 'auto',
                overflow: 'hidden',
                marginBottom: 'auto',
                zIndex: 999999,
            }}
        >
            <div
                className="col-span-12 flex flex-col justify-end items-center h-full"
                style={{ justifyContent: 'center', marginLeft: '3rem' }}
            >
                <i className="w-32 h-32">
                    <ThreeDotsLoader />
                </i>
            </div>
        </div>
    );
};
export default FullScreenLoading;
