/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@mui/material';
import Tags from '@yaireo/tagify/dist/react.tagify';
import '@yaireo/tagify/dist/tagify.css'; // Tagify CSS
import 'font-awesome/css/font-awesome.min.css';
import { useEffect, useRef, useState } from 'react';
import { X } from 'react-feather';
import {
    createAccessMapping,
    deleteAccessDetail,
    deleteUserAccess,
    getUserByRole,
    updateUserAccess,
} from '../requests/AccessRequest';
import UpdateShareModal from './UpdateShareModal';

export const colorsClasses = ['bg-lexigo-green', 'bg-lexigo-purple', 'bg-lexigo-blue', 'bg-lexigo-yellow'];

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
const ShareModal = ({
    openShareModal,
    setOpenShareModal,
    id,
    user,
    access,
    translatedLanguage,
    projectData,
    t,
    tid,
    ...props
}) => {
    const [emailTo, setEmailTo] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [role, setRole] = useState('editor');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [all, setAll] = useState([]);
    const [editor, setEditor] = useState([]);
    const [commentor, setCommentor] = useState([]);
    const [viewer, setViewer] = useState([]);
    const [isUserFetched, setIsUserFetched] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [languageValid, setLanguageValid] = useState(true);
    const tagRef = useRef();

    const onEmailChange = (e) => {
        setEmailTo(e.detail.tagify.value);
    };

    const validateEmail = () => {
        if (emailTo.length <= 0) {
            const tag = tagRef.current; // corresponding DOM node
            tag.className =
                'w-full block mt-1 px-3 py-2 text-xs rounded border focus:border-blue-400 focus:outline-none parsley-error';
            setEmailValid(false);
            return false;
        }
        setEmailValid(true);
        return true;
    };

    const validateLanguage = () => {
        if (selectedLanguages.length <= 0) {
            setLanguageValid(false);
            return false;
        }
        setLanguageValid(true);
        return true;
    };

    const validateForm = () => {
        const emailValidated = validateEmail();
        const languageValidated = validateLanguage();
        return emailValidated && languageValidated;
    };

    const resetForm = () => {
        setEmailTo([]);
        setSelectedLanguages([]);
        setRole('editor');
        setMessage('');
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            await createAccessMapping(emailTo, role, selectedLanguages, message, id, user.signature, t, tid);
            await fetchUser();
            setOpenShareModal(false);
            resetForm();
            setLoading(false);
        }
    };

    const addSelectedLanguage = async (id) => {
        let language = languages.filter((x) => x.language.id == id);
        if (id != -1 && language.length <= 0) return;
        const newSelectedLanguages = [...selectedLanguages];
        let index = newSelectedLanguages.findIndex((x) => x.id == id);
        if (id > 0) {
            if (index >= 0) {
                return;
            } else {
                newSelectedLanguages.push(language[0].language);
            }
        } else if (id == -1) {
            index = newSelectedLanguages.findIndex((x) => x.id == -1);
            if (index >= 0) {
                return;
            } else {
                let lang = projectData.file.language;
                lang.id = -1;
                newSelectedLanguages.push(lang);
            }
        }
        setSelectedLanguages(newSelectedLanguages);
    };

    const removeSelectedLanguage = (language) => {
        const newSelectedLanguages = [...selectedLanguages];

        const index = newSelectedLanguages.findIndex((x) => x.id == language.id);
        if (index > -1) {
            newSelectedLanguages.splice(index, 1); // 2nd parameter means remove one item only
        }
        setSelectedLanguages(newSelectedLanguages);
    };

    useEffect(async () => {
        if (translatedLanguage != null) {
            setLanguages(translatedLanguage);
        }
    }, [translatedLanguage]);

    const fetchUser = async () => {
        setIsUserFetched(false);
        setAll(await getUserByRole(id, 'All'));
        setEditor(await getUserByRole(id, 'Editor'));
        setCommentor(await getUserByRole(id, 'Commentor'));
        setViewer(await getUserByRole(id, 'Viewer'));
        setIsUserFetched(true);
    };

    useEffect(async () => {
        if (openShareModal && (id !== undefined || id !== null)) {
            await fetchUser();
        }
    }, [id, openShareModal]);

    const deleteUser = async (userId, subtitleId) => {
        setIsUserFetched(false);
        await deleteUserAccess(userId, subtitleId);
        await fetchUser();
    };

    const updateUser = async (userId, subtitleId, role) => {
        setIsUserFetched(false);
        await updateUserAccess(userId, subtitleId, role);
        await fetchUser();
    };

    const removeLanguage = async (detailId) => {
        setIsUserFetched(false);
        await deleteAccessDetail(detailId);
        await fetchUser();
    };

    const renderUserList = (data, key) => {
        return (
            <div className="px-0 py-2 flex items-center">
                <div
                    className={`rounded-full text-white font-regular w-10 h-10 flex items-center justify-center ${
                        colorsClasses[key % 4]
                    }`}
                >
                    {data.user.email.at(0)}
                </div>
                <div className="ml-4 mr-auto" style={{ flex: 1 }}>
                    <div className="font-medium" style={{ textAlign: 'left' }}>
                        {capitalizeFirstLetter(data.user.email)}
                    </div>
                    <div id="text-role-6" style={{ textAlign: 'left' }} className="text-gray-600 text-xs mt-0.5">
                        {capitalizeFirstLetter(data.role)}
                    </div>

                    <div className="flex items-center mt-3 flex-wrap relative max-width-tar-lang" id="targetlangform">
                        {data.details.map((detail) => {
                            return (
                                <button
                                    key={detail.id}
                                    style={{
                                        fontSize: '10px',
                                        padding: '0.25rem 0.5rem',
                                    }}
                                    type="button"
                                    className="shrink bg-control-dark p-2 flex items-center rounded-3px line-height-1 mr-2 mb-2 text-xs"
                                >
                                    {detail.language ? detail.language.code : projectData.file.language.language_code}
                                    <X className="w-3 h-3 ml-2" onClick={() => removeLanguage(detail.id)} />
                                </button>
                            );
                        })}
                    </div>
                </div>
                <UpdateShareModal data={data} updateUser={updateUser} deleteUser={deleteUser} />
            </div>
        );
    };

    useEffect(() => {
        if (selectedLanguages.length == 1 && selectedLanguages.filter((x) => x.id == -1)) {
            if (role == 'commentor') {
                setRole('editor');
            }
        }
    }, [selectedLanguages]);

    return (
        <Modal
            open={openShareModal}
            onClose={() => {
                setOpenShareModal(false);
            }}
        >
            <div
                id="modal-share"
                className={`modal modal-share ${openShareModal ? 'show' : ''}`}
                style={{
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    zIndex: 10000,
                }}
                data-backdrop="static"
                tabIndex="-1"
                aria-hidden="true"
                role="dialog"
            >
                <div className="modal-dialog h-100vh background-none" style={{ width: '579px' }}>
                    <div className="modal-content h-full">
                        <div className="modal-header flex items-center justify-between">
                            <p className="modal-title font-bold">Invite</p>
                            <a data-dismiss="modal" href="javascript:;" onClick={() => setOpenShareModal(false)}>
                                <X className="w-5 h-5" />
                            </a>
                        </div>
                        {access && access.userPackage.invite_collaborators ? (
                            <div className="modal-body h-100vh">
                                <div className="mb-4 flex">
                                    <div className="w-3/4 pr-1 mobile-share-width-email">
                                        <label for="email-to">Email</label>
                                        <div
                                            className={`w-full block mt-1 text-xs rounded border border-input ${
                                                emailValid ? '' : 'parsley-error'
                                            }`}
                                        >
                                            <Tags
                                                settings={{
                                                    pattern:
                                                        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                                }}
                                                className="custom-tagify"
                                                style={{
                                                    border: 'none !important',
                                                    width: '100%',
                                                }}
                                                tagifyRef={tagRef}
                                                onChange={(e) => onEmailChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-1/4 pl-1 mobile-share-width-editor">
                                        <label for="role">Role</label>
                                        <select
                                            onChange={(e) => {
                                                setRole(e.target.value);
                                            }}
                                            name="role"
                                            id="role-select"
                                            className={`form-select w-full block mt-1 px-3 py-2 text-xs rounded border border-input`}
                                        >
                                            <option value="editor">Editor</option>
                                            {selectedLanguages.length > 1 ||
                                            selectedLanguages.filter((x) => x.id != -1).length > 0 ? (
                                                <option value="commentor">Commentor</option>
                                            ) : (
                                                ''
                                            )}

                                            <option value="viewer">Viewer</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label for="">Select Language</label>
                                    <select
                                        onChange={(e) => {
                                            addSelectedLanguage(e.target.value);
                                        }}
                                        value={0}
                                        name=""
                                        id="selectLangInvited"
                                        className={`form-control mt-1  ${!languageValid ? 'parsley-error' : ''}`}
                                    >
                                        <option value="0">Select Language</option>
                                        <option value="-1">{projectData && projectData.file.language.language}</option>
                                        {languages.map((data) => {
                                            return (
                                                <option key={data.language.id} value={data.language.id}>
                                                    {data.language.language}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div
                                        className={`flex items-center mt-3 flex-wrap relative max-width-tar-lang`}
                                        id="targetlangform"
                                    >
                                        {selectedLanguages.map((selected) => {
                                            return (
                                                <button
                                                    key={selected.code}
                                                    type="button"
                                                    className="shrink bg-box-content-darknp p-2 flex items-center rounded-3px line-height-1 mr-2 mb-2 text-xs"
                                                >
                                                    {selected.code || selected.language_code}
                                                    <X
                                                        onClick={() => removeSelectedLanguage(selected)}
                                                        className="w-4 h-4 ml-2"
                                                    />
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="mb-4" id="message-form">
                                    <label for="message-to">Message</label>
                                    <textarea
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        id="message-to"
                                        rows="5"
                                        className="w-full block mt-1 px-3 py-2 text-xs rounded border focus:border-blue-400 focus:outline-none"
                                    ></textarea>
                                </div>
                                <div className="mb-4" id="list-invited">
                                    <div className="intro-y mb-2">
                                        <div className="bg-box-content-dark">
                                            <div
                                                className="nav nav-tabs nav-tabs-invited justify-center rounded-3px"
                                                role="tablist"
                                                id="invited-user-tab"
                                            >
                                                <a
                                                    id="all-tab"
                                                    data-toggle="tab"
                                                    data-target="#invited-all"
                                                    href="javascript:;"
                                                    className="flex-1 py-2 px-1 rounded-md text-center active"
                                                    role="tab"
                                                    aria-controls="all"
                                                    aria-selected="true"
                                                >
                                                    All
                                                </a>
                                                <a
                                                    id="editor-tab"
                                                    data-toggle="tab"
                                                    data-target="#invited-editor"
                                                    href="javascript:;"
                                                    className="flex-1 py-2 rounded-md text-center px-1"
                                                    role="tab"
                                                    aria-controls="translator"
                                                    aria-selected="false"
                                                >
                                                    Editor
                                                </a>
                                                <a
                                                    id="commentor-tab"
                                                    data-toggle="tab"
                                                    data-target="#invited-commentor"
                                                    href="javascript:;"
                                                    className="flex-1 py-2 rounded-md text-center px-1"
                                                    role="tab"
                                                    aria-controls="client"
                                                    aria-selected="false"
                                                >
                                                    Commentor
                                                </a>
                                                <a
                                                    id="viewrer-tab"
                                                    data-toggle="tab"
                                                    data-target="#invited-viewer"
                                                    href="javascript:;"
                                                    className="flex-1 py-2 rounded-md text-center px-1"
                                                    role="tab"
                                                    aria-controls="stakeholder"
                                                    aria-selected="false"
                                                >
                                                    Viewer
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div
                                            id="invited-all"
                                            className="list-invited-users tab-pane w-full active"
                                            role="tabpanel"
                                            aria-labelledby="all-tab"
                                        >
                                            <div
                                                className="border border-input rounded-md p-3 w-full"
                                                style={{
                                                    height: '150px',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                <div className="font-medium border-gray-200 dark:border-dark-5 text-center flex flex-col gap-2">
                                                    {isUserFetched ? (
                                                        all && all.length <= 0 ? (
                                                            <p className="absolute-center">No user found!</p>
                                                        ) : (
                                                            all.map((data, key) => {
                                                                return renderUserList(data, key);
                                                            })
                                                        )
                                                    ) : (
                                                        <p className="absolute-center">Loading...</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="invited-editor"
                                            className="list-invited-users tab-pane w-full"
                                            role="tabpanel"
                                            aria-labelledby="translator-tab"
                                        >
                                            <div
                                                className="border border-input rounded-md p-3 w-full"
                                                style={{
                                                    height: '150px',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                <div className="font-medium border-gray-200 dark:border-dark-5 text-center">
                                                    {isUserFetched ? (
                                                        editor && editor.length <= 0 ? (
                                                            <p className="absolute-center">No user found!</p>
                                                        ) : (
                                                            editor.map((data) => {
                                                                return renderUserList(data);
                                                            })
                                                        )
                                                    ) : (
                                                        <p className="absolute-center">Loading...</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="invited-commentor"
                                            className="list-invited-users tab-pane w-full"
                                            role="tabpanel"
                                            aria-labelledby="client-tab"
                                        >
                                            <div
                                                className="border border-input rounded-md p-3 w-full"
                                                style={{
                                                    height: '150px',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                <div className="font-medium border-gray-200 dark:border-dark-5 text-center">
                                                    {isUserFetched ? (
                                                        commentor && commentor.length <= 0 ? (
                                                            <p className="absolute-center">No user found!</p>
                                                        ) : (
                                                            commentor.map((data) => {
                                                                return renderUserList(data);
                                                            })
                                                        )
                                                    ) : (
                                                        <p className="absolute-center">Loading...</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="invited-viewer"
                                            className="list-invited-users tab-pane w-full"
                                            role="tabpanel"
                                            aria-labelledby="stakeholder-tab"
                                        >
                                            <div
                                                className="border border-input rounded-md p-3 w-full"
                                                style={{
                                                    height: '150px',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                <div className="font-medium border-gray-200 dark:border-dark-5 text-center">
                                                    {isUserFetched ? (
                                                        viewer && viewer.length <= 0 ? (
                                                            <p className="absolute-center">No user found!</p>
                                                        ) : (
                                                            viewer.map((data) => {
                                                                return renderUserList(data);
                                                            })
                                                        )
                                                    ) : (
                                                        <p className="absolute-center">Loading...</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="flex justify-end">
                                        <button
                                            onClick={handleSubmit}
                                            className="btn btn-lexigo-soft-green text-white font-bold rounded-3px"
                                            disabled={loading ? true : false}
                                        >
                                            {loading ? 'Sending' : 'Send Invite'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="modal-body h-100vh">Upgrade your account to use this feature</div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default ShareModal;
