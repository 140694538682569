import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useActions, useAppState } from '../overmind';
import { getComments, storeComment } from '../requests/TranslateRequest';

/* eslint-disable jsx-a11y/anchor-is-valid */
const CommentsTab = (props) => {
    const [translationComment, setTranslationComment] = useState('');
    const [segmentComment, setSegmentComment] = useState('');
    const [comments, setComments] = useState(null);
    const [segmentComments, setSegmentComments] = useState(null);
    const [commentLoading, setCommentLoading] = useState(false);
    const [isGeneral, setIsGeneral] = useState(true);
    const state = useAppState();
    const actions = useActions();
    const segmentRef = useRef();
    const saveTranslationComment = async (e) => {
        e.preventDefault();
        setCommentLoading(true);
        props.setIsSaving(true);
        const translation = props.translatedLanguage.filter((x) => x.language_id === props.currentLanguage)[0];
        await storeComment(translation.id, props.user.signature, 'translation', translationComment);
        await fetchComment();
        props.setIsSaving(false);
        setTranslationComment('');
        setCommentLoading(false);
    };

    const saveSegmentComment = async (e) => {
        e.preventDefault();
        setCommentLoading(true);
        props.setIsSaving(true);
        setSegmentComment('');
        await storeComment(state.workbenchDetailId, props.user.signature, 'segment', segmentComment);
        fetchSegmentComment(state.workbenchDetailId);
        props.setIsSaving(false);
        setCommentLoading(false);
    };

    const formatComment = (details) => {
        const result = {};
        details.forEach((data) => {
            const createdAt = moment(moment.utc(data.created_at).local().format());
            const key = `${createdAt.format('D MMMM YYYY')}`;
            data.time = `${createdAt.format('hh:mm A')}`;
            if (result[key]) {
                result[key] = [...result[key], data];
            } else {
                result[key] = [data];
            }
        });
        return result;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchComment = async () => {
        setCommentLoading(true);
        const translation = props.translatedLanguage.filter((x) => x.language_id === props.currentLanguage)[0];
        const data = await getComments(translation.id, 'translation', props.user.signature, props.id);
        setComments(formatComment(data.data));
        translationCommentSSE();
        setCommentLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchSegmentComment = async (workbenchDetailId) => {
        setCommentLoading(true);
        const data = await getComments(workbenchDetailId, 'segment', props.user.signature, props.id);
        setSegmentComments(formatComment(data.data));
        setCommentLoading(false);
    };

    const getInitials = (string) => {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(() => {
        if (props.translatedLanguage) {
            const translation = props.translatedLanguage.filter((x) => x.language_id === props.currentLanguage);
            if (translation.length > 0) {
                fetchComment();
            }
        }
    }, [props.translatedLanguage]);

    const [eventSource, setEventSource] = useState(null);

    useEffect(() => {
        if (state.workbenchDetailId != null && segmentRef.current) {
            segmentRef.current.click();
            fetchSegmentComment(state.workbenchDetailId);
        }
    }, [state.workbenchDetailId]);

    useEffect(() => {
        if (eventSource != null) {
            eventSource.close();
        }
        const fetchData = async () => {
            const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/translations';
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const newEventSource = new EventSource(
                BACKEND_URL +
                    `/comment-stream?translation_id=${state.workbenchDetailId}&type=segment&au=${
                        props.user.signature + '!' + props.id
                    }`,
                { withCredentials: true },
            );

            newEventSource.onmessage = function (event) {
                setSegmentComments(formatComment(JSON.parse(event.data)));
            };
            setEventSource(newEventSource);
        };
        if (props.user && state.workbenchDetailId && segmentRef.current) {
            segmentRef.current.click();
            fetchData();
        }
    }, [state.workbenchDetailId, segmentComment]);

    const translationCommentSSE = () => {
        if (eventSource != null) {
            eventSource.close();
        }
        const fetchData = async () => {
            const translation = props.translatedLanguage.filter((x) => x.language_id == props.currentLanguage)[0];
            if (!translation) return;
            const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/translations';
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const newEventSource = new EventSource(
                BACKEND_URL +
                    `/comment-stream?translation_id=${translation.id}&type=translation&au=${
                        props.user.signature + '!' + props.id
                    }`,
                { withCredentials: true },
            );
            newEventSource.onmessage = function (event) {
                setComments(formatComment(JSON.parse(event.data)));
            };
            setEventSource(newEventSource);
        };
        if (props.user && props.translatedLanguage) {
            fetchData();
        }
    };

    useEffect(() => {
        translationCommentSSE();
    }, [translationComment]);

    let header = useRef(null);
    let [headerHeight, setHeaderHeight] = useState(0);

    // useEffect(() => {
    //     if (props.activeTab === 'comment') {
    //         setHeaderHeight(header.current.offsetHeight)
    //     }
    // }, [props.activeTab])

    return (
        <div
            id="comments"
            className={`flex flex-col tab-pane ${
                props.access && props.access.accessMapping.role.toLowerCase() === 'commentor' ? 'active' : ''
            }`}
            style={{ height: 'calc(100% - 108px)' }}
            role="tabpanel"
            aria-labelledby="comments-tab"
        >
            <div ref={header}>
                <div className="chat bg-f6 rounded-3px">
                    <div className="chat__tabs nav nav-tabs justify-center" role="tablist">
                        <a
                            onClick={() => {
                                fetchComment();
                                setIsGeneral(true);
                            }}
                            id="global-comments-tab"
                            href="javascript:;"
                            className={`flex-1 py-2 rounded-md text-center font-bold text-theme-lexigo ${
                                isGeneral ? 'active' : ''
                            }`}
                        >
                            General
                        </a>
                        <a
                            onClick={() => {
                                setIsGeneral(false);
                            }}
                            href="javascript:;"
                            className={`flex-1 py-2 rounded-md text-center font-bold text-theme-lexigo ${
                                !isGeneral ? 'active' : ''
                            }`}
                            ref={segmentRef}
                        >
                            Segments
                        </a>
                    </div>
                </div>
            </div>

            {commentLoading ? (
                <div className="flex flex-col gap-4 py-4">
                    <div className="flex">
                        <Skeleton
                            variant="circular"
                            height={40}
                            width={40}
                            style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                            className="mr-2"
                        />
                        <div>
                            <Skeleton height={20} width={70} style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                            <Skeleton height={20} width={150} style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                        </div>
                        <Skeleton
                            height={20}
                            width={50}
                            style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                            className="ml-auto"
                        />
                    </div>
                    <div className="flex">
                        <Skeleton
                            variant="circular"
                            height={40}
                            width={40}
                            style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                            className="mr-2"
                        />
                        <div>
                            <Skeleton height={20} width={70} style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                            <Skeleton height={20} width={150} style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                        </div>
                        <Skeleton
                            height={20}
                            width={50}
                            style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                            className="ml-auto"
                        />
                    </div>
                    <div className="flex">
                        <Skeleton
                            variant="circular"
                            height={40}
                            width={40}
                            style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                            className="mr-2"
                        />
                        <div>
                            <Skeleton height={20} width={70} style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                            <Skeleton height={20} width={150} style={{ backgroundColor: 'rgba(0,0,0,.11)' }} />
                        </div>
                        <Skeleton
                            height={20}
                            width={50}
                            style={{ backgroundColor: 'rgba(0,0,0,.11)' }}
                            className="ml-auto"
                        />
                    </div>
                </div>
            ) : (
                <>
                    {isGeneral ? (
                        <div
                            id="global-comments"
                            className={`tab-pane ${isGeneral ? 'active' : ''}`}
                            style={{ height: `calc(100% - ${headerHeight}px)` }}
                        >
                            {comments === null || (comments && Object.keys(comments).length <= 0) ? (
                                <div className="p-4 min-h-sidetab-comments flex items-center justify-center text-center text-xs h-100">
                                    There's nothing to see here. Kindly start commenting...
                                </div>
                            ) : (
                                <div className="p-4 min-h-sidetab-comments">
                                    <div className="report-timeline relative p-5 pl-0 chat-container pt-0">
                                        {comments &&
                                            Object.keys(comments).map((key, index) => {
                                                return (
                                                    <>
                                                        <div className="text-gray-500 text-xs text-center my-4">
                                                            {key}
                                                        </div>
                                                        {comments[key].map((data) => {
                                                            return (
                                                                <div className="relative flex items-center mb-3">
                                                                    <div className="report-timeline__image">
                                                                        {/* <img
                                                                            src="dist/images/dummy-2.jpg"
                                                                            alt=""
                                                                            className="w-10 h-10 lg:w-10 lg:h-10 flex items-center image-fit rounded-full overflow-hidden lg:mr-1"
                                                                        /> */}
                                                                        <div className="w-10 h-10 lg:w-10 lg:h-10 flex items-center avatar-bench image-fit rounded-full bg-color0 text-white overflow-hidden lg:mr-1">
                                                                            {data.user.type == 'external'
                                                                                ? data.user.email.at(0)
                                                                                : getInitials(data.user.name)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="box px-5 py-3 ml-4 flex-1 zoom-in">
                                                                        <div className="flex items-center">
                                                                            <div
                                                                                id="unread-general-dot"
                                                                                className="unread-comment-notification hide-visibility"
                                                                            ></div>
                                                                            <div className="font-medium">
                                                                                {data.user.type == 'external'
                                                                                    ? data.user.email
                                                                                    : data.user.name}
                                                                            </div>
                                                                            <div className="text-xs text-gray-500 ml-auto">
                                                                                {data.time}
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-gray-600 mt-1">
                                                                            {data.text}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                            <form onSubmit={saveTranslationComment}>
                                <div className="flex items-center chat-area-new">
                                    <input
                                        className="chat__box__input form-control dark:bg-dark-3 resize-none border-transparent pl-4 px-5 py-3 shadow-none focus:ring-0"
                                        placeholder="Type your message..."
                                        value={translationComment}
                                        onChange={(e) => setTranslationComment(e.target.value)}
                                        disabled={
                                            props.access && props.access.accessMapping.role.toLowerCase() === 'viewer'
                                                ? true
                                                : false
                                        }
                                    />
                                    <button
                                        className="btn btn-primary block bg-theme-25 text-white font-bold button-send-chat"
                                        type="submit"
                                        disabled={
                                            props.access && props.access.accessMapping.role.toLowerCase() === 'viewer'
                                                ? true
                                                : false
                                        }
                                    >
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div
                            className={`tab-pane ${!isGeneral ? 'active' : ''}`}
                            style={{ height: `calc(100% - ${headerHeight}px)` }}
                        >
                            {segmentComments === null ||
                            (segmentComments && Object.keys(segmentComments).length <= 0) ? (
                                <div className="p-4 min-h-sidetab-comments flex items-center justify-center text-center text-xs h-100">
                                    There's nothing to see here. Kindly start commenting...
                                </div>
                            ) : (
                                <div className="p-4 min-h-sidetab-comments">
                                    <div className="report-timeline relative p-5 pl-0 chat-container pt-0">
                                        {segmentComments &&
                                            Object.keys(segmentComments).map((key, index) => {
                                                return (
                                                    <>
                                                        <div className="text-gray-500 text-xs text-center my-4">
                                                            {key}
                                                        </div>
                                                        {segmentComments[key].map((data, i) => {
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    className="relative flex items-center mb-3"
                                                                >
                                                                    <div className="report-timeline__image">
                                                                        {/* <img
                                                                            src="dist/images/dummy-2.jpg"
                                                                            alt=""
                                                                            className="w-10 h-10 lg:w-10 lg:h-10 flex items-center image-fit rounded-full overflow-hidden lg:mr-1"
                                                                        /> */}
                                                                        <div className="w-10 h-10 lg:w-10 lg:h-10 flex items-center avatar-bench image-fit rounded-full bg-color0 text-white overflow-hidden lg:mr-1">
                                                                            {data.user.type == 'external'
                                                                                ? data.user.email.at(0)
                                                                                : getInitials(data.user.name)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="box px-5 py-3 ml-4 flex-1 zoom-in">
                                                                        <div className="flex items-center">
                                                                            <div
                                                                                id="unread-general-dot"
                                                                                className="unread-comment-notification hide-visibility"
                                                                            ></div>
                                                                            <div className="font-medium">
                                                                                {' '}
                                                                                {data.user.type == 'external'
                                                                                    ? data.user.email
                                                                                    : data.user.name}
                                                                            </div>
                                                                            <div className="text-xs text-gray-500 ml-auto">
                                                                                {data.time}
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-gray-600 mt-1">
                                                                            {data.text}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                            <form onSubmit={saveSegmentComment}>
                                <div className="flex items-center chat-area-new">
                                    <input
                                        className="chat__box__input form-control dark:bg-dark-3 resize-none border-transparent pl-4 px-5 py-3 shadow-none focus:ring-0"
                                        placeholder="Type your message..."
                                        onChange={(e) => setSegmentComment(e.target.value)}
                                        value={segmentComment}
                                        disabled={
                                            props.access && props.access.accessMapping.role.toLowerCase() === 'viewer'
                                                ? true
                                                : false
                                        }
                                    />
                                    <button
                                        className="btn btn-primary block bg-theme-25 text-white font-bold button-send-chat"
                                        type="submit"
                                        disabled={
                                            props.access && props.access.accessMapping.role.toLowerCase() === 'viewer'
                                                ? true
                                                : false
                                        }
                                    >
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default CommentsTab;
