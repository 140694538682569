import React, { useState } from 'react';
import useComponentVisible from "./useComponentVisible.js";
import ChevronDownIcon from "./Icon/ChevronDownIcon.js";

const FontSizePicker = (props) => {
    const [fontSize, setFontSize] = useState(['9', '18', '27', '36', '45', '54', '63', '72', '144', '180']);

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    useState(() => {
        let size = [];
        for (let i = 6; i < 300; i += 2) {
            size.push(i);
        }
        setFontSize(size);
    }, []);

    const selectFontSize = (value) => {
        props.onConfigClick(props.name, value)
        setIsComponentVisible(false)
    }

    return (
        <>
            <div className="custom-dropdown custom-dropdown-fixed dropdown-toolbar pr-2 w-full" ref={ref}>
                <button
                    id=""
                    className="dropdown-toggle font-gordita font-bold flex items-center justify-between w-full"
                    onClick={async () => {
                        setIsComponentVisible(!isComponentVisible);
                    }}
                >
                    {props.fontSize}
                    <ChevronDownIcon className="w-6 h-6"/>
                </button>
                <div
                    className={`custom-dropdown-menu ${isComponentVisible ? 'show' : ''}`}
                >
                    {fontSize.map((size, i) => (
                        <div className={`custom-dropdown-menu__item ${size === props.fontSize ? 'active' : ''}`} onClick={() => selectFontSize(size)}>
                            {size}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default FontSizePicker;
