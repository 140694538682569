import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/speaker';

export const fetchSpeakers = async (subtitle_id) => {
    return axios
        .post(BACKEND_URL, {
            subtitle_id: subtitle_id,
        })
        .then((res) => {
            return res.data.data;
        });
};

export const updateSpeakerDetail = async (speaker_id, speaker_name) => {
    return axios
        .put(BACKEND_URL, {
            id: speaker_id,
            speaker_name: speaker_name,
        })
        .then((res) => {
            return res.data.data;
        });
};

export const createSpeaker = async (subtitle_id, speaker_name) => {
    return axios
        .post(BACKEND_URL + '/create', {
            subtitle_id: subtitle_id,
            speaker_name: speaker_name,
        })
        .then((res) => {
            return res.data.data;
        });
};

export const removeSpeaker = async (speaker_id) => {
    return axios.delete(BACKEND_URL + '/delete/' + speaker_id).then((res) => {
        return res.data.data;
    });
};
