import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/subtitle';

export const createSubtitleProject = async (name, file) => {
    var formData = new FormData();
    formData.append('name', name);
    formData.append('video', file);

    return axios
        .post(BACKEND_URL + '/store', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const fetchSubtitleProject = async () => {
    return axios.get(BACKEND_URL + '/list').then((res) => {
        return res.data;
    });
};

export const fetchSubtitleData = async (id) => {
    return axios.get(BACKEND_URL + '/data?id=' + id).then((res) => {
        return res.data.data;
    });
};

export const fetchSubtitleStyle = async (id, ratio, language_id = 0) => {
    return axios
        .get(BACKEND_URL + '/style?id=' + id + '&ratio=' + ratio + '&language_id=' + language_id)
        .then((res) => {
            return res.data.data;
        });
};

export const downloadVideo = async (id) => {};

export const deleteWatermarkRequest = async (id, ratio) => {
    return axios
        .delete(BACKEND_URL + '/watermark', {
            data: {
                id: id,
                ratio: ratio,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const uploadWatermark = async (id, ratio, file, filename) => {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('ratio', ratio);
    formData.append('watermark', file);
    formData.append('filename', filename);
    return axios
        .post(BACKEND_URL + '/watermark', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            return res.data.watermark_url;
        });
};

export const removeSubtitle = async (subtitleData) => {
    return axios
        .put(BACKEND_URL + '/details/delete', {
            data: subtitleData,
        })
        .then((res) => {
            return res.data;
        });
};

export const createSubtitle = async (id, subtitleData) => {
    return axios
        .post(BACKEND_URL + '/details/create', {
            id: id,
            data: subtitleData,
        })
        .then((res) => {
            return res.data.data;
        });
};
export const saveSubtitle = async (id, config, language_id = 0) => {
    return axios
        .post(BACKEND_URL + '/details/store', {
            id: id,
            config: config,
            language_id,
        })
        .then((res) => {
            return res.data.data;
        });
};

export const updateSubtitleSpeaker = async (subtitle_detail_id, speaker_id) => {
    return axios
        .put(BACKEND_URL + '/details/update-speaker', {
            id: subtitle_detail_id,
            speaker_id,
        })
        .then((res) => {
            return res.data.data;
        });
};

export const updateSubtitle = async (subtitleData) => {
    return axios
        .put(BACKEND_URL + '/details/update', {
            data: subtitleData,
        })
        .then((res) => {
            return res.data.data;
        });
};

export const audioSubtitlePreparation = async (
    id,
    vtt,
    srt,
    txt,
    language,
    signature,
    volume,
    voiceOverVolume,
    originalMusicVolume,
    originalVocalVolume,
) => {
    return axios
        .get(
            BACKEND_URL +
                `/audio-download?id=${id}&vtt=${vtt}&srt=${srt}&txt=${txt}&language=${language}&signature=${signature}&volume=${volume}&voiceOverVolume=${voiceOverVolume}&originalVocalVolume=${originalVocalVolume}&originalMusicVolume=${originalMusicVolume}`,
        )
        .then((res) => {
            return res.data;
        });
};

export const subtitleRender = async (
    id,
    vtt,
    srt,
    txt,
    ratio,
    language,
    signature,
    compression,
    volume,
    voiceOverVolume,
    originalMusicVolume,
    originalVocalVolume,
    burnSubtitle,
    sendEmail
) => {
    return axios
        .get(
            BACKEND_URL +
                `/render?id=${id}&vtt=${vtt}&srt=${srt}&txt=${txt}&language=${language}&signature=${signature}&ratio=${ratio}&compression=${compression}&volume=${volume}&voiceOverVolume=${voiceOverVolume}&originalVocalVolume=${originalVocalVolume}&originalMusicVolume=${originalMusicVolume}&burnSubtitle=${burnSubtitle}&sendEmail=${sendEmail}`,
        )
        .then((res) => {
            return res.data;
        });
};

export const getExportStatus = async (id) => {
    return axios.get(BACKEND_URL + '/export-status/' + id).then((res) => {
        return res.data;
    });
};

export const getRenderPercentage = async (id, ratio) => {
    return axios.get(BACKEND_URL + '/render-percentage/' + id + '/' + ratio).then((res) => {
        return res.data;
    });
};

export const getExportedUrl = async (id) => {
    return axios.get(BACKEND_URL + '/exported-url/' + id).then((res) => {
        return res.data;
    });
};

export const getFileData = async (id) => {
    return axios.get(BACKEND_URL + '/file-data/' + id).then((res) => {
        return res.data.data;
    });
};

export const getIsTranslating = async (id) => {
    return axios.get(BACKEND_URL + '/is-translating/' + id).then((res) => {
        return res.data.data;
    });
};

export const deleteCoverRequest = async (id, ratio, language_id = 0) => {
    return axios
        .delete(BACKEND_URL + '/cover', {
            data: {
                id: id,
                ratio: ratio,
                language_id,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const uploadCover = async (formData) => {
    return axios
        .post(BACKEND_URL + '/cover', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            return res.data;
        });
};
