import { Modal } from '@mui/material';
import React from 'react';
import { X } from 'react-feather';

const DurationExceedModal = ({ durationExceedModal, setDurationExceedModal, access }) => {
    return (
        <Modal
            open={durationExceedModal}
            onClose={() => {
                setDurationExceedModal(false);
            }}
        >
            <div
                id="modal-translate"
                className="modal show"
                style={{
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    zIndex: 10000,
                }}
                tabIndex="-1"
                aria-hidden="true"
                data-backdrop="static"
                role="dialog"
            >
                <div className="modal-dialog">
                    <div className="modal-content h-full">
                        <div className="modal-header flex items-center justify-between">
                            <p className="modal-title font-bold"></p>
                            <a data-dismiss="modal" href="javascript:;" onClick={() => setDurationExceedModal(false)}>
                                <X className="w-5 h-5" />
                            </a>
                        </div>
                        <div className="modal-body h-full">
                            <p className="modal-title font-bold">
                                Your current package doesn't allow more than {access && access.userPackage.length / 60}{' '}
                                minutes of video. Please contact support for upgrading
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DurationExceedModal;
