import { TextareaAutosize } from '@material-ui/core';
import { debounce } from 'lodash';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import { useAppState } from '../../overmind';
import { updateSubtitle } from '../../requests/SubtitleRequest';
import { extractContent } from '../../utils';

export default function TranslationEditorSubtitleCardInput({
    checkSub,
    projectData,
    access,
    s,
    setIsSaving,
    updateSub,
    tempSubtitle,
    setTempSubtitle,
    i,
    highlightSegment,
    ...props
}) {
    const state = useAppState();
    const [tempSub, setTempSub] = useState('');
    const hasSub = useCallback(
        (sub) => {
            let index = state.subtitle.indexOf(sub);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
                index = data.map((a) => a.id).indexOf(sub.id);
            }
            return index;
        },
        [state.subtitle],
    );

    useEffect(() => {
        if (s) {
            setTempSub(s);
        }
    }, [s]);

    const debouncedSave = useCallback(
        debounce((sub, nextValue) => {
            updateSubtitle(nextValue);
            setTempSubtitle(sub);
            setIsSaving(false);
        }, 1000),
        [],
    );

    const updateText = async (sub, text) => {
        setIsSaving(true);
        const index = hasSub(sub);
        if (index < 0) return;
        let newSub = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
        newSub[index].text = text;
        let temp = [...tempSubtitle];
        temp[index].text = text;
        debouncedSave(temp, newSub[index]);
        setTempSub(newSub[index]);
    };

    return (
        <TextareaAutosize
            className={[
                'input-table',
                'w-full',
                'input-light',
                projectData.file.language.rtl === 1 ? 'rtl-target-input' : '',
            ]
                .join(' ')
                .trim()}
            disabled={true}
            value={extractContent(tempSub.text, false)}
            style={{
                border: 'none',
                minHeight: '24px',
            }}
            onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
            }}
            id={`subtitle-card-${s.id}`}
            onFocus={() => {
                highlightSegment(s, i);
            }}
            onChange={(event) => {
                updateText(s, event.target.value);
            }}
        />
    );
}
