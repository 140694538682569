import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/access-mapping';

export const createAccessMapping = async (emails, role, languages, message, subtitle_id, signature, t, tid) => {
    return axios
        .post(BACKEND_URL + '/create', {
            emails: emails,
            role: role,
            languages: languages,
            message: message,
            subtitle_id: subtitle_id,
            signature: signature,
            workbench: t, //true -> lagi buka workbench
            tid,
        })
        .then((res) => {
            return res.data.data;
        });
};

export const getUserByRole = async (id, role) => {
    return axios.get(BACKEND_URL + '/get-user-by-role?id=' + id + '&role=' + role).then((res) => {
        return res.data.data;
    });
};

export const getAccessDetails = async (subtitleId, signature) => {
    return axios
        .get(BACKEND_URL + '/get-details', {
            headers: {
                Authorization: signature + '!' + subtitleId,
            },
        })
        .then((res) => {
            return res.data.data;
        });
};

export const getUserBySignatureAndSubtitleId = async (subtitleId, signature) => {
    return axios
        .get(BACKEND_URL + '/', {
            headers: {
                Authorization: signature + '!' + subtitleId,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            window.location.replace('/404');
        });
};

export const deleteUserAccess = async (userId, subtitleId) => {
    return axios.delete(BACKEND_URL + `/${userId}/${subtitleId}`).then((res) => {
        return res.data;
    });
};

export const deleteAccessDetail = async (detailId) => {
    return axios.delete(BACKEND_URL + `/detail/${detailId}`).then((res) => {
        return res.data;
    });
};

export const updateUserAccess = async (userId, subtitleId, role) => {
    return axios.put(BACKEND_URL + `/${userId}/${subtitleId}/${role}`).then((res) => {
        return res.data;
    });
};
