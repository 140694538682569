import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { X } from 'react-feather';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { extractPositionTag } from '../../utils';
import ColorPic from './ColorPic';

const CloseButton = ({ setFormatId, formatId, i }) => {
    return (
        <div
            style={{
                marginLeft: 'auto',
                cursor: 'pointer',
            }}
        >
            <X
                width={20}
                height={20}
                onClick={() => {
                    if (formatId.includes(i)) {
                        const index = formatId.indexOf(i);
                        const newFormatId = [...formatId];
                        newFormatId.splice(index, 1);
                        setFormatId(newFormatId);
                    } else {
                        setFormatId([...formatId, i]);
                    }
                }}
            />
        </div>
    );
};

const EditorComponent = ({ updateText, s, setFormatId, formatId, i }) => {
    const [editorState, setEditorState] = useState(() => {
        if (s.text != null) {
            const text = s.text.replace(/{\\an\d}/, '');

            const blocksFromHtml = htmlToDraft(text);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

            const edState = EditorState.createWithContent(contentState);
            return edState;
        }
    });
    const [position, setPosition] = useState(() => {
        if (s.text != null) {
            return extractPositionTag(s.text);
        }
    });

    const debounceEdit = useCallback(
        debounce((newEditorState) => {
            update(newEditorState);
        }, 1000),
        [],
    );

    const update = async (newEditorState) => {
        const blocks = convertToRaw(newEditorState.getCurrentContent()).blocks;
        const text = blocks.map((block) => (!block.text.trim() && '\n') || block.text).join('\n');
        const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
        if (editorState.getCurrentContent() == newEditorState.getCurrentContent()) {
            return;
        }
        if (text != null) {
            let newText = html;
            if (position != null) {
                newText = position + html;
            }
            updateText(s, newText);
        }
    };

    const onChange = async (newEditorState) => {
        setEditorState(newEditorState);

        debounceEdit(newEditorState);
    };

    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={onChange}
            toolbar={{
                options: ['inline', 'colorPicker'],
                inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic', 'underline'],
                },
                colorPicker: { component: ColorPic },
            }}
            toolbarCustomButtons={[<CloseButton setFormatId={setFormatId} formatId={formatId} i={i} />]}
        />
    );
};

export default EditorComponent;
