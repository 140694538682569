import axios from 'axios';
import { parse } from 'opentype.js';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/font';

export const fetchFontFamilies = async (authorization) => {
    return axios
        .get(BACKEND_URL, {
            headers: {
                Authorization: authorization,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const uploadCustomFont = async (file, authorization) => {
    var formData = new FormData();
    formData.append('font_file', file);
    let ascender = 0;
    let descender = 0;
    let xHeight = 0;
    try {
        const fontData = parse(await file.arrayBuffer());
        ascender = fontData.ascender;
        descender = fontData.descender;
        xHeight = fontData.tables.head.yMax - fontData.tables.head.yMin;
    } catch (e) {}
    formData.append('ascender', ascender);
    formData.append('descender', descender);
    formData.append('xHeight', xHeight);
    return axios
        .post(BACKEND_URL, formData, {
            headers: {
                Authorization: authorization,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getFontById = async (id) => {
    return axios.get(BACKEND_URL + `/${id}`).then((res) => {
        return res.data;
    });
};

export const getFont = async (url) => {
    return axios.get(url).then((res) => {
        return res.data;
    });
};
