import { json } from 'overmind';
import { useEffect } from 'react';
import { useActions, useAppState } from '../overmind';
import { updateVolume } from '../requests/VolumeRequest';

const MuteAll = (props) => {
    const state = useAppState();
    const actions = useActions();

    const commitVolume = async (muted) => {
        let is_translation = false;
        if (props.translateState) {
            is_translation = true;
        }
        await updateVolume({
            id: props.translateState ? props.translationHeader.id : props.id,
            is_translation: is_translation,
            volume: muted ? 0 : 100,
            original_music_volume: muted ? 0 : 100,
            original_vocal_volume: muted ? 0 : 100,
            voice_over_volume: muted ? 0 : 100,
        });
    };

    const muteAll = async () => {
        if (state.voiceOverAudio !== null) {
            if (!state.originalVocal || !state.voiceOverAudio || !state.originalMusic) return;
            state.originalVocal.muted = true;
            state.voiceOverAudio.muted = true;
            state.originalMusic.muted = true;
            actions.setMutedOriginalMusic(true);
            actions.setMutedOriginalVocal(true);
            actions.setMutedVoiceOver(true);
        } else {
            if (!props.player) return;
            props.player.muted = true;
            actions.setMutedPlayer(true);
        }
        await commitVolume(true);
    };

    const unMuteAll = async () => {
        if (state.voiceOverAudio !== null) {
            if (!state.originalVocal || !state.voiceOverAudio || !state.originalMusic) return;
            state.originalVocal.muted = false;
            state.voiceOverAudio.muted = false;
            state.originalMusic.muted = false;
            actions.setMutedOriginalMusic(false);
            actions.setMutedOriginalVocal(false);
            actions.setMutedVoiceOver(false);
        } else {
            if (!props.player) return;
            props.player.muted = false;
            actions.setMutedPlayer(false);
        }
        await commitVolume(false);
    };

    useEffect(() => {
        if (json(state.muted)) {
            muteAll();
        } else {
            unMuteAll();
        }
    }, [state.muted]);

    const muteOrUnMute = () => {
        actions.setMuted(!json(state.muted));
    };

    return (
        <button
            className="btn btn-bg-control text-white py-1 lg:py-2 font-bold rounded-3px flex-1 text-content"
            onClick={muteOrUnMute}
        >
            {json(state.muted) ? 'Unmute All' : 'Mute All'}
        </button>
    );
};

export default MuteAll;
