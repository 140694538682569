function ScreenShareSlashIcon({ className }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M2 21C2 20.4477 2.44772 20 3 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 3.5H5C4.17157 3.5 3.5 4.17157 3.5 5V16C3.5 16.8284 4.17157 17.5 5 17.5H19C19.8284 17.5 20.5 16.8284 20.5 16V5C20.5 4.17157 19.8284 3.5 19 3.5ZM5 2C3.34315 2 2 3.34315 2 5V16C2 17.6569 3.34315 19 5 19H19C20.6569 19 22 17.6569 22 16V5C22 3.34315 20.6569 2 19 2H5Z'
        fill='currentColor'
      />
      <path
        d='M8.46957 9.90627C8.17569 10.2901 8 10.7767 8 11.3061V14.3878C8 14.7259 8.26279 15 8.58696 15C8.91112 15 9.17391 14.7259 9.17391 14.3878V11.3061C9.17391 11.1059 9.22922 10.9191 9.32477 10.7615L8.46957 9.90627Z'
        fill='currentColor'
      />
      <path
        d='M14.3185 10.9924L14.996 10.2857H13.6119L12.3874 9.06122H14.996L13.0632 7.04517C12.834 6.80607 12.834 6.41842 13.0632 6.17932C13.2924 5.94023 13.6641 5.94023 13.8933 6.17932L16.8281 9.24055C17.0573 9.47964 17.0573 9.8673 16.8281 10.1064L15.1661 11.84L14.3185 10.9924Z'
        fill='currentColor'
      />
      <path
        d='M7.03451 6.08987C7.30717 5.81722 7.74923 5.81722 8.02188 6.08987L16.0287 14.0967C16.3013 14.3693 16.3013 14.8114 16.0287 15.084C15.756 15.3567 15.3139 15.3567 15.0413 15.084L7.03451 7.07724C6.76186 6.80459 6.76186 6.36253 7.03451 6.08987Z'
        fill='currentColor'
      />
    </svg>
  );
}

export default ScreenShareSlashIcon;
