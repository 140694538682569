/* eslint-disable no-lone-blocks */

import { useAppState } from '../overmind';
import HalfThreeDotsLoader from './HalfThreeDotsLoader';

/* eslint-disable jsx-a11y/anchor-is-valid */
const TranslationMemoryTab = (props) => {
    const state = useAppState();

    const handleTmClick = (tmId) => {
        const ref = props.translationSegmentRef(`translation-ref-${state.segmentIdx}`).current;
        if (ref === null) return;
        let currentText = ref.value;
        const target = state.tm[tmId].txttarget;
        currentText = target;

        const valueSetter = Object.getOwnPropertyDescriptor(ref, 'value').set;
        const prototype = Object.getPrototypeOf(ref);
        const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

        if (valueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(ref, currentText);
        } else {
            valueSetter.call(ref, currentText);
        }
        const event = new Event('input', { bubbles: true });

        ref.dispatchEvent(event);
    };

    return (
        <div id="tm" className="tab-pane" role="tabpanel" aria-labelledby="tm-tab">
            <div className="min-h-sidetab-tm">
                {props.termsAndTmLoading ? (
                    <div
                        className="col-span-12 flex flex-col justify-end items-center h-full"
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '75vh',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                        }}
                    >
                        <div
                            className="col-span-12 flex flex-col justify-end items-center h-full"
                            style={{ justifyContent: 'center' }}
                        >
                            <i className="w-32 h-32">
                                <HalfThreeDotsLoader />
                            </i>
                        </div>
                    </div>
                ) : !state.tm || state.tm.length <= 0 ? (
                    <div className="bg-f6 rounded-3px p-4 pr-2 text-xs">No Data Available</div>
                ) : (
                    <table className="table table-tab-tm">
                        <thead>
                            <tr>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.tm &&
                                state.tm.map((data, i) => {
                                    return (
                                        <tr key={i} className={`${i % 2 === 0 ? 'bg-gray-200 dark:bg-dark-1' : ''}`}>
                                            <td
                                                className="border-b dark:border-dark-5 pointer"
                                                onClick={() => {
                                                    handleTmClick(i);
                                                }}
                                            >
                                                <span className="block">{data.matchpercent}%</span>
                                                {data.txtsource}

                                                <div className="divider my-2" style={{ borderColor: '#ddd' }}></div>

                                                <div>{data.txttarget}</div>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};
export default TranslationMemoryTab;
