/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from '@mui/material';
import { MoreHorizontal } from 'react-feather';
import useComponentVisible from '../useComponentVisible';
const BreadcrumbsMore = ({ projectData }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    return (
        <div className="custom-dropdown" ref={ref}>
            <button
                style={{ marginTop: '0.5rem' }}
                className={`dropdown-toggle `}
                aria-expanded="false"
                onClick={() => {
                    setIsComponentVisible(!isComponentVisible);
                }}
            >
                <MoreHorizontal className="w-5 h-4" stroke="#17BDB7" />
            </button>

            <div
                className={`custom-dropdown-menu dropdown-menu-translation-tab w-56 ${
                    isComponentVisible ? 'show' : ''
                }`}
                style={{}}
            >
                <div className="custom-dropdown-menu__content box ">
                    <div className="py-4 p-5">
                        {projectData.breadcrumbs_data.map((folder) => {
                            return (
                                <div>
                                    <Link
                                        underline="hover"
                                        key={folder.id}
                                        target="_blank"
                                        color="inherit"
                                        href={`${
                                            process.env.REACT_APP_BACKEND_URL.replace('/api', '/folders') +
                                            '?id=' +
                                            folder.id
                                        }`}
                                    >
                                        {'>'} {folder.name}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BreadcrumbsMore;
