import { Skeleton } from '@mui/material';
import DT from 'duration-time-conversion';
import debounce from 'lodash/debounce';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import ViewportList from 'react-viewport-list';
import { useDebounce } from 'use-debounce';
import { useActions, useAppState, useReaction } from '../../overmind';
import { getIsTranslating } from '../../requests/SubtitleRequest';
import { saveTranslation } from '../../requests/TranslateRequest';
import SpeakerMenu from '../Audio/SpeakerMenu';
import EditorComponent from '../EditorComponent/EditorComponent';
import FormatMenu from '../EditorComponent/FormatMenu';
import StudioTranslationCardInput from './StudioTranslationCardInput';
import TimeInput from './TimeInput';

export default function StudioTranslationCard(props) {
    const [height, setHeight] = useState(100);
    const [tempTranslation, setTempTranslation] = useState([]);
    const [formatId, setFormatId] = useState([]);
    const [updatedTranslation] = useDebounce(tempTranslation, 1000);
    const [selectedTranslation, setSelectedTranslation] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);
    const state = useAppState();
    const actions = useActions();
    const reaction = useReaction();
    const currentIndex = props.translateState
        ? state.translation.findIndex((item) => item.startTime <= state.currentTime && item.endTime > state.currentTime)
        : state.subtitle.findIndex((item) => item.startTime <= state.currentTime && item.endTime > state.currentTime);

    const resize = useCallback(() => {
        setHeight(document.body.clientHeight - 170);
    }, [setHeight]);

    const validateDurationFormat = (time) => {
        return /^\d\d:\d\d:\d\d(\.\d{1,7})?$/.test(time);
    };

    const validateDuration = (oldSub, time, start = false) => {
        if (
            validateDurationFormat(time) &&
            validateDurationFormat(oldSub.end) &&
            validateDurationFormat(oldSub.start)
        ) {
            if (start) {
                if (DT.t2d(oldSub.end.slice(0, 12)) > DT.t2d(time.slice(0, 12))) {
                    return true;
                }
            } else {
                if (DT.t2d(oldSub.start.slice(0, 12)) < DT.t2d(time.slice(0, 12))) {
                    return true;
                }
            }
            return false;
        }
        return false;
    };

    const hasSub = useCallback((sub) => tempTranslation.indexOf(sub), [tempTranslation]);

    useEffect(() => {
        const getIsTranslatingFromId = async (id) => {
            const isTranslating = await getIsTranslating(id);
            actions.setTimeLock(isTranslating);
        };
        // console.log(`Timelock: ${state.timeLock}`);
        if (state.timeLock == false) return;
        const intervalId = setInterval(() => {
            // console.log('check is translating');
            if (!state.timeLock) {
                clearInterval(intervalId);
            }
            getIsTranslatingFromId(props.id);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [state.timeLock]);

    useEffect(() => {
        resize();
        if (!resize.init) {
            resize.init = true;
            const debounceResize = debounce(resize, 500);
            window.addEventListener('resize', debounceResize);
        }
    }, [resize]);

    useEffect(() => {
        const sub = [...json(state.translation)];
        setTempTranslation(sub);
    }, [state.translation]);

    const updateTime = (sub, time, start = false) => {
        const index = hasSub(sub);
        if (index < 0) return;
        let newSub = [...tempTranslation];
        if (validateDurationFormat(time)) {
            if (start) {
                props.updateTranslation(sub, { start: time });
            } else {
                props.updateTranslation(sub, { end: time });
            }
        }
        if (start) {
            newSub[index].start = time;
        } else {
            newSub[index].end = time;
        }
        setTempTranslation(newSub);
    };

    const debouncedSave = useCallback(
        debounce((sub, nextValue, signature) => {
            props.updateTranslation(sub, {
                text: nextValue.text,
            });
            saveTranslation(nextValue, signature);
            props.setActiveTranslationDetailString(nextValue);
            props.setIsSaving(false);
        }, 2000),
        [],
    );

    const updateText = async (sub, text) => {
        props.setIsSaving(true);
        const index = hasSub(sub);
        if (index < 0) return;
        let newSub = [...tempTranslation];
        newSub[index].text = text;
        debouncedSave(sub, newSub[index], props.user.signature);
        setTempTranslation(newSub);
    };

    useEffect(() => {
        if (currentIndex != -1 && props.playing) {
            document.querySelectorAll('[id^=translation-box-]').forEach((box) => {
                box.classList.remove('segment-highlight');
                box.style.borderColor = 'inherit';
            });
            document.querySelectorAll('[id^=translation-span-]').forEach((box) => {
                box.classList.remove('plain-text-highlight');
                box.style.borderColor = 'inherit';
            });
            let element = document.getElementById('translation-box-' + currentIndex);
            if (element != null) {
                element.classList.add('segment-highlight');
                element.style.borderColor = tempTranslation[currentIndex].speaker
                    ? tempTranslation[currentIndex].speaker.speaker_color
                    : '#17BDB8';
            }

            element = document.getElementById('translation-span-' + currentIndex);
            if (element != null) {
                element.classList.add('plain-text-highlight');
            }

            let scrollableContainer = document.getElementById('subtitles');
            try {
                scrollableContainer.scroll({
                    top: document.getElementById(`translation-box-${currentIndex}`).offsetTop, //scroll to the bottom of the element
                    behavior: 'smooth', //auto, smooth, initial, inherit
                });
            } catch (e) {}

            if (scrollableContainer) {
                scrollableContainer = document.getElementById('scrollable-container-translate');
                try {
                    scrollableContainer.scroll({
                        top: document.getElementById(`translation-box-${currentIndex}`).offsetTop, //scroll to the bottom of the element
                        behavior: 'smooth', //auto, smooth, initial, inherit
                    });
                } catch (e) {}
            }
            try {
                scrollableContainer = document.getElementById('plain-text');
                scrollableContainer.scroll({
                    top: document.getElementById(`translation-span-${currentIndex}`).offsetTop, //scroll to the bottom of the element
                    behavior: 'smooth', //auto, smooth, initial, inherit
                });
            } catch (e) {}
            // const titleElement = document.getElementById('translation-box-' + currentIndex);
            // if (titleElement != null) {
            //     titleElement.scrollIntoView({ behavior: 'smooth' });
            // }
        }
    }, [currentIndex, tempTranslation, props.playing]);

    useEffect(() => {
        if (currentIndex != -1 && props.miniPlaying) {
            document.querySelectorAll('[id^=workbench-translation-box-]').forEach((box) => {
                box.classList.remove('segment-highlight');
                box.style.borderColor = 'inherit';
            });
            let element = document.getElementById('workbench-translation-box-' + currentIndex);
            if (element != null) {
                element.classList.add('segment-highlight');
                element.style.borderColor = tempTranslation[currentIndex].speaker
                    ? tempTranslation[currentIndex].speaker.speaker_color
                    : '#17BDB8';
            }
        }
    }, [currentIndex, tempTranslation, props.miniPlaying]);

    const highlightSegment = (s, i) => {
        if (!props.playing && !props.miniPlaying) {
            let prefix = '';
            if (props.parent === 'workbench') {
                prefix = 'workbench-';
            }
            document.querySelectorAll(`[id^=${prefix}subtitle-box-]`).forEach((box) => {
                box.classList.remove('segment-highlight');
                box.style.borderColor = 'inherit';
            });
            document.querySelectorAll(`[id^=${prefix}translation-box-]`).forEach((box) => {
                box.classList.remove('segment-highlight');
                box.style.borderColor = 'inherit';
            });
            let cardId = `${prefix}translation-box-${s.id}`;
            document.getElementById(cardId).classList.add('segment-highlight');
            document.getElementById(cardId).style.borderColor = s.speaker ? s.speaker.speaker_color : '#17BDB8';
            try {
                cardId = `${prefix}subtitle-box-${s.id}`;
                document.getElementById(cardId).classList.add('segment-highlight');
                document.getElementById(cardId).style.borderColor = s.speaker ? s.speaker.speaker_color : '#17BDB8';
            } catch (e) {}

            if (props.isTranslation) {
                document.getElementById(`translation-card-${s.id}`).focus();
            }
        }
    };

    return (
        <>
            {props.translationLoading ? (
                <>
                    {[...Array(5)].map((x, i) => (
                        <div
                            key={`translation-loading-${i}`}
                            className={`p-5 rounded-3px ${parent}-box bg-box-dark`}
                            style={{
                                position: 'relative',
                            }}
                        >
                            <Skeleton width="50%" />
                            <Skeleton />
                        </div>
                    ))}
                </>
            ) : (
                <div
                    className={`margin-top-0-mobile flex flex-col ${props.parent === 'workbench' ? 'gap-5' : 'gap-2'}`}
                >
                    {tempTranslation && (
                        <ViewportList ref={props.listRef} viewportRef={props.scrollRef} items={tempTranslation}>
                            {(s, i) => {
                                if (s.text === null) {
                                    s.text = '';
                                }
                                if (
                                    s.text != null &&
                                    s.text.toLowerCase().indexOf(props.targetQuery) >= 0 &&
                                    (!props.isTranslation ||
                                        (props.isTranslation &&
                                            state.subtitle[i].text.toLowerCase().indexOf(props.sourceQuery) >= 0))
                                ) {
                                    return (
                                        <div
                                            key={`${props.parent === 'workbench' ? 'workbench-' : ''}translation-box-${
                                                s.id
                                            }`}
                                            id={`${props.parent === 'workbench' ? 'workbench-' : ''}translation-box-${
                                                s.id
                                            }`}
                                            onMouseEnter={() => {
                                                setMouseEnter(true);
                                                setHoverIndex(i);
                                            }}
                                            onClick={() => {
                                                actions.setActiveTranslationDetailId(s.id);
                                                props.setActiveTranslationDetailString(s.text);
                                                props.setSegmentIndex(i);
                                                highlightSegment(s, i);
                                            }}
                                            onMouseLeave={() => {
                                                setMouseEnter(false);
                                                setHoverIndex(null);
                                            }}
                                            className={`p-5 ${
                                                props.checkTranslation(s) ? 'illegal' : 'bg-box-dark'
                                            } rounded-3px ${props.parent}-box`}
                                            style={{
                                                position: 'relative',
                                                overflow: 'auto',
                                                overflowY: 'hidden',
                                            }}
                                        >
                                            {!props.isTranslation ? (
                                                <div className="flex items-center">
                                                    <TimeInput
                                                        updateTranslation={props.updateTranslation}
                                                        translationState={true}
                                                        access={props.access}
                                                        s={s}
                                                        setIsSaving={props.setIsSaving}
                                                        signature={props.user.signature}
                                                        translatedLanguage={props.translatedLanguage}
                                                    />
                                                    {mouseEnter &&
                                                    hoverIndex === i &&
                                                    props.access &&
                                                    props.config.box_highlight &&
                                                    (props.access.accessMapping.role.toLowerCase() === 'owner' ||
                                                        props.access.accessMapping.role.toLowerCase() === 'editor') &&
                                                    state.voiceOverAudio === null ? (
                                                        <button
                                                            type="button"
                                                            className="ml-2 bg-box-content-dark text-white p-1 rounded-3px flex"
                                                            style={{
                                                                height: '24px',
                                                            }}
                                                        >
                                                            <FormatMenu
                                                                i={i}
                                                                setFormatId={setFormatId}
                                                                formatId={formatId}
                                                                s={s}
                                                                updateText={updateText}
                                                                config={props.config}
                                                            />
                                                        </button>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {mouseEnter &&
                                                    hoverIndex === i &&
                                                    props.access &&
                                                    (props.access.accessMapping.role.toLowerCase() === 'owner' ||
                                                        props.access.accessMapping.role.toLowerCase() === 'editor') ? (
                                                        <button
                                                            type="button"
                                                            className="ml-2 bg-box-content-dark text-white p-1 rounded-3px flex"
                                                            style={{
                                                                height: '24px',
                                                            }}
                                                        >
                                                            <SpeakerMenu
                                                                i={i}
                                                                getSpeakers={props.getSpeakers}
                                                                id={props.id}
                                                                translation={true}
                                                                speakers={props.speakers}
                                                                setSpeakers={props.setSpeakers}
                                                                setFormatId={setFormatId}
                                                                formatId={formatId}
                                                                s={s}
                                                                updateText={updateText}
                                                                config={props.config}
                                                            />
                                                        </button>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            <div className={` ${!props.isTranslation ? 'mt-3' : ''}`}>
                                                {!props.isTranslation && formatId.includes(i) ? (
                                                    <EditorComponent
                                                        {...props}
                                                        setFormatId={setFormatId}
                                                        formatId={formatId}
                                                        i={i}
                                                        updateText={updateText}
                                                        s={s}
                                                    />
                                                ) : (
                                                    <span
                                                        onClick={() => {
                                                            actions.setActiveTranslationDetailId(s.id);
                                                            props.setActiveTranslationDetailString(s.text);
                                                            props.setSegmentIndex(i);
                                                            highlightSegment(s, i);
                                                        }}
                                                    >
                                                        <StudioTranslationCardInput
                                                            tempTranslation={tempTranslation}
                                                            setTempTranslation={setTempTranslation}
                                                            highlightSegment={highlightSegment}
                                                            s={s}
                                                            i={i}
                                                            {...props}
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }
                            }}
                        </ViewportList>
                    )}
                </div>
            )}
        </>
    );
}
