import {ChevronDown, Clock} from "react-feather";

const handleClickAccordion = (e) => {
    let content = e.target.nextElementSibling;
    if (!content) return;
    let currentHeight = content.offsetHeight;
    let realHeight = content.scrollHeight;

    if (currentHeight === 0) {
        content.style.maxHeight = realHeight + 'px';
        content.classList.add('is--open');
        e.target.classList.add('is--open');
    } else {
        content.style.maxHeight = realHeight + 'px';
        setTimeout(() => (content.style.maxHeight = '0'), 100);
        content.classList.remove('is--open');
        e.target.classList.remove('is--open');
    }
};


const CompareFeatures = (props) => {
    return (
        <>
            <div id="div_block-176-672-1-1" className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                <div className="ct-div-block price-row__title flex--row--center">
                    <div className="ct-text-block">
                        <span className="ct-span">Estimated delivery time</span>
                    </div>
                    <div
                        id="div_block-180-672-1-1"
                        className="ct-div-block price-row__title-tooltip"
                    >
                        <div
                            id="fancy_icon-181-672-1-1"
                            className="ct-fancy-icon"
                            data-id="fancy_icon-181-672"
                        >
                            <svg
                                id="svg-fancy_icon-181-672-1-1"
                                data-id="svg-fancy_icon-181-672"
                            >
                                <use xlinkHref="#Lexigoquestion"></use>
                            </svg>
                        </div>
                        <div
                            id="div_block-182-672-1-1"
                            className="ct-div-block price-row__tooltip-container"
                        >
                            <p
                                id="text_block-183-672-1-1"
                                className="ct-text-block price-row__tooltip-text"
                                data-id="text_block-183-672"
                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    LEXIGO delivers 98%
                                                                                                    of orders before the
                                                                                                    estimated delivery
                                                                                                    time. If you require
                                                                                                    a guaranteed
                                                                                                    delivery time or
                                                                                                    have more urgent
                                                                                                    requirements, don't
                                                                                                    hesitate to get in
                                                                                                    touch with
                                                                                                    hi@lexigo.com
                                                                                                </span>
                            </p>
                            <div
                                id="div_block-185-672-1-1"
                                className="ct-div-block lines-container"
                                data-id="div_block-185-672"
                            >
                                <div
                                    id="code_block-186-672-1-1"
                                    className="ct-code-block lines"
                                    data-id="code_block-186-672"
                                >
                                    <svg>
                                        <use xlinkHref="#lines-1"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="div_block-187-672-5-1"
                    className="ct-div-block flex--row--center"
                    data-id="div_block-187-672"
                >
                    <div
                        id=""
                        className="ct-text-block flex items-center"
                    >
                        <Clock className="w-4 h-4 mr-1"/>
                        <span
                            id=""
                            className="ct-span t--14 primetimedelivery"
                        >
                                                                                            {props.pricing.prime_time}
                                                                                        </span>
                    </div>
                </div>
                <div
                    id="div_block-187-672-5-1"
                    className="ct-div-block flex--row--center"
                    data-id="div_block-187-672"
                >
                    <div
                        id=""
                        className="ct-text-block flex items-center"
                    >
                        <Clock className="w-4 h-4 mr-1"/>
                        <span
                            id=""
                            className="ct-span t--14 protimedelivery"
                        >
                                                                                            {props.pricing.pro_time}
                                                                                        </span>
                    </div>
                </div>
                <div
                    id="div_block-187-672-5-1"
                    className="ct-div-block flex--row--center"
                    data-id="div_block-187-672"
                >
                    <div
                        id=""
                        className="ct-text-block flex items-center"
                    >
                        <Clock className="w-4 h-4 mr-1"/>
                        <span
                            id=""
                            className="ct-span t--14 plustimedelivery"
                        >
                                                                                            {props.pricing.plus_time}
                                                                                        </span>
                    </div>
                </div>
                <div
                    id="div_block-187-672-5-1"
                    className="ct-div-block flex--row--center"
                    data-id="div_block-187-672"
                >
                    <div
                        id=""
                        className="ct-text-block flex items-center"
                    >
                        <Clock className="w-4 h-4 mr-1"/>
                        <span
                            id=""
                            className="ct-span t--14 puretimedelivery"
                        >
                                                                                            {props.pricing.pure_time}
                                                                                        </span>
                    </div>
                </div>
            </div>

            <div className="compare-features-overlay rounded-3px">
                <div className="compare-features-header flex items-center p-5" style={{
                    padding: '1.25rem 16px',
                }}>
                    <h4 className="title--medium-24 ">FAQ</h4>
                </div>
            </div>

            <div className="oxy-dynamic-list pricing-table-overlay">
                <div
                    id="div_block-139-672-1"
                    className="ct-div-block"
                    data-id="div_block-139-672"
                >
                    <div
                        id="div_block-140-672-1"
                        className="price-table-section bg-lexigo-gray border"
                        style={{borderColor: '#e2e8f0'}}
                        onClick={handleClickAccordion}
                        data-id="div_block-140-672"
                    >
                        <h5
                            id="headline-141-672-1"
                            className="ct-headline title--medium-20"
                            data-id="headline-141-672"
                        >
                            <span
                                id="span-142-672-1"
                                className="ct-span"
                                data-id="span-142-672"
                            >
                                What's included in my order?
                            </span>
                        </h5>
                        <ChevronDown className="collapse-arrow w-6 h-6"/>
                    </div>
                    <div
                        id="collapseEssentials"
                        className="oxy-dynamic-list price-table-rows"
                        data-id="_dynamic_list-143-672"
                        style={{maxHeight: 0}}
                    >
                        <div
                            id="div_block-144-672-2-1"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-2-1"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-2-1"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-2-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-2-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Advanced-AI integration
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-2-1"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-2-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-2-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-2-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-2-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-2-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Backed by
                                                                                                    advanced-AI,
                                                                                                    professional human
                                                                                                    translators* work on
                                                                                                    your content to
                                                                                                    produce faster and
                                                                                                    more accurate
                                                                                                    translated content
                                                                                                    (*excludes Pure
                                                                                                    pricing).
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-2-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-2-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-2-1"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-2-1"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-2-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-2-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Advanced-AI integration
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-2-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-2-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Backed by
                                                                                                    advanced-AI,
                                                                                                    professional human
                                                                                                    translators* work on
                                                                                                    your content to
                                                                                                    produce faster and
                                                                                                    more accurate
                                                                                                    translated content
                                                                                                    (*excludes Pure
                                                                                                    pricing).
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-3-1"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-3-1"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-3-1"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-3-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-3-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Automatic Quality Assurance
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-1"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Get over 130
                                                                                                    automatic quality
                                                                                                    assurance checks
                                                                                                    across the entire
                                                                                                    translation process.
                                                                                                    From pre-translation
                                                                                                    to final delivery,
                                                                                                    our flagship CORE
                                                                                                    technology checks
                                                                                                    for spelling,
                                                                                                    grammar, consistency
                                                                                                    and more.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Automatic Quality Assurance
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-3-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-3-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Get over 130
                                                                                                    automatic quality
                                                                                                    assurance checks
                                                                                                    across the entire
                                                                                                    translation process.
                                                                                                    From pre-translation
                                                                                                    to final delivery,
                                                                                                    our flagship CORE
                                                                                                    technology checks
                                                                                                    for spelling,
                                                                                                    grammar, consistency
                                                                                                    and more.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile pure-color">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-4-1"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-4-1"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-4-1"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-4-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-4-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Dedicated Customer Success
                                                                                            Manager
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-4-1"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-4-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-4-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-4-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-4-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-4-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    A dedicated Customer
                                                                                                    Success Manager will
                                                                                                    be there to help
                                                                                                    with any questions
                                                                                                    related to your
                                                                                                    order and ensure
                                                                                                    that your order is
                                                                                                    processed and
                                                                                                    completed
                                                                                                    successfully.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-4-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-4-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-4-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-4-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Dedicated Customer Success
                                                                                            Manager
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-4-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-4-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    A dedicated Customer
                                                                                                    Success Manager will
                                                                                                    be there to help
                                                                                                    with any questions
                                                                                                    related to your
                                                                                                    order and ensure
                                                                                                    that your order is
                                                                                                    processed and
                                                                                                    completed
                                                                                                    successfully.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-5-1"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-5-1"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-5-1"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-5-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-5-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Dedicated Project Success
                                                                                            Manager
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-5-1"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-5-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-5-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-5-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-5-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-5-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Beyond Customer
                                                                                                    Success, a Project
                                                                                                    Success Manager will
                                                                                                    ensure the
                                                                                                    performance of
                                                                                                    Quality Assurance,
                                                                                                    address cultural
                                                                                                    issues, and flag
                                                                                                    translation-critical
                                                                                                    points during the
                                                                                                    process. Your
                                                                                                    project will be
                                                                                                    managed and finished
                                                                                                    successfully.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-5-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-5-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    id="div_block-224-672-5-1"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-5-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-5-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Dedicated Project Success
                                                                                            Manager
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-5-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-5-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Beyond Customer
                                                                                                    Success, a Project
                                                                                                    Success Manager will
                                                                                                    ensure the
                                                                                                    performance of
                                                                                                    Quality Assurance,
                                                                                                    address cultural
                                                                                                    issues, and flag
                                                                                                    translation-critical
                                                                                                    points during the
                                                                                                    process. Your
                                                                                                    project will be
                                                                                                    managed and finished
                                                                                                    successfully.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div
                                        id="text_block-178-672-6-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-6-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Dedicated Client Success
                                                                                            Manager
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-6-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-6-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-6-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-6-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-6-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    As your Translation
                                                                                                    Strategist, your
                                                                                                    Client Success
                                                                                                    Manager assesses
                                                                                                    your content and
                                                                                                    employs the best
                                                                                                    translation approach
                                                                                                    to guarantee
                                                                                                    success.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-6-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-6-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-6-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-6-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-6-1"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-6-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-6-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-6-1"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    id="div_block-224-672-6-1"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-6-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-6-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Dedicated Client Success
                                                                                            Manager
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-6-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-6-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    As your Translation
                                                                                                    Strategist, your
                                                                                                    Client Success
                                                                                                    Manager assesses
                                                                                                    your content and
                                                                                                    employs the best
                                                                                                    translation approach
                                                                                                    to guarantee
                                                                                                    success.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="oxy-dynamic-list pricing-table-overlay">
                <div
                    id="div_block-139-672-3"
                    className="ct-div-block"
                    data-id="div_block-139-672"
                >
                    <div
                        id="div_block-140-672-3"
                        className="price-table-section bg-lexigo-gray border"
                        style={{borderColor: '#e2e8f0'}}
                        onClick={handleClickAccordion}
                        data-id="div_block-140-672"
                    >
                        <h5
                            id="headline-141-672-3"
                            className="ct-headline title--medium-20"
                            data-id="headline-141-672"
                        >
                           <span
                               id="span-142-672-3"
                               className="ct-span"
                               data-id="span-142-672"
                           >
                               Which order option is most suitable for my content?
                           </span>
                        </h5>
                        <ChevronDown className="collapse-arrow w-6 h-6"/>
                    </div>
                    <div
                        id="collapseSuitability"
                        className="oxy-dynamic-list price-table-rows"
                        data-id="_dynamic_list-143-672"
                        style={{maxHeight: 0}}
                    >
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Digital + Ecommerce
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    Best for user reviews, customer
                                    support, online help and FAQ,
                                    product support, survey results,
                                    product descriptions, formal and
                                    legal supplier and merchant
                                    communication
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    Best for user reviews, customer
                                    support, online help and FAQ,
                                    product support, survey results,
                                    product descriptions, supplier and
                                    merchant communication
                                </div>
                                <div
                                    id="div_block-192-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    Best for user reviews, customer
                                    support, online help and FAQ,
                                    product support, survey results,
                                    product descriptions, informal
                                    supplier and merchant communication
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    Best for understanding online help
                                    enquiries, product support, survey
                                    results, informal supplier and
                                    merchant communication, informal
                                    stakeholder communication
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Digital + Ecommerce
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="font-size-12"
                                            >
                                                Best for user reviews,
                                                customer support, online
                                                help and FAQ, product
                                                support, survey results,
                                                product descriptions,
                                                formal and legal
                                                supplier and merchant
                                                communication
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="font-size-12"
                                            >
                                                Best for user reviews,
                                                customer support, online
                                                help and FAQ, product
                                                support, survey results,
                                                product descriptions,
                                                supplier and merchant
                                                communication
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="font-size-12"
                                            >
                                                Best for user reviews,
                                                customer support, online
                                                help and FAQ, product
                                                support, survey results,
                                                product descriptions,
                                                informal supplier and
                                                merchant communication
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="font-size-12"
                                                data-id="fancy_icon-188-672"
                                            >
                                                Best for understanding
                                                online help enquiries,
                                                product support, survey
                                                results, informal
                                                supplier and merchant
                                                communication, informal
                                                stakeholder
                                                communication
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Marketing and Communication
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    Product and service descriptions,
                                    blogs, articles, captions and
                                    subtitles, market research and
                                    responses, survey results,
                                    production ready content, emails,
                                    lead-gen driven blogs, guides
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    Product and service descriptions,
                                    blogs, articles, captions and
                                    subtitles, market research and
                                    responses, survey results,
                                    production ready content, emails,
                                    lead-gen driven blogs, guides
                                </div>
                                <div
                                    id="div_block-192-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    Captions and subtitles, market
                                    research and responses, survey
                                    results, draft content, emails,
                                    lead-gen driven blogs, guides
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    Market research and responses,
                                    survey results, draft content,
                                    emails, lead-gen driven blogs, first
                                    draft marketing copy
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Marketing and Communication
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="font-size-12"
                                            >
                                                Product and service
                                                descriptions, blogs,
                                                articles, captions and
                                                subtitles, market
                                                research and responses,
                                                survey results,
                                                production ready
                                                content, emails,
                                                lead-gen driven blogs,
                                                guides
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="font-size-12"
                                            >
                                                Product and service
                                                descriptions, blogs,
                                                articles, captions and
                                                subtitles, market
                                                research and responses,
                                                survey results,
                                                production ready
                                                content, emails,
                                                lead-gen driven blogs,
                                                guides
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="font-size-12"
                                            >
                                                Captions and subtitles,
                                                market research and
                                                responses, survey
                                                results, draft content,
                                                emails, lead-gen driven
                                                blogs, guides
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="font-size-12"
                                            >
                                                Market research and
                                                responses, survey
                                                results, draft content,
                                                emails, lead-gen driven
                                                blogs, first draft
                                                marketing copy
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Enterprise and Business
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="t-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    Marketing material, brochures,
                                    websites, software, apps, company
                                    profiles and all market-facing
                                    content, contracts
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="t-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    Marketing material, brochures,
                                    websites, software, apps, company
                                    profiles and all market-facing
                                    content
                                </div>
                                <div
                                    id="div_block-192-672-1-3"
                                    className="t-div-block price-row__title flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    Internal communications,
                                    intercompany emails, first drafts,
                                    informal supplier and merchant
                                    communication
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    Content discovery, internal
                                    communications, intercompany emails,
                                    first draft reports and other copy,
                                    informal supplier and merchant
                                    communication
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Enterprise and Business
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="font-size-12"
                                            >
                                                Marketing material,
                                                brochures, websites,
                                                software, apps, company
                                                profiles and all
                                                market-facing content,
                                                contracts
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="font-size-12"
                                            >
                                                Marketing material,
                                                brochures, websites,
                                                software, apps, company
                                                profiles and all
                                                market-facing content
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="font-size-12"
                                            >
                                                Internal communications,
                                                intercompany emails,
                                                first drafts, informal
                                                supplier and merchant
                                                communication
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile flex-col">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="font-size-12"
                                            >
                                                Content discovery,
                                                internal communications,
                                                intercompany emails,
                                                first draft reports and
                                                other copy, informal
                                                supplier and merchant
                                                communication
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Government and Public Sector
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    News, community announcements,
                                    country-post content, marketing
                                    material, fact sheets, explainer
                                    videos and subtitles, newsletters
                                    and other market-facing content
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    News, community announcements,
                                    country-post content, marketing
                                    material, newsletters
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    First draft translations, content
                                    discovery and preparation, light
                                    human review of machine translation,
                                    informal stakeholder communication,
                                    intercompany emails and any informal
                                    content
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    Content discovery, internal
                                    communications, intercompany emails,
                                    first draft reports and other copy,
                                    informal supplier and merchant
                                    communication
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Government and Public Sector
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing-table-row--mobile-data flex-col prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="font-size-12"
                                            >
                                                News, community
                                                announcements,
                                                country-post content,
                                                marketing material, fact
                                                sheets, explainer videos
                                                and subtitles,
                                                newsletters and other
                                                market-facing content
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data flex-col pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="font-size-12"
                                            >
                                                News, community
                                                announcements,
                                                country-post content,
                                                marketing material,
                                                newsletters
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data flex-col plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="font-size-12"
                                            >
                                                First draft
                                                translations, content
                                                discovery and
                                                preparation, light human
                                                review of machine
                                                translation, informal
                                                stakeholder
                                                communication,
                                                intercompany emails and
                                                any informal content
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data flex-col pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="font-size-12"
                                            >
                                                Content discovery,
                                                internal communications,
                                                intercompany emails,
                                                first draft reports and
                                                other copy, informal
                                                supplier and merchant
                                                communication
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Medical and Legal
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-3"
                                    className="ct-div-block price-row__title flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    Contracts, official documentation,
                                    documents required for court or
                                    legal proceedings, technical medical
                                    and legal information and other
                                    medical or legal translation
                                    requirements
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    Certificates, certified documents,
                                    medical and legal reports, personal
                                    documentation such as passports,
                                    family records. medical history,
                                    drivers licenses, marriage
                                    certificates, birth certificates and
                                    more
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    First draft translations, content
                                    discovery and preparation, light
                                    human review of machine translation,
                                    informal stakeholder communication,
                                    intercompany emails and any informal
                                    content
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    Content discovery, internal
                                    communications, intercompany emails,
                                    first draft reports and other copy,
                                    informal supplier and merchant
                                    communication
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Medical and Legal
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing-table-row--mobile-data flex-col prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="font-size-12"
                                            >
                                                Contracts, official
                                                documentation, documents
                                                required for court or
                                                legal proceedings,
                                                technical medical and
                                                legal information and
                                                other medical or legal
                                                translation requirements
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data flex-col pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="font-size-12"
                                            >
                                                Certificates, certified
                                                documents, medical and
                                                legal reports, personal
                                                documentation such as
                                                passports, family
                                                records. medical
                                                history, drivers
                                                licenses, marriage
                                                certificates, birth
                                                certificates and more
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data flex-col plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="font-size-12"
                                            >
                                                First draft
                                                translations, content
                                                discovery and
                                                preparation, light human
                                                review of machine
                                                translation, informal
                                                stakeholder
                                                communication,
                                                intercompany emails and
                                                any informal content
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data flex-col pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="font-size-12"
                                            >
                                                Content discovery,
                                                internal communications,
                                                intercompany emails,
                                                first draft reports and
                                                other copy, informal
                                                supplier and merchant
                                                communication
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="oxy-dynamic-list pricing-table-overlay">
                <div
                    id="div_block-139-672-1"
                    className="ct-div-block"
                    data-id="div_block-139-672"
                >
                    <div
                        id="div_block-140-672-1"
                        className="price-table-section bg-lexigo-gray border"
                        style={{borderColor: '#e2e8f0'}}
                        onClick={handleClickAccordion}
                        data-id="div_block-140-672"
                    >
                        <h5
                            id="headline-141-672-1"
                            className="ct-headline title--medium-20"
                            data-id="headline-141-672"
                        >
                           <span
                               id="span-142-672-1"
                               className="ct-span"
                               data-id="span-142-672"
                           >
                               How can I trust the quality of your translations?
                           </span>
                        </h5>
                        <ChevronDown className="collapse-arrow w-6 h-6 active"/>
                    </div>
                    <div
                        id="collapseTranslator"
                        className="oxy-dynamic-list price-table-rows"
                        data-id="_dynamic_list-143-672"
                        style={{maxHeight:0}}
                    >
                        <div
                            id="div_block-144-672-1-1"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-1"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Subject Matter Experience
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-1"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Subject matter
                                                                                                    experts translate
                                                                                                    your content. Our
                                                                                                    adopted and strict
                                                                                                    approach to working
                                                                                                    with a limited set
                                                                                                    of industries means
                                                                                                    we only recruit
                                                                                                    linguists with
                                                                                                    subject matter
                                                                                                    experience in the
                                                                                                    industries we work
                                                                                                    with, resulting in
                                                                                                    higher quality
                                                                                                    translations.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-5-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id=""
                                        className="ct-text-block flex items-center"
                                    >
                                                                                        <span
                                                                                            id=""
                                                                                            className="ct-span t--14"
                                                                                        >
                                                                                            Experts
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-5-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id=""
                                        className="ct-text-block flex items-center"
                                    >
                                                                                        <span
                                                                                            id=""
                                                                                            className="ct-span t--14"
                                                                                        >
                                                                                            Experts
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-5-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id=""
                                        className="ct-text-block flex items-center"
                                    >
                                                                                        <span
                                                                                            id=""
                                                                                            className="ct-span t--14"
                                                                                        >
                                                                                            Generalists
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-5-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id=""
                                        className="ct-text-block flex items-center"
                                    >
                                                                                        <span
                                                                                            id=""
                                                                                            className="ct-span t--14"
                                                                                        >
                                                                                            Neural Machine
                                                                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Subject Matter Experience
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-1-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-231-672-1-1"
                                                    data-id="svg-fancy_icon-231-672"
                                                >
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p
                                                id="text_block-232-672-1-1"
                                                className="ct-text-block t--14"
                                            >
                                                                                                <span
                                                                                                    id="span-233-672-1-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Subject matter
                                                                                                    experts translate
                                                                                                    your content. Our
                                                                                                    adopted and strict
                                                                                                    approach to working
                                                                                                    with a limited set
                                                                                                    of industries means
                                                                                                    we only recruit
                                                                                                    linguists with
                                                                                                    subject matter
                                                                                                    experience in the
                                                                                                    industries we work
                                                                                                    with, resulting in
                                                                                                    higher quality
                                                                                                    translations.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div
                                            id="div_block-246-672-1-1"
                                            className="ct-div-block pricing-table-row--mobile-data prime-mobile"
                                        >
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="div_block-187-672-5-1"
                                                className="ct-div-block flex--row--center"
                                                data-id="div_block-187-672"
                                            >
                                                <div
                                                    id=""
                                                    className="ct-text-block"
                                                >
                                                                                                    <span
                                                                                                        id=""
                                                                                                        className="ct-span t--14"
                                                                                                    >
                                                                                                        Experts
                                                                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="div_block-246-672-1-1"
                                            className="ct-div-block pricing-table-row--mobile-data pro-mobile"
                                        >
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="div_block-187-672-5-1"
                                                className="ct-div-block flex--row--center"
                                                data-id="div_block-187-672"
                                            >
                                                <div
                                                    id=""
                                                    className="ct-text-block"
                                                >
                                                                                                    <span
                                                                                                        id=""
                                                                                                        className="ct-span t--14"
                                                                                                    >
                                                                                                        Experts
                                                                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="div_block-240-672-1-1"
                                            className="ct-div-block pricing-table-row--mobile-data plus-mobile"
                                        >
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="div_block-187-672-5-1"
                                                className="ct-div-block flex--row--center"
                                                data-id="div_block-187-672"
                                            >
                                                <div
                                                    id=""
                                                    className="ct-text-block"
                                                >
                                                                                                    <span
                                                                                                        id=""
                                                                                                        className="ct-span t--14"
                                                                                                    >
                                                                                                        Generalists
                                                                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="div_block-187-672-5-1"
                                                className="ct-div-block flex--row--center"
                                                data-id="div_block-187-672"
                                            >
                                                <div
                                                    id=""
                                                    className="ct-text-block"
                                                >
                                                                                                    <span
                                                                                                        id=""
                                                                                                        className="ct-span t--14"
                                                                                                    >
                                                                                                        Neural Machine
                                                                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-2-1"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-2-1"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-2-1"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-2-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-2-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            SmartSelect™
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-2-1"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-2-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-2-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-2-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-2-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-2-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    LEXIGO's SmartSelect
                                                                                                    algorithm ranks
                                                                                                    individual scores
                                                                                                    for translators or
                                                                                                    Enterprise Neural
                                                                                                    Machine Translation
                                                                                                    engines based on
                                                                                                    critical criteria
                                                                                                    vital to the
                                                                                                    project's success.
                                                                                                    When assigning
                                                                                                    translators, LEXIGO
                                                                                                    Project Managers
                                                                                                    cross-check
                                                                                                    SmartSelect™ results
                                                                                                    and allocate
                                                                                                    translators
                                                                                                    accordingly with one
                                                                                                    click.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-2-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-2-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-2-1"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-2-1"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-2-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-2-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            SmartSelect™
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-2-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-2-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    LEXIGO's SmartSelect
                                                                                                    algorithm ranks
                                                                                                    individual scores
                                                                                                    for translators or
                                                                                                    Enterprise Neural
                                                                                                    Machine Translation
                                                                                                    engines based on
                                                                                                    critical criteria
                                                                                                    vital to the
                                                                                                    project's success.
                                                                                                    When assigning
                                                                                                    translators, LEXIGO
                                                                                                    Project Managers
                                                                                                    cross-check
                                                                                                    SmartSelect™ results
                                                                                                    and allocate
                                                                                                    translators
                                                                                                    accordingly with one
                                                                                                    click.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-3-1"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-3-1"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-3-1"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-3-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-3-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Qualified Translators
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-2-1"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-2-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-2-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-2-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-2-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-2-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Your translations
                                                                                                    are performed by
                                                                                                    certified
                                                                                                    translators who are
                                                                                                    qualified
                                                                                                    professionals in
                                                                                                    their respective
                                                                                                    fields.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-2-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-2-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Qualified Translators
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-3-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-3-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Your translations
                                                                                                    are performed by
                                                                                                    certified
                                                                                                    translators who are
                                                                                                    qualified
                                                                                                    professionals in
                                                                                                    their respective
                                                                                                    fields.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile pure-color">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-4-1"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-4-1"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-4-1"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-4-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-4-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Qualified Native Translators
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-4-1"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-4-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-4-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-4-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-4-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-4-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Your translations
                                                                                                    are performed by
                                                                                                    certified
                                                                                                    translators who are
                                                                                                    qualified
                                                                                                    professionals in
                                                                                                    their respective
                                                                                                    fields and are
                                                                                                    native to the target
                                                                                                    language.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-4-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-4-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-4-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-4-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Qualified Native Translators
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-4-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-4-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Your translations
                                                                                                    are performed by
                                                                                                    certified
                                                                                                    translators who are
                                                                                                    qualified
                                                                                                    professionals in
                                                                                                    their respective
                                                                                                    fields and are
                                                                                                    native to the target
                                                                                                    language.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-5-1"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-5-1"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-5-1"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-5-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-5-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Extra Proofreading
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-5-1"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-5-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-5-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-5-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-5-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-5-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    An additional round
                                                                                                    of proofreading is
                                                                                                    conducted before
                                                                                                    finalising your
                                                                                                    translation.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-5-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-5-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-3-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-3-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg>
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    id="div_block-224-672-5-1"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-5-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-5-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Extra Proofreading
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-5-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-5-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    An additional round
                                                                                                    of proofreading is
                                                                                                    conducted before
                                                                                                    finalising your
                                                                                                    translation.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div
                                        id="text_block-178-672-6-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-6-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Separate Checking and
                                                                                            Editing
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-6-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-6-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-6-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-6-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-6-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    A separate round of
                                                                                                    checking and editing
                                                                                                    is conducted by a
                                                                                                    native and qualified
                                                                                                    professional
                                                                                                    translator.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-6-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-6-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-6-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-6-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-6-1"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    id="div_block-224-672-6-1"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-6-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-6-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Separate Checking and
                                                                                            Editing
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-6-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-6-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    A separate round of
                                                                                                    checking and editing
                                                                                                    is conducted by a
                                                                                                    native and qualified
                                                                                                    professional
                                                                                                    translator.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div
                                        id="text_block-178-672-6-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-6-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Veteran Qualified
                                                                                            Translators
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-6-1"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-6-1"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-6-1"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-6-1"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-6-1"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Your order will be
                                                                                                    assigned a veteran
                                                                                                    translator with at
                                                                                                    least ten years of
                                                                                                    professional
                                                                                                    experience.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-6-1"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-6-1"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-6-1"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-6-1"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-6-1"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    id="div_block-224-672-6-1"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-6-1"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-6-1"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Veteran Qualified
                                                                                            Translators
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-6-1"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-6-1"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Your order will be
                                                                                                    assigned a veteran
                                                                                                    translator with at
                                                                                                    least ten years of
                                                                                                    professional
                                                                                                    experience.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="oxy-dynamic-list pricing-table-overlay">
                <div
                    id="div_block-139-672-2"
                    className="ct-div-block"
                    data-id="div_block-139-672"
                >
                    <div
                        id="div_block-140-672-2"
                        className="price-table-section bg-lexigo-gray border"
                        style={{borderColor: '#e2e8f0'}}
                        onClick={handleClickAccordion}
                        data-id="div_block-140-672"
                    >
                        <h5
                            id="headline-141-672-2"
                            className="ct-headline title--medium-20"
                            data-id="headline-141-672"
                        >
                           <span
                               id="span-142-672-2"
                               className="ct-span"
                               data-id="span-142-672"
                           >
                               What QA processes do you have?
                           </span>
                        </h5>
                        <ChevronDown className="collapse-arrow w-6 h-6"/>
                    </div>
                    <div
                        id="collapseQA"
                        className="oxy-dynamic-list price-table-rows"
                        data-id="_dynamic_list-143-672"
                        style={{maxHeight:0}}
                    >
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            ISO-17100 Certified:
                                                                                            Translation Services
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            ISO-17100 Certified:
                                                                                            Translation Services
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            ISO-9001 Certified: Quality
                                                                                            Management
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            ISO-9001 Certified: Quality
                                                                                            Management
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Original format retention
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-2"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-2"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-2"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-2"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-2"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-2"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Get your content
                                                                                                    back in the same way
                                                                                                    you provided. We
                                                                                                    support a number of
                                                                                                    file formats
                                                                                                    including .docx,
                                                                                                    .xliff, .txt, .xlsx,
                                                                                                    .csv, .html, .idml,
                                                                                                    .xml, .jpeg, .jpg,
                                                                                                    .png, .bmp,
                                                                                                    .strings, .json,
                                                                                                    .odt, .doc and more
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-2"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-2"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Original format retention
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Get your content
                                                                                                    back in the same way
                                                                                                    you provided. We
                                                                                                    support a number of
                                                                                                    file formats
                                                                                                    including .docx,
                                                                                                    .xliff, .txt, .xlsx,
                                                                                                    .csv, .html, .idml,
                                                                                                    .xml, .jpeg, .jpg,
                                                                                                    .png, .bmp,
                                                                                                    .strings, .json,
                                                                                                    .odt, .doc and more.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Number accuracy
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-2"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-2"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-2"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-2"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-2"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-2"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Quality assurance
                                                                                                    checks are
                                                                                                    automatically
                                                                                                    performed on all
                                                                                                    types of numbers,
                                                                                                    plus a manual human
                                                                                                    review is complete
                                                                                                    on Plus, Pro, and
                                                                                                    Prime packages.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-2"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-2"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Number accuracy
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Quality assurance
                                                                                                    checks are
                                                                                                    automatically
                                                                                                    performed on all
                                                                                                    types of numbers,
                                                                                                    plus a manual human
                                                                                                    review is complete
                                                                                                    on Plus, Pro, and
                                                                                                    Prime packages.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Code and tag accuracy
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-2"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-2"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-2"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-2"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-2"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-2"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Quality assurance
                                                                                                    checks are
                                                                                                    automatically
                                                                                                    performed on tags,
                                                                                                    HTML and other
                                                                                                    codes, plus a manual
                                                                                                    human review is
                                                                                                    complete on Plus,
                                                                                                    Pro and Prime
                                                                                                    packages.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-2"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-2"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Code and tag accuracy
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Quality assurance
                                                                                                    checks are
                                                                                                    automatically
                                                                                                    performed on tags,
                                                                                                    HTML and other
                                                                                                    codes, plus a manual
                                                                                                    human review is
                                                                                                    complete on Plus,
                                                                                                    Pro and Prime
                                                                                                    packages.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile ">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Glossary and term adherence
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-2"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-2"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-2"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-2"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-2"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-2"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Have a glossary or
                                                                                                    terminology bank?
                                                                                                    Provide this to us,
                                                                                                    and we'll integrate
                                                                                                    it into the
                                                                                                    translation process
                                                                                                    to ensure your
                                                                                                    content is
                                                                                                    translated on-brand
                                                                                                    and according to
                                                                                                    your tone of voice.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-2"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-2"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Glossary and term adherence
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Have a glossary or
                                                                                                    terminology bank?
                                                                                                    Provide this to us,
                                                                                                    and we'll integrate
                                                                                                    it into the
                                                                                                    translation process
                                                                                                    to ensure your
                                                                                                    content is
                                                                                                    translated on-brand
                                                                                                    and according to
                                                                                                    your tone of voice.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile ">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Integrated Grammar and
                                                                                            Spellcheck
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-2"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-2"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-2"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-2"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-2"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-2"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    In addition to
                                                                                                    rigorous human
                                                                                                    checks and edits,
                                                                                                    our integrated
                                                                                                    grammar and
                                                                                                    spellcheck across 25
                                                                                                    languages ensure
                                                                                                    nothing is missed.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-2"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-2"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Integrated Grammar and
                                                                                            Spellcheck
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    In addition to
                                                                                                    rigorous human
                                                                                                    checks and edits,
                                                                                                    our integrated
                                                                                                    grammar and
                                                                                                    spellcheck across 25
                                                                                                    languages ensure
                                                                                                    nothing is missed.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            130+ automated QA checks
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-2"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-2"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-2"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-2"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-2"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-2"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Get over 130
                                                                                                    automatic quality
                                                                                                    assurance checks
                                                                                                    across the entire
                                                                                                    translation process.
                                                                                                    From pre-translation
                                                                                                    to final delivery,
                                                                                                    our flagship CORE
                                                                                                    technology checks
                                                                                                    for spelling,
                                                                                                    grammar, consistency
                                                                                                    and more.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-2"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-2"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            130+ automated QA checks
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Get over 130
                                                                                                    automatic quality
                                                                                                    assurance checks
                                                                                                    across the entire
                                                                                                    translation process.
                                                                                                    From pre-translation
                                                                                                    to final delivery,
                                                                                                    our flagship CORE
                                                                                                    technology checks
                                                                                                    for spelling,
                                                                                                    grammar, consistency
                                                                                                    and more.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            QA check by LEXIGO Project
                                                                                            Success Manager
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-2"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-2"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-2"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-2"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-2"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-2"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Before delivery,
                                                                                                    LEXIGO Senior
                                                                                                    Project Success
                                                                                                    Managers conduct
                                                                                                    final QA checks
                                                                                                    against your order's
                                                                                                    requirements to
                                                                                                    ensure high-quality
                                                                                                    order delivery.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-2"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-2"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            QA check by LEXIGO Project
                                                                                            Success Manager
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Before delivery,
                                                                                                    LEXIGO's dedicated
                                                                                                    QA team conducts
                                                                                                    final checks against
                                                                                                    order requirements
                                                                                                    to ensure a
                                                                                                    high-quality
                                                                                                    standard of work.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            QA check by LEXIGO QA
                                                                                            Manager
                                                                                        </span>
                                    </div>
                                    <div
                                        id="div_block-180-672-1-2"
                                        className="ct-div-block price-row__title-tooltip"
                                    >
                                        <div
                                            id="fancy_icon-181-672-1-2"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-2"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div
                                            id="div_block-182-672-1-2"
                                            className="ct-div-block price-row__tooltip-container"
                                        >
                                            <p
                                                id="text_block-183-672-1-2"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span
                                                                                                    id="span-184-672-1-2"
                                                                                                    className="ct-span"
                                                                                                >
                                                                                                    Before delivery,
                                                                                                    LEXIGO Senior
                                                                                                    Project Success
                                                                                                    Managers conduct
                                                                                                    final QA checks
                                                                                                    against your order's
                                                                                                    requirements to
                                                                                                    ensure high-quality
                                                                                                    order delivery.
                                                                                                </span>
                                            </p>
                                            <div
                                                id="div_block-185-672-1-2"
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-2"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            QA check by LEXIGO QA
                                                                                            Manager
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Before delivery,
                                                                                                    LEXIGO's dedicated
                                                                                                    QA team conducts
                                                                                                    final checks against
                                                                                                    order requirements
                                                                                                    to ensure a
                                                                                                    high-quality
                                                                                                    standard of work.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Qualified Translators
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Lorem ipsum dolor
                                                                                                    sit amet,
                                                                                                    consectetur
                                                                                                    adipiscing elit.
                                                                                                    Integer rhoncus
                                                                                                    ultrices.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Qualified Native Translators
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Lorem ipsum dolor
                                                                                                    sit amet,
                                                                                                    consectetur
                                                                                                    adipiscing elit.
                                                                                                    Integer rhoncus
                                                                                                    ultrices.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-176-672-1-2"
                                className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1"
                            >
                                <div
                                    id="div_block-177-672-1-2"
                                    className="ct-div-block price-row__title flex--row--center"
                                >
                                    <div
                                        id="text_block-178-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-179-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Accuracy Guaranteed
                                                                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-2-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-189-672-2-2"
                                        className="ct-fancy-icon price-row__cross"
                                        data-id="fancy_icon-189-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-189-672-2-2"
                                            data-id="svg-fancy_icon-189-672"
                                        >
                                            <use xlinkHref="#Lexigocross"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Accuracy Guaranteed
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Extra Proofreading
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Lorem ipsum dolor
                                                                                                    sit amet,
                                                                                                    consectetur
                                                                                                    adipiscing elit.
                                                                                                    Integer rhoncus
                                                                                                    ultrices.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="div_block-144-672-1-2"
                            className="ct-div-block pricing-table-row"
                        >
                            <div
                                id="div_block-223-672-1-2"
                                className="ct-div-block pricing-table-row--mobile"
                            >
                                <button
                                    id="div_block-224-672-1-2"
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div
                                        id="text_block-225-672-1-2"
                                        className="ct-text-block"
                                    >
                                                                                        <span
                                                                                            id="span-226-672-1-2"
                                                                                            className="ct-span"
                                                                                        >
                                                                                            Separate Checking and
                                                                                            Editing
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-2"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-2"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Lorem ipsum dolor
                                                                                                    sit amet,
                                                                                                    consectetur
                                                                                                    adipiscing elit.
                                                                                                    Integer rhoncus
                                                                                                    ultrices.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-189-672-2-2"
                                                className="ct-fancy-icon price-row__cross"
                                                data-id="fancy_icon-189-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-189-672-2-2"
                                                    data-id="svg-fancy_icon-189-672"
                                                >
                                                    <use xlinkHref="#Lexigocross"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="oxy-dynamic-list pricing-table-overlay">
                <div
                    id="div_block-139-672-3"
                    className="ct-div-block"
                    data-id="div_block-139-672"
                >
                    <div
                        id="div_block-140-672-3"
                        className="price-table-section"
                        className="price-table-section bg-lexigo-gray border"
                        style={{borderColor: '#e2e8f0'}}
                        onClick={handleClickAccordion}
                        data-id="div_block-140-672"
                    >
                        <h5
                            id="headline-141-672-3"
                            className="ct-headline title--medium-20"
                            data-id="headline-141-672"
                        >
                           <span
                               id="span-142-672-3"
                               className="ct-span"
                               data-id="span-142-672"
                           >
                               Is my data safe and secure?
                           </span>
                        </h5>
                        <ChevronDown className="collapse-arrow w-6 h-6"/>
                    </div>
                    <div
                        id="collapseSecurity"
                        className="oxy-dynamic-list price-table-rows"
                        data-id="_dynamic_list-143-672"
                        style={{maxHeight: 0}}
                    >
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Secure Cloud Platform
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-1-3"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-3"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div className="ct-div-block price-row__tooltip-container">
                                            <p
                                                id="text_block-183-672-1-3"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span className="ct-span">
                                                                                                    We take your content
                                                                                                    and privacy very
                                                                                                    seriously and
                                                                                                    continuously invest
                                                                                                    in ensuring your
                                                                                                    data is safe and
                                                                                                    secure. Your data is
                                                                                                    stored with
                                                                                                    government and
                                                                                                    bank-grade secure
                                                                                                    servers, AES-256
                                                                                                    encryption and an
                                                                                                    ISO-27001 certified
                                                                                                    environment. Our
                                                                                                    servers are with
                                                                                                    Amazon Web Services,
                                                                                                    hosted in Sydney,
                                                                                                    Australia and
                                                                                                    utilise the same
                                                                                                    network used by
                                                                                                    Netflix, Slack,
                                                                                                    Adobe and more.
                                                                                                </span>
                                            </p>
                                            <div
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-3"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            Secure Cloud Platform
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    We take your content
                                                                                                    and privacy very
                                                                                                    seriously and
                                                                                                    continuously invest
                                                                                                    in ensuring your
                                                                                                    data is safe and
                                                                                                    secure. Your data is
                                                                                                    stored with
                                                                                                    government and
                                                                                                    bank-grade secure
                                                                                                    servers, AES-256
                                                                                                    encryption and an
                                                                                                    ISO-27001 certified
                                                                                                    environment. Our
                                                                                                    servers are with
                                                                                                    Amazon Web Services,
                                                                                                    hosted in Sydney,
                                                                                                    Australia and
                                                                                                    utilise the same
                                                                                                    network used by
                                                                                                    Netflix, Slack,
                                                                                                    Adobe and more.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            2048-bit encryption
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-1-3"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-3"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div className="ct-div-block price-row__tooltip-container">
                                            <p
                                                id="text_block-183-672-1-3"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span className="ct-span">
                                                                                                    End-to-end
                                                                                                    encryption. Data is
                                                                                                    encrypted at the
                                                                                                    strongest available
                                                                                                    encryption level
                                                                                                    (2048-bit).
                                                                                                </span>
                                            </p>
                                            <div
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-3"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            2048-bit encryption
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    End-to-end
                                                                                                    encryption. Data is
                                                                                                    encrypted at the
                                                                                                    strongest available
                                                                                                    encryption level
                                                                                                    (2048-bit).
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            ISO-27001 Certified (server
                                                                                            side)
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-1-3"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-3"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div className="ct-div-block price-row__tooltip-container">
                                            <p
                                                id="text_block-183-672-1-3"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span className="ct-span">
                                                                                                    ISO-27001 Certified
                                                                                                    for Information
                                                                                                    Security (server
                                                                                                    side), encompassing
                                                                                                    the following
                                                                                                    compliance
                                                                                                    certifications: ISO
                                                                                                    27017 - Cloud
                                                                                                    Information
                                                                                                    Security, ISO 27018
                                                                                                    - Processing of Data
                                                                                                    within the Cloud,
                                                                                                    ISO 27701 - Privacy
                                                                                                    Information
                                                                                                    Management System
                                                                                                    for Personally
                                                                                                    Identifiable
                                                                                                    Information.
                                                                                                </span>
                                            </p>
                                            <div
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-3"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            ISO-27001 Certified (server
                                                                                            side)
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    ISO-27001 Certified
                                                                                                    for Information
                                                                                                    Security (server
                                                                                                    side), encompassing
                                                                                                    the following
                                                                                                    compliance
                                                                                                    certifications: ISO
                                                                                                    27017 - Cloud
                                                                                                    Information
                                                                                                    Security, ISO 27018
                                                                                                    - Processing of Data
                                                                                                    within the Cloud,
                                                                                                    ISO 27701 - Privacy
                                                                                                    Information
                                                                                                    Management System
                                                                                                    for Personally
                                                                                                    Identifiable
                                                                                                    Information.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            ISO-27001 Certified
                                                                                            (organisation side)
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-1-3"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-3"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div className="ct-div-block price-row__tooltip-container">
                                            <p
                                                id="text_block-183-672-1-3"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span className="ct-span">
                                                                                                    LEXIGO is certified
                                                                                                    and regularly
                                                                                                    audited for the
                                                                                                    following
                                                                                                    internationally-recognised
                                                                                                    certifications; ISO
                                                                                                    27001 for
                                                                                                    Information Security
                                                                                                    encompassing the
                                                                                                    following compliance
                                                                                                    certifications: ISO
                                                                                                    17100 - Translation
                                                                                                    Services and ISO
                                                                                                    9001 - Quality
                                                                                                    Management.
                                                                                                </span>
                                            </p>
                                            <div
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-3"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            ISO-27001 Certified
                                                                                            (organisation side)
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    LEXIGO is certified
                                                                                                    and regularly
                                                                                                    audited for the
                                                                                                    following
                                                                                                    internationally-recognised
                                                                                                    certifications; ISO
                                                                                                    27001 for
                                                                                                    Information Security
                                                                                                    encompassing the
                                                                                                    following compliance
                                                                                                    certifications: ISO
                                                                                                    17100 - Translation
                                                                                                    Services and ISO
                                                                                                    9001 - Quality
                                                                                                    Management.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            SOC 1/ISAE 3402, SOC 2, SOC
                                                                                            3
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-1-3"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-3"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div className="ct-div-block price-row__tooltip-container">
                                            <p
                                                id="text_block-183-672-1-3"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span className="ct-span">
                                                                                                    LEXIGO utlises
                                                                                                    Amazon Web Services
                                                                                                    (AWS). AWS's IT
                                                                                                    infrastructure is
                                                                                                    designed and managed
                                                                                                    in alignment with
                                                                                                    best security
                                                                                                    practices and
                                                                                                    various IT security
                                                                                                    standards, including
                                                                                                    SOC 1/ISAE 3402, SOC
                                                                                                    2 and SOC 3.
                                                                                                </span>
                                            </p>
                                            <div
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-3"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            SOC 1/ISAE 3402, SOC 2, SOC
                                                                                            3
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    LEXIGO utlises
                                                                                                    Amazon Web Services
                                                                                                    (AWS). AWS's IT
                                                                                                    infrastructure is
                                                                                                    designed and managed
                                                                                                    in alignment with
                                                                                                    best security
                                                                                                    practices and
                                                                                                    various IT security
                                                                                                    standards, including
                                                                                                    SOC 1/ISAE 3402, SOC
                                                                                                    2 and SOC 3.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            FISMA, DIACAP, and FedRAMP
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-1-3"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-3"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div className="ct-div-block price-row__tooltip-container">
                                            <p
                                                id="text_block-183-672-1-3"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span className="ct-span">
                                                                                                    LEXIGO utilises
                                                                                                    Amazon Web Services
                                                                                                    (AWS). The AWS IT
                                                                                                    infrastructure is
                                                                                                    designed and managed
                                                                                                    in alignment with
                                                                                                    best security
                                                                                                    practices and
                                                                                                    various IT security
                                                                                                    standards, including
                                                                                                    FISMA, DIACAP and
                                                                                                    FedRAMP
                                                                                                </span>
                                            </p>
                                            <div
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-3"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            FISMA, DIACAP, and FedRAMP
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    LEXIGO utilises
                                                                                                    Amazon Web Services
                                                                                                    (AWS). The AWS IT
                                                                                                    infrastructure is
                                                                                                    designed and managed
                                                                                                    in alignment with
                                                                                                    best security
                                                                                                    practices and
                                                                                                    various IT security
                                                                                                    standards, including
                                                                                                    FISMA, DIACAP and
                                                                                                    FedRAMP
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ct-div-block pricing-table-row">
                            <div className="ct-div-block pricing-table-row--desktop pricing-grid--3 pricing-grid--new-1">
                                <div className="ct-div-block price-row__title flex--row--center">
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            PCI DSS Level 1
                                                                                        </span>
                                    </div>
                                    <div className="ct-div-block price-row__title-tooltip">
                                        <div
                                            id="fancy_icon-181-672-1-3"
                                            className="ct-fancy-icon"
                                            data-id="fancy_icon-181-672"
                                        >
                                            <svg
                                                id="svg-fancy_icon-181-672-1-3"
                                                data-id="svg-fancy_icon-181-672"
                                            >
                                                <use xlinkHref="#Lexigoquestion"></use>
                                            </svg>
                                        </div>
                                        <div className="ct-div-block price-row__tooltip-container">
                                            <p
                                                id="text_block-183-672-1-3"
                                                className="ct-text-block price-row__tooltip-text"
                                                data-id="text_block-183-672"
                                            >
                                                                                                <span className="ct-span">
                                                                                                    To process your
                                                                                                    payments safely and
                                                                                                    securely, LEXIGO
                                                                                                    employs the
                                                                                                    world-leading
                                                                                                    payments processing
                                                                                                    platform, Stripe.
                                                                                                    Audited by an
                                                                                                    independent PCI
                                                                                                    Qualified Security
                                                                                                    Assessor (QSA),
                                                                                                    Stripe is certified
                                                                                                    as a PCI Level 1
                                                                                                    Service Provider—the
                                                                                                    most stringent level
                                                                                                    of certification
                                                                                                    available in the
                                                                                                    payments industry
                                                                                                </span>
                                            </p>
                                            <div
                                                className="ct-div-block lines-container"
                                                data-id="div_block-185-672"
                                            >
                                                <div
                                                    id="code_block-186-672-1-3"
                                                    className="ct-code-block lines"
                                                    data-id="code_block-186-672"
                                                >
                                                    <svg>
                                                        <use xlinkHref="#lines-1"></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672-1"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-197-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-197-672"
                                >
                                    <div
                                        id="fancy_icon-198-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-198-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-198-672-1-2"
                                            data-id="svg-fancy_icon-198-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-192-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-192-672"
                                >
                                    <div
                                        id="fancy_icon-193-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-193-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-193-672-1-2"
                                            data-id="svg-fancy_icon-193-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    id="div_block-187-672-1-2"
                                    className="ct-div-block flex--row--center"
                                    data-id="div_block-187-672"
                                >
                                    <div
                                        id="fancy_icon-188-672-1-2"
                                        className="ct-fancy-icon price-row__checkmark"
                                        data-id="fancy_icon-188-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-188-672-1-2"
                                            data-id="svg-fancy_icon-188-672"
                                        >
                                            <use xlinkHref="#Lexigocheckmark"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="ct-div-block pricing-table-row--mobile">
                                <button
                                    className="ct-div-block accordion-btn pricing-table__accordion-btn"
                                    onClick={handleClickAccordion}
                                >
                                    <div className="ct-text-block">
                                                                                        <span className="ct-span">
                                                                                            PCI DSS Level 1
                                                                                        </span>
                                    </div>
                                    <div
                                        id="fancy_icon-227-672-1-3"
                                        className="ct-fancy-icon"
                                        data-id="fancy_icon-227-672"
                                    >
                                        <svg
                                            id="svg-fancy_icon-227-672-1-3"
                                            data-id="svg-fancy_icon-227-672"
                                        >
                                            <use xlinkHref="#Lexigochevron"></use>
                                        </svg>
                                    </div>
                                </button>
                                <div className="ct-div-block">
                                    <div className="ct-div-block pricing-table__accordtion-content">
                                        <div className="ct-div-block pricing__accordion-tooltip">
                                            <div
                                                id="fancy_icon-231-672-2-1"
                                                className="ct-fancy-icon"
                                            >
                                                <svg>
                                                    <use xlinkHref="#Lexigoquestion"></use>
                                                </svg>
                                            </div>
                                            <p className="ct-text-block t--14">
                                                                                                <span className="ct-span">
                                                                                                    Lorem ipsum dolor
                                                                                                    sit amet,
                                                                                                    consectetur
                                                                                                    adipiscing elit.
                                                                                                    Integer rhoncus
                                                                                                    ultrices.
                                                                                                </span>
                                            </p>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data prime-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRIME-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Prime
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672-1"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pro-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PRO-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pro
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-198-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-198-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-198-672-3-1"
                                                    data-id="svg-fancy_icon-198-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data plus-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PLUS-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Plus
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-193-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-193-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-193-672-3-1"
                                                    data-id="svg-fancy_icon-193-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ct-div-block pricing-table-row--mobile-data pure-mobile">
                                            <div className="flex items-center mr-auto">
                                                <img
                                                    className="mr-1 w-4 h-4 mb-1px"
                                                    src="https://devapp.lexigo.co/images/instantquote/PURE-logo.svg"
                                                    alt=""
                                                />
                                                <div
                                                    id="code_block-235-672-1-1"
                                                    className="ct-code-block pricing-table__accordion-plan flex items-center"
                                                    data-id="code_block-235-672 "
                                                >
                                                    Pure
                                                </div>
                                            </div>
                                            <div
                                                id="fancy_icon-188-672-3-1"
                                                className="ct-fancy-icon price-row__checkmark"
                                                data-id="fancy_icon-188-672"
                                            >
                                                <svg
                                                    id="svg-fancy_icon-188-672-3-1"
                                                    data-id="svg-fancy_icon-188-672"
                                                >
                                                    <use xlinkHref="#Lexigocheckmark"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompareFeatures
