import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';

const ColorPic = (props) => {
    const [fontColor, setFontColor] = useColor('hex', props.currentState.color || '#000000');
    const [fontColorOld, setFontColorOld] = useColor('hex', props.currentState.color || '#000000');
    const [fontColorTemp, setFontColorTemp] = useColor('hex', props.currentState.color || '#000000');
    const [modal, setModal] = useState(false);
    const { expanded, onExpandEvent } = props;
    const { color } = props.currentState;

    const updateColor = (color) => {
        const { onChange } = props;
        onChange('color', color.hex);
    };

    const handleFontColorModal = (isSave) => {
        setModal(!modal);
        if (isSave === undefined) return;
        if (isSave) {
            const { onChange } = props;
            props.onChange('color', fontColor.hex);
            setFontColorOld(fontColor);
        } else {
            setFontColor(fontColorOld);
        }
    };

    return (
        <div aria-haspopup="true" aria-expanded={expanded} aria-label="rdw-color-picker">
            <div
                onClick={() => {
                    onExpandEvent();
                    setModal(!modal);
                }}
                className={`btn-toolbar`}
                style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: color || '#000000',
                    border: '1px solid #fff',
                    cursor: 'pointer',
                    marginBottom: '6px'
                }}
            ></div>
            <Modal
                open={modal}
                onClose={async () => {
                    await props.onChange('color', fontColorOld.hex);
                    setModal(!modal);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <ColorPicker
                        width={456}
                        height={228}
                        color={fontColorTemp}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        onChange={async (col) => {
                            await props.onChange('color', fontColorTemp.hex);
                            setFontColor(fontColorTemp);
                            setFontColorTemp(col);
                        }}
                        hideHSB
                        alpha
                        hideHSV
                        dark
                    />
                    <Stack
                        style={{
                            backgroundColor: '#181818',
                            padding: '0 20px 20px',
                            borderBottomRightRadius: '10px',
                            borderBottomLeftRadius: '10px',
                        }}
                        direction="row"
                        className="flex justify-end gap-2"
                    >
                        <Button
                            onClick={async (e) => {
                                e.stopPropagation();
                                await props.onChange('color', fontColorOld.hex);
                                setModal(!modal);
                            }}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={async (e) => {
                                e.stopPropagation();
                                await props.onChange('color', fontColorTemp.hex);
                                setFontColor(fontColorTemp);
                                setModal(!modal);
                            }}
                            variant="contained"
                        >
                            Save
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default ColorPic;
