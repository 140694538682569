import * as Chime from 'amazon-chime-sdk-js';
import { useEffect, useRef } from 'react';
import { Mic, MicOff, X } from 'react-feather';

interface Props {
    transcriptionData: Chime.TranscriptResult[][];
    muted: boolean;
    setMuted: (value: boolean) => void;
}

const LiveCaptionContainer = ({ transcriptionData, muted, setMuted }: Props) => {
    const transcriptionContainerRef = useRef<any>(null);

    useEffect(() => {
        const transcriptionContainer = transcriptionContainerRef.current;
        if (!transcriptionContainer) return;

        transcriptionContainer.scrollTop = transcriptionContainer.scrollHeight;
    }, [transcriptionData]);

    return (
        <div
            style={{
                marginTop: '1rem',
                padding: '0px 100px',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem', // This gives a gap between the child div elements
            }}
        >
            <div
                ref={transcriptionContainerRef}
                style={{
                    backgroundColor: 'white',
                    height: '70vh',
                    borderRadius: '8px',
                    padding: '30px',
                    overflow: 'auto',
                }}
            >
                {transcriptionData.length > 0 ? (
                    transcriptionData
                        .slice()
                        .reverse()
                        .filter(
                            (el, index, self) =>
                                self.findIndex((obj) => obj[0]?.resultId === el[0]?.resultId) === index,
                        )
                        .reverse()
                        .map((data) => (
                            <div
                                key={data[0].resultId}
                                style={{
                                    fontFamily: 'GorditaRegular',
                                    fontSize: '16px',
                                }}
                            >
                                {data[0]?.alternatives[0].transcript}
                            </div>
                        ))
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center', // centers vertically
                            justifyContent: 'center', // centers horizontally
                        }}
                    >
                        <img style={{ height: '15vh', minHeight: '150px' }} src="./listening.gif" alt="listening..." />
                    </div>
                )}
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', gap: '30px' }}>
                <button
                    className="btn btn-lexigo-green text-white font-bold "
                    style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                    onClick={() => window.open('https://scribe-dev.lexigo.com', '_self')}
                >
                    <X width={20} height={20} />
                </button>
                <button
                    className="btn btn-lexigo-green text-white font-bold rounded-3px btn-save-hide-mobile"
                    style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                    onClick={() => setMuted(!muted)}
                >
                    {muted ? <MicOff /> : <Mic />}
                </button>
            </div>
        </div>
    );
};

export default LiveCaptionContainer;
