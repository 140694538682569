import useComponentVisible from "./useComponentVisible.js";
import ChevronDownIcon from "./Icon/ChevronDownIcon.js";
import React from "react";

const Effect = (props) => {
    let effectOptions = ['None', 'Outline', 'Drop Shadow', 'Background'];


    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const selectEffect = (value) => {
        props.onConfigClick(props.name, value)
        setIsComponentVisible(false)
    }

    return (
        <>
            <div className="custom-dropdown custom-dropdown-fixed dropdown-toolbar pr-2 w-full" ref={ref}>
                <button
                    id=""
                    className="dropdown-toggle font-gordita font-bold flex items-center justify-between w-full"
                    onClick={async () => {
                        setIsComponentVisible(!isComponentVisible);
                    }}
                >
                    {props.effect}
                    <ChevronDownIcon className="w-6 h-6"/>
                </button>
                <div
                    className={`custom-dropdown-menu ${isComponentVisible ? 'show' : ''}`}
                >
                    {effectOptions.map((effect, i) => (
                        <div className={`custom-dropdown-menu__item ${effect === props.effect ? 'active' : ''}`} onClick={() => selectEffect(effect)}>
                            {effect}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Effect;
