import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/translations';

export const fetchTranslatedLanguage = async (id, signature) => {
    return axios
        .get(BACKEND_URL + '/' + id, {
            headers: {
                Authorization: signature + '!' + id,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const fetchTranslationHeader = async (id, language_id) => {
    return axios.get(BACKEND_URL + '/' + id + '/' + language_id).then((res) => {
        return res.data;
    });
};

export const fetchTranslation = async (id, language_id) => {
    return axios.get(BACKEND_URL + '/details/' + id + '/' + language_id).then((res) => {
        return res.data;
    });
};

export const getIsSaved = async (id, language_id) => {
    return axios.get(BACKEND_URL + '/is-saved/' + id + '/' + language_id).then((res) => {
        return res.data;
    });
};

export const createTranslation = async (id, language_id) => {
    return axios
        .post(BACKEND_URL, {
            subtitle_id: id,
            language_id: language_id,
        })
        .then((res) => {
            return res.data;
        });
};

export const lockTranslation = async (id, language_id) => {
    return axios
        .post(BACKEND_URL + '/lock', {
            subtitle_id: id,
            language_id: language_id,
        })
        .then((res) => {
            return res.data;
        });
};

export const saveAll = async (translation_id, signature) => {
    return axios
        .post(BACKEND_URL + '/save-all', {
            signature: signature,
            translation_id: translation_id,
        })
        .then((res) => {
            return res;
        });
};

export const updateIsSaved = async (data, signature) => {
    return axios
        .post(BACKEND_URL + '/is-saved/store', {
            data: data,
            signature: signature,
        })
        .then((res) => {
            return res;
        });
};

export const saveTranslation = async (translationData, signature) => {
    return axios
        .post(BACKEND_URL + '/details/store', {
            data: translationData,
            signature: signature,
        })
        .then((res) => {
            return res.data.data;
        });
};

export const oldsaveTranslation = async (subtitle_id, language_id, translationData, config) => {
    return axios
        .post(BACKEND_URL + '/details/store', {
            subtitle_id: subtitle_id,
            translation_datas: translationData,
            language_id: language_id,
        })
        .then((res) => {
            return res.data.data;
        });
};

export const storeComment = async (translationId, signature, type, text) => {
    return axios
        .post(BACKEND_URL + '/comment/store', {
            translation_id: translationId,
            signature: signature,
            type: type,
            text: text,
            localtime: new Date(),
        })
        .then((res) => {
            return res.data;
        });
};

export const getCommentCount = async (translationId, signature, subtitleId) => {
    return axios
        .get(BACKEND_URL + `/comment-count?translation_id=${translationId}`, {
            headers: {
                Authorization: signature + '!' + subtitleId,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getComments = async (translationId, type, signature, subtitleId) => {
    return axios
        .get(BACKEND_URL + `/comment?translation_id=${translationId}&type=${type}`, {
            headers: {
                Authorization: signature + '!' + subtitleId,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getLogs = async (translationDetailId) => {
    return axios.get(BACKEND_URL + `/logs?d_id=${translationDetailId}`).then((res) => {
        return res.data;
    });
};
