import * as Chime from 'amazon-chime-sdk-js';
import axios from 'axios';
import { uniqueId } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from '../css/LiveCaption.module.css';

import LiveCaptionContainer from '../components/LiveCaption/LiveCaptionContainer';
import FullScreenLoading from '../components/LiveCaption/Loading';

enum LiveCaptionStatus {
    INITIALIZING = 'Loading...',
    SUCCESS = 'success',
    ERROR = 'error',
}
type State = {
    meetingResponse: any;
    attendeeResponse: any;
    callCreated: boolean;
    status: LiveCaptionStatus;
    session: Chime.DefaultMeetingSession | null;
    muted: boolean;
    tempTranscription: Chime.TranscriptResult[];
    transcriptionData: Chime.TranscriptResult[][];
};
function LiveCaption(props: any) {
    const [state, setState] = useState<State>({
        meetingResponse: null,
        attendeeResponse: null,
        callCreated: false,
        status: LiveCaptionStatus.INITIALIZING,
        session: null,
        muted: false,
        tempTranscription: [],
        transcriptionData: [],
    });

    const videoElement = useRef<any>();
    const audioElement = useRef<any>();

    const startCall = useCallback(async () => {
        const query = new URLSearchParams(props.location.search);
        const signature = query.get('u');
        const languages = query.get('lang');
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/live-caption' + `?lang=${languages}`, {
            headers: {
                Authorization: `${signature}!${uniqueId()}`,
            },
        });
        setState((prevState) => ({
            ...prevState,
            meetingResponse: response.data.meetingResponse,
            attendeeResponse: response.data.attendee,
            callCreated: true,
        }));
    }, [props.location.search]);

    const transcriptEventHandler = useCallback(
        (transcriptEvent: Chime.TranscriptEvent): void => {
            if (transcriptEvent instanceof Chime.Transcript) {
                let newTranscription: Chime.TranscriptResult[] = transcriptEvent.results;
                if (newTranscription !== state.tempTranscription) {
                    setState((prevState) => ({ ...prevState, tempTranscription: newTranscription }));
                }
            }
        },
        [state.tempTranscription],
    );

    useEffect(() => {
        if (state.tempTranscription.length > 0) {
            setState((prevState) => ({
                ...prevState,
                transcriptionData: [...prevState.transcriptionData, state.tempTranscription],
            }));
        }
    }, [state.tempTranscription]);

    useEffect(() => {
        startCall();
    }, [startCall]);

    useEffect(() => {
        if (state.callCreated) joinVideoCall();
    }, [state.callCreated]);

    const joinVideoCall = useCallback(async () => {
        const logger = new Chime.ConsoleLogger('ChimeMeetingLogs', Chime.LogLevel.ERROR);
        const deviceController = new Chime.DefaultDeviceController(logger);
        const configuration = new Chime.MeetingSessionConfiguration(state.meetingResponse, state.attendeeResponse);
        const meetingSession = new Chime.DefaultMeetingSession(configuration, logger, deviceController);
        setState((prevState) => ({
            ...prevState,
            session: meetingSession,
        }));
        const observer = {
            audioVideoDidStart: () => {
                setState((prevState) => ({
                    ...prevState,
                    status: LiveCaptionStatus.SUCCESS,
                }));
            },
        };

        meetingSession.audioVideo.addObserver(observer);

        const activeSpeakerPolicy = new Chime.DefaultActiveSpeakerPolicy();
        meetingSession.audioVideo.subscribeToActiveSpeakerDetector(activeSpeakerPolicy, (_activeSpeakers: string[]) => {
            console.log(_activeSpeakers);
        });
        const audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();
        if (audioInputDevices.length === 1 && audioInputDevices[0].deviceId.length === 0) {
            alert('No audio input device found');
            setState((prevState) => ({
                ...prevState,
                status: LiveCaptionStatus.ERROR,
            }));
            history.back();
            return;
        }
        if (audioInputDevices.length > 0) {
            const firstAudioDeviceId = audioInputDevices[0].deviceId;
            const audioStream = await meetingSession.audioVideo.startAudioInput(firstAudioDeviceId);
            console.log(audioStream);
            meetingSession.audioVideo.start();
            meetingSession.audioVideo.transcriptionController?.subscribeToTranscriptEvent(transcriptEventHandler);
        } else {
            setState((prevState) => ({
                ...prevState,
                status: LiveCaptionStatus.ERROR,
            }));
            alert('No audio input device found');
            history.back();
        }
    }, [state.meetingResponse, state.attendeeResponse]);

    useEffect(() => {
        if (!state.session) return;
        if (state.muted) {
            state.session.audioVideo.realtimeMuteLocalAudio();
        } else {
            state.session.audioVideo.realtimeUnmuteLocalAudio();
        }
    }, [state.muted, state.session]);

    if (state.status === LiveCaptionStatus.ERROR) {
        return '';
    } else if (state.status === LiveCaptionStatus.INITIALIZING) {
        return <FullScreenLoading />;
    }

    const setMuted = (value: boolean) => {
        setState((prevState) => ({
            ...prevState,
            muted: value,
        }));
    };

    return (
        <div className={styles.LiveCaption}>
            <img className="w-80px mr-3" src="./SCRIBE-logo 1.png" alt="SCRIBE Logo" />

            <div id="video-list"></div>
            <audio ref={audioElement}></audio>
            <LiveCaptionContainer transcriptionData={state.transcriptionData} muted={state.muted} setMuted={setMuted} />

            <div>
                <video style={{ display: 'none' }} ref={videoElement}></video>
            </div>
        </div>
    );
}

export default LiveCaption;
