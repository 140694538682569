/* eslint-disable react-hooks/exhaustive-deps */
import { Slider, Stack } from '@mui/material';
import { json } from 'overmind';
import { useEffect, useState } from 'react';
import { Volume1, Volume2, VolumeX } from 'react-feather';
import { useActions, useAppState } from '../overmind';
import VolumeIcon from './Icon/VolumeIcon.js';

const Volume = (props) => {
    const state = useAppState();
    const { setVolume, setVoiceOverVolume, setOriginalMusicVolume, setOriginalVocalVolume, setMuted } = useActions();
    const [voiceOver, setVoiceOver] = useState(null);

    const handleChange = (event, newValue) => {
        if (!props.player) return;
        props.player.volume = parseFloat(newValue / 100);
        setVolume(newValue);
    };

    const handleChangeVoiceOver = (event, newValue) => {
        if (!state.voiceOverAudio) return;
        state.voiceOverAudio.volume = parseFloat(newValue / 100);
        setVoiceOverVolume(newValue);
    };

    const handleChangeOriginalMusic = (event, newValue) => {
        if (!state.originalMusic) return;
        setOriginalMusicVolume(newValue);
        state.originalMusic.volume = parseFloat(newValue / 100);
    };

    const handleChangeOriginalVocal = (event, newValue) => {
        if (!state.originalVocal) return;
        setOriginalVocalVolume(newValue);
        state.originalVocal.volume = parseFloat(newValue / 100);
    };

    useEffect(() => {
        if (json(state.voiceOver) && props.player) {
            props.player.muted = true;
            setVoiceOver(json(state.voiceOver));
        } else {
            if (props.player) {
                props.player.muted = false;
                setVoiceOver(null);
            }
        }
    }, [props.player, state.voiceOver]);

    useEffect(() => {
        if (state.mutedPlayer) {
            handleChange(null, 0);
        } else {
            handleChange(null, 100);
        }
    }, [state.mutedPlayer]);

    useEffect(() => {
        if (state.mutedOriginalVocal) {
            handleChangeOriginalVocal(null, 0);
        } else {
            handleChangeOriginalVocal(null, 100);
        }
    }, [state.mutedOriginalVocal]);

    useEffect(() => {
        if (state.mutedOriginalMusic) {
            handleChangeOriginalMusic(null, 0);
        } else {
            handleChangeOriginalMusic(null, 100);
        }
    }, [state.mutedOriginalMusic]);

    useEffect(() => {
        if (state.mutedVoiceOver) {
            handleChangeVoiceOver(null, 0);
        } else {
            handleChangeVoiceOver(null, 100);
        }
    }, [state.mutedVoiceOver]);

    const muteOrUnMute = () => {
        setMuted(!json(state.muted));
    };

    useEffect(() => {
        if (state.volume <= 0) {
            setMuted(true);
        } else {
            setMuted(false);
        }
    }, [state.volume]);

    useEffect(() => {
        if (state.voiceOverVolume <= 0 && state.originalMusicVolume <= 0 && state.originalVocalVolume <= 0) {
            setMuted(true);
        } else {
            setMuted(false);
        }
    }, [state.voiceOverVolume, state.originalMusicVolume, state.originalVocalVolume]);

    return (
        <div className={`volume-dropdown ml-3 inline-block ${props.fullscreen ? 'volume-fullscreen' : ''}`}>
            <button
                className="dropdown-toggle btn box-shadow-none border-none text-white btn-bg-control py-2 rounded-3px"
                onClick={muteOrUnMute}
            >
                {state.muted ? <VolumeX className="w-5 h-5" /> : <VolumeIcon className="w-5 h-5" />}{' '}
            </button>
            <div className={`volume-dropdown-content text-white`}>
                {voiceOver ? (
                    <>
                        Original Music:
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Volume1 className="w-5 h-5" />
                            <Slider
                                aria-label="Volume"
                                value={state.originalMusicVolume}
                                onChange={handleChangeOriginalMusic}
                                onChangeCommitted={props.commitOriginalMusicVolume}
                            />
                            <Volume2 className="w-5 h-5" />
                        </Stack>{' '}
                        Original Vocal:
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Volume1 className="w-5 h-5" />
                            <Slider
                                aria-label="Volume"
                                value={state.originalVocalVolume}
                                onChange={handleChangeOriginalVocal}
                                onChangeCommitted={props.commitOriginalVocalVolume}
                            />
                            <Volume2 className="w-5 h-5" />
                        </Stack>{' '}
                        Voice over:
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Volume1 className="w-5 h-5" />
                            <Slider
                                aria-label="Volume"
                                value={state.voiceOverVolume}
                                onChange={handleChangeVoiceOver}
                                onChangeCommitted={props.commitVoiceOverVolume}
                            />
                            <Volume2 className="w-5 h-5" />
                        </Stack>{' '}
                    </>
                ) : (
                    <>
                        <Stack
                            spacing={2}
                            direction="row"
                            sx={{ mb: 1 }}
                            alignItems="center"
                            className="text-white px-2"
                        >
                            <Volume1 className="w-6 h-6" />
                            <Slider
                                aria-label="Volume"
                                value={state.volume}
                                onChange={handleChange}
                                onChangeCommitted={props.commitVolume}
                            />
                            <Volume2 className="w-6 h-6" />
                        </Stack>
                    </>
                )}
            </div>
        </div>
    );
};

export default Volume;
