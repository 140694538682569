import { Skeleton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import { Plus, Trash2 } from 'react-feather';
import ViewportList from 'react-viewport-list';
import { useActions, useAppState, useReaction } from '../../overmind';
import { getIsTranslating, updateSubtitle } from '../../requests/SubtitleRequest';
import SpeakerMenu from '../Audio/SpeakerMenu';
import DeleteModal from '../DeleteModal';
import EditorComponent from '../EditorComponent/EditorComponent';
import FormatMenu from '../EditorComponent/FormatMenu';
import StudioSubtitleCardInput from './StudioSubtitleCardInput';
import TimeInput from './TimeInput';

export default function StudioSubtitleCard({
    addSubtitleWithoutIndex,
    updateSub,
    checkSub,
    projectData,
    removeSub,
    access,
    id,
    getSpeakers,
    miniPlaying,
    sourceQuery,
    targetQuery,
    setIsSaving,
    addSubtitle,
    speakers,
    setSpeakers,
    setSegmentIndex,
    isTranslation,
    translatedLanguage,
    translateState,
    playing,
    type,
    parent,
    config,
    isOriginalLocked,
    isShared,
    scrollRef,
    listRef,
}) {
    const [height, setHeight] = useState(100);
    const [formatId, setFormatId] = useState([]);
    const [selectedSub, setSelectedSub] = useState(null);
    const [mouseEnter, setMouseEnter] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [tempSubtitle, setTempSubtitle] = useState([]);
    const state = useAppState();
    const actions = useActions();
    const reaction = useReaction();
    const currentIndex = translateState
        ? state.translation.findIndex((item) => item.startTime <= state.currentTime && item.endTime > state.currentTime)
        : state.subtitle.findIndex((item) => item.startTime <= state.currentTime && item.endTime > state.currentTime);
    const hasSub = useCallback((sub) => tempSubtitle.indexOf(sub), [tempSubtitle]);

    const resize = useCallback(() => {
        setHeight(document.body.clientHeight - 170);
    }, [setHeight]);

    useEffect(() => {
        resize();
        if (!resize.init) {
            resize.init = true;
            const debounceResize = debounce(resize, 500);
            window.addEventListener('resize', debounceResize);
        }
    }, [resize]);

    const debouncedSave = useCallback(
        debounce((sub, nextValue) => {
            updateSubtitle(nextValue);
            updateSub(sub, nextValue);
            setIsSaving(false);
        }, 2000),
        [],
    );

    const updateText = async (sub, text) => {
        setIsSaving(true);
        const index = hasSub(sub);
        if (index < 0) return;
        let newSub = [...JSON.parse(JSON.stringify(tempSubtitle))];
        newSub[index].text = text;
        setTempSubtitle(newSub);
        debouncedSave(sub, newSub[index]);
    };

    useEffect(() => {
        const sub = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
        setTempSubtitle(sub);
    }, [state.subtitle]);

    useEffect(() => {
        const getIsTranslatingFromId = async (id) => {
            const isTranslating = await getIsTranslating(id);
            actions.setTimeLock(isTranslating);
        };
        if (state.timeLock == false) return;
        const intervalId = setInterval(() => {
            if (!state.timeLock) {
                clearInterval(intervalId);
            }
            getIsTranslatingFromId(id);
        }, 3000);

        return () => clearInterval(intervalId);
    }, [state.timeLock]);

    // useEffect(() => {
    //     if (currentIndex !== -1 && playing) {
    //         document.querySelectorAll('[id^=subtitle-box-]').forEach((box) => {
    //             box.classList.remove('segment-highlight');
    //             box.style.borderColor = 'inherit';
    //         });
    //         document.querySelectorAll('[id^=subtitle-span-]').forEach((box) => {
    //             box.classList.remove('plain-text-highlight');
    //             box.style.borderColor = 'inherit';
    //         });
    //         let element = document.getElementById('subtitle-box-' + currentIndex);
    //         if (element != null) {
    //             element.classList.add('segment-highlight');
    //             element.style.borderColor = tempSubtitle[currentIndex].speaker
    //                 ? tempSubtitle[currentIndex].speaker.speaker_color
    //                 : '#17BDB8';
    //         }
    //         element = document.getElementById('subtitle-span-' + currentIndex);
    //         if (element != null) {
    //             element.classList.add('plain-text-highlight');
    //         }
    //         let scrollableContainer = document.getElementById('subtitles');
    //         if (scrollableContainer) {
    //             try {
    //                 scrollableContainer.scroll({
    //                     top: document.getElementById(`subtitle-box-${currentIndex}`).offsetTop, //scroll to the bottom of the element
    //                     behavior: 'smooth', //auto, smooth, initial, inherit
    //                 });
    //             } catch (e) {}
    //         }
    //         try {
    //             scrollableContainer = document.getElementById('plain-text');
    //             scrollableContainer.scroll({
    //                 top: document.getElementById(`subtitle-span-${currentIndex}`).offsetTop, //scroll to the bottom of the element
    //                 behavior: 'smooth', //auto, smooth, initial, inherit
    //             });
    //         } catch (e) {}
    //     }
    // }, [currentIndex, tempSubtitle, playing]);

    useEffect(() => {
        if (currentIndex !== -1 && miniPlaying) {
            document.querySelectorAll('[id^=workbench-subtitle-box-]').forEach((box) => {
                box.classList.remove('segment-highlight');
                box.style.borderColor = 'inherit';
            });
            let element = document.getElementById('workbench-subtitle-box-' + currentIndex);
            if (element != null) {
                element.classList.add('segment-highlight');
                element.style.borderColor = tempSubtitle[currentIndex].speaker
                    ? tempSubtitle[currentIndex].speaker.speaker_color
                    : '#17BDB8';
            }
            let scrollableContainer = document.getElementById('scrollable-container-subtitle');
            if (scrollableContainer) {
                try {
                    if (autoscrollTop === 0) {
                        autoscrollTop =
                            document.getElementById(`workbench-subtitle-box-${currentIndex + 1}`).offsetTop -
                            document.getElementById(`scrollable-container-subtitle`).offsetTop -
                            (document.getElementById(`workbench-subtitle-box-${currentIndex}`).offsetTop -
                                document.getElementById(`scrollable-container-subtitle`).offsetTop);
                    }
                    let cardElement = document.getElementById(`workbench-subtitle-box-${currentIndex}`);
                    const index = currentIndex < 2 ? currentIndex : currentIndex - 2;
                    let top = autoscrollTop * (index - 2);
                    if (cardElement) {
                        top = document.getElementById(`workbench-subtitle-box-${currentIndex}`).offsetTop - 10;
                    }
                    scrollableContainer.scroll({
                        top, //scroll to the bottom of the element
                        behavior: 'smooth', //auto, smooth, initial, inherit
                    });
                    scrollableContainer.scroll({
                        top: document.getElementById(`workbench-subtitle-box-${currentIndex}`).offsetTop - 10, //scroll to the bottom of the element
                        behavior: 'smooth', //auto, smooth, initial, inherit
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }, [currentIndex, tempSubtitle, miniPlaying]);

    const syncTempSubtitleToGlobal = () => {
        actions.setSubtitle(tempSubtitle);
    };

    const onViewportIndexesChange = (viewportIndexes) => {
        const firstIndex = viewportIndexes[0];
        const lastIndex = viewportIndexes[1];
        for (let i = firstIndex; i <= lastIndex; i++) {
            const s = json(state.subtitle[i]);
            const invalid = checkSub(s, i);
            // const previous = json(state.subtitle[i - 1]);
            // const invalid = false;
            //previous && s.startTime < previous.endTime;
            // || !s.check || s.duration < 0.2;

            const id = `${parent === 'workbench' ? 'workbench-' : ''}subtitle-box-${s.id}`;
            const element = document.getElementById(id);
            if (element == null) continue;
            if (invalid) {
                element.classList.remove('bg-box-dark');
                element.classList.add('illegal');
            } else {
                element.classList.remove('illegal');
                element.classList.add('bg-box-dark');
            }
        }
    };

    const highlightSegment = (s, i) => {
        if (!playing && !miniPlaying) {
            let prefix = '';
            if (parent === 'workbench') {
                prefix = 'workbench-';
            }
            document.querySelectorAll(`[id^=${prefix}subtitle-box-]`).forEach((box) => {
                box.classList.remove('segment-highlight');
                box.style.borderColor = 'inherit';
            });
            document.querySelectorAll(`[id^=${prefix}translation-box-]`).forEach((box) => {
                box.classList.remove('segment-highlight');
                box.style.borderColor = 'inherit';
            });

            let cardId = `${prefix}subtitle-box-${s.id}`;
            document.getElementById(cardId).classList.add('segment-highlight');
            document.getElementById(cardId).style.borderColor = s.speaker ? s.speaker.speaker_color : '#17BDB8';
            try {
                cardId = `${prefix}translation-box-${s.id}`;
                document.getElementById(cardId).classList.add('segment-highlight');
                document.getElementById(cardId).style.borderColor = s.speaker ? s.speaker.speaker_color : '#17BDB8';
            } catch (e) {}
            if (isTranslation) {
                document.getElementById(`subtitle-card-${s.id}`).focus();
            }
        }
    };
    if (state.skeletonLoading || state.voiceOverLoading) {
        return (
            <>
                {[...Array(5)].map((x, i) => (
                    <div
                        className={`p-5 rounded-3px ${parent}-box bg-box-dark`}
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Skeleton width="50%" />
                        <Skeleton />
                    </div>
                ))}
            </>
        );
    } else if (tempSubtitle.length > 0) {
        return (
            <>
                <ViewportList
                    ref={listRef}
                    viewportRef={scrollRef}
                    items={tempSubtitle}
                    onViewportIndexesChange={onViewportIndexesChange}
                >
                    {(s, i) => {
                        if (
                            s.text.toLowerCase().indexOf(sourceQuery) >= 0 &&
                            (!isTranslation ||
                                (isTranslation &&
                                    state.translation[i] &&
                                    state.translation[i].text.toLowerCase().indexOf(targetQuery) >= 0))
                        ) {
                            return (
                                <div
                                    key={`${parent === 'workbench' ? 'workbench-' : ''}subtitle-box-${s.id}`}
                                    id={`${parent === 'workbench' ? 'workbench-' : ''}subtitle-box-${s.id}`}
                                    className={`p-5 rounded-3px ${parent}-box 
                                    ${checkSub(s, i) ? 'invalid' : 'bg-box-dark'}
                                    `}
                                    onMouseEnter={() => {
                                        setMouseEnter(true);
                                        setHoverIndex(i);
                                    }}
                                    onMouseLeave={() => {
                                        setMouseEnter(false);
                                        setHoverIndex(null);
                                    }}
                                    onClick={(e) => {
                                        highlightSegment(s, i);
                                        setSegmentIndex(i);
                                    }}
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    {openDeleteModal && s === selectedSub ? (
                                        <DeleteModal
                                            openDeleteModal={openDeleteModal}
                                            setOpenDeleteModal={setOpenDeleteModal}
                                            action={removeSub}
                                            setSelected={setSelectedSub}
                                            selected={selectedSub}
                                        />
                                    ) : (
                                        ''
                                    )}

                                    {!isTranslation ? (
                                        <div className="flex items-center">
                                            <TimeInput
                                                updateSub={updateSub}
                                                access={access}
                                                s={s}
                                                setIsSaving={setIsSaving}
                                                translatedLanguage={translatedLanguage}
                                            />
                                            <Tooltip title="Add new subtitle" placement="top" arrow>
                                                <button
                                                    id="add-subtitle"
                                                    type="button"
                                                    className="bg-box-content-dark text-white p-1 rounded-3px flex ml-2 mr-2"
                                                    style={{
                                                        display:
                                                            mouseEnter &&
                                                            hoverIndex === i &&
                                                            access &&
                                                            translatedLanguage &&
                                                            translatedLanguage.length <= 0 &&
                                                            (access.accessMapping.role.toLowerCase() === 'owner' ||
                                                                access.accessMapping.role.toLowerCase() === 'editor') &&
                                                            state.voiceOverAudio === null
                                                                ? 'inline-block'
                                                                : 'none',
                                                    }}
                                                    onClick={() => {
                                                        // removeSub(s);
                                                        syncTempSubtitleToGlobal();
                                                        addSubtitle(i);
                                                        // setSelectedSub(s);
                                                        // setOpenDeleteModal(true);
                                                    }}
                                                >
                                                    <Plus className="w-4 h-4" />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Delete subtitle" placement="top" arrow>
                                                <button
                                                    id="delete-subtitles"
                                                    type="button"
                                                    className="bg-box-content-dark text-white p-1 rounded-3px flex"
                                                    style={{
                                                        display:
                                                            mouseEnter &&
                                                            hoverIndex === i &&
                                                            access &&
                                                            translatedLanguage &&
                                                            translatedLanguage.length <= 0 &&
                                                            (access.accessMapping.role.toLowerCase() === 'owner' ||
                                                                access.accessMapping.role.toLowerCase() === 'editor') &&
                                                            state.voiceOverAudio === null
                                                                ? 'inline-block'
                                                                : 'none',
                                                    }}
                                                    onClick={() => {
                                                        // removeSub(s);
                                                        setSelectedSub(s);
                                                        setOpenDeleteModal(true);
                                                    }}
                                                >
                                                    <Trash2 className="w-4 h-4" />
                                                </button>
                                            </Tooltip>

                                            {mouseEnter &&
                                            hoverIndex === i &&
                                            access &&
                                            translatedLanguage &&
                                            translatedLanguage.length <= 0 &&
                                            !config.box_highlight &&
                                            (access.accessMapping.role.toLowerCase() === 'owner' ||
                                                access.accessMapping.role.toLowerCase() === 'editor') &&
                                            state.voiceOverAudio === null ? (
                                                <Tooltip title="Format this subtitle" placement="top" arrow>
                                                    <button
                                                        type="button"
                                                        className="ml-2 bg-box-content-dark text-white p-1 rounded-3px flex"
                                                        style={{
                                                            height: '24px',
                                                        }}
                                                    >
                                                        <FormatMenu
                                                            i={i}
                                                            setFormatId={setFormatId}
                                                            formatId={formatId}
                                                            s={s}
                                                            updateText={updateText}
                                                            config={config}
                                                        />
                                                    </button>
                                                </Tooltip>
                                            ) : (
                                                ''
                                            )}
                                            {mouseEnter &&
                                            hoverIndex === i &&
                                            access &&
                                            (access.accessMapping.role.toLowerCase() === 'owner' ||
                                                (isShared == 1 &&
                                                    translateState == false &&
                                                    isOriginalLocked.length >= 0 &&
                                                    isOriginalLocked[0] !== 0 &&
                                                    access.accessMapping.role.toLowerCase() === 'editor')) ? (
                                                <Tooltip title="Assign Speaker" placement="top" arrow>
                                                    <button
                                                        type="button"
                                                        className="ml-2 bg-box-content-dark text-white p-1 rounded-3px flex"
                                                        style={{
                                                            height: '24px',
                                                        }}
                                                    >
                                                        <SpeakerMenu
                                                            i={i}
                                                            getSpeakers={getSpeakers}
                                                            id={id}
                                                            translation={false}
                                                            speakers={speakers}
                                                            setSpeakers={setSpeakers}
                                                            setFormatId={setFormatId}
                                                            formatId={formatId}
                                                            s={s}
                                                            updateText={updateText}
                                                            config={config}
                                                        />
                                                    </button>
                                                </Tooltip>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div className={`${!isTranslation ? 'mt-3' : ''}`}>
                                        {!isTranslation && formatId.includes(i) ? (
                                            <EditorComponent
                                                openDeleteModal={openDeleteModal}
                                                setOpenDeleteModal={setOpenDeleteModal}
                                                setFormatId={setFormatId}
                                                formatId={formatId}
                                                i={i}
                                                updateText={updateText}
                                                s={s}
                                            />
                                        ) : (
                                            <StudioSubtitleCardInput
                                                tempSubtitle={tempSubtitle}
                                                setTempSubtitle={setTempSubtitle}
                                                checkSub={checkSub}
                                                projectData={projectData}
                                                access={access}
                                                s={s}
                                                i={i}
                                                highlightSegment={highlightSegment}
                                                setSegmentIndex={setSegmentIndex}
                                                setIsSaving={setIsSaving}
                                                updateSub={updateSub}
                                                translatedLanguage={translatedLanguage}
                                            />
                                            // <input
                                            //     type="text"
                                            //     className={[
                                            //         'input-subtitle',
                                            //         'text-content',
                                            //         'w-full',
                                            //         checkSub(s) ? 'illegal' : '',
                                            //         projectData.file.language.rtl === 1 ? 'rtl-target-input' : '',
                                            //     ]
                                            //         .join(' ')
                                            //         .trim()}
                                            //     disabled={
                                            //         access &&
                                            //         access.accessMapping.role.toLowerCase() !== 'owner' &&
                                            //         access.accessMapping.role.toLowerCase() !== 'editor'
                                            //             ? true
                                            //             : false
                                            //     }
                                            //     value={extractContent(s.text, false)}
                                            //     style={{ border: 'none' }}
                                            //     onChange={(event) => {
                                            //         updateText(s, event.target.value);
                                            //     }}
                                            // />
                                        )}
                                    </div>
                                </div>
                            );
                        }
                    }}
                </ViewportList>
                <button
                    style={{
                        cursor: 'pointer',
                        display:
                            access &&
                            translatedLanguage &&
                            translatedLanguage.length <= 0 &&
                            (access.accessMapping.role.toLowerCase() === 'owner' ||
                                access.accessMapping.role.toLowerCase() === 'editor') &&
                            state.voiceOverAudio === null
                                ? 'inline-block'
                                : 'none',
                    }}
                    onClick={() => {
                        syncTempSubtitleToGlobal();
                        addSubtitle(tempSubtitle.length - 1);
                    }}
                    className="btn btn-bg-control text-white py-1 lg:py-2 font-bold rounded-3px flex-1 text-content"
                >
                    Add New Subtitle
                </button>
            </>
        );
    } else {
        return (
            <button
                style={{
                    cursor: 'pointer',
                    display:
                        access &&
                        (access.accessMapping.role.toLowerCase() === 'owner' ||
                            access.accessMapping.role.toLowerCase() === 'editor') &&
                        state.voiceOverAudio === null
                            ? 'inline-block'
                            : 'none',
                }}
                onClick={() => {
                    addSubtitleWithoutIndex();
                }}
                className="btn text-white btn-lexigo-green font-bold w-full mr-3 rounded-3px mb-2"
            >
                Add Subtitles
            </button>
        );
    }
}
