import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/volume';

export const getVolume = async (id, is_translation) => {
    return axios.get(BACKEND_URL + `/${id}/${is_translation}`).then((res) => {
        return res.data;
    });
};

// payload:
// id,
// is_translation,
// volume,
// original_music_volume,
// original_voice_volume,
// voice_over_volume,
export const updateVolume = async (payload) => {
    return axios.post(BACKEND_URL, payload).then((res) => {
        return res.data;
    });
};
