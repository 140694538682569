import React from 'react';
import ChevronDownIcon from './Icon/ChevronDownIcon.js';
import useComponentVisible from './useComponentVisible.js';

const Position = (props) => {
    const positionOptions = ['Top', 'Center', 'Bottom', 'Custom'];
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const selectPosition = (value) => {
        props.onConfigClick(props.name, value);
        setIsComponentVisible(false);
    };

    return (
        <>
            <div className="custom-dropdown custom-dropdown-fixed dropdown-toolbar pr-2 w-full" ref={ref}>
                <button
                    id=""
                    className="dropdown-toggle font-gordita font-bold flex items-center justify-between w-full"
                    onClick={async () => {
                        setIsComponentVisible(!isComponentVisible);
                    }}
                >
                    {props.position}
                    <ChevronDownIcon className="w-6 h-6" />
                </button>
                <div className={`custom-dropdown-menu ${isComponentVisible ? 'show' : ''}`}>
                    {positionOptions.map((position, i) => {
                        if (props.name === 'heading_position' && position === 'Custom') return;
                        return (
                            <div
                                className={`custom-dropdown-menu__item ${position === props.position ? 'active' : ''}`}
                                onClick={() => selectPosition(position)}
                            >
                                {position}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Position;
