import { createActionsHook, createEffectsHook, createReactionHook, createStateHook } from 'overmind-react';
import { createRef } from 'react';

export const overmindConfig = {
    state: {
        playerTime: 0,
        trimVideo: [],
        currentTime: 0, //obsolete
        miniCurrentTime: 0,
        seekTime: 0,
        currentIndex: -1,
        subtitle: [],
        translation: [],
        openMobileNav: false,
        voiceOver: null,
        skeletonLoading: false,
        audioPlayerLoading: false,
        loading: false,
        voiceOverRequest: false,
        voiceOverLoading: false,
        voiceOverLoaded: false,
        originalMusicLoaded: false,
        originalVocalLoaded: false,
        voiceOverAudio: null,
        originalMusic: null,
        originalVocal: null,
        voiceOverSync: false,
        volume: 100,
        voiceOverVolume: 100,
        originalMusicVolume: 100,
        originalVocalVolume: 100,
        waveformDuration: 0,
        centerSecond: 0,
        waveformZoom: 80,
        maxZoom: 150,
        minZoom: 30,
        originalWaveform: null,
        originalMusicWaveform: null,
        originalVoiceWaveform: null,
        voiceOverWaveform: null,
        wavesurferCursor: createRef(),
        wavesurferOriginal: null, // original voice nad music
        wavesurferOriginalVoice: null, // original voice
        wavesurferOriginalMusic: null, // original music
        wavesurferVoiceOver: null,
        muted: false,
        mutedPlayer: false,
        mutedOriginalVocal: false,
        mutedOriginalMusic: false,
        mutedVoiceOver: false,
        waveformLoaded: false,
        originalMusicWaveformLoaded: false,
        originalVoiceWaveformLoaded: false,
        voiceOverWaveformLoaded: false,
        voiceOverWaveformRef: createRef(),
        originalMusicWaveformRef: createRef(),
        originalVoiceWaveformRef: createRef(),
        originalWaveformRef: createRef(),
        activeTranslationDetailId: null,
        segmentIdx: null,
        workbenchDetailId: null,
        terms: [],
        tm: [],
        timeLock: true,
        audioCoverActive: false,
        audioCoverSrc: null,
        audioCoverFilename: null,
        accessDetails: [],
        triggerTrimVideo: false,
        wsRegions: null,
        skipVideoData: [],
        currentSub:null,
        miniCurentSub:null
    },

    actions: {
        setMiniCurrentSub({ state }, value) {
            state.miniCurrentSub = value;
        },
        setCurrentSub({ state }, value) {
            state.currentSub = value;
        },
        setSkipVideoData({ state }, value) {
            state.skipVideoData = value;
        },
        setWsRegions({ state }, value) {
            state.wsRegions = value;
        },
        setTriggerTrimVideo({ state }, value) {
            state.triggerTrimVideo = value;
        },
        setTrimVideo({ state }, value) {
            state.trimVideo = value;
        },
        setAccessDetails({ state }, value) {
            state.accessDetails = value;
        },
        setTimeLock({ state }, value) {
            state.timeLock = value;
        },
        setSeekTime({ state }, value) {
            state.seekTime = value;
        },
        setPlayerTime({ state }, value) {
            state.playerTime = value;
        },
        setLoading({ state }, value) {
            state.loading = value;
        },
        setSkeletonLoading({ state }, value) {
            state.skeletonLoading = value;
        },
        setAudioPlayerLoading({ state }, value) {
            state.audioPlayerLoading = value;
        },
        setWorkbenchDetailId({ state }, value) {
            state.workbenchDetailId = value;
        },
        setSegmentIdx({ state }, value) {
            state.segmentIdx = value;
        },
        setTerms({ state }, value) {
            state.terms = value;
        },
        setTm({ state }, value) {
            state.tm = value;
        },
        setActiveTranslationDetailId({ state }, value) {
            state.activeTranslationDetailId = value;
        },
        setMinZoom({ state }, value) {
            state.minZoom = value;
        },
        setMaxZoom({ state }, value) {
            state.maxZoom = value;
        },
        setOriginalMusicWaveformRef({ state }, value) {
            state.originalMusicWaveformRef = value;
        },
        setVoiceOverWaveformRef({ state }, value) {
            state.voiceOverWaveformRef = value;
        },
        setOriginalVoiceWaveformRef({ state }, value) {
            state.originalVoiceWaveformRef = value;
        },
        setOriginalWaveformRef({ state }, value) {
            state.originalWaveformRef = value;
        },
        setMuted({ state }, value) {
            state.muted = value;
        },
        setVoiceOverRequest({ state }, value) {
            state.voiceOverRequest = value;
        },
        setWaveformLoaded({ state }, value) {
            state.waveformLoaded = value;
        },
        setOriginalMusicWaveformLoaded({ state }, value) {
            state.originalMusicWaveformLoaded = value;
        },
        setOriginalVoiceWaveformLoaded({ state }, value) {
            state.originalVoiceWaveformLoaded = value;
        },
        setVoiceOverWaveformLoaded({ state }, value) {
            state.voiceOverWaveformLoaded = value;
        },
        setMutedPlayer({ state }, value) {
            state.mutedPlayer = value;
        },
        setMutedOriginalVocal({ state }, value) {
            state.mutedOriginalVocal = value;
        },
        setMutedVoiceOver({ state }, value) {
            state.mutedVoiceOver = value;
        },
        setMutedOriginalMusic({ state }, value) {
            state.mutedOriginalMusic = value;
        },
        setWavesurferCursor({ state }, value) {
            state.wavesurferCursor = value;
        },
        setWavesurferVoiceOver({ state }, value) {
            state.wavesurferVoiceOver = value;
        },
        setWavesurferOriginalMusic({ state }, value) {
            state.wavesurferOriginalMusic = value;
        },
        setWavesurferOriginalVoice({ state }, value) {
            state.wavesurferOriginalVoice = value;
        },
        setWavesurferOriginal({ state }, value) {
            state.wavesurferOriginal = value;
        },
        setVoiceOverWaveform({ state }, value) {
            state.voiceOverWaveform = value;
        },
        setOriginalMusicWaveform({ state }, value) {
            state.originalMusicWaveform = value;
        },
        setOriginalVoiceWaveform({ state }, value) {
            state.originalVoiceWaveform = value;
        },
        setOriginalWaveform({ state }, value) {
            state.originalWaveform = value;
        },
        setWaveformZoom({ state }, value) {
            state.waveformZoom = value;
        },
        setCenterSecond({ state }, value) {
            state.centerSecond = value;
        },
        setWaveformDuration({ state }, value) {
            state.waveformDuration = value;
        },
        setTime({ state }, value) {
            state.currentTime = value;
        },
        setMiniTime({ state }, value) {
            state.miniCurrentTime = value;
        },
        setCurrentIndex({ state }, value) {
            state.currentIndex = value;
        },
        setSubtitle({ state, actions }, value) {
            state.subtitle = value;
        },
        setTranslation({ state }, value) {
            state.translation = value;
        },
        setOpenMobileNav({ state }, value) {
            state.openMobileNav = value;
        },
        setVoiceOver({ state }, value) {
            state.voiceOver = value;
        },
        setVoiceOverLoading({ state }, value) {
            state.voiceOverLoading = value;
        },
        setVoiceOverAudio({ state }, value) {
            state.voiceOverAudio = value;
        },
        setVoiceOverSync({ state }, value) {
            state.voiceOverSync = value;
        },
        setVoiceOverVolume({ state }, value) {
            state.voiceOverVolume = value;
        },
        setVolume({ state }, value) {
            state.volume = value;
        },
        setOriginalMusic({ state }, value) {
            state.originalMusic = value;
        },
        setOriginalVocal({ state }, value) {
            state.originalVocal = value;
        },
        setOriginalMusicVolume({ state }, value) {
            state.originalMusicVolume = value;
        },
        setOriginalVocalVolume({ state }, value) {
            state.originalVocalVolume = value;
        },
        setOriginalMusicLoaded({ state }, value) {
            state.originalMusicLoaded = value;
        },
        setOriginalVocalLoaded({ state }, value) {
            state.originalVocalLoaded = value;
        },
        setVoiceOverLoaded({ state }, value) {
            state.voiceOverLoaded = value;
        },
        setAudioCoverActive({ state }, value) {
            state.audioCoverActive = value;
        },
        setAudioCoverSrc({ state }, value) {
            state.audioCoverSrc = value;
        },
        setAudioCoverFilename({ state }, value) {
            state.audioCoverFilename = value;
        },
    },
};

export const useAppState = createStateHook();
export const useActions = createActionsHook();
export const useEffects = createEffectsHook();
export const useReaction = createReactionHook();
