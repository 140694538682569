/* eslint-disable jsx-a11y/alt-text */
import DT from 'duration-time-conversion';
import { useEffect, useState } from 'react';
import { Pause, Play } from 'react-feather';
import styled from 'styled-components';
import { useActions, useAppState } from '../overmind';
import BackwardIcon from './Icon/BackwardIcon.js';
import ForwardIcon from './Icon/ForwardIcon.js';
const Style = styled.div`
    ${
        '' /* display: flex;
    align-items: center;
    justify-content: center; */
    }
    width: 100%;
    ${
        '' /* padding: 0% 1.25rem;
    @media (max-width: 768px) {
        display: block;
        padding: 0% 0%;
    } */
    }

    .player-control-container {
        position: relative;
        .player-control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            position: relative;
            @media (max-width: 768px) {
                flex-wrap: wrap;
                justify-content: center;
                padding: 10px 0;
                gap: 0.25rem;
            }
        }
        .fullscreen {
            width: 100vw;
        }
        .time {
            color: #ffffff;
        }
        .time-total {
            color: rgba(255, 255, 255, 0.3);
            margin-left: 0.25rem;
        }
    }
`;

export default function MiniPlayerControl(props) {
    const [playbackRate, setPlaybackRate] = useState(1);
    const state = useAppState();
    const actions = useActions();

    const playPauseAction = () => {
        if (!state.voiceOverLoading) {
            props.setMiniPlaying(!props.miniPlaying);
        }
    };
    const getDuration = (time) => {
        time = time === Infinity || isNaN(time) ? 0 : time;
        return DT.d2t(time).split('.')[0];
    };

    useEffect(() => {
        if (playbackRate && props.player) {
            props.player.playbackRate = playbackRate;
            if (state.originalVocal) {
                state.originalVocal.playbackRate = playbackRate;
            }
            if (state.originalMusic) {
                state.originalMusic.playbackRate = playbackRate;
            }
            if (state.voiceOverAudio) {
                state.voiceOverAudio.playbackRate = playbackRate;
            }
        }
    }, [playbackRate, props.player, state.originalMusic, state.originalVocal, state.voiceOverAudio]);

    return (
        <Style>
            <div
                className={`player-control-container bg-control-dark rounded-3px ${
                    props.fullscreen ? 'fullscreen' : ''
                }`}
            >
                <div className={`player-control ${props.fullscreen ? 'fullscreen' : ''}`}>
                    <div className="flex items-center order-1">
                        <button
                            className="btn box-shadow-none border-none"
                            onClick={() => {
                                props.miniPlayer.currentTime = props.miniPlayer.currentTime - 5;
                            }}
                        >
                            <BackwardIcon className="w-6 h-6 text-white" />
                        </button>
                        <button className="btn box-shadow-none border-none text-title" onClick={playPauseAction}>
                            {props.miniPlaying ? (
                                <Pause
                                    className="w-8 h-8 fill-lexigo-white stroke-lexigo-white"
                                    // onClick={playPauseAction}
                                />
                            ) : (
                                <Play
                                    className="w-8 h-8 fill-lexigo-white stroke-lexigo-white"
                                    // onClick={playPauseAction}
                                />
                            )}
                        </button>

                        <span className="time text-content">
                            <p
                                style={{
                                    width: '70px',
                                    textAlign: 'center',
                                }}
                            >
                                {getDuration(state.miniCurrentTime)}
                            </p>
                        </span>
                        <span className="time time-total flex items-center text-content">
                            <span>/</span>
                            <p
                                style={{
                                    width: '70px',
                                    textAlign: 'center',
                                }}
                            >
                                {getDuration(props.videoTime)}
                            </p>
                        </span>
                        <button
                            className="btn box-shadow-none border-none"
                            onClick={() => {
                                props.miniPlayer.currentTime = props.miniPlayer.currentTime + 5;
                            }}
                        >
                            <ForwardIcon className="w-6 h-6 text-white" />
                        </button>
                    </div>
                </div>
            </div>
        </Style>
    );
}
