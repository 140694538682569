/* eslint-disable jsx-a11y/alt-text */
import { Skeleton } from '@mui/material';
import { debounce } from 'lodash';
import { json } from 'overmind';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { unstable_batchedUpdates } from 'react-dom';
import styled from 'styled-components';
import styles from '../css/SubtitleEdit.module.css';
import { useActions, useAppState } from '../overmind';
import { getFontById } from '../requests/FontRequest';
import { updateSubtitle } from '../requests/SubtitleRequest';
import { saveTranslation } from '../requests/TranslateRequest';
import { isPlaying, removePositionTag } from '../utils';
import AudioCoverRND from './AudioCoverRND.js';
import { ARIAL_ASCENDER, ARIAL_DESCENDER, ARIAL_XHEIGHT } from './Player';
import TitleRND from './TitleRND';
import PlayerSubtitle from './PlayerComponents/PlayerSubtitle';

const Style = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .vid-container {
        position: relative;
        background-color: #000000;
        .video-mini {
            height: 150px;
            width: 266.67px;
        }
        .style-container {
            position: absolute;
            top: 0;
            height: 100%;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            aspect-ratio: 16 / 9;
            .container {
                display: flex;
                position: absolute;
                width: 100%;
                overflow: hidden;
            }
            .subtitle-number {
                z-index: 19;
                > span {
                    background-color: #ffffff;
                    padding: 0.1rem 0.25rem;
                    margin-bottom: 1px;
                    font-weight: bold;
                }
            }
            .subtitle {
                display: flex;
                position: absolute;
                overflow: hidden;
                z-index: 22;
                left: 0;
                right: 0;
                width: 100%;
                user-select: none;
                pointer-events: none;

                .operate {
                    color: #fff;
                    font-size: 13px;
                    border-radius: 3px;
                    margin-bottom: 5px;
                    background-color: rgb(0 0 0 / 75%);
                    border: 1px solid rgb(255 255 255 / 20%);
                    cursor: pointer;
                    pointer-events: all;
                }

                .textarea {
                    overflow: hidden !important;
                    outline: none;
                    resize: none;
                    line-height: 1.2;
                    border: 1px solid #ffffff;
                    border-radius: 3px;
                    user-select: all;
                    pointer-events: all;
                }
            }
        }
        .video {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            aspect-ratio: 16 / 9;
            position: relative;
            video {
                position: relative;
                height: 100%;
                width: 100%;
                outline: none;
                background-color: #000;
                cursor: pointer;
            }
        }
    }
`;

const AudioWrap = ({
    $audio,
    setPlayer,
    setPlaying,
    projectData,
    audioTime,
    setAudioTime,
    setAudioLoaded,
    setNearest,
    setCurrentIndex,
    ...props
}) => {
    const { setTime } = useActions();
    const state = useAppState();
    const actions = useActions();

    useEffect(() => {
        setPlayer($audio.current);
    }, [$audio]);

    const onTimeUpdate = useCallback(
        (e) => {
            if ($audio.current) {
                if (audioTime === 0) {
                    setAudioTime($audio.current.duration);
                }
                const voiceOverAudio = state.voiceOverAudio;
                const originalMusic = state.originalMusic;
                const originalVocal = state.originalVocal;
                if (voiceOverAudio !== null && originalVocal !== null && originalMusic !== null) {
                    // ini ambil apakah voice over audio dan video perbedaannya lebih dari setengah detik
                    if (Math.abs(voiceOverAudio.currentTime - $audio.current.currentTime) > 0.5) {
                        voiceOverAudio.currentTime = $audio.current.currentTime || 0;
                    }
                    if (Math.abs(originalMusic.currentTime - $audio.current.currentTime) > 0.5) {
                        originalMusic.currentTime = $audio.current.currentTime || 0;
                    }
                    if (Math.abs(originalVocal.currentTime - $audio.current.currentTime) > 0.5) {
                        originalVocal.currentTime = $audio.current.currentTime || 0;
                    }
                }
                // setTime($audio.current.currentTime || 0);
                //set player time
                actions.setPlayerTime($audio.current.currentTime || 0);

                //auto scroll subtitle
                const currentIndex = props.translateState
                    ? state.translation.findIndex(
                          (item) =>
                              item.startTime <= $audio.current.currentTime && item.endTime > $audio.current.currentTime,
                      )
                    : state.subtitle.findIndex(
                          (item) =>
                              item.startTime <= $audio.current.currentTime && item.endTime > $audio.current.currentTime,
                      );
                let sub;
                const subData = props.translateState ? json(state.translation) : json(state.subtitle);
                sub = subData[currentIndex];
                actions.setCurrentSub(sub);
                let newNearest;
                if (currentIndex < 0) {
                    if (subData.length <= 0) return;
                    newNearest = subData.reduce(function (prev, curr) {
                        return Math.abs(curr.startTime - $audio.current.currentTime) <
                            Math.abs(prev.startTime - props.currentTime)
                            ? curr
                            : prev;
                    });
                } else {
                    newNearest = subData[currentIndex];
                }
                setCurrentIndex(currentIndex);
                setNearest(newNearest);
            }
        },
        [$audio],
    );

    const onLoadedMetaData = () => {
        actions.setVoiceOverLoading(false);
        if ($audio.current) {
            if (audioTime === 0) {
                setAudioTime($audio.current.duration);
            }
            setAudioLoaded(true);
        }
    };

    const onLoadStart = () => {
        // actions.setVoiceOverLoading(true)
    };

    const onLoadedData = () => {
        // actions.setVoiceOverLoading(false);
    };

    const onEnded = () => {
        if ($audio.current) {
            setTime(0);
            props.playPauseAction();
            const footer = document.getElementById('footer');
            footer.scrollTo(0, 0);
        }
    };
    return (
        <div style={{ height: '100%', width: '100%' }}>
            {state.skeletonLoading || state.voiceOverLoading || state.audioPlayerLoading ? (
                <Skeleton
                    width="100%"
                    height="100%"
                    sx={{
                        transform: 'unset',
                    }}
                />
            ) : (
                ''
            )}
            <audio
                id="audio"
                className={`hidden`}
                onTimeUpdate={onTimeUpdate}
                src={projectData.header.dropbox_url.replace('www.dropbox.com', 'dl.dropboxusercontent.com')}
                preload="auto"
                onLoadedData={onLoadedData}
                onLoadStart={onLoadStart}
                onEnded={onEnded}
                onLoadedMetadata={onLoadedMetaData}
                ref={$audio}
                controls
            />
        </div>
    );
};
export default function AudioPlayer(props) {
    const [currentIndex, setCurrentIndex] = useState(-1);
    const videoLikeRef = useRef();
    const styleRef = useRef();
    const player = createRef();
    const [nearest, setNearest] = useState(null);
    const state = useAppState();

    const [styleString, setStyleString] = useState(``);
    const [headingStyleString, setHeadingStyleString] = useState(``);
    const [headingTextContainerPosition, setHeadingTextContainerPosition] = useState(``);
    const [fontName, setFontName] = useState('Verdana');
    const [heading_fontName, setHeading_FontName] = useState('Verdana');
    const [fontData, setFontData] = useState(null);
    const [headingFontData, setHeadingFontData] = useState(null);

    const getTextStyle = () => {
        let str = ``;
        if (props.config.bold) {
            str += ` ${styles.bold}`;
        }
        if (props.config.italic) {
            str += ` ${styles.italic}`;
        }
        if (props.config.underline) {
            str += ` ${styles.underline}`;
        }
        if (props.config.align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (props.config.align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (props.config.align === 'right') {
            str += ` ${styles.alignRight}`;
        }
        if (nearest) {
            if (/^{\\an[4|5|6]}/.test(nearest.text)) {
                str += ` ${styles.center}`;
            } else if (/^{\\an[1|2|3]}/.test(nearest.text)) {
                str += ` ${styles.bottom}`;
            } else if (/^{\\an[7|8|9]}/.test(nearest.text)) {
                str += ` ${styles.top}`;
            } else {
                if (props.config.position === 'Center') {
                    str += ` ${styles.center}`;
                } else if (props.config.position === 'Bottom' || props.config.position === 'Custom') {
                    str += ` ${styles.bottom}`;
                } else if (props.config.position === 'Top') {
                    str += ` ${styles.top}`;
                }
            }
        }
        // setStyleString(str);
        return str;
    };    

    const getHeadingTextStyle = () => {
        let str = ``;
        if (props.config.heading_bold) {
            str += ` ${styles.bold}`;
        }
        if (props.config.heading_italic) {
            str += ` ${styles.italic}`;
        }
        if (props.config.heading_underline) {
            str += ` ${styles.underline}`;
        }
        if (props.config.heading_align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (props.config.heading_align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (props.config.heading_align === 'right') {
            str += ` ${styles.alignRight}`;
        }
        if (props.config.heading_position === 'Center') {
            str += ` ${styles.center}`;
        } else if (props.config.heading_position === 'Bottom') {
            str += ` ${styles.bottom}`;
        } else if (props.config.heading_position === 'Top') {
            str += ` ${styles.top}`;
        }

        // setHeadingStyleString(str);
        return str;
    };

    const getHeadingTextContainerPosition = () => {
        let str = ``;
        if (props.config.heading_position === 'Center') {
            str += ` ${styles.center}`;
        } else if (props.config.heading_position === 'Bottom') {
            str += ` ${styles.bottom}`;
        } else if (props.config.heading_position === 'Top') {
            str += ` ${styles.top}`;
        }

        if (props.config.heading_align === 'center') {
            str += ` ${styles.alignCenter}`;
        } else if (props.config.heading_align === 'left') {
            str += ` ${styles.alignLeft}`;
        } else if (props.config.heading_align === 'right') {
            str += ` ${styles.alignRight}`;
        }

        // setHeadingTextContainerPosition(str);
        return str;
    };

    const getFontFamily = async () => {
        const font = await getFontById(props.config.fontFamily);
        const headingFont = await getFontById(props.config.heading_fontFamily);
        unstable_batchedUpdates(() => {
            setFontName(font.font_name);
            setHeading_FontName(headingFont.font_name);
            setFontData(font);
            setHeadingFontData(headingFont);
        });
    };

    useEffect(() => {
        getFontFamily();
    }, [props.config.fontFamily, props.config.heading_fontFamily]);

    const initByConfig = async () => {
        const textStyle = getTextStyle();
        // getTextContainerPosition();
        const headingTextContainerPosition = getHeadingTextContainerPosition();
        const headingTextStyle = getHeadingTextStyle();
        unstable_batchedUpdates(() => {
            setHeadingStyleString(headingTextStyle);
            setHeadingTextContainerPosition(headingTextContainerPosition);
            setStyleString(textStyle);
        });
    };

    useEffect(() => {
        initByConfig();
    }, [props.config]);

    const getAspectRatio = () => {
        if (props.config.ratio == '16:9') {
            return {};
        } else {
            return {
                width: '100%',
                height: 'auto',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            };
        }
    };

    const getContainerAspectRatio = () => {
        if (props.config.ratio == '1:1') {
            return {
                width: '100%',
                height: '100%',
                aspectRatio: `1/1`,
            };
        } else if (props.config.ratio == '16:9') {
            return {
                width: '100%',
                backgroundColor: '#000000',
                aspectRatio: `16/9`,
            };
        } else if (props.config.ratio == '4:5') {
            return {
                width: '100%',
                height: '100%',
                aspectRatio: `4/5`,
            };
        } else if (props.config.ratio == '9:16') {
            return {
                width: '100%',
                height: '100%',
                aspectRatio: `9/16`,
            };
        }
    };

    const getLogoStyle = () => {
        let baseStyle = {
            display: 'flex',
            zIndex: 22,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            opacity: props.config.watermarkOpacity,
            height: styleRef.current
                ? (styleRef.current.offsetWidth >= styleRef.current.offsetHeight
                      ? styleRef.current.offsetHeight * 7
                      : styleRef.current.offsetHeight * 5) / 100
                : 0, //(props.player.offsetHeight / props.projectData.header.height) * 50
            objectFit: 'contain',
        };

        if (props.config.watermarkX == 50) {
            if (props.config.watermarkY == 50) {
                //center
                baseStyle = {
                    ...baseStyle,
                    top: '50%',
                    left: '50%',
                    transform: 'translateY(-50%) translateX(-50%)',
                };
            } else if (props.config.watermarkY < 50) {
                // top center
                baseStyle = {
                    ...baseStyle,
                    top: '8px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                };
            } else {
                //bottom center
                baseStyle = {
                    ...baseStyle,
                    bottom: '8px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                };
            }
        } else if (props.config.watermarkX < 50) {
            if (props.config.watermarkY == 50) {
                // center left
                baseStyle = {
                    ...baseStyle,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: '8px',
                };
            } else if (props.config.watermarkY < 50) {
                // top left
                baseStyle = {
                    ...baseStyle,
                    top: '8px',
                    left: '8px',
                };
            } else {
                //bottom left
                baseStyle = {
                    ...baseStyle,
                    bottom: '8px',
                    left: '8px',
                };
            }
        } else if (props.config.watermarkX > 50) {
            if (props.config.watermarkY == 50) {
                // center right
                baseStyle = {
                    ...baseStyle,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '8px',
                };
            } else if (props.config.watermarkY < 50) {
                // top right
                baseStyle = {
                    ...baseStyle,
                    top: '8px',
                    right: '8px',
                };
            } else {
                //bottom right
                baseStyle = {
                    ...baseStyle,
                    bottom: '8px',
                    right: '8px',
                };
            }
        }

        return baseStyle;
    };


    const PlayerSubtitleProps = {
        nearest,
        styleRef,
        currentIndex,
        styleString,
        headingStyleString,
        headingTextContainerPosition,
        fontName,
        fontData,
        ...props
    }

    return (
        // <Style className="player">
        //     <AudioWrap {...props}></AudioWrap>
        // </Style>
        <Style
            className="player"
            style={{
                background: props.fullscreen ? '#000' : '',
            }}
            ref={videoLikeRef}
        >
            <div ref={styleRef} className="vid-container mx-auto rnd-cover" style={getContainerAspectRatio()}>
                {state.audioCoverActive && state.audioCoverSrc ? (
                    <AudioCoverRND {...props} videoCoverRef={styleRef} />
                ) : (
                    ''
                )}
                <div className={`video`} style={getAspectRatio()} ref={player}>
                    <AudioWrap
                        {...props}
                        setNearest={setNearest}
                        setCurrentIndex={setCurrentIndex}
                    ></AudioWrap>
                </div>
                <div
                    className="style-container"
                    style={{ height: '100%' }}
                    // onClick={
                    //     state.audioCoverActive
                    //         ? ''
                    //         : (e) => {
                    //               e.stopPropagation();
                    //               props.playPauseAction();
                    //           }
                    // }
                >
                    {props.projectData.watermark_url != '' ? (
                        <div
                            className="container"
                            style={{
                                height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                            }}
                        >
                            <img src={props.projectData.watermark_url} style={getLogoStyle()} />
                        </div>
                    ) : (
                        ''
                    )}
                    {props.projectData.cover_url != '' ? (
                        <div
                            className="container"
                            style={{
                                height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                            }}
                        >
                            <img
                                src={props.projectData.cover_url}
                                style={{
                                    zIndex: 22,
                                    position: 'absolute',
                                    height: `${props.projectData.cover.percent_height}%`,
                                    width: `${props.projectData.cover.percent_width}%`,
                                    top: `${props.projectData.cover.percent_position_y}%`,
                                    left: `${props.projectData.cover.percent_position_x}%`,
                                }}
                            />
                        </div>
                    ) : (
                        ''
                    )}

                    {props.access && props.access.userPackage.remove_scribe_watermark ? (
                        ''
                    ) : (
                        <div
                            className="container"
                            style={{
                                height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                            }}
                        >
                            <img
                                src="SCRIBE-watermark-logo.svg"
                                style={{
                                    display: 'flex',
                                    zIndex: 22,
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    opacity: 0.8,
                                    height: styleRef.current
                                        ? (styleRef.current.offsetWidth >= styleRef.current.offsetHeight
                                              ? styleRef.current.offsetHeight * 7
                                              : styleRef.current.offsetHeight * 5) / 100
                                        : 0, //(props.player.offsetHeight / props.projectData?.header.height) * 50
                                    objectFit: 'contain',
                                    top: '35px',
                                    right: '35px',
                                }}
                            />
                        </div>
                    )}
                    <div
                        className="container"
                        style={{
                            height: props.fullscreen ? 'calc(( 100vw / 16 * 9) - 65px)' : '100%',
                        }}
                    >
                        {(props.config.heading != '' || props.config.heading != null) &&
                        (!props.config.customHeadingTime || state.playerTime <= props.config.headingTime) ? (
                            <TitleRND
                                {...props}
                                headingFontData={headingFontData}
                                styleRef={styleRef}
                                heading_fontName={heading_fontName}
                            />
                        ) : null}

                        {props.player && state.currentSub ? (
                              <PlayerSubtitle {...PlayerSubtitleProps}/>
                            ) : null}
                        
                    </div>
                </div>
            </div>
        </Style>
    );
}
