import Button from '../Button';
import Icon, { ScreenShareIcon, ScreenShareSlashIcon } from '../Icon';

export function ScreenShareButton({
  active,
  handleScreenShare,
  ...additionalProps
}) {
  const buttonStyle = active ? 'positive' : 'base';
  return (
      
      <button
          className="btn btn-lexigo-green text-white font-bold rounded-3px btn-save-hide-mobile"
          style={{ width: '50px', height: '50px', borderRadius: '50%' }}
          // onClick={() => setMuted(!muted)}
          onClick={handleScreenShare}
          {...additionalProps}
      >
      <Icon>
        {active ? (
          <ScreenShareSlashIcon className='text-current h-6 w-6' />
        ) : (
          <ScreenShareIcon className='text-current h-6 w-6' />
        )}
      </Icon>
    </button>
  );
}
