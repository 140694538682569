import DT from 'duration-time-conversion';
import { validateAccessToken } from '../requests/auth';

export const userAgent = window.navigator.userAgent;
export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

export function getExt(url) {
    return url.trim().toLowerCase().split('.').pop();
}

export function sleep(ms = 0) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function download(url, name) {
    const elink = document.createElement('a');
    elink.style.display = 'none';
    elink.href = url;
    elink.download = name;
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
}

export function getKeyCode(event) {
    const tag = document.activeElement.tagName.toUpperCase();
    const editable = document.activeElement.getAttribute('contenteditable');
    if (tag !== 'INPUT' && tag !== 'TEXTAREA' && editable !== '' && editable !== 'true') {
        return Number(event.keyCode);
    }
}

export function isPlaying($video) {
    return !!($video.currentTime > 0 && !$video.paused && !$video.ended && $video.readyState > 2);
}

export function isLogin(token) {
    return validateAccessToken(token);
}

export function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const capitalize = (text) => {
    return text.replace(/\b\w/g, (l) => l.toUpperCase());
};

export const extractContent = (s, space) => {
    let span = document.createElement('span');
    span.innerHTML = s;
    if (space) {
        let children = span.querySelectorAll('*');
        for (const i = 0; i < children.length; i++) {
            if (children[i].textContent) children[i].textContent += ' ';
            else children[i].innerText += ' ';
        }
    }
    const currentSub = [span.textContent || span.innerText].toString();
    return currentSub.replace(/{\\an\d}/, '');
};

export const removePositionTag = (text) => {
    return text.replace(/{\\an\d}/, '');
};

export const removeHTMLTag = (text)=>{
    return text.replace(/(<([^>]+)>)/ig, "");
}

export const extractPositionTag = (text) => {
    return text.match(/{\\an\d}/);
};

export const getDuration = (time) => {
    time = time === Infinity || isNaN(time) ? 0 : time;
    return DT.d2t(time).split('.')[0];
};

export const normalize = (val, min = 0, max = 255) => {
    return (val - min) / (max - min);
};
