export default function NotFound() {
    return (
        <div className="not-found-container">
            <h1 className="not-found-title">404</h1>
            <div style={{ width: '100vw', textAlign: 'center' }}>
                <button
                    onClick={() => {
                        window.location.href = process.env.REACT_APP_BACKEND_URL.replace('/api', '/projects');
                    }}
                    className="btn btn-white font-bold mr-3 rounded-3px"
                >
                    Back to Scribe
                </button>
            </div>
        </div>
    );
}
