import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import '../css/rcp.css';
import { useActions } from '../overmind';
import { deleteCoverRequest, uploadCover } from '../requests/SubtitleRequest';
import TrashIcon from './Icon/TrashIcon';

const AudioCoverBar = ({
    id,
    config,
    setConfig,
    setProjectData,
    projectData,
    showCover,
    setShowCover,
    currentLanguage,
    ...props
}) => {
    const [upImg, setUpImg] = useState();
    const [editorModal, setEditorModal] = useState(false);
    const previewCanvasRef = useRef(null);
    const actions = useActions();
    const [crop, setCrop] = useState({
        unit: '%',
        x: 10,
        y: 10,
        width: 80,
        height: 80,
    });
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);
    const inputRef = useRef();

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );
    }, [completedCrop]);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const [filename, setFilename] = useState('');

    const reset = () => {
        inputRef.current.value = '';
    };
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            actions.setAudioCoverActive(true);

            const reader = new FileReader();
            // reader.addEventListener('load', () => setUpImg(reader.result));
            reader.addEventListener('load', () => actions.setAudioCoverSrc(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            // setFilename(e.target.files[0].name);
            actions.setAudioCoverFilename(e.target.files[0].name);
        }
        // setEditorModal(true);
        reset();
        setShowCover(false);
    };

    const handleModal = (isSave) => {
        setEditorModal(!editorModal);
        if (isSave === undefined) return;
        if (isSave) {
            upload(previewCanvasRef.current, completedCrop);
        }
    };

    useEffect(() => {
        setFilename(config.coverFileName);
    }, [config]);

    const upload = (canvas, crop) => {
        if (!crop || !canvas) {
            return;
        }

        canvas.toBlob(async (blob) => {
            actions.setAudioPlayerLoading(true);
            const coverUrl = await uploadCover(id, config.ratio, blob, filename, currentLanguage);
            setProjectData((prevData) => ({
                ...prevData,
                cover_url: coverUrl,
            }));
            actions.setAudioPlayerLoading(false);
        });
    };

    const deleteCover = async () => {
        actions.setAudioPlayerLoading(true);
        setFilename('');
        setProjectData((prevData) => ({
            ...prevData,
            cover_url: '',
            cover: {},
        }));
        setShowCover(false);
        await deleteCoverRequest(id, config.ratio, currentLanguage);
        actions.setAudioPlayerLoading(false);
    };

    return (
        <>
            <>
                <label className="btn btn-lexigo-green text-white font-bold w-full mr-3 rounded-3px mb-2">
                    <input
                        style={{ display: 'none' }}
                        accept="image/*"
                        className="input-file"
                        type="file"
                        id="cover"
                        ref={inputRef}
                        name="cover"
                        onChange={onSelectFile}
                    />
                    Upload Audio Cover
                </label>
                {filename || (projectData ? projectData.cover_url : true) ? (
                    <>
                        <div className="divider divider-dark m-0"></div>
                        <div className="flex items-center justify-between">
                            <p>{filename ? filename : config.coverFileName}</p>
                            <TrashIcon
                                onClick={() => {
                                    deleteCover();
                                }}
                                className="w-5 h-5 cursor-pointer"
                                style={{ minWidth: '1.25rem' }}
                            />
                        </div>
                    </>
                ) : (
                    ''
                )}
            </>
            {/* Modal */}
            <Modal
                open={editorModal}
                onClose={() => {
                    handleModal(false);
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        borderBottomRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        p: 4,
                    }}
                >
                    <ReactCrop
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        minWidth={50}
                        minHeight={50}
                        style={{
                            cursor: 'default',
                        }}
                        imageStyle={{
                            minWidth: '100px',
                            minHeight: '100px',
                        }}
                        keepSelection
                        ruleOfThirds
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />
                    <div>
                        <canvas
                            ref={previewCanvasRef}
                            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                            style={{
                                display: 'none',
                            }}
                        />
                    </div>
                    <Stack
                        style={{
                            backgroundColor: '#white',
                            padding: '0.5rem',
                            borderBottomRightRadius: '10px',
                            borderBottomLeftRadius: '10px',
                        }}
                        direction="row"
                        spacing={2}
                    >
                        <Button
                            onClick={() => {
                                handleModal(true);
                            }}
                            variant="contained"
                        >
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                handleModal(false);
                            }}
                            style={{ backgroundColor: '#c70422ff', color: '#FFFFFF' }}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default AudioCoverBar;
