import { ThemeProvider, createTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { debounce } from 'lodash';
import { json } from 'overmind';
import * as React from 'react';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { ChevronLeft, Edit, Save, Trash2 } from 'react-feather';
import { useActions, useAppState } from '../../overmind';
import { createSpeaker, removeSpeaker, updateSpeakerDetail } from '../../requests/SpeakerRequest';
import { updateSubtitleSpeaker } from '../../requests/SubtitleRequest';
import { NestedMenuItem } from '../MuiNestedMenu/components/NestedMenuItem';
import AudioIcon from './AudioIcon';

export default function SpeakerMenu(props) {
    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        minWidth: 0,
                        padding: 'inherit',
                        color: 'inherit',
                        borderRadius: 'inherit',
                        fontFamily: 'inherit',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontFamily: 'GorditaRegular',
                        fontSize: '0.8rem',
                        '& .Mui-selected': {
                            backgroundColor: 'red',
                        },
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: 'GorditaRegular',
                        fontSize: '0.8rem',
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        padding: '0',
                    },
                },
            },
        },
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editId, setEditId] = React.useState(null);
    const [tempSpeakers, setTempSpeakers] = React.useState(null);
    const [isAddSpeaker, setIsAddSpeaker] = React.useState(false);
    const [newSpeakerName, setNewSpeakerName] = React.useState('');
    const [speakersBefore, setSpeakersBefore] = React.useState(null);
    const [currentSpeaker, setCurrentSpeaker] = React.useState(null);
    const open = Boolean(anchorEl);
    const state = useAppState();
    const actions = useActions();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateSpeaker = async (s, speaker, index) => {
        let newSubs;
        if (!props.translation) {
            newSubs = json(state.subtitle);
        } else {
            newSubs = json(state.translation);
        }
        newSubs[index].speaker = speaker;
        if (!props.translation) {
            let cardId = `subtitle-box-${s.id}`;
            actions.setSubtitle(newSubs);
            document.getElementById(cardId).style.borderColor = speaker ? speaker.speaker_color : '#17BDB8';
        } else {
            actions.setTranslation(newSubs);
        }
        await updateSubtitleSpeaker(s.id, speaker.id);
    };

    useEffect(() => {
        const speakers = JSON.parse(JSON.stringify(props.speakers));
        setTempSpeakers(speakers);
    }, [props.speakers]);

    const debounceUpdateSpeakers = useCallback(
        debounce(async (newSpeaker) => {
            if (newSpeaker) {
                await updateSpeakerDetail(newSpeaker.id, newSpeaker.speaker_name);
            }
        }, 1000),
        [],
    );

    const onMouseEnter = () => {
        setSpeakersBefore(JSON.parse(JSON.stringify(props.speakers)));
        setEditId(null);
    };

    const onSpeakerEdit = (e, speaker_id) => {
        const newSpeakers = JSON.parse(JSON.stringify(tempSpeakers));
        let newSpeaker = newSpeakers[speaker_id];
        newSpeaker.speaker_name = e.target.value;
        setTempSpeakers(newSpeakers);
        setCurrentSpeaker(newSpeaker);
    };

    const addNewSpeaker = async () => {
        await createSpeaker(props.id, newSpeakerName);
        await props.getSpeakers();
        setIsAddSpeaker(false);
        setNewSpeakerName('');
    };

    const deleteSpeaker = async (speaker_id) => {
        await removeSpeaker(speaker_id);
        await props.getSpeakers();
    };

    const inputRef = useRef(null);

    const [editRefs, setEditRefs] = useState([]);

    useEffect(() => {
        setEditRefs((editRefs) => {
            if (tempSpeakers) return tempSpeakers.map((_, i) => editRefs[i] || createRef());
            return [];
        });
    }, [tempSpeakers]);

    return (
        <ThemeProvider theme={theme}>
            <span
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <AudioIcon
                    fill={props.s.speaker && props.s.speaker.speaker_color ? props.s.speaker.speaker_color : '#ffffff'}
                    className="w-4 h-4"
                />
            </span>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 22,
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClick={(e) => {
                    if (e && e.stopPropagation) e.stopPropagation();
                }}
            >
                <NestedMenuItem
                    onMouseEnter={onMouseEnter}
                    onMouseOut={() => {
                        setTempSpeakers(speakersBefore);
                        setIsAddSpeaker(false);
                        setNewSpeakerName('');
                        setEditId(null);
                    }}
                    label="Speaker"
                    parentMenuOpen={open}
                    transformOrigin={{
                        vertical: 0,
                        horizontal: 'right',
                    }}
                    leftIcon={
                        <ChevronLeft
                            className="w-4 h-4"
                            style={{
                                fontSize: '17px!important',
                                marginTop: '1.25px',
                            }}
                        />
                    }
                >
                    {tempSpeakers &&
                        tempSpeakers.length > 0 &&
                        tempSpeakers.map((speaker, i) => {
                            return (
                                <MenuItem
                                    key={speaker.id}
                                    id={speaker.id}
                                    selected={props.s.speaker && speaker.id === props.s.speaker.id ? true : false}
                                    style={{
                                        width: '170px',
                                        borderLeft: `6px solid ${speaker.speaker_color}`,
                                    }}
                                >
                                    <span
                                        onClick={(e) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            speaker.id !== editId ? updateSpeaker(props.s, speaker, props.i) : '';
                                        }}
                                    >
                                        <input
                                            type="text"
                                            disabled={speaker.id === editId ? false : true}
                                            style={{
                                                maxWidth: '90px',
                                                padding: '5px',
                                                cursor: speaker.id !== editId ? 'pointer' : 'text',
                                            }}
                                            onClick={(e) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                            }}
                                            value={speaker.speaker_name}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                onSpeakerEdit(e, i);
                                            }}
                                            onKeyDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                            ref={editRefs[i]}
                                        />
                                    </span>
                                    <button style={{ marginLeft: '0.5rem' }}>
                                        {speaker.id === editId ? (
                                            <Save
                                                onClick={(e) => {
                                                    if (e && e.stopPropagation) e.stopPropagation();
                                                    props.setSpeakers(tempSpeakers);
                                                    setEditId(null);
                                                    debounceUpdateSpeakers(currentSpeaker);
                                                }}
                                                className="w-4 h-4"
                                            />
                                        ) : (
                                            <Edit
                                                onClick={(e) => {
                                                    if (e && e.stopPropagation) e.stopPropagation();
                                                    setEditId(speaker.id);
                                                    setTimeout(() => {
                                                        editRefs[i].current.focus();
                                                    }, 0);
                                                }}
                                                className="w-4 h-4"
                                            />
                                        )}
                                    </button>
                                    <button style={{ marginLeft: '0.5rem', marginRight: '-2rem' }}>
                                        <Trash2 onClick={() => deleteSpeaker(speaker.id)} className="w-4 h-4" />
                                    </button>
                                </MenuItem>
                            );
                        })}
                    {isAddSpeaker ? (
                        <MenuItem
                            onClick={(e) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                            }}
                            style={{
                                paddingRight: '8px',
                            }}
                        >
                            <input
                                type="text"
                                style={{
                                    maxWidth: '90px',
                                    padding: '5px',
                                    cursor: 'text',
                                }}
                                ref={inputRef}
                                value={newSpeakerName}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setNewSpeakerName(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                            <button
                                className="btn btn-lexigo-green text-white font-bold rounded-3px flex items-center ml-auto"
                                style={{
                                    height: '24.7px',
                                    padding: '0 .45rem',
                                    marginLeft: '8px',
                                }}
                                onClick={(e) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    addNewSpeaker();
                                }}
                            >
                                Save
                            </button>
                        </MenuItem>
                    ) : (
                        <MenuItem style={{ padding: '0' }}>
                            <button
                                className="btn btn-lexigo-green text-white font-bold rounded-3px w-full"
                                style={{
                                    borderTopRightRadius: 0,
                                    borderTopLeftRadius: 0,
                                }}
                                onClick={(e) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    setIsAddSpeaker(true);
                                    setTimeout(() => {
                                        inputRef.current.focus();
                                    }, 0);
                                }}
                            >
                                Add new speaker
                            </button>
                        </MenuItem>
                    )}
                </NestedMenuItem>
            </Menu>
        </ThemeProvider>
    );
}
