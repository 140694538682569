import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const [cookies] = useCookies(['u']);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);

    useEffect(() => {
        // const checkLogin = async () => {
        //     let res = await isLogin(cookies.u);
        //     setIsLoggedIn(res);
        //     setLoadingComplete(true);
        // };
        // checkLogin();
    }, []);

    // if (loadingComplete) {
    return (
        <Route
            {...rest}
            render={(props) => (isLoggedIn && restricted ? <Redirect to="/studio" /> : <Component {...props} />)}
        />
    );
    // } else {
    //     return <div> Loading... </div>;
    // }
};

export default PublicRoute;
