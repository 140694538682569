/* eslint-disable no-script-url */

import { Modal } from '@mui/material';
import { Check, Clock } from 'react-feather';
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';
import { PricingPackage } from './PricingModal';

/* eslint-disable jsx-a11y/anchor-is-valid */
const PricingDetailsModal = (props) => {
    return (
        <Modal
            open={props.openDetailsModal}
            onClose={() => {
                props.setOpenDetailsModal(false);
            }}
        >
            <div
                id="modal-pricing-details"
                className="modal show"
                data-backdrop="static"
                tabIndex="-1"
                aria-hidden="true"
                style={{
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    zIndex: 10001,
                    overflow: 'auto',
                }}
            >
                <div className="modal-dialog modal-xl modal-xl-pricing-details">
                    <div className="modal-content modal-transparent h-full">
                        <div className="modal-body h-full p-10 text-xs">
                            <div className="grid grid-cols-12 gap-5 h-full"></div>
                            <div className="col-span-12">
                                <div
                                    className="box bg-lexigo-gray"
                                    style={{
                                        backgroundColor: '#ffffff',
                                    }}
                                >
                                    <TinySlider
                                        settings={{
                                            slideBy: 'page',
                                            mouseDrag: true,
                                            autoplay: false,
                                            loop: false,
                                            controls: true,
                                            nav: true,
                                            startIndex: 2,
                                            speed: 500,
                                            items: 1,
                                            edgePadding: 0,
                                            responsive: {
                                                1024: {
                                                    gutter: 16,
                                                    items: 2,
                                                },
                                                1280: {
                                                    items: 4,
                                                },
                                            },
                                        }}
                                        style={{ paddingTop: `2rem` }}
                                    >
                                        <div>
                                            <div className="col-span-12 lg:col-span-3 intro-y">
                                                <div
                                                    className={`flex flex-col h-full  border ${
                                                        props.selectedPackage === PricingPackage.PURE
                                                            ? 'pure-selected'
                                                            : 'no-selected-pure'
                                                    }`}
                                                >
                                                    <div
                                                        className={`text-change font-bold text-center pure-text text-sm ${
                                                            props.selectedPackage === PricingPackage.PURE
                                                                ? 'text-white'
                                                                : 'text-lexigo-4'
                                                        }`}
                                                    >
                                                        Pure
                                                    </div>
                                                    <div
                                                        className={`text-3xl font-bold mt-3 text-center pricepurehead ${
                                                            props.selectedPackage === PricingPackage.PURE
                                                                ? 'text-white'
                                                                : 'text-lexigo-4'
                                                        }`}
                                                    >
                                                        ${props.pricing.package_pure_price}
                                                    </div>
                                                    <div className="w-full mt-1 border-b">
                                                        <div className="p-3 pt-0 text-xs rounded-3px mx-auto text-center italic">
                                                            from {props.currency.toUpperCase()} $
                                                            {props.pricing.package_pure_price_per_word} per word*
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col h-full">
                                                        <div
                                                            className="flex flex-col p-5"
                                                            style={{
                                                                height: '250px',
                                                            }}
                                                        >
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PURE
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-4'
                                                                    }`}
                                                                />
                                                                <div className="font-bold text-custom-lexigo">
                                                                    AI-powered
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PURE
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-4'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Private Enterprise Machine
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PURE
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-4'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    2048-bit encryption
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PURE
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-4'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    ISO-27001 security
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PURE
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-4'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Dedicated Customer Success Manager
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col mt-auto sm:mt-auto px-5">
                                                            <div className="flex items-center justify-center">
                                                                <Clock className="w-4 h-4 mr-2" />
                                                                <div className="text-xs">Estimated delivery by</div>
                                                            </div>
                                                            <div
                                                                className={`p-3 text-xs rounded-3px mt-2 text-center bg-change tooltip puretimedelivery pointer ${
                                                                    props.selectedPackage === PricingPackage.PURE
                                                                        ? 'bg-lexigo-pure'
                                                                        : 'bg-lexigo-gray-1'
                                                                }`}
                                                            >
                                                                {props.pricing.pure_time}
                                                            </div>
                                                        </div>

                                                        <div className="flex items-center justify-center mt-5">
                                                            <div
                                                                className="wrapper px-5 w-full"
                                                                onClick={() => {
                                                                    props.handleSelect(PricingPackage.PURE);
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="selectdetail"
                                                                    id="plan-list-3"
                                                                    value="pure"
                                                                    onChange={() => {}}
                                                                    checked={
                                                                        props.selectedPackage === PricingPackage.PURE
                                                                    }
                                                                />

                                                                <label
                                                                    for="plan-list-3"
                                                                    className="option option-3 font-bold"
                                                                >
                                                                    <span>Select</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="font-bold mt-5 text-center text-sm">
                                                            Pure suitable for:
                                                        </div>
                                                        <div
                                                            className="flex flex-col p-5 bg-details bg-details-change mt-5 h-full"
                                                            style={{ minHeight: `760px` }}
                                                        >
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Digital + Ecommerce
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Understanding online help enquiries, product
                                                                    support, survey results, informal supplier and
                                                                    merchant communication, informal stakeholder
                                                                    communication
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Marketing + Communication
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Market research and responses, survey results, draft
                                                                    content, emails, lead-gen driven blogs, first draft
                                                                    marketing copy
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Enterprise + Business
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Content discovery, internal communications,
                                                                    intercompany emails, first draft reports and other
                                                                    copy, informal supplier and merchant communication
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="col-span-12 lg:col-span-3 intro-y">
                                                <div
                                                    className={`flex flex-col h-full border  ${
                                                        props.selectedPackage === PricingPackage.PLUS
                                                            ? 'plus-selected'
                                                            : 'no-selected-plus'
                                                    }`}
                                                >
                                                    <div
                                                        className={`text-change font-bold text-center text-sm ${
                                                            props.selectedPackage === PricingPackage.PLUS
                                                                ? 'text-white'
                                                                : 'text-lexigo-3'
                                                        } border-b pb-3`}
                                                    >
                                                        Plus
                                                    </div>
                                                    <div
                                                        className={`text-3xl font-bold ${
                                                            props.selectedPackage === PricingPackage.PLUS
                                                                ? 'text-white'
                                                                : 'text-lexigo-3'
                                                        } mt-3 text-center priceplushead`}
                                                    >
                                                        ${props.pricing.package_plus_price}
                                                    </div>
                                                    <div className="w-full mt-1 border-b">
                                                        <div className="p-3 pt-0 text-xs rounded-3px mx-auto italic text-center">
                                                            from {props.currency.toUpperCase()} $
                                                            {props.pricing.package_plus_price_per_word} per word*
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col h-full">
                                                        <div
                                                            className="flex flex-col p-5"
                                                            style={{
                                                                height: '250px',
                                                            }}
                                                        >
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PLUS
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-3'
                                                                    }`}
                                                                />
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Everything in Pure
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PLUS
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-3'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Human Translation backed by AI
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PLUS
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-3'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">Automatic QA</div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PLUS
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-3'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Dedicated Project Success Manager
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col mt-auto sm:mt-auto px-5">
                                                            <div className="flex items-center justify-center">
                                                                <Clock className="w-4 h-4 mr-2" />
                                                                <div className="text-xs">Estimated delivery by</div>
                                                            </div>
                                                            <div
                                                                className={` p-3 text-xs rounded-3px mt-2 text-center bg-change tooltip plustimedelivery pointer ${
                                                                    props.selectedPackage === PricingPackage.PLUS
                                                                        ? 'bg-lexigo-plus'
                                                                        : 'bg-lexigo-gray-1'
                                                                }`}
                                                            >
                                                                {props.pricing.plus_time}
                                                            </div>
                                                        </div>
                                                        <a
                                                            href="javascript:;"
                                                            className="text-center underline-hover text-xs mt-2 tryExpress"
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            Need it faster? Try Express
                                                        </a>
                                                        <a
                                                            href="javascript:;"
                                                            className="underline-hover text-xs mt-2 text-center remove-express"
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            Remove Express
                                                        </a>

                                                        <div className="flex items-center justify-center mt-5">
                                                            <div
                                                                className="wrapper px-5 w-full"
                                                                onClick={() => {
                                                                    props.handleSelect(PricingPackage.PLUS);
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="selectdetail"
                                                                    id="plan-list-4"
                                                                    value="plus"
                                                                    onChange={() => {}}
                                                                    checked={
                                                                        props.selectedPackage === PricingPackage.PLUS
                                                                    }
                                                                />
                                                                <label
                                                                    for="plan-list-4"
                                                                    className="option option-4 font-bold"
                                                                >
                                                                    <span>Select</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="font-bold mt-5 text-center text-sm">
                                                            Plus suitable for:
                                                        </div>
                                                        <div
                                                            className="flex flex-col p-5 bg-details bg-details-change mt-5 h-full"
                                                            style={{ minHeight: `760px` }}
                                                        >
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Digital + Ecommerce
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    User reviews, customer support, online help and FAQ,
                                                                    product support, survey results, product
                                                                    descriptions, informal supplier and merchant
                                                                    communication
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Marketing + Communication
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Captions and subtitles, market research and
                                                                    responses, survey results, draft content, emails,
                                                                    lead-gen driven blogs, guides
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Enterprise + Business
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Internal communications, intercompany emails, first
                                                                    drafts, informal supplier and merchant communication
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="col-span-12 lg:col-span-3 intro-y">
                                                <div
                                                    className={`flex flex-col h-full border ${
                                                        props.selectedPackage === PricingPackage.PRO
                                                            ? 'pro-selected'
                                                            : 'no-selected-pro'
                                                    }`}
                                                >
                                                    <div
                                                        className="best-value border-r border-l"
                                                        style={{ fontSize: `14px` }}
                                                    >
                                                        Best Value
                                                    </div>
                                                    <div
                                                        className={`text-change font-bold text-center pb-3 border-b text-sm ${
                                                            props.selectedPackage === PricingPackage.PRO
                                                                ? 'text-white'
                                                                : 'text-lexigo-2'
                                                        }`}
                                                    >
                                                        Pro
                                                    </div>
                                                    <div
                                                        className={`text-3xl font-bold italic mt-3 text-center priceprohead ${
                                                            props.selectedPackage === PricingPackage.PRO
                                                                ? 'text-white'
                                                                : 'text-lexigo-2'
                                                        }`}
                                                    >
                                                        ${props.pricing.package_pro_price}
                                                    </div>
                                                    <div className="w-full mt-1 border-b">
                                                        <div className="p-3 text-xs rounded-3px mx-auto text-center pt-0">
                                                            from {props.currency.toUpperCase()} $
                                                            {props.pricing.package_pro_price_per_word} per word*
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col h-full">
                                                        <div
                                                            className="flex flex-col p-5"
                                                            style={{
                                                                height: '250px',
                                                            }}
                                                        >
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRO
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-2'
                                                                    }`}
                                                                />
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Everything in Plus
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRO
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-2'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">Proofreading</div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRO
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-2'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Qualified Native Translator
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRO
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-2'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Dedicated Client Success Manager
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRO
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-2'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Subject Matter Experts
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRO
                                                                            ? 'text-white'
                                                                            : 'text-lexigo-2'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Accuracy guaranteed
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col mt-auto sm:mt-auto px-5">
                                                            <div className="flex items-center justify-center">
                                                                <Clock className="w-4 h-4 mr-2" />
                                                                <div className="text-xs">Estimated delivery by</div>
                                                            </div>
                                                            <div
                                                                className={`p-3 text-xs rounded-3px mt-2 text-center bg-change tooltip protimedelivery pointer ${
                                                                    props.selectedPackage === PricingPackage.PRO
                                                                        ? 'bg-lexigo-pro'
                                                                        : 'bg-lexigo-gray-1'
                                                                }`}
                                                            >
                                                                {props.pricing.pro_time}
                                                            </div>
                                                        </div>
                                                        <a
                                                            href="javascript:;"
                                                            className="text-center underline-hover text-xs mt-2 tryExpress"
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            Need it faster? Try Express
                                                        </a>
                                                        <a
                                                            href="javascript:;"
                                                            className="underline-hover text-xs mt-2 text-center remove-express"
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            Remove Express
                                                        </a>

                                                        <div className="flex items-center justify-center mt-5">
                                                            <div
                                                                className="wrapper px-5 w-full"
                                                                onClick={() => {
                                                                    props.handleSelect(PricingPackage.PRO);
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="selectdetail"
                                                                    id="plan-list-5"
                                                                    value="pro"
                                                                    onChange={() => {}}
                                                                    checked={
                                                                        props.selectedPackage === PricingPackage.PRO
                                                                    }
                                                                />
                                                                <label
                                                                    for="plan-list-5"
                                                                    className="option option-5 font-bold select-inside-modal"
                                                                >
                                                                    <span>Select</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="font-bold mt-5 text-center text-sm">
                                                            Pro suitable for:
                                                        </div>
                                                        <div
                                                            className="flex flex-col p-5 bg-details-change mt-5 h-full bg-lexigo-2"
                                                            style={{ minHeight: `760px` }}
                                                        >
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Digital + Ecommerce
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    User reviews, customer support, online help and FAQ,
                                                                    product support, survey results, product
                                                                    descriptions, supplier and merchant communication
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Marketing + Communication
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Product and service descriptions, blogs, articles,
                                                                    captions and subtitles, market research and
                                                                    responses, survey results, production ready content,
                                                                    emails, lead-gen driven blogs, guides
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Enterprise + Business
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Marketing material, brochures, websites, software,
                                                                    apps, company profiles and all market-facing content
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Goverment + Public Sector
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    News, community announcements, country-post content,
                                                                    marketing material
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="col-span-12 lg:col-span-3 intro-y">
                                                <div
                                                    className={`flex flex-col h-full no-selected border ${
                                                        props.selectedPackage === PricingPackage.PRIME
                                                            ? 'prime-selected'
                                                            : 'no-selected-prime'
                                                    }`}
                                                >
                                                    <div
                                                        className={`font-bold text-center text-change ${
                                                            props.selectedPackage === PricingPackage.PRIME
                                                                ? 'text-white'
                                                                : 'text-lexigo-1'
                                                        } border-b pb-3 text-sm`}
                                                    >
                                                        Prime
                                                    </div>
                                                    <div
                                                        className={`text-3xl font-bold ${
                                                            props.selectedPackage === PricingPackage.PRIME
                                                                ? 'text-white'
                                                                : 'text-lexigo-1'
                                                        } mt-3 text-center priceprimehead`}
                                                    >
                                                        ${props.pricing.package_prime_price}
                                                    </div>
                                                    <div className="w-full mt-1 border-b">
                                                        <div className="p-3 text-xs rounded-3px mx-auto pt-0 italic text-center">
                                                            from {props.currency.toUpperCase()} $
                                                            {props.pricing.package_prime_price_per_word} per word*
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col h-full">
                                                        <div
                                                            className="flex flex-col p-5"
                                                            style={{
                                                                height: '250px',
                                                            }}
                                                        >
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRIME
                                                                            ? 'white'
                                                                            : 'text-lexigo-1'
                                                                    }`}
                                                                />
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Everything in Pro
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRIME
                                                                            ? 'white'
                                                                            : 'text-lexigo-1'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Separate checking + Editing
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRIME
                                                                            ? 'white'
                                                                            : 'text-lexigo-1'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Dedicated Project Success Team
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center pr-5 mt-3">
                                                                <Check
                                                                    className={`w-4 h-4 mr-2 flex-none feather-check ${
                                                                        props.selectedPackage === PricingPackage.PRIME
                                                                            ? 'white'
                                                                            : 'text-lexigo-1'
                                                                    }`}
                                                                />
                                                                <div className="text-custom-lexigo">
                                                                    Veteran qualified translator
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col mt-auto sm:mt-auto px-5">
                                                            <div className="flex items-center justify-center">
                                                                <Clock className="w-4 h-4 mr-2" />
                                                                <div className="text-xs">Estimated delivery by</div>
                                                            </div>
                                                            <div
                                                                className={`p-3 text-xs rounded-3px mt-2 text-center bg-change tooltip primetimedelivery pointer ${
                                                                    props.selectedPackage === PricingPackage.PRIME
                                                                        ? 'bg-lexigo-3'
                                                                        : 'bg-lexigo-gray-1'
                                                                }`}
                                                            >
                                                                {props.pricing.prime_time}
                                                            </div>
                                                        </div>
                                                        <a
                                                            href="javascript:;"
                                                            className="text-center underline-hover text-xs mt-2 tryExpress"
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            Need it faster? Try Express
                                                        </a>
                                                        <a
                                                            href="javascript:;"
                                                            className="underline-hover text-xs mt-2 text-center remove-express"
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        >
                                                            Remove Express
                                                        </a>

                                                        <div className="flex items-center justify-center mt-5">
                                                            <div
                                                                className="wrapper px-5 w-full"
                                                                onClick={() => {
                                                                    props.handleSelect(PricingPackage.PRIME);
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="selectdetail"
                                                                    id="plan-list-8"
                                                                    value="prime"
                                                                />
                                                                <label
                                                                    for="plan-list-8"
                                                                    className="option option-8 option-6 font-bold"
                                                                >
                                                                    <span>Select</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="font-bold mt-5 text-center text-center text-sm">
                                                            Prime suitable for:
                                                        </div>
                                                        <div
                                                            className="flex flex-col p-5 bg-details bg-details-change mt-5 h-full"
                                                            style={{ minHeight: `760px` }}
                                                        >
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Digital + Ecommerce
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    User reviews, customer support, online help and FAQ,
                                                                    product support, survey results, product
                                                                    descriptions, formal and legal supplier and merchant
                                                                    communication
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Marketing + Communication
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Product and service descriptions, blogs, articles,
                                                                    captions and subtitles, market research and
                                                                    responses, survey results, production ready content,
                                                                    emails, lead-gen driven blogs, guides
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Enterprise + Business
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Marketing material, brochures, websites, software,
                                                                    apps, company profiles and all market-facing
                                                                    content, contracts
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Goverment + Public Sector
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    News, community announcements, country-post content,
                                                                    marketing material
                                                                </div>
                                                            </div>
                                                            <div className="border-b border-gray-200 mt-5 mb-5"></div>
                                                            <div className="flex flex-col items-start mt-3">
                                                                <div className="font-bold text-custom-lexigo">
                                                                    Legal + Justice
                                                                </div>
                                                                <div className="text-left mt-3 text-custom-lexigo">
                                                                    Contracts, Certified Documents, Official Documents,
                                                                    Court Document and other Legal Translation
                                                                    Requirements
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TinySlider>
                                </div>
                            </div>
                            <div
                                className="px-5 text-center mt-10"
                                onClick={() => {
                                    props.setOpenDetailsModal(false);
                                }}
                            >
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn outline w-32 btn-white font-bold"
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PricingDetailsModal;
