import { useState } from 'react';
import useComponentVisible from './useComponentVisible';

const UpdateShareModal = ({ updateUser, data, deleteUser }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const onClick = (role) => {
        updateUser(data.user.id, data.subtitle_id, role);
        setIsComponentVisible(false);
    };

    const [x, setX] = useState(null);
    const [y, setY] = useState(null);

    return (
        <>
            <div className="custom-dropdown custom-dropdown-fixed px-2" ref={ref}>
                <button
                    id=""
                    className={`dropdown-toggle focus:outline-none `}
                    onClick={async () => {
                        setIsComponentVisible(!isComponentVisible);
                        let position = ref.current.getBoundingClientRect();

                        let xTemp = position.x;
                        let yTemp = position.y + ref.current.offsetHeight;

                        let windowHeight = window.innerHeight;
                        let windowWidth = window.innerWidth;
                        let dropdown = ref.current.querySelector('.custom-dropdown-menu');
                        let dropdownHeight = dropdown.clientHeight;
                        let dropdownWidth = dropdown.clientWidth;

                        if (yTemp + dropdownHeight > windowHeight) {
                            yTemp -= dropdownHeight + 10;
                        }
                        if (xTemp + dropdownWidth > windowWidth) {
                            xTemp -= dropdownWidth - ref.current.clientWidth;
                        }

                        setY(yTemp);
                        setX(xTemp);
                    }}
                >
                    <i className="fas fa-ellipsis-h"></i>
                </button>
                <div
                    className={`custom-dropdown-menu ${isComponentVisible ? 'show' : ''}`}
                    style={{
                        left: x + 'px',
                        top: y + 'px',
                    }}
                >
                    <div className="custom-dropdown-menu__content bg-box-dark dark:bg-dark-1 p-2 dropdown-menu-sharelink-6 flex flex-col gap-2">
                        <button
                            className={`${
                                data.role.toLowerCase() === 'editor' ? 'bg-lexigo-green' : 'bg-box-content-dark'
                            } block p-2 transition duration-300 ease-in-out  rounded-md`}
                            onClick={() => onClick('editor')}
                        >
                            Editor
                        </button>
                        {data.details.length === 1 && data.details[0].language_id == -1 ? (
                            ''
                        ) : (
                            <button
                                className={`${
                                    data.role.toLowerCase() === 'commentor' ? 'bg-lexigo-green' : ''
                                } block p-2 transition duration-300 ease-in-out rounded-md`}
                                onClick={() => onClick('commentor')}
                            >
                                Commentor
                            </button>
                        )}
                        <button
                            className={`${
                                data.role.toLowerCase() === 'viewer' ? 'bg-lexigo-green' : 'bg-box-content-dark'
                            } block p-2 transition duration-300 ease-in-out  rounded-md`}
                            onClick={() => onClick('viewer')}
                        >
                            Viewer
                        </button>
                        <div className="divider divider-dark m-0"></div>
                        <button
                            className="block p-2 transition duration-300 ease-in-out bg-box-content-dark rounded-md"
                            onClick={() => deleteUser(data.user.id, data.subtitle_id)}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default UpdateShareModal;
