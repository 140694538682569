/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ChevronDown } from 'react-feather';
import { fetchLexigoLanguage } from '../requests/LanguageRequest';
import useComponentVisible from './useComponentVisible';

export default function TranslationSingleDropdown(props) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    // useEffect(async () => {
    //     if (props.translatedLanguage && props.translatedLanguage.length > 0) {
    //         const data = await fetchTranslation(props.id, props.translatedLanguage[0].language_id);
    //         props.setTranslation(props.formatTranslation(data));
    //         fetchSelectedTranslation(props.translatedLanguage[0].language_id);
    //     }
    // }, [props.translatedLanguage]);

    const fetchSelectedTranslation = async (selectedId) => {
        if (props.translatedLanguage) {
            props.setTranslationSingleSelected(selectedId);
            props.setCurrentLanguage(selectedId);
            if (props.translatedLanguage.filter((e) => e.language_id == selectedId).length > 0) {
                props.setLanguageText(
                    props.translatedLanguage.filter((e) => e.language_id == selectedId)[0].language.language,
                );
            }
            props.setCurrentLanguageObject(await fetchLexigoLanguage(selectedId));
            // props.setTranslationSingleSelected(-1);
        }
    };

    const handleLanguageClick = async (selectedId) => {
        if (selectedId == props.translationSingleSelected) return;
        props.setIsTranslateChange(true);
        fetchSelectedTranslation(selectedId);
        // props.setTranslationSingleDropdown(false); -> ga guan
        // setIsComponentVisible(false);
    };

    return (
        <div className="text-center" ref={ref}>
            <div className="dropdown inline-block" data-placement="bottom-start">
                <button
                    className="dropdown-toggle p-0 border-none box-shadow-none w-56 btn bg-white text-theme-lexigo font-bold text-theme-lexigo"
                    aria-expanded="false"
                    style={{
                        display: 'inline!important',
                    }}
                    disabled={props.translationLoading && props.isSaving ? true : false}
                    onClick={() => {
                        setIsComponentVisible(!isComponentVisible);
                    }}
                >
                    {props.languageText}
                    <ChevronDown className="w-4 h-4 ml-2" />
                </button>
                <div
                    className={`custom-dropdown-menu dropdown-menu-translation-tab w-56  ${
                        isComponentVisible ? 'show' : ''
                    }`}
                >
                    <div className="custom-dropdown-menu__content box py-4 p-5">
                        <div className="container-language">
                            {props.translatedLanguage &&
                                props.translatedLanguage.map((data) => {
                                    return (
                                        <div key={'data-' + data.id} className="form-check mt-2 pl-1">
                                            <input
                                                id={`checkbox-tr-${data.language.id}`}
                                                className="form-check-input"
                                                type="checkbox"
                                                value={data.language.id}
                                                onChange={() => {}}
                                                checked={
                                                    props.translationSingleSelected == data.language.id ? true : false
                                                }
                                                onClick={() => handleLanguageClick(data.language.id)}
                                            />
                                            <label
                                                className="form-check-label text-left"
                                                onClick={() => handleLanguageClick(data.language.id)}
                                                style={{ color: 'inherit' }}
                                            >
                                                {data.language.language}
                                            </label>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
