import debounce from 'lodash/debounce';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useAppState } from '../overmind';
import { updateSubtitle } from '../requests/SubtitleRequest';
import { saveTranslation } from '../requests/TranslateRequest';
import { extractContent } from '../utils';
import TranslationSingleDropdown from './TranslationSingleDropdown';

export default function MobileSubtitleTranslationCard(props) {
    const [tempTranslation, setTempTranslation] = useState([]);
    const [tempSubtitle, setTempSubtitle] = useState([]);
    const state = useAppState();
    const hasSubtitle = useCallback((sub) => tempSubtitle.indexOf(sub), [tempSubtitle]);
    const hasTranslation = useCallback((sub) => tempTranslation.indexOf(sub), [tempTranslation]);

    useEffect(() => {
        const sub = [...json(state.subtitle)];
        setTempSubtitle(sub);
    }, [state.subtitle]);

    useEffect(() => {
        const sub = [...json(state.translation)];
        setTempTranslation(sub);
    }, [state.translation]);

    const debouncedSaveSubtitle = useCallback(
        debounce((sub, nextValue) => {
            props.updateSub(sub, {
                text: nextValue.text,
            });
            updateSubtitle(nextValue);
            props.setIsSaving(false);
        }, 1000),
        [],
    );

    const debouncedSaveTranslation = useCallback(
        debounce((nextValue, signature) => {
            saveTranslation(nextValue, signature);
            props.setIsSaving(false);
        }, 1000),
        [],
    );

    const updateSubtitleText = async (sub, text) => {
        props.setIsSaving(true);
        const index = hasSubtitle(sub);
        if (index < 0) return;
        let newSub = [...tempSubtitle];
        newSub[index].text = text;
        debouncedSaveSubtitle(newSub[index]);
        setTempSubtitle(newSub);
    };

    const updateTranslationText = async (sub, text) => {
        props.setIsSaving(true);
        const index = hasTranslation(sub);
        if (index < 0) return;
        let newSub = [...tempTranslation];
        newSub[index].text = text;
        debouncedSaveTranslation(newSub[index], props.user.signature);
        setTempTranslation(newSub);
    };

    const debouncedTranslationUpdate = useDebouncedCallback((sub, text) => {
        props.updateTranslation(sub, {
            text: text,
        });
    }, 1000);

    return (
        <div className="box box-shadow-none rounded-3px border border-color p-5">
            <div className="text-center text-theme-lexigo font-bold">
                {props.projectData ? props.projectData.file.language.language : ''} (Original Language) /{' '}
                <TranslationSingleDropdown {...props} />
            </div>

            <div className="pr-5" style={{ overflow: 'auto', height: `calc(100vh - 200px)` }}>
                {tempSubtitle.map((s, i) => {
                    return (
                        <div
                            key={i}
                            id={`mobile-subtitle-box-${i}`}
                            className="p-5 border-1 rounded-3px mt-5"
                            style={{ position: 'relative' }}
                        >
                            <div className="space-y-2">
                                <input
                                    type="text"
                                    className="border p-2 text-content w-full"
                                    value={extractContent(s.text || '', false)}
                                    onChange={(event) => {
                                        updateSubtitleText(s, event.target.value);
                                    }}
                                />
                                <input
                                    type="text"
                                    className="border p-2 text-content w-full"
                                    value={
                                        props.translationHeader && props.translationHeader.locked
                                            ? 'Translating...'
                                            : tempTranslation[i] && extractContent(tempTranslation[i].text)
                                    }
                                    onChange={(event) => {
                                        if (
                                            props.access &&
                                            (props.access.accessMapping.role.toLowerCase() == 'owner' ||
                                                props.access.accessMapping.role.toLowerCase() == 'editor')
                                        ) {
                                            props.setActiveTranslationDetailString(event.target.value);
                                            updateTranslationText(tempTranslation[i], event.target.value);
                                            debouncedTranslationUpdate(tempTranslation[i], event.target.value);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
