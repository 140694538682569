/* eslint-disable no-lone-blocks */

import { Check } from 'react-feather';
import { useAppState } from '../overmind';
import HalfThreeDotsLoader from './HalfThreeDotsLoader';

/* eslint-disable jsx-a11y/anchor-is-valid */
const TermsTab = (props) => {
    const state = useAppState();

    const handleTermSelect = (termId) => {
        const ref = props.translationSegmentRef(`translation-ref-${state.segmentIdx}`).current;
        if (ref === null) return;
        let currentText = ref.value;
        const target = state.terms[termId].target;
        if (currentText.includes(target)) {
            currentText = currentText.replace(target, '');
        } else {
            currentText += target;
        }

        const valueSetter = Object.getOwnPropertyDescriptor(ref, 'value').set;
        const prototype = Object.getPrototypeOf(ref);
        const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

        if (valueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(ref, currentText);
        } else {
            valueSetter.call(ref, currentText);
        }
        const event = new Event('input', { bubbles: true });

        ref.dispatchEvent(event);
    };
    return (
        <div id="terms" className="tab-pane active" role="tabpanel" aria-labelledby="terms-tab">
            {/*<div className="text-xl font-bold color-lexigo-1">Terms</div>*/}
            <div className="min-h-sidetab-terms">
                {props.termsAndTmLoading ? (
                    <div
                        className="col-span-12 flex flex-col justify-end items-center h-full"
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '75vh',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                        }}
                    >
                        <div
                            className="col-span-12 flex flex-col justify-end items-center h-full"
                            style={{ justifyContent: 'center' }}
                        >
                            <i className="w-32 h-32">
                                <HalfThreeDotsLoader />
                            </i>
                        </div>
                    </div>
                ) : !state.terms || state.terms.length <= 0 ? (
                    <div className="bg-f6 rounded-3px p-4 pr-2 text-xs">No Data Available</div>
                ) : (
                    <div className="bg-f6 rounded-3px p-4 pr-2 text-xs">
                        This segment contains crucial terminology that is hihgly recommended for use. Please only apply
                        your own terminology if the below terms does not work in-context. <br />
                        <br />
                        Simply click the target term below to add to segment.
                    </div>
                )}

                {state.terms &&
                    state.terms.map((term, i) => {
                        return (
                            <div
                                key={'term-' + i}
                                className={`flex items-center mt-5

                            `}
                                onClick={() => {
                                    handleTermSelect(i);
                                }}
                            >
                                <div className="font-bold mr-3 w-1/2">{term.source}</div>
                                <div className={`w-1/2 `}>
                                    <div
                                        id="terms-segment"
                                        className={`flex items-center terms-segment
                                    ${
                                        state.translation[state.segmentIdx] &&
                                        state.translation[state.segmentIdx].text !== undefined &&
                                        state.translation[state.segmentIdx].text.includes(term.target)
                                            ? 'terms-segment-after-click'
                                            : ''
                                    }
                                    `}
                                    >
                                        <a className="text-xs flex-1">{term.target}</a>
                                        <Check
                                            className={`w-4 h-4 ml-2 check-segment ${
                                                state.translation[state.segmentIdx] &&
                                                state.translation[state.segmentIdx].text !== undefined &&
                                                state.translation[state.segmentIdx].text.includes(term.target)
                                                    ? ''
                                                    : 'hide-check'
                                            }`}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default TermsTab;
