/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
import { Modal } from '@mui/material';
import React, { useState } from 'react';
import { XCircle } from 'react-feather';
import { useActions } from '../../overmind';
import { fetchSubtitleStyle } from '../../requests/SubtitleRequest';
import { applyTemplate } from '../../requests/TemplateRequest';
const LoadTemplateModal = (props) => {
    const [selectedTemplateId, setSelectedTemplateId] = useState('0');
    const [loading, setLoading] = useState(false);
    const actions = useActions();

    const loadTemplate = async () => {
        setLoading(true);
        props.fetchSubtitle(props.id);
        if (selectedTemplateId === '0') {
            setLoading(false);
            return;
        }
        let ratio;
        let newConfig;
        if (props.translateState) {
            ratio = (await applyTemplate(selectedTemplateId, props.id, props.currentLanguage)).ratio;
            newConfig = await fetchSubtitleStyle(props.id, ratio, props.currentLanguage);
        } else {
            ratio = (await applyTemplate(selectedTemplateId, props.id)).ratio;
            newConfig = await fetchSubtitleStyle(props.id, ratio);
        }
        props.setProjectData((prevData) => ({
            ...prevData,
            watermark_url: newConfig.watermark_url,
            cover_url: newConfig.cover_url,
            cover: newConfig.cover,
        }));
        props.setConfig({
            bold: newConfig.style.bold === 1,
            underline: newConfig.style.underline === 1,
            italic: newConfig.style.italic === 1,
            align: newConfig.style.text_align,
            position: newConfig.style.subtitle_position.position,
            fontColor: newConfig.style.font_color,
            backgroundColor: newConfig.style.background_color,
            effect: newConfig.style.effect,
            fontSize: newConfig.style.font_size,
            fontFamily: newConfig.style.font,
            coverFileName: newConfig.cover?.file_name,
            watermarkX: newConfig.style.watermark_x,
            watermarkY: newConfig.style.watermark_y,
            watermarkFileName: newConfig.style.watermark_file_name,
            watermarkOpacity: newConfig.style.watermark_opacity,
            heading: newConfig.style.heading,
            ratio: ratio,
            heading_bold: newConfig.style.heading_bold === 1,
            heading_underline: newConfig.style.heading_underline === 1,
            heading_italic: newConfig.style.heading_italic === 1,
            heading_align: newConfig.style.heading_text_align,
            heading_position: newConfig.style.heading_subtitle_position.position,
            heading_fontColor: newConfig.style.heading_font_color,
            heading_backgroundColor: newConfig.style.heading_background_color,
            heading_effect: newConfig.style.heading_effect,
            heading_fontSize: newConfig.style.heading_font_size,
            heading_fontFamily: newConfig.style.heading_font,
            hideTranslateModal: newConfig.header.hide_translate_modal,
            hideVoiceOverModal: newConfig.header.hide_voice_over_modal,
            headingTime: newConfig.style.heading_time,
            customHeadingTime: newConfig.style.heading_time > 0 ? true : false,
            marginV: newConfig.style.marginV,
            heading_pos_x: newConfig.style.heading_pos_x,
            heading_pos_y: newConfig.style.heading_pos_y,
            box_highlight: newConfig.style.box_highlight,
            box_highlight_color: newConfig.style.box_highlight_color,
        });
        setLoading(false);
        props.setLoadTemplateModal(false);
        setSelectedTemplateId('0');
        setTimeout(() => {
            actions.setVoiceOverLoading(false);
        }, 1000);
    };

    return (
        <Modal
            open={props.loadTemplateModal}
            onClose={() => {
                props.setLoadTemplateModal(false);
            }}
        >
            <div
                id="modal-download"
                className="modal show"
                style={{
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    zIndex: 10000,
                }}
                tabIndex="-1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-xl-custom">
                    <div className="modal-content modal-transparent h-full border-0">
                        <a data-dismiss="modal" href="javascript:;" onClick={() => props.setLoadTemplateModal(false)}>
                            {' '}
                            <XCircle className="w-8 h-8 text-gray-100" />{' '}
                        </a>
                        <div className="modal-body h-full">
                            <div className="grid grid-cols-12 gap-5">
                                <div className="col-span-4"></div>
                                <div className="col-span-12 lg:col-span-4">
                                    <div className="p-5 text-center mt-5">
                                        <div className="text-3xl font-bold" id="create-folder-text">
                                            Load Template
                                        </div>
                                    </div>
                                    <div className="p-5 mt-5">
                                        <input type="hidden" id="folder_id" name="folder_id" value="" />
                                        <input type="hidden" name="project_id" value="{{$project->id}}" />
                                        <div className="">
                                            <label
                                                for=""
                                                className="form-label font-bold text-title text-base rounded-3px"
                                            >
                                                Template name
                                            </label>
                                            <select
                                                onChange={(event) => {
                                                    setSelectedTemplateId(event.target.value);
                                                }}
                                                name="parent_id"
                                                id="selectFolderSaveto"
                                                className="form-control"
                                            >
                                                <option value={0} selected={selectedTemplateId == '0'}>
                                                    Select template
                                                </option>
                                                {props.template &&
                                                    props.template.map((t, i) => (
                                                        <option
                                                            key={i}
                                                            value={t.id}
                                                            selected={selectedTemplateId == t.id}
                                                        >
                                                            {t.name} ({t.ratio})
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div className="mt-5">
                                            <div className="flex items-center justify-center">
                                                <button
                                                    onClick={() => {
                                                        props.setLoadTemplateModal(false);
                                                    }}
                                                    className="btn font-bold mr-5 rounded-3px w-full"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-lexigo-soft-green text-white font-bold rounded-3px w-full"
                                                    id="selectTemplate"
                                                    onClick={loadTemplate}
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Processing' : 'Select'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default LoadTemplateModal;
