import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Test from './components/TestFooter';
import AudioStudio from './pages/AudioStudio';
import CreateSubtitle from './pages/CreateSubtitle';
import LiveCaption from './pages/LiveCaption';
import NotFound from './pages/NotFound';
import StudioNew from './pages/Studio';
import LiveStream from './pages/LiveStream';
import WatchStream from './pages/WatchStream';

const theme = createTheme({
    palette: {
        primary: {
            main: '#17BDB7',
            contrastText: '#fff',
        },
        light: {
            main: '#fff',
            contrastText: '#000',
        },
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(22, 23, 30, 0.8)',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#2B2C39',
                    color: '#ffffff',
                    fontSize: '.8rem',
                    fontFamily: 'GorditaRegular',
                    padding: '.35rem .5rem',
                },
                arrow: {
                    '::before': {
                        backgroundColor: '#2B2C39',
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                    border: 'none',
                    ':hover, :active, :focus': {
                        backgroundColor: 'transparent!important',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: 'GorditaRegular',
                    borderBottom: 'unset',
                    padding: '0.75rem 0.5rem',
                    border: '0 solid #17BDB7',
                },
                head: {
                    padding: '.5rem .25rem',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderSpacing: '0 .5rem',
                    height: '1px',
                    tableLayout: 'fixed',
                    whiteSpace: 'nowrap',
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: 'hidden',
                    '&::-webkit-scrollbar-track': {
                        marginTop: '100px',
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    borderRadius: '3px',
                    backgroundColor: 'transparent',
                    ' > td': {
                        backgroundColor: '#F6F8F9!important',
                    },
                    ' > td:first-child': {
                        borderTopLeftRadius: '3px',
                        borderBottomLeftRadius: '3px',
                    },
                    ' > td:last-child': {
                        borderTopRightRadius: '3px',
                        borderBottomRightRadius: '3px',
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'unset',
                    margin: 0,
                    borderBottomWidth: '1px',
                    '&.Mui-expanded': {
                        margin: 0,
                        borderBottomWidth: '1px',
                    },
                    '&::before': {
                        display: 'none',
                    },
                    ':last-of-type': {
                        borderRadius: 0,
                        borderBottomWidth: 0,
                    },
                    height: '57px',
                    transition:
                        'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                },
                gutters: {
                    '&.Mui-expanded': {
                        height: '100%',
                    },
                },
                region: {
                    height: '100%',
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    minHeight: '57px',
                    color: '#2D3748',
                    fontFamily: 'GorditaRegular',
                    fontSize: '12.8px',
                    '&.Mui-expanded': {
                        minHeight: '57px',
                        color: '#2f5ad8',
                        fontFamily: 'GorditaBold',
                    },
                },
                content: {
                    '&.Mui-expanded': {
                        margin: '12px 0',
                    },
                    alignItems: 'center',
                },
                expandIconWrapper: {
                    '&.Mui-expanded': {
                        color: '#2f5ad8',
                    },
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    transitionDuration: '300ms!important',
                    height: 0,
                },
                entered: {
                    height: '100%!important',
                },
                wrapper: {
                    height: '100%',
                },
                wrapperInner: {
                    height: '100%',
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    height: '100%',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: '200px',
                    backgroundColor: 'rgba(203,213,224,1)',
                },
                bar: {
                    borderRadius: '200px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    ':hover, :active, :focus': {
                        backgroundColor: '#0c1352!important',
                        borderColor: '#0c1352!important',
                        color: '#ffffff!important',
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(255,255,255,.4)',
                },
            },
        },
    },
});

const App = () => {
    // const [cookies, setCookie] = useCookies(['u']);
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Switch>
                    <PublicRoute restricted={true} component={CreateSubtitle} path="/create" exact />
                    <PublicRoute restricted={true} component={Test} path="/test" exact />
                    <PublicRoute restricted={true} component={WatchStream} path="/watch-stream" exact />
                    {/* <PublicRoute restricted={true} component={Auth} path="/auth" exact /> */}
                    {/* <PublicRoute restricted={true} component={ListSubtitleProject} path="/" exact /> */}
                    <PublicRoute restricted={true} component={NotFound} path="/404" exact />
                    <PrivateRoute restricted={true} component={StudioNew} path="/edit-subtitle" exact />
                    <PrivateRoute restricted={true} component={AudioStudio} path="/edit-audio-subtitle" exact />
                    <PrivateRoute restricted={true} component={LiveCaption} path="/live-caption" exact />
                    <PrivateRoute restricted={true} component={LiveStream} path="/live-stream" exact />
                    {/* <PublicRoute restricted={true} component={SignIn} path="/signin" exact /> */}
                    {/* <PrivateRoute component={Studio} path="/studio" exact /> */}
                </Switch>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
