import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Route } from 'react-router-dom';
import { getUserBySignatureAndSubtitleId } from '../requests/AccessRequest';
import { getUserBySignature } from '../requests/auth';
import ThreeDotsLoader from './ThreeDotsLoader';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [cookies, setCookie] = useCookies(['u']);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);

    useEffect(() => {
        const query = new URLSearchParams(rest.location.search);
        const userSignature = query.get('u');
        const needToBeSaved = query.get('s');
        const subtitleId = query.get('id');
        const isShared = query.get('shared');

        const checkLogin = async (signature) => {
            let res = await getUserBySignature(signature);
            if (needToBeSaved === '1') {
                setIsLoggedIn(res.status);
                if (res.status) {
                    setCookie('u', userSignature, { path: '/', maxAge: 3600 * 24 });
                }
                window.history.replaceState(
                    null,
                    'Studio — SCRIBE by LEXIGO',
                    window.location.href.replace('&s=1', ''),
                );
                setLoadingComplete(true);
            } else {
                if (res.status) {
                    if (res.data.type === 'internal') {
                        if (cookies.u !== signature) {
                            if (isShared === '1') {
                                const access = await getUserBySignatureAndSubtitleId(subtitleId, userSignature);
                                if (access.status) {
                                    if (access.data.accessMapping.role != 'owner') {
                                        setIsLoggedIn(true);
                                        setLoadingComplete(true);
                                        return;
                                    }
                                }
                            }
                            setIsLoggedIn(false);
                        } else {
                            setIsLoggedIn(true);
                        }
                    } else {
                        setIsLoggedIn(true);
                    }
                }
                setLoadingComplete(true);
            }
        };
        checkLogin(userSignature);
    }, []);

    if (loadingComplete) {
        return (
            <Route
                {...rest}
                render={(props) =>
                    isLoggedIn ? (
                        <Component {...props} />
                    ) : (
                        // (window.location.href = process.env.REACT_APP_BACKEND_URL.replace('/api', ''))
                        window.location.replace('/404')
                    )
                }
            />
        );
    } else {
        return (
            <div
                className="col-span-12 flex flex-col justify-end items-center h-full"
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    marginTop: 'auto',
                    overflow: 'hidden',
                    marginBottom: 'auto',
                    zIndex: 999999,
                }}
            >
                <div
                    className="col-span-12 flex flex-col justify-end items-center h-full"
                    style={{ justifyContent: 'center', marginLeft: '3rem' }}
                >
                    <i className="w-32 h-32">
                        <ThreeDotsLoader />
                    </i>
                </div>
            </div>
        );
        // (
        //     <div
        //         className="col-span-12 flex flex-col justify-end items-center h-full"
        //         style={{
        //             justifyContent: 'center',
        //             alignItems: 'center',
        //             height: '100vh',
        //             marginTop: 'auto',
        //             marginBottom: 'auto',
        //         }}
        //     >
        //         <div
        //             className="col-span-12 flex flex-col justify-end items-center h-full"
        //             style={{ justifyContent: 'center' }}
        //         >
        //             <i className="w-32 h-32">
        //                 <ThreeDotsLoader />
        //             </i>
        //         </div>
        //     </div>
        // );
    }
};

export default PrivateRoute;
