import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getIp = async () => {
    return axios.get(BACKEND_URL + `/ip`).then((res) => {
        return res.data;
    });
};

export const getTotalDurationRequest = async (userId, workspace_id) => {
    return axios
        .get(BACKEND_URL + `/duration/${userId}`, {
            workspace_id: workspace_id,
        })
        .then((res) => {
            return res.data;
        });
};
