import React, { useState } from 'react';
import { fetchSubtitleStyle } from '../requests/SubtitleRequest';
import ChevronDownIcon from './Icon/ChevronDownIcon.js';
import useComponentVisible from './useComponentVisible.js';

const AspectRatioPicker = ({ id, config, setConfig, setProjectData, projectData, currentLanguage, translateState }) => {
    const [aspectRatio] = useState(['16:9', '9:16', '1:1', '4:5']);
    const desc = [
        'Good for Facebook, Instagram, LinkedIn, TikTok, Vimeo and YouTube in landscape view',
        'Good for Facebook and Instagram Stories, LinkedIn, Snapchat, TikTok and YouTube Shorts in portrait view',
        'Good for most social platforms that support media posts',
        'Good for most social platforms that support media posts',
    ];
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const onConfigClick = async (key, value) => {
        let newConfig = await fetchSubtitleStyle(id, value);
        if (translateState) {
            newConfig = await fetchSubtitleStyle(id, value, currentLanguage);
        }
        setProjectData((prevData) => ({
            ...prevData,
            watermark_url: newConfig.watermark_url,
            cover: newConfig.cover,
            cover_url: newConfig.cover_url,
            header: {
                ...prevData.header,
                aspect_ratio: value,
            },
        }));
        setConfig({
            bold: newConfig.style.bold === 1,
            underline: newConfig.style.underline === 1,
            italic: newConfig.style.italic === 1,
            align: newConfig.style.text_align,
            position: newConfig.style.subtitle_position.position,
            fontColor: newConfig.style.font_color,
            backgroundColor: newConfig.style.background_color,
            effect: newConfig.style.effect,
            fontSize: newConfig.style.font_size,
            fontFamily: newConfig.style.font,
            watermarkX: newConfig.style.watermark_x,
            watermarkY: newConfig.style.watermark_y,
            watermarkFileName: newConfig.style.watermark_file_name,
            watermarkOpacity: newConfig.style.watermark_opacity,
            heading: newConfig.style.heading,
            coverFileName: newConfig.cover?.file_name,
            ratio: value,
            heading_bold: newConfig.style.heading_bold === 1,
            heading_underline: newConfig.style.heading_underline === 1,
            heading_italic: newConfig.style.heading_italic === 1,
            heading_align: newConfig.style.heading_text_align,
            heading_position: newConfig.style.heading_subtitle_position.position,
            heading_fontColor: newConfig.style.heading_font_color,
            heading_backgroundColor: newConfig.style.heading_background_color,
            heading_effect: newConfig.style.heading_effect,
            heading_fontSize: newConfig.style.heading_font_size,
            heading_fontFamily: newConfig.style.heading_font,
            hideTranslateModal: newConfig.header.hide_translate_modal,
            hideVoiceOverModal: newConfig.header.hide_voice_over_modal,
            headingTime: newConfig.style.heading_time,
            customHeadingTime: newConfig.style.heading_time > 0 ? true : false,
            marginV: newConfig.style.marginV,
            heading_pos_x: newConfig.style.heading_pos_x,
            heading_pos_y: newConfig.style.heading_pos_y,
            box_highlight: newConfig.style.box_highlight,
            box_highlight_color: newConfig.style.box_highlight_color|| '#fff'
        });
        setIsComponentVisible(false);
    };
    return (
        <div className="text-center w-full">
            <div className="custom-dropdown custom-dropdown-fixed dropdown-toolbar pr-2 w-full" ref={ref}>
                <button
                    id=""
                    className="dropdown-toggle font-gordita font-bold flex items-center justify-between w-full"
                    onClick={async () => {
                        setIsComponentVisible(!isComponentVisible);
                    }}
                >
                    <div className="flex items-center">
                        {projectData ? projectData.header.aspect_ratio : ''}
                        {projectData && projectData.header.aspect_ratio === projectData.file.original_aspect_ratio ? (
                            <div className="py-1 px-3 ml-3 rounded-full text-xs bg-box-content-dark text-white cursor-pointer font-normal">
                                Original
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <ChevronDownIcon className="w-6 h-6" />
                </button>
                <div
                    className={`custom-dropdown-menu ${isComponentVisible ? 'show' : ''}`}
                    style={{
                        maxWidth: '350px',
                        overflowX: 'visible',
                        maxHeight: 'unset',
                    }}
                >
                    {aspectRatio.map((ratio, i) => (
                        <div
                            className={`custom-dropdown-menu__item flex flex-col ${
                                ratio === (projectData && projectData.header.aspect_ratio) ? 'active' : ''
                            }`}
                            onClick={() => onConfigClick('ratio', ratio)}
                            style={{
                                paddingRight: '.5rem',
                            }}
                        >
                            <div className="flex items-center">
                                {ratio}
                                {ratio === projectData.file.original_aspect_ratio ? (
                                    <div className="py-1 px-3 ml-3 rounded-full text-xs bg-box-content-dark text-white cursor-pointer font-normal">
                                        Original
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <p className="text-left whitespace-normal" style={{ fontSize: '10.8px' }}>
                                {desc[i]}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AspectRatioPicker;
