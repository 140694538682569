import debounce from 'lodash/debounce';
import { json } from 'overmind';
import { useCallback, useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { Copy } from 'react-feather';
import styled from 'styled-components';
import { useAppState } from '../overmind';
import { saveTranslation } from '../requests/TranslateRequest';
import { extractContent } from '../utils';

const Style = styled.div`
    color: #ffffff;
    margin-top: 10px;
    .text:focus {
        outline: none;
        box-shadow: none;
    }
    .plain-text-highlight {
        background-color: #9bcce3;
    }
`;
export default function TranslationPlainTextEditor({
    checkTranslation,
    updateTranslation,
    removeTranslation,
    setTranslation,
    translationLoading,
    setIsSaving,
    user,
}) {
    const [height, setHeight] = useState(100);
    const [tempTranslation, setTempTranslation] = useState([]);
    const state = useAppState();
    const [copied, setCopied] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const resize = useCallback(() => {
        setHeight(document.body.clientHeight - 170);
    }, [setHeight]);

    const hasSub = useCallback((sub) => tempTranslation.indexOf(sub), [tempTranslation]);

    useEffect(() => {
        resize();
        if (!resize.init) {
            resize.init = true;
            const debounceResize = debounce(resize, 500);
            window.addEventListener('resize', debounceResize);
        }
    }, [resize]);

    useEffect(() => {
        const sub = [...json(state.translation)];
        setTempTranslation(sub);
    }, [state.translation]);

    const debouncedSave = useCallback(
        debounce((nextValue) => {
            if (!user) {
                // console.log('no user');
            }
            saveTranslation(nextValue, user.signature);
            setIsSaving(false);
        }, 1000),
        [],
    );

    const updateText = async (sub, text) => {
        setIsSaving(true);
        const index = hasSub(sub);
        if (index < 0) return;
        let newSub = [...tempTranslation];
        newSub[index].text = text;
        setTempTranslation(newSub);
        debouncedSave(newSub[index]);
    };

    const props = {
        openDeleteModal,
        setOpenDeleteModal,
    };

    const copyToClipboard = () => {
        let subtitleData = '';
        tempTranslation.map((s, i) => {
            if (i > 0) {
                subtitleData += '\n';
            }
            subtitleData += extractContent(s.text);
        });

        setCopied(true);
        navigator.clipboard.writeText(subtitleData);
    };
    useEffect(() => {
        setTimeout(function () {
            setCopied(false);
        }, 5000);
    }, [copied]);

    return (
        <Style>
            <>
                <div className="flex justify-between">
                    <div></div>
                    <button onClick={copyToClipboard}>
                        <Copy
                            stroke="#17BDB7"
                            className="w-4 h-4"
                            style={{
                                display: 'inline',
                                marginBottom: '0.5rem',
                            }}
                        />{' '}
                        {copied ? (
                            <span
                                style={{
                                    fontSize: '10px',
                                    marginLeft: '0.25rem',
                                }}
                            >
                                Copied to clipboard
                            </span>
                        ) : (
                            ''
                        )}
                    </button>
                </div>
                {tempTranslation.map((s, i) => {
                    return (
                        <span key={`translation-span-${s.id}`}>
                            <ContentEditable
                                className={[
                                    'text',
                                    props.currentLanguageObject && props.currentLanguageObject.rtl === 1
                                        ? 'rtl-target-input'
                                        : '',
                                ]
                                    .join(' ')
                                    .trim()}
                                html={s.text}
                                disabled={state.voiceOverAudio !== null}
                                id={`translation-span-${s.id}`}
                                onChange={(e) => {
                                    updateText(s, e.target.value);
                                }} // handle innerHTML change
                                tagName="span"
                            />
                            <span> / </span>
                        </span>
                    );
                })}
            </>
        </Style>
    );
}
