import React, { useEffect, useRef, useState } from 'react';
import { fetchFontFamilies, uploadCustomFont } from '../requests/FontRequest';
import ChevronDownIcon from './Icon/ChevronDownIcon.js';
import useComponentVisible from './useComponentVisible.js';

const FontFamilyPicker = (props) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const uploadRef = useRef();
    const [fontFamilies, setFontFamilies] = useState([]);
    const [fontLoaded, setFontLoaded] = useState(false);
    const [selectedFontFamily, setSelectedFontFamily] = useState({});
    const getData = async () => {
        const fontFamiliesData = await fetchFontFamilies(`${props.user.signature}!${props.id}`);
        // for await (const font of fontFamiliesData) {
        //     if (font.font_path == null) continue;
        //     let custom_font = new FontFace(
        //         font.font_name,
        //         `url(${process.env.REACT_APP_BACKEND_URL}/font/download/${font.id})`,
        //     );
        //     await custom_font
        //         .load()
        //         .then(function (loaded_face) {
        //             document.fonts.add(loaded_face);
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // }

        setFontFamilies(fontFamiliesData);
    };

    useEffect(async () => {
        if (!fontLoaded) {
            await getData();
            setFontLoaded(true);
        }
        findFontFamily(props.fontFamily);
    }, [fontLoaded]);

    const selectFontFamily = (value) => {
        props.onConfigClick(props.name, value);
        findFontFamily(value);
        setIsComponentVisible(false);
    };

    const findFontFamily = (value) => {
        setSelectedFontFamily(fontFamilies.find((e) => e.id == value) || {});
    };

    useEffect(() => {
        findFontFamily(props.fontFamily);
    }, [fontFamilies]);

    useEffect(() => {
        findFontFamily(props.fontFamily);
    }, [props.fontFamily, fontFamilies]);

    return (
        <div
            style={{
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <div className="custom-dropdown custom-dropdown-fixed dropdown-toolbar pr-2 w-full" ref={ref}>
                <button
                    id=""
                    className="dropdown-toggle font-gordita font-bold flex items-center justify-between w-full"
                    onClick={async () => {
                        setIsComponentVisible(!isComponentVisible);
                    }}
                    style={{
                        fontFamily: selectedFontFamily.font_name || 'GorditaRegular',
                    }}
                >
                    {selectedFontFamily.font_name}
                    <ChevronDownIcon className="w-6 h-6" />
                </button>
                <div className={`custom-dropdown-menu ${isComponentVisible ? 'show' : ''}`}>
                    {fontFamilies.map((font, i) => (
                        <div
                            key={i}
                            className={`custom-dropdown-menu__item ${font.id === props.fontFamily ? 'active' : ''}`}
                            style={{
                                fontFamily: font.font_name,
                                marginBlock: 0,
                                marginBlockStart: 0,
                                marginBlockEnd: 0,
                                marginInlineStart: 0,
                                marginInlineEnd: 0,
                                margin: 0,
                                marginBottom: 0,
                            }}
                            onClick={() => selectFontFamily(font.id)}
                        >
                            {font.font_name}
                        </div>
                    ))}
                    <div
                        className="custom-dropdown-menu__item"
                        onClick={() => {
                            if (props.access && props.access.userPackage.custom_fonts) {
                                uploadRef.current.click();
                            }
                        }}
                    >
                        <label className="btn btn-info">
                            {props.access && props.access.userPackage.custom_fonts
                                ? 'Upload custom font'
                                : `Upgrade to upload custom font`}
                        </label>
                    </div>
                </div>
            </div>
            <input
                id="font-file-selector"
                type="file"
                ref={uploadRef}
                className="d-none"
                name="font_file"
                accept=".ttf, .otf"
                onInput={(event) => {
                    uploadCustomFont(event.target.files[0], `${props.user.signature}!${props.id}`).then((data) => {
                        if (data.success === true) {
                            getData();
                        }
                    });
                }}
            />
        </div>
    );
};

export default FontFamilyPicker;
