import React, { useState } from 'react';
import { render } from 'react-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
// import styles from './../css/CreateSubtitle.module.css';
import { createSubtitleProject } from '../requests/SubtitleRequest';
import { useHistory } from 'react-router';

class Thumb extends React.Component {
    state = {
        loading: false,
        thumb: undefined,
    };

    componentWillReceiveProps(nextProps) {
        if (!nextProps.file) {
            return;
        }

        this.setState({ loading: true }, () => {
            let reader = new FileReader();

            reader.onloadend = () => {
                this.setState({ loading: false, thumb: reader.result });
            };

            reader.readAsDataURL(nextProps.file);
        });
    }

    render() {
        const { file } = this.props;
        const { loading, thumb } = this.state;

        if (!file) {
            return null;
        }

        if (loading) {
            return <p>loading...</p>;
        }

        return <img src={thumb} alt={file.name} className="img-thumbnail mt-2" height={200} width={200} />;
    }
}

const CreateSubtitle = () => {
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    return (
        <div className="container">
            <Formik
                initialValues={{ name: null, file: null }}
                onSubmit={async (values) => {
                    setLoading(true);
                    const res = await createSubtitleProject(values.name, values.file);
                    setLoading(false);
                    history.push('/');
                }}
                validationSchema={yup.object().shape({
                    file: yup.mixed().required(),
                })}
                render={({ values, handleSubmit, setFieldValue }) => {
                    return (
                        <div className="form">
                            <h2>Create Subtitle Project</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        onChange={(event) => {
                                            setFieldValue('name', event.currentTarget.value);
                                        }}
                                        placeholder="Project name"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        id="file"
                                        name="file"
                                        type="file"
                                        onChange={(event) => {
                                            setFieldValue('file', event.currentTarget.files[0]);
                                        }}
                                        className="form-control"
                                    />
                                    {/* <Thumb file={values.file} /> */}
                                </div>
                                {!loading ? '' : <p>Creating...</p>}
                                <button disabled={loading} type="submit" className="btn btn-primary">
                                    Create
                                </button>
                            </form>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default CreateSubtitle;
