import React, { useState } from 'react';
import ChevronDownIcon from './Icon/ChevronDownIcon.js';
import useComponentVisible from './useComponentVisible.js';

const PlaybackSpeed = (props) => {
    const playbackOptions = [0.5, 1, 2];

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const selectPlayback = (value) => {
        props.onConfigClick(value);
        setIsComponentVisible(false);
    };

    const [y, setY] = useState(null);

    return (
        <>
            <div className="custom-dropdown dropdown-toolbar relative" ref={ref}>
                <button
                    className="dropdown-toggle font-gordita flex items-center justify-between w-full text-content"
                    onClick={async () => {
                        setIsComponentVisible(!isComponentVisible);
                        let position = ref.current.getBoundingClientRect();

                        let yTemp = position.y + ref.current.offsetHeight;

                        let windowHeight = window.innerHeight;
                        let dropdown = ref.current.querySelector('.custom-dropdown-menu');
                        let dropdownHeight = dropdown.clientHeight;

                        if (yTemp + dropdownHeight > windowHeight) {
                            yTemp = -dropdownHeight;
                        } else {
                            yTemp = ref.current.offsetHeight;
                        }

                        setY(yTemp);
                    }}
                >
                    <span className="text-gray-500 mr-1">Playback speed:</span>
                    <span className="font-bold mr-2">{props.playbackRate}x</span>
                    <ChevronDownIcon className="w-5 h-5" />
                </button>
                <div
                    className={`custom-dropdown-menu ${isComponentVisible ? 'show' : ''}`}
                    style={{
                        right: '0',
                        top: y + 'px',
                    }}
                >
                    {playbackOptions.map((playback, i) => (
                        <div
                            className={`custom-dropdown-menu__item text-left ${
                                playback === props.playbackRate ? 'active' : ''
                            }`}
                            onClick={() => selectPlayback(playback)}
                        >
                            {playback}x
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PlaybackSpeed;
