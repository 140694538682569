import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchTermsAndTm = async (sourcelanguageid, targetlanguageid, sourcetext, companyid) => {
    return axios
        .post(BACKEND_URL + '/segment/terms-tm', {
            sourcelanguageid: sourcelanguageid,
            targetlanguageid: targetlanguageid,
            sourcetext: sourcetext,
            companyid: companyid,
        })
        .then((res) => {
            return res.data;
        });
};

export const fetchTermsAndTmAll = async (sourcelanguageid, targetlanguageid, companyid, fileid) => {
    return axios
        .post(BACKEND_URL + '/segment/terms-tm-all', {
            sourcelanguageid: sourcelanguageid,
            targetlanguageid: targetlanguageid,
            fileid: fileid,
            companyid: companyid,
        })
        .then((res) => {
            return res.data;
        });
};
