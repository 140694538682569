import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const calculatePrice = async (targetLangIds, currency, wordCount, naatiCertified) => {
    return axios
        .post(BACKEND_URL + '/calculate-price', {
            targetLang: targetLangIds,
            currency: currency,
            wordCount: wordCount,
            add_naati_price: naatiCertified ? 1 : 0,
        })
        .then((res) => {
            return res.data;
        });
};
