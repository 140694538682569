import React, { useEffect, useState } from 'react';
import ChevronDownIcon from './Icon/ChevronDownIcon.js';
import useComponentVisible from './useComponentVisible.js';

const LogoPos = (props) => {
    const positionOptions = [
        'Bottom Left',
        'Bottom Center',
        'Bottom Right',
        'Center Left',
        'Center',
        'Center Right',
        'Top Left',
        'Top Center',
        'Top Right',
    ];
    const [selectedPosition, setSelectedPosition] = useState('');

    const setPosition = (value) => {
        if (value === 'Bottom Left') {
            props.onChange('watermarkX', 1);
            props.onChange('watermarkY', 99);
        } else if (value === 'Bottom Center') {
            props.onChange('watermarkX', 50);
            props.onChange('watermarkY', 99);
        } else if (value === 'Bottom Right') {
            props.onChange('watermarkX', 99);
            props.onChange('watermarkY', 99);
        } else if (value === 'Top Left') {
            props.onChange('watermarkX', 1);
            props.onChange('watermarkY', 1);
        } else if (value === 'Top Center') {
            props.onChange('watermarkX', 50);
            props.onChange('watermarkY', 1);
        } else if (value === 'Top Right') {
            props.onChange('watermarkX', 99);
            props.onChange('watermarkY', 1);
        } else if (value === 'Center Left') {
            props.onChange('watermarkX', 1);
            props.onChange('watermarkY', 50);
        } else if (value === 'Center') {
            props.onChange('watermarkX', 50);
            props.onChange('watermarkY', 50);
        } else if (value === 'Center Right') {
            props.onChange('watermarkX', 99);
            props.onChange('watermarkY', 50);
        }
    };
    useEffect(() => {
        getPosition(props.watermarkX, props.watermarkY);
    }, [props.watermarkX, props.watermarkY]);

    const getPosition = (x, y) => {
        if (x == 1 && y == 99) {
            setSelectedPosition('Bottom Left');
        } else if (x == 50 && y == 99) {
            setSelectedPosition('Bottom Center');
        } else if (x == 99 && y == 99) {
            setSelectedPosition('Bottom Right');
        } else if (x == 1 && y == 1) {
            setSelectedPosition('Top Left');
        } else if (x == 50 && y == 1) {
            setSelectedPosition('Top Center');
        } else if (x == 99 && y == 1) {
            setSelectedPosition('Top Right');
        } else if (x == 1 && y == 50) {
            setSelectedPosition('Center Left');
        } else if (x == 50 && y == 50) {
            setSelectedPosition('Center');
        } else if (x == 99 && y == 50) {
            setSelectedPosition('Center Right');
        }
    };
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const selectPosition = (value) => {
        setPosition(value);
        setSelectedPosition(value);
        setIsComponentVisible(false);
    };

    return (
        <>
            <div className="custom-dropdown custom-dropdown-fixed dropdown-toolbar pr-2 w-full" ref={ref}>
                <button
                    id=""
                    className="dropdown-toggle font-gordita font-bold flex items-center justify-between w-full"
                    onClick={async () => {
                        setIsComponentVisible(!isComponentVisible);
                    }}
                >
                    {selectedPosition}
                    <ChevronDownIcon className="w-6 h-6" />
                </button>
                <div className={`custom-dropdown-menu ${isComponentVisible ? 'show' : ''}`}>
                    {positionOptions.map((position, i) => (
                        <div
                            className={`custom-dropdown-menu__item ${position === selectedPosition ? 'active' : ''}`}
                            onClick={() => selectPosition(position)}
                        >
                            {position}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default LogoPos;
