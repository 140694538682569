import { MicrophoneIcon } from '@heroicons/react/24/solid';
import Button from '../Button';
import Icon, { MicrophoneSlashIcon } from '../Icon';
import { Mic, MicOff } from 'react-feather';

export function MuteButton({ muted, handleMicMute, ...additionalProps }) {
  const buttonStyle = muted ? 'destruct' : 'base';
  return (
    // <Button
    //   type={`${buttonStyle}`}
    //   style='round'
    //   onClick={handleMicMute}
    //   {...additionalProps}
    // >
      <button
          className="btn btn-lexigo-green text-white font-bold rounded-3px btn-save-hide-mobile"
          style={{ width: '50px', height: '50px', borderRadius: '50%' }}
          // onClick={() => setMuted(!muted)}
          onClick={handleMicMute}
          {...additionalProps}
      >
      {!muted ? (
        <Icon>
          <MicrophoneIcon className='text-inherit h-6 w-6 white' />
        </Icon>
      ) : (
        <Icon>
          <MicrophoneSlashIcon className='text-inherit h-6 w-6 white' />
        </Icon>
      )}
      </button>
  );
}
