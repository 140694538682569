import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/voice-over';

export const fetchVoiceOverLanguage = async (language) => {
    return axios
        .post(BACKEND_URL + '/languages', {
            language: language,
        })
        .then((res) => {
            return res.data;
        });
};

export const getVoiceOverPreviewWithCustomText = async (
    user_id,
    provider,
    provider_language_code,
    text,
    provider_id,
) => {
    return axios
        .post(BACKEND_URL + '/preview', {
            user_id: user_id,
            provider: provider,
            provider_language_code: provider_language_code,
            text: text,
            provider_id: provider_id,
        })
        .then((res) => {
            return res.data;
        });
};

export const getVoiceOverPreview = async (provider, provider_language_code, provider_id) => {
    return axios
        .post(BACKEND_URL + '/preview', {
            provider: provider,
            provider_language_code: provider_language_code,
            provider_id: provider_id,
        })
        .then((res) => {
            return res.data;
        });
};

export const createVoiceOver = async (user_id, provider, provider_language_code, provider_id, id, is_translation) => {
    return axios
        .post(BACKEND_URL + '/create', {
            user_id: user_id,
            provider: provider,
            provider_language_code: provider_language_code,
            provider_id: provider_id,
            id: id,
            is_translation: is_translation,
        })
        .then((res) => {
            return res.data;
        });
};

export const getVoiceOver = async (id, is_translation) => {
    return axios
        .post(BACKEND_URL + '/data', {
            id: id,
            is_translation: is_translation,
        })
        .then((res) => {
            return res.data;
        });
};
