import { Skeleton } from '@mui/material';
import { json } from 'overmind';
import React, { createRef, useEffect, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import styled from 'styled-components';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import { useActions, useAppState } from '../overmind';
import { createSkipVideo, deleteSkipVideo, fetchSkipVideo, updateSkipVideo } from '../requests/skipVideoRequest';
import Timeline from './Timeline';

const Style = styled.div`
    position: relative;
    width: 100%;
    bottom: 0;
    visibility: hidden;
    height: auto !important;
    overflow: auto hidden;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (min-width: 1024px) {
        visibility: visible;
        height: 100% !important;
    }
    .wave-cursor {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        // top: 5px;
        z-index: 1100;
        display: flex;
        height: 100%;
        flex-direction: column;
    }
    .wave-cursor > div,
    #wave-cursor ::part(scroll) {
        height: 100%;
    }

    #wave-cursor ::part(scroll) > .wrapper {
        height: 100%;
    }

    #wave-cursor ::part(timeline) {
        top: calc(100% - 123px);
        position: relative;
    }

    #wave-cursor ::part(cursor):before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: middle;
        position: absolute;
        right: 0px;
        border: 5px solid transparent;
        border-left-color: #17bdb7;
        border-color: #17bdb7;
        transform: translateX(50%);
    }

    #wave-cursor ::part(cursor) {
        pointer-events: auto;
        cursor: -webkit-grab;
        cursor: grab;
    }

    #wave-cursor ::part(cursor):active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }

    #wave-cursor ::part(cursor):after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: middle;
        position: absolute;
        right: 0px;
        border: 5px solid transparent;
        border-left-color: #17bdb7;
        transform: translateX(50%) translateY(100%) rotate(90deg);
    }
`;
let regionCount = 0;
const SurferCursor = ({ player, setWaveform, duration, setVideoTime, setAudioTime, projectData, ...props }) => {
    const $wavesurfer = useRef();
    const $wavesurferCursorRef = useRef({
        isPlaying: () => false,
    });
    const actions = useActions();
    const state = useAppState();

    const updateRegionData = async (region) => {
        let skipVideoId = region.id.match(/\d+/);
        skipVideoId = skipVideoId ? parseInt(skipVideoId[0]) : null;
        await updateSkipVideo({ id: skipVideoId, start_time: region.start, end_time: region.end });
    };

    const removeRegionData = async (id) => {
        await deleteSkipVideo(id);
    };

    const createRegion = (id, start, end) => {
        const wavesurferCursor = json(state.wavesurferCursor?.current);
        const wsRegions = json(state.wsRegions);
        var el = document.createElement('a');
        el.style.display = 'none';
        el.innerHTML = '&#10005;';
        const regionId = `region-${regionCount++}`;
        el.id = regionId;
        el.title = 'Cancel';
        let region = wsRegions.addRegion({
            start: start,
            end: end,
            content:
                props.access &&
                props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                props.access.accessMapping.role.toLowerCase() !== 'editor'
                    ? ''
                    : el,
            color: 'rgba(255, 0, 0, 0.1)',
            drag: true,
            resize: true,
            id: `region-trim skip-${id}`,
            drag: !(
                props.access &&
                props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                props.access.accessMapping.role.toLowerCase() !== 'editor'
            ),
            resize: !(
                props.access &&
                props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                props.access.accessMapping.role.toLowerCase() !== 'editor'
            ),
        });
        el.onclick = () => {
            region.remove();
            removeRegionData(id);
        };
        wsRegions.on('region-in', (region) => {
            wavesurferCursor.setTime(region.end);
        });
        wsRegions.on('region-updated', (region) => {
            updateRegionData(region);
        });
        const waveCursor = document.getElementById('wave-cursor');
        let shadowRoot;
        const wavesurferCursorDiv = waveCursor.querySelector('div');
        if (wavesurferCursorDiv) {
            shadowRoot = wavesurferCursorDiv.shadowRoot;
        }
        region.on('click', (event) => {
            event.stopPropagation();
        });
        region.on('over', (event) => {
            if (
                props.access &&
                props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                props.access.accessMapping.role.toLowerCase() !== 'editor'
            )
                return;
            shadowRoot.querySelector(`#${regionId}`).style.display = 'flex';
            shadowRoot.querySelector(`#${regionId}`).style.cursor = 'pointer';
            shadowRoot.querySelector(`#${regionId}`).style.backgroundColor = 'red';
        });
        region.on('leave', (event) => {
            if (
                props.access &&
                props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                props.access.accessMapping.role.toLowerCase() !== 'editor'
            )
                return;
            shadowRoot.querySelector(`#${regionId}`).style = 'display:none';
        });
    };

    useEffect(async () => {
        const wavesurferCursor = json(state.wavesurferCursor?.current);
        const wsRegions = json(state.wsRegions);
        if (state.triggerTrimVideo && wavesurferCursor && wsRegions) {
            const start =
                state.playerTime + 2 > wavesurferCursor.getDuration() ? state.playerTime - 2 : state.playerTime;
            const end = state.playerTime + 2 > wavesurferCursor.getDuration() ? state.playerTime : state.playerTime + 2;
            const skipVideoData = await createSkipVideo({
                subtitle_translation_id: props.translateState ? props.translationHeader.id : props.id,
                start_time: start,
                end_time: end,
            });
            createRegion(skipVideoData.id, start, end);
        }
        actions.setTriggerTrimVideo(false);
    }, [state.triggerTrimVideo]);

    useEffect(async () => {
        const wsRegions = json(state.wsRegions);
        if (wsRegions) {
            wsRegions.clearRegions();
        }
        if (!props.translateState) {
            if (props.id) {
                const skipVideoData = await fetchSkipVideo(props.id);
                skipVideoData.forEach((data) => {
                    createRegion(data.id, data.start_time, data.end_time);
                });
                actions.setSkipVideoData(skipVideoData);
            }
        } else {
            if (props.translationHeader) {
                const skipVideoData = await fetchSkipVideo(props.translationHeader.id);
                skipVideoData.forEach((data) => {
                    createRegion(data.id, data.start_time, data.end_time);
                });
            }
        }
    }, [props.id, props.translateState, props.translationHeader]);

    useEffect(() => {
        try {
            const wavesurferCursor = WaveSurfer.create({
                container: $wavesurfer.current,
                media: document.querySelector('#video') || document.querySelector('#audio'),
                peaks: new Array(1000).fill(0),
                waveColor: 'rgba(0, 0, 0,0)',
                progressColor: 'rgba(0, 0, 0,0)',
                minPxPerSec: 80,
                autoCenter: false,
                cursorColor: '#17bdb7',
            });
            wavesurferCursor.registerPlugin(
                TimelinePlugin.create({
                    style: {
                        color: '#ffffff',
                    },
                    insertPosition: 'beforebegin',
                    timeInterval: 1,
                }),
            );
            const wsRegions = wavesurferCursor.registerPlugin(RegionsPlugin.create());
            wavesurferCursor.on('ready', (e) => {
                $wavesurferCursorRef.current = wavesurferCursor
                actions.setWavesurferCursor($wavesurferCursorRef);

                const time = wavesurferCursor.getDuration();
                const waveCursor = document.getElementById('wave-cursor');
                if (waveCursor) {
                    const wavesurferCursorDiv = waveCursor.querySelector('div');
                    if (wavesurferCursorDiv) {
                        const shadowRoot = wavesurferCursorDiv.shadowRoot;
                        if (shadowRoot) {
                            const style = document.createElement('style');
                            style.innerHTML = `
                            :host .scroll::-webkit-scrollbar {
                                width: 5px;
                                height: 5px;
                            }
                            :host .scroll::-webkit-scrollbar-thumb {
                                background: #525965;
                                border: 0px none #ffffff;
                                border-radius: 0px;
                            }

                            :host div[part="region region-trim"] {
                                border: solid 2px rgba(255, 0, 0, 1);
                                border-left: solid 2px rgba(255, 0, 0, 1)!important;
                                border-radius: 3px!important;
                                z-index: 2000!important;
                                background-color: rgba(255, 0, 0, 0.1)!important;
                            }
                            :host div[data-resize="left"] {
                                border-left: 6px solid rgba(255, 0, 0, 1)!important;
                            }
                            :host div[data-resize="right"] {
                                border-right: 6px solid rgba(255, 0, 0, 1)!important;
                            }
                             :host div[data-resize="right"], :host div[data-resize="left"]{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                             } 
                            :host div[data-resize="left"]:before, :host div[data-resize="right"]:before {
                                content: "";
                                width: 2.5px;
                                height: 10px;
                                display: block;
                                position:absolute;
                                background: rgba(0,0,0,0.3);
                            }
                            :host div[data-resize="right"]:before {
                                right: -5px
                            }
                            :host div[data-resize="left"]:before {
                                left: -5px
                            }

                            :host a[part="region-content"] {
                                position: absolute;
                                background: rgba(255, 0, 0, 1);
                                right: 10px;
                                color: #fff;
                                border-radius: 50%;
                                width: 15px;
                                height: 15px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 10px;
                                top: 5px;
                            }

                            :host div[part="scroll"] > .wrapper {
                                height: 100%
                            }
                        `;
                            shadowRoot.appendChild(style);
                        }
                    }
                }
                try {
                    setVideoTime(time);
                } catch (e) {
                    setAudioTime(time);
                }
            });
            unstable_batchedUpdates(() => {
                // actions.setWavesurferCursor($wavesurfer);
                actions.setWsRegions(wsRegions);
            });
            return () => {
                wavesurferCursor.destroy();
            };
        } catch (e) {}
    }, [player, $wavesurfer, setWaveform, duration, setVideoTime]);

    return <div className="wavesurfer wave-cursor" id="wave-cursor" ref={$wavesurfer} />;
};

export default function NewFooter(props) {
    const $footer = createRef();
    const state = useAppState();
    const actions = useActions();

    useEffect(() => {
        const wavesurferCursor = json(state.wavesurferCursor?.current);
        if (wavesurferCursor) {
            actions.setCenterSecond($footer.current.offsetWidth / 2 / state.waveformZoom);
            actions.setWaveformDuration($footer.current.offsetWidth / state.waveformZoom);
        }
    }, [$footer, actions, state.waveformZoom]);

    return (
        <Style className="footer" ref={$footer} id="footer">
            {props.player ? (
                <React.Fragment>
                    <SurferCursor {...props} />

                    {state.voiceOverLoading ? (
                        <Skeleton variant="rounded" height={45} className="skeleton timeline-skeleton" />
                    ) : (
                        <Timeline {...props} key={props.translateState} />
                    )}
                </React.Fragment>
            ) : null}
        </Style>
    );
}
