import { LinearProgress, Modal } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { X } from 'react-feather';
import { useActions } from '../overmind';
import { fetchTranslation } from '../requests/TranslateRequest';

const UploadTranscriptionModal = ({
    openUploadTranscriptModal,
    setUploadTranscriptModal,
    currentLanguageObject,
    formatTranslation,
    projectData,
}) => {
    const [isUploading, setIsUploading] = useState(false);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        accept: {
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx', '.doc'],
        },
    });
    const actions = useActions();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        upload(acceptedFiles[0]);
    }, [acceptedFiles]);

    const upload = async (file) => {
        if (!file) return;
        const targetLang = currentLanguageObject.id;
        const sourceId = projectData.header.id;

        setIsUploading(true);
        let formData = new FormData();
        formData.append('scribe_id', sourceId);
        formData.append('target_lang', targetLang);
        formData.append('file', acceptedFiles[0]);
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress > 80) {
                    clearInterval(timer);
                    return oldProgress;
                }
                const diff = Math.random() * 5;
                return Math.min(oldProgress + diff, 100);
            });
        }, 1000);

        axios
            .post(`${process.env.REACT_APP_LEXIGO_API_URL}/segment/bulk-translate-from-file-scribe`, formData)
            .then(async function (response) {
                setProgress(100);
                clearInterval(timer);
                //handle success
                const data = await fetchTranslation(sourceId, targetLang);
                const newData = formatTranslation(data);
                unstable_batchedUpdates(() => {
                    actions.setTranslation(newData);
                    setIsUploading(false);
                    setProgress(0);
                });
                setUploadTranscriptModal(false);
            })
            .catch(function (response) {
                //handle error
                setProgress(0);
                alert('Error');
                // console.log(response);
                clearInterval(timer);
            });
    };

    return (
        <>
            <Modal
                open={openUploadTranscriptModal}
                onClose={() => {
                    setUploadTranscriptModal(false);
                }}
            >
                <div
                    id="modal-upload-transcription"
                    className={`modal modal-upload ${openUploadTranscriptModal ? 'show' : ''}`}
                    style={{
                        marginTop: '0px',
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        zIndex: 10000,
                    }}
                    data-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    role="dialog"
                >
                    <div className="modal-dialog h-100vh background-none">
                        <div className="modal-content">
                            <a
                                className="close-btn"
                                data-dismiss="modal"
                                href="javascript:;"
                                onClick={() => setUploadTranscriptModal(false)}
                            >
                                <X className="w-5 h-5" />
                            </a>
                            <div className="modal-title">Upload File/s</div>
                            <div className="dropzone-wrapper">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <div className="dropzone-action">
                                        <div className="dropzone-text">Drag and drop a file here</div>
                                        <span>or</span>
                                        <div className="file-btn">
                                            <input disabled={isUploading} {...getInputProps()} />
                                            <span className="text-btn">Select from computer</span>
                                        </div>
                                    </div>
                                </div>
                                {isUploading ? (
                                    <div style={{ marginTop: '1rem' }}>
                                        <LinearProgress variant="determinate" value={progress} />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UploadTranscriptionModal;
