import { useCallback, useEffect, useRef } from 'react';
import { ARIAL_ASCENDER, ARIAL_DESCENDER, ARIAL_XHEIGHT } from '../Player';
import { useActions, useAppState } from '../../overmind';
import { debounce } from 'lodash';
import styles from '../../css/SubtitleEdit.module.css';
import { removePositionTag } from '../../utils';
import { json } from 'overmind';
import { updateSubtitle } from '../../requests/SubtitleRequest';
import { saveTranslation } from '../../requests/TranslateRequest';
import ContentEditable from 'react-contenteditable';

const MiniPlayerSubtitle = ({
    styleRef,
    currentIndex,
    styleString,
    headingStyleString,
    fontName,
    headingTextContainerPosition,
    fontData,
    nearest,
    ...props
}) => {
    const state = useAppState();
    const actions = useActions();
    const subRef = useRef();

    const getStyleHeight = () => {
        return styleRef.current?.clientHeight;
    };

    const debouncedSave = useCallback(
        debounce((translateState, sub, nextValue) => {
            if (translateState) {
                props.updateTranslation(sub, {
                    text: nextValue.text,
                });
                saveTranslation(nextValue, props.user.signature);
                props.setActiveTranslationDetailString(nextValue);
            } else {
                updateSubtitle(nextValue);
                props.updateSub(sub, nextValue);
            }
            props.setIsSaving(false);
        }, 1000),
        [],
    );

    const hasSub = useCallback(
        (sub) => {
            let index = state.subtitle.indexOf(sub);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
                index = data.map((a) => a.id).indexOf(sub.id);
            }
            return index;
        },
        [state.subtitle],
    );

    const hasTranslation = useCallback(
        (sub) => {
            let index = state.translation.indexOf(sub);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.translation)))];
                index = data.map((a) => a.id).indexOf(sub.id);
            }
            return index;
        },
        [state.translation],
    );

    // video width untuk render
    const getVideoWidth = () => {
        let width = props.projectData.header.width;
        if (props.config.ratio == '16:9') {
            if (width < 1280) {
                return 1280;
            }
        } else if (props.config.ratio == '9:16') {
            width = props.projectData.header.height;
            if (width < 720) {
                return 720;
            }
        } else if (props.config.ratio == '4:5') {
            width = (width * 4) / 5;
            if (width < 576) {
                return 576;
            }
        } else if (props.config.ratio == '1:1') {
            if (width < 720) {
                return 720;
            }
        }
        return width;
    };

    const calculateFontSize = () => {
        try {
            let subFontSize = styleRef ? (styleRef.current.offsetWidth / getVideoWidth()) * props.config.fontSize
                : 0;
            if (!fontData.xHeight) {
                if (subFontSize > 10) return subFontSize;
                else return 10;
            }
            const arialCender = ARIAL_ASCENDER - ARIAL_DESCENDER;
            const destinationCender = fontData.ascender - fontData.descender;

            const res = (subFontSize * (fontData.xHeight / ARIAL_XHEIGHT)) / (destinationCender / arialCender);
            if (res > 10) return Math.floor(res);
            else return 10;
        } catch (e) {
            return 0;
        }
    };

    const getTextContainerPosition = () => {
        let obj = {};
        let str = ``;
        let height = getStyleHeight(); // - 8; // 16 ini 8 top 8 bottom
        if (nearest) {
            if (/^{\\an[4|5|6]}/.test(nearest.text)) {
                str += ` ${styles.center}`;
                obj.bottom = '50%';
            } else if (/^{\\an[1|2|3]}/.test(nearest.text)) {
                str += ` ${styles.bottom}`;
                if (props.config.position === 'Custom' && props.config.marginV > 50) {
                    obj.top = `${height - (height * props.config.marginV) / 100}px`;
                } else {
                    obj.bottom = `${height - (height * (100 - props.config.marginV)) / 100}px`;
                }
            } else if (/^{\\an[7|8|9]}/.test(nearest.text)) {
                str += ` ${styles.top}`;
                if (props.config.position === 'Custom' && props.config.marginV > 50) {
                    obj.bottom = `${height - (height * props.config.marginV) / 100}px`;
                } else {
                    obj.top = `${height - (height * (100 - props.config.marginV)) / 100}px`;
                }
            } else {
                if (props.config.position === 'Center') {
                    obj.bottom = '50%';
                    str += ` ${styles.center}`;
                } else if (props.config.position === 'Bottom') {
                    str += ` ${styles.bottom}`;
                    obj.bottom = `8px`;
                } else if (props.config.position === 'Top') {
                    str += ` ${styles.top}`;
                    obj.top = `8px`;
                } else if (props.config.position === 'Custom') {
                    str += ` ${styles.bottom}`;
                    if (props.config.marginV > 50) {
                        obj.top = `${height - (height * props.config.marginV) / 100}px`;
                    } else {
                        obj.bottom = `${height - (height * (100 - props.config.marginV)) / 100}px`;
                    }
                }
            }
        }

        if (props.config.align === 'center') {
            str += ` ${styles.alignCenter}`;
            obj.textAlign = 'center';
            obj.justifyContent = 'center';
        } else if (props.config.align === 'left') {
            str += ` ${styles.alignLeft}`;
            obj.textAlign = 'left';
            obj.justifyContent = 'flex-start';
            obj.transform = 'translateX(8px)';
        } else if (props.config.align === 'right') {
            str += ` ${styles.alignRight}`;
            obj.textAlign = 'right';
            obj.justifyContent = 'flex-end';
            obj.transform = 'translateX(-8px)';
        }
        return obj;
    };

    function getTextWidth(text, font, subtitle = false) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        const videoWidth = props.miniPlayer ? props.miniPlayer.offsetWidth : '800px';
        let maxWidth = videoWidth;
        if (text == null || text.length <= 0) return 0;
        let words = text.split(' ');
        let tempWord = [];
        let width = 0;
        let tempWidth = 0;
        for (const word of words) {
            tempWord.push(word);
            let line = tempWord.join(' ');
            let lineWidth = context.measureText(line).width;
            if (lineWidth < maxWidth) {
                tempWidth = lineWidth;
            } else {
                if (width < tempWidth) {
                    width = tempWidth;
                }
                tempWord = [];
            }
            if (width < lineWidth) {
                width = lineWidth;
            }
        }
        return width + 20;
    }

    function countUntilEndContainer(parent, endNode, offset, countingState = { count: 0 }) {
        for (let node of parent.childNodes) {
            if (countingState.done) break;
            if (node === endNode) {
                countingState.done = true;
                countingState.offsetInNode = offset;
                return countingState;
            }
            if (node.nodeType === Node.TEXT_NODE) {
                countingState.offsetInNode = offset;
                countingState.count += node.length;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                countUntilEndContainer(node, endNode, offset, countingState);
            } else {
                countingState.error = true;
            }
        }
        return countingState;
    }

    function getCaretPosition(editor) {
        // let editor = document.getElementById('editor');
        let sel = window.getSelection();
        if (sel.rangeCount === 0) {
            return null;
        }
        let range = sel.getRangeAt(0);
        let start = countUntilEndContainer(editor, range.startContainer, range.startOffset);
        let end = countUntilEndContainer(editor, range.endContainer, range.endOffset);
        let offsetsCounts = { start: start.count + start.offsetInNode, end: end.count + end.offsetInNode };
        let offsets = { start: start, end: end, offsets: offsetsCounts };
        return offsets;
    }

    function countUntilOffset(parent, offset, countingState = { count: 0 }) {
        for (let node of parent.childNodes) {
            if (countingState.done) break;
            if (node.nodeType === Node.TEXT_NODE) {
                if (countingState.count <= offset && offset < countingState.count + node.length) {
                    countingState.offsetInNode = offset - countingState.count;
                    countingState.node = node;
                    countingState.done = true;
                    return countingState;
                } else {
                    countingState.count += node.length;
                }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                countUntilOffset(node, offset, countingState);
            } else {
                countingState.error = true;
            }
        }
        return countingState;
    }

    function setCaretPosition(editor, start, end) {
        try {
            let sel = window.getSelection();
            if (sel.rangeCount === 0) {
                return null;
            }
            let range = sel.getRangeAt(0);
            let startNode = countUntilOffset(editor, start);
            let endNode = countUntilOffset(editor, end);
            let newRange = new Range();
            newRange.setStart(startNode.node, startNode.offsetInNode);
            newRange.setEnd(endNode.node, endNode.offsetInNode);
            sel.removeAllRanges();
            sel.addRange(newRange);
        } catch (e) {}
        return true;
    }

    const updateText = async (text) => {
        props.setIsSaving(true);
        let index = -1;
        let newSub = [];
        if (props.translateState) {
            index = hasTranslation(state.miniCurrentSub);
            if (index < 0) return;
            newSub = [...JSON.parse(JSON.stringify(json(state.translation)))];
        } else {
            index = hasSub(state.miniCurrentSub);
            if (index < 0) return;
            newSub = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
        }
        newSub[index].text = text;
        debouncedSave(props.translateState, state.miniCurrentSub, newSub[index]);
        actions.setMiniCurrentSub(newSub[index]);
    };

    const onClick = useCallback(
        (event) => {
            event.stopPropagation();
            props.setPlaying(false);
        },
        [props.miniPlayer],
    );

    const onFocus = useCallback((event) => {
        props.setPlaying(false);
    }, []);

    const renderSubtitle = () => {
        if(!props.config.box_highlight){
            return state.miniCurrentSub.text;
        }
        try {
            const startSeconds = state.miniCurrentSub.startTime;
            const endSeconds = state.miniCurrentSub.endTime;
            const currentSeconds = state.miniCurrentTime;
            const wps = 1;
            const words = removePositionTag(state.miniCurrentSub.text).split(' ');

            // Calculate the duration each word should be displayed
            const durationPerWord = (endSeconds - startSeconds) / words.length;

            // Find the index of the word that should be displayed at currentTime
            const currentIndex = Math.floor((currentSeconds - startSeconds) / durationPerWord);

            // Check if the currentTime is within the subtitle's start and end time
            if (currentSeconds >= startSeconds && currentSeconds <= endSeconds) {
                words[currentIndex] = `<span style="background:${props.config.box_highlight_color};padding:0 2px;margin:0 -2px">${words[currentIndex]}</span>`;
            }
            return words.join(' ');
        } catch (e) {
            return state.miniCurrentSub.text;
        }

    };

    return (
        <>
            <div className={`subtitle`} style={{ ...getTextContainerPosition(), flexFlow: 'wrap' }}>
                <div>
                    <div
                        className="subtitle-number"
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        <span>#{currentIndex + 1}</span>
                        {/* <span></span> */}
                    </div>
                    <hr
                        style={{
                            width: '100%',
                            border: 'none',
                        }}
                    />
                    <ContentEditable
                        ref={subRef}
                        className={`textarea ${styleString} ${
                            state.miniCurrentSub.text == null || state.miniCurrentSub.text == '' ? 'hidden' : ''
                        }`}
                        style={{
                            color: props.config.fontColor,
                            direction: props.translateState
                                ? props.currentLanguageObject && props.currentLanguageObject.rtl == 1
                                    ? 'rtl'
                                    : 'ltr'
                                : props.projectData?.file.language.rtl === 1
                                ? 'rtl'
                                : 'ltr',
                            font: `${calculateFontSize()}px ${fontName}`,
                            width: `auto`,
                            padding: '5px',
                            backgroundColor:
                                props.config.effect === 'Background' ? props.config.backgroundColor : 'rgba(0,0,0,0)',
                            borderColor:
                                props.config.effect === 'Background' ? props.config.backgroundColor : 'rgba(0,0,0,0)',
                            textShadow:
                                props.config.effect === 'Outline'
                                    ? `-2px -2px 0 ${props.config.backgroundColor}, 2px -2px 0 ${props.config.backgroundColor}, -2px 2px 0 ${props.config.backgroundColor}, 2px 2px 0 ${props.config.backgroundColor}`
                                    : props.config.effect === 'Drop Shadow'
                                    ? `5pt 5pt ${props.config.backgroundColor}`
                                    : '',
                            // visibility: show ? 'visible' : 'hidden',
                        }}
                        disabled={
                            (props.access &&
                                props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                                props.access.accessMapping.role.toLowerCase() !== 'editor') ||
                            state.voiceOverAudio !== null ||
                            state.voiceOverLoading
                                ? true
                                : false
                        }
                        id="test"
                        onChange={(event) => {
                            let position = getCaretPosition(subRef.current.el.current);
                            updateText(event.target.value).then(() =>
                                setCaretPosition(
                                    subRef.current.el.current,
                                    position.offsets.start,
                                    position.offsets.start,
                                ),
                            );
                        }}
                        onClick={onClick}
                        onFocus={onFocus}
                        onKeyDown={onFocus}
                        spellCheck={false}
                        value={state.miniCurrentSub.text}
                        html={renderSubtitle()}
                        // tagName="input"
                    />
                </div>
            </div>
            
        </>
    );
};

export default MiniPlayerSubtitle;
