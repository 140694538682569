import React from 'react';

const DeleteModal = ({ setOpenDeleteModal, action, setSelected, selected }) => {
    return (
        <div id="" className="box-scribe-menu p-5 rounded-3pxpointer">
            <button id="" className="btn-close-right-clicked">
                <i data-feather="x" className="w-4 h-4 text-white"></i>
            </button>
            <div className="flex justify-center items-center">
                <button
                    onClick={() => {
                        action(selected);
                        setSelected(null);
                        setOpenDeleteModal(false);
                    }}
                    className="btn btn-outline-white text-white rounded-3px mr-5"
                >
                    Delete
                </button>
                <button
                    onClick={() => {
                        setOpenDeleteModal(false);
                    }}
                    className="btn btn-outline-white text-white rounded-3px"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default DeleteModal;
